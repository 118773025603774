<template>
  <Modal>
    <template #body>
      <my-input
        class="flex flex-col items-start mt-3"
        type="text"
        :initValue="uid"
        v-model="uid"
        label="UID"
        :required="validateRule.uid.required"
        :errorMessage="validateRule.uid.errorMessage"
      />
      <my-input
        class="flex flex-col items-start mt-3"
        type="text"
        :initValue="cause"
        v-model="cause"
        label="原因"
        :required="validateRule.cause.required"
        :errorMessage="validateRule.cause.errorMessage"
      />
    </template>
    <template v-slot:footer="{ closeModal }">
      <button
        class="mx-1 btn btn-primary py-3 px-4 w-full align-top"
        @click="openConfirm"
      >
        禁言
      </button>
      <button
        class="mx-1 btn btn-secondary py-3 px-4 w-full align-top"
        @click="closeModal"
      >
        取消
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { helper as $h } from '@/utils/helper'
import { defineComponent, ref } from 'vue'
import { useModal } from '@/plugins/modals/index'

import MyInput from '@/global-components/input/Main.vue'
import Modal from './template.vue'

export default defineComponent({
  components: {
    Modal,
    MyInput
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const uid = ref('')
    const cause = ref('')
    const validateRule = {
      uid: {
        required: true,
        errorMessage: '請填寫 UID'
      },
      cause: {
        required: true,
        errorMessage: '請填寫原因'
      }
    }

    const openConfirm = () => {
      // 防呆
      const data = {
        uid: uid.value,
        cause: cause.value
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        const { openModal } = useModal()
        openModal('alertModal', {
          icon: 'warning',
          message: errorMessage
        })
        return
      }

      props.confirmFunction(data)
    }

    return {
      uid,
      cause,
      validateRule,
      openConfirm
    }
  }
})
</script>
<style lang="scss" scoped></style>
