
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import Google from '@/components/google-login/Main.vue'
import { loginWithGoogle } from '@/store/user'
import { GoogleUser } from '@/types/index'

export default defineComponent({
  name: 'Login',
  components: {
    Google
  },
  setup() {
    const loginGoogle = async (googleUser: any) => {
      try {
        const user = new GoogleUser.GUser(googleUser)
        await loginWithGoogle(user)
        router.push({ path: '/' })
      } catch (e) {
        console.error(e)
      }
    }
    const showModal = ref<boolean>(false)
    const account = ref<string>()
    const password = ref<string>()
    const router = useRouter()

    const closeModal = (value: boolean) => {
      showModal.value = value
    }
    return {
      showModal,
      closeModal,
      account,
      password,
      loginGoogle
    }
  }
})
