import { defineComponent, toRefs, watch, ref } from 'vue'
import { FAKE_AVATAR } from '@/utils/index'

export default defineComponent({
  name: 'EmptyRoom',
  props: {
    displayName: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { displayName, photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)
    watch(photoURL, () => {
      const img = new Image()
      img.src = photoURL.value
      img.onerror = function () {
        newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
      }
      img.onload = function () {
        newUrl.value = photoURL.value
      }
    })
    return () => {
      return <div class="box h-full flex items-center py-3">
        <div class="mx-auto text-center">
          <div class="w-16 h-16 flex-none image-fit rounded-full overflow-hidden mx-auto">
            <img alt="avatar" src={ newUrl.value }></img>
          </div>
          <div class="mt-3">
            <div class="font-medium">請選擇 { displayName.value } 的聊天室</div>
          </div>
        </div>
      </div>
    }
  }
})
