// import Chart from './chart/Main.vue'
// import GoogleMapLoader from './google-map-loader/Main.vue'
// import Highlight from './highlight/Main.vue'
import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
// import TippyContent from './tippy-content/Main.vue'
// import TailSelect from './tail-select/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import MyInput from './input/Main.vue'
import MySelect from './select/Main.vue'

// import TinySlider from './tiny-slider/Main.vue'
// import CKEditor from './ckeditor/Main.vue'
// import Dropzone from './dropzone/Main.vue'
// import FullCalendar from './calendar/Main.vue'
// import FullCalendarDraggable from './calendar/Draggable.vue'

export default app => {
  // app.component('Chart', Chart)
  // app.component('GoogleMapLoader', GoogleMapLoader)
  // app.component('Highlight', Highlight)
  app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  // app.component('TippyContent', TippyContent)
  // app.component('TailSelect', TailSelect)
  app.component('LoadingIcon', LoadingIcon)
  app.component('MyInput', MyInput)
  app.component('MySelect', MySelect)
  // app.component('TinySlider', TinySlider)
  // app.component('Dropzone', Dropzone)
  // app.component('CKEditor', CKEditor)
  // app.component('FullCalendar', FullCalendar)
  // app.component('FullCalendarDraggable', FullCalendarDraggable)
}
