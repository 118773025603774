import * as grpcLib from '@/libs/grpc'
import * as adminEffectPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/effect_pb'
import { AdminEffectPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/effect_grpc_web_pb'
import { EffectType, MaterialType } from '@/types/'
const EffectService = new AdminEffectPromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MEDAL))
grpcLib.EnableGrpcDebugTools(EffectService)

export const getInfo = async(token: string, materialId: Array<string>):Promise<adminEffectPb.GetInfoEffectRes> => {
  const req: adminEffectPb.GetInfoEffectReq = new adminEffectPb.GetInfoEffectReq()
  req.setAdminToken(token)
  req.setMaterialIdList(materialId)
  const res: adminEffectPb.GetInfoEffectRes = await EffectService.getInfo(req)
  return grpcLib.resolveData(res)
}

export const create = async(token: string, { materialId, effectType, minKind, maxKind, chest }: {
  materialId: string
  effectType: EffectType.EffectType
  minKind: number
  maxKind: number
  chest: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>
}): Promise<adminEffectPb.CreateEffectRes> => {
  const req: adminEffectPb.CreateEffectReq = new adminEffectPb.CreateEffectReq()
  req.setAdminToken(token)
  const effectInfo = new adminEffectPb.EffectInfo()
  const chestList = chest.map((val) => createChest(materialId, val))
  effectInfo.setMaterialId(materialId)
  effectInfo.setEffectType(EffectType.toGrpcEffectType[effectType])
  effectInfo.setMinKind(minKind)
  effectInfo.setMaxKind(maxKind)
  effectInfo.setChestList(chestList)
  req.setEffectInfo(effectInfo)
  const res = await EffectService.create(req)
  return grpcLib.resolveData(res)
}

export const update = async(token: string, { materialId, effectType, minKind, maxKind, chest }: {
  materialId: string
  effectType: EffectType.EffectType
  minKind: number
  maxKind: number
  chest: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>
}): Promise<adminEffectPb.UpdateEffectRes> => {
  const req: adminEffectPb.UpdateEffectReq = new adminEffectPb.UpdateEffectReq()
  req.setAdminToken(token)
  const effectInfo = new adminEffectPb.EffectInfo()
  const chestList = chest.map((val) => createChest(materialId, val))
  effectInfo.setMaterialId(materialId)
  effectInfo.setEffectType(EffectType.toGrpcEffectType[effectType])
  effectInfo.setMinKind(minKind)
  effectInfo.setMaxKind(maxKind)
  effectInfo.setChestList(chestList)
  req.setEffectInfo(effectInfo)
  const res = await EffectService.update(req)
  return grpcLib.resolveData(res)
}

export const deleteEffect = async(token: string, materialId: string): Promise<adminEffectPb.DeleteEffectRes> => {
  const req: adminEffectPb.DeleteEffectReq = new adminEffectPb.DeleteEffectReq()
  req.setAdminToken(token)
  req.setMaterialId(materialId)
  const res = EffectService.delete(req)
  return grpcLib.resolveData(res)
}

const createChest = (materialId: string, chest: EffectType.Chest<MaterialType.Material | MaterialType.Credit>):adminEffectPb.Chest => {
  const result = new adminEffectPb.Chest()
  result.setEffectId(materialId)
  result.setId(chest.info.id)
  result.setMinCount(chest.minCount)
  result.setMaxCount(chest.maxCount)
  result.setRate(chest.rate)
  result.setChestType(EffectType.toGrpcChestType[chest.chestType])
  return result
}
