import { computed, reactive } from 'vue'
import { MedalType } from '@/types/index'
import { MedalService } from '@/services/'
// key: uuid, value: material
export const medalMap = reactive<Map<string, MedalType.Medal>>(new Map())

export const setMedalMap = (id: string, value: MedalType.Medal) => {
  medalMap.set(id, value)
}
export const updateMedalMap = (id: string, value: Partial<MedalType.Medal>) => {
  const obj = medalMap.get(id)
  if (!obj) throw new Error('not has this medal')
  medalMap.set(id, Object.assign(obj, { ...value }))
}

export const deleteMedalMap = (id: string) => {
  medalMap.delete(id)
}

export const getMedal = async (ids: Array<string>): Promise<Map<string, MedalType.Medal>> => {
  // 去除重複的 id
  ids = [...new Set([...ids])]
  const notHasMedal: Array<string> = []
  const result = new Map(
    ids.filter((id) => {
      if (!medalMap.has(id)) notHasMedal.push(id)
      return medalMap.has(id)
    }).map(id => [id, medalMap.get(id)])
  ) as Map<string, MedalType.Medal>
  // 有缺資料
  if (notHasMedal.length > 0) {
    await getAndSetMedal(notHasMedal)
    return getMedal(ids)
  }
  return result
}

export const getAndSetMedal = async(ids: Array<string>): Promise<void> => {
  const result = await MedalService.getInfo(ids)
  result.forEach(val => {
    if (!medalMap.has(val.id)) setMedalMap(val.id, val)
  })
}

export const emptyMedal = computed(() => {
  return {
    id: '',
    displayName: '',
    description: '',
    img: '',
    property: {
      furnaceLuck: 0,
      craftLuck: 0,
      enhanceLuck: 0
    }
  }
})
