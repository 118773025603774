import { render } from "./addRole.vue?vue&type=template&id=e1fbcc14"
import script from "./addRole.vue?vue&type=script&lang=ts"
export * from "./addRole.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e1fbcc14"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e1fbcc14', script)) {
    api.reload('e1fbcc14', script)
  }
  
  module.hot.accept("./addRole.vue?vue&type=template&id=e1fbcc14", () => {
    api.rerender('e1fbcc14', render)
  })

}

script.__file = "src/plugins/modals/addRole.vue"

export default script