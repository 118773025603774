<template>
  <Modal>
    <template #body>
      <div class="input-form">
        <label for="edit-domain-id" class="form-label w-full flex flex-col sm:flex-row">
          Domain ID
          <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">必填，至少 2 字元</span>
        </label>
        <input
          id="edit-domain-id"
          v-model.trim="validate.domainID.$model"
          type="text"
          name="name"
          class="form-control"
          :class="{ 'border-theme-6': validate.domainID.$error }"
          placeholder="jkforum"
          :disabled="mode === 'edit'"
        />
        <template v-if="validate.domainID.$error">
          <div
            v-for="(error, index) in validate.domainID.$errors"
            :key="index"
            class="text-theme-6 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div class="input-form mt-3">
        <label for="edit-domain-displayname" class="form-label w-full flex flex-col sm:flex-row">
          顯示名稱
          <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">必填，至少 2 字元</span>
        </label>
        <input
          id="edit-domain-displayname"
          v-model.trim="validate.domainDisplayName.$model"
          type="text"
          name="name"
          class="form-control"
          :class="{ 'border-theme-6': validate.domainDisplayName.$error }"
          placeholder="JKF 捷克論壇"
        />
        <template v-if="validate.domainDisplayName.$error">
          <div
            v-for="(error, index) in validate.domainDisplayName.$errors"
            :key="index"
            class="text-theme-6 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button class="mx-1 btn btn-primary py-3 px-4 w-full align-top" @click="submit(closeModal)">
        送出
      </button>
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">
        取消
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import Modal from './template.vue'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { GuardianAPI } from '@/api/index'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    domainID: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<'create' | 'edit'>,
      required: true
    },
    done: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { openModal } = useModal()
    const formData = reactive({
      domainID: props.domainID,
      domainDisplayName: props.displayName
    })
    const rules = {
      domainID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      domainDisplayName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))

    const submit = (closeModal: Function) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        const errorMessage = '請修正資料格式'
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return
      }
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const domain = new GuardianQueryPB.Domain()
      domain.setDomainId(formData.domainID)
      domain.setDisplayName(formData.domainDisplayName)
      if (props.mode == 'create') {
        GuardianAPI.CreateDomain(token, domain).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
      } else if (props.mode == 'edit') {
        GuardianAPI.UpdateDomain(token, domain).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
      }
    }

    onMounted(() => {
    })

    return {
      formData,
      validate,
      submit
    }
  }
})
</script>

<style scoped>

</style>
