<template>
  <div class="border-2 border-dashed shadow-sm border-gray-200 dark:border-dark-5 rounded-md p-5">
    <div
      class="h-40 relative image-fit flex cursor-pointer mx-auto "
      :class="{'animate-pulse bg-gray-300': state == 'loading'}"
    >
      <img
        class="img"
        :src="localImage"
      />
    </div>
    <div class="mx-auto cursor-pointer relative mt-5">
      <button type="button" class="btn btn-primary w-full loading">
        <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" v-if="state == 'uploading'" />
        {{ buttonText }}
      </button>
      <input
        type="file"
        class="w-full h-full top-0 left-0 absolute opacity-0"
        :accept="acceptType"
        @change="uploadImage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { tryOnBeforeUnmount } from '@vueuse/core'
import { defineComponent, toRef, ref, watch, computed, PropType, unref } from 'vue'
import { useModal } from '@/plugins/modals/'
// @ts-ignore
import mime from 'mime-types'
import { MaterialService, MedalService } from '@/services/'
import axios from '@/api/axios'
export default defineComponent({
  name: 'UploadImg',
  props: {
    image: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String],
      default: ''
    },
    buttonTitle: {
      type: String,
      default: '更換圖片'
    },
    type: {
      type: String as PropType<'material'|'medal'>,
      default: 'material'
    },
    acceptType: {
      type: String,
      default: 'image/png'
    },
    limitWidth: {
      type: Number,
      required: true
    },
    limitHeight: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const IMAGE_WIDTH = unref(props.limitWidth)
    const IMAGE_HEIGHT = unref(props.limitHeight)
    const state = ref<'loading' | 'uploading' | 'done'>('loading')
    const image = toRef(props, 'image')
    const localImage = ref('')
    const { openModal } = useModal()
    const buttonText = computed(() => {
      let text: string = ''
      if (state.value == 'loading') text = props.buttonTitle
      if (state.value == 'done') text = props.buttonTitle
      return text
    })

    const prepareUploadImage = computed(() => {
      return props.type === 'material' ? MaterialService.prepareUploadImage : MedalService.prepareUploadImage
    })

    const unwatch = watch(image, (newValue) => {
      if (newValue != '') {
        localImage.value = image.value
        state.value = 'done'
        emit('update:modelValue', localImage.value)
      }
    })

    tryOnBeforeUnmount(() => {
      unwatch()
    })

    /*
    先不限制size
    const fileSizeOver = (size: number, max: number) => {
      return (size / 1024 / 1024) > max
    }
    */

    const checkImageWidthAndHeight = async({ file, width, height }: { file: string, width:number, height:number }):Promise<boolean> => {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = file
        image.onload = function () {
          // @ts-ignore
          const imgWidth = this.width
          // @ts-ignore
          const imgHeight = this.height
          // 不符合限制的寬高
          if (imgWidth > width || imgHeight > height) resolve(false)
          resolve(true)
        }
        image.onerror = function () {
          reject(new Error('出錯了'))
        }
      })
    }

    const uploadImage = async($e: Event) => {
      state.value = 'uploading'
      const file = ($e.target as HTMLInputElement).files?.[0] as File
      const uploadImageFn = unref(prepareUploadImage)
      // 沒檔案的話要退出
      if (!file) {
        state.value = 'done'
        return false
      }
      try {
        const isPassLimit = await checkImageWidthAndHeight({
          file: (window.URL || window.webkitURL).createObjectURL(file),
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT
        })
        if (!isPassLimit) {
          openModal('alertModal', { icon: 'warning', message: `請上傳符合限制的圖片(${IMAGE_WIDTH}px * ${IMAGE_HEIGHT}px)` })
          state.value = 'done'
          return false
        }

        // upload image to gcs
        const fileName = file.name
        const mimeType = mime.lookup(fileName)
        const { signedPostPolicyMap, urlToUpload } = await uploadImageFn(mimeType)
        const fd = new FormData()
        const signMap = new Map(signedPostPolicyMap)
        signMap.forEach((value, key) => {
          fd.append(key, value)
        })
        fd.append('file', file)
        // filename
        const imageGCSurl: string = signMap.get('key') || ''
        await axios.post(urlToUpload, fd, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        // full file path
        localImage.value = urlToUpload + imageGCSurl
        emit('update:modelValue', localImage.value)
      } catch (e) {
        console.error(e)
        openModal('alertModal', { icon: 'warning', message: e.message })
      } finally {
        state.value = 'done'
      }
    }
    return {
      state,
      localImage,
      uploadImage,
      buttonText
    }
  }
})
</script>

<style scoped>
.img {
  object-fit: cover;
  margin: auto;
  max-height: 160px;
}
</style>
