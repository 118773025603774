
import { defineComponent, nextTick, ref } from 'vue'
import dayjs, * as dayFn from '@/libs/dayjs'
import { MessageAll } from '@/services/'
import { getHashIdByUid } from '@/store/users'
import DataTable from '@/components/data-table/Main.vue'
import Pagination from '@/components/data-table/Pagination.vue'
import messageLog from '@/composition/message'
import { TableColumns } from '@/types/index'
import { useLoading } from '@/plugins/loading/'
import SvgIcon from '@/components/svg-icons/Main.vue'

type dataType = {
  roomId: string
  id: string
  sysf1: boolean
  sysf2: boolean
  uid: number
  sender: string
  receiver: string
  text: string
  time: number
  imageAll: Array<string>
}

export default defineComponent({
  directives: {
    formatTime: {
      mounted(el, binding) {
        el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
      },
      updated(el, binding) {
        if (binding.value) el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
      }
    }
  },
  components: {
    DataTable,
    Pagination,
    SvgIcon
  },
  setup() {
    const maxDayRange = dayjs(dayjs().subtract(1, 'day')).format('DD MMM, YYYY')
    const daterange = ref(`${maxDayRange} - ${maxDayRange}`)
    const uid = ref<string>('')
    const isMark = ref(false)
    const isRead = ref(false)
    const idName = ref('fullscreen-message-log')
    const page = ref(1)
    const messageArray = ref<Array<dataType>>([])
    const pageSize = ref(10)
    const { startLoading } = useLoading()
    const columns = TableColumns.MESSAGE_LOG

    const {
      litePickerOption,
      replaceImgUrl,
      checkSysf1,
      imageUrlPrefix,
      combineAllTableData
    } = messageLog()

    const search = async () => {
      const load = startLoading()
      try {
        const { startDate, endDate } = dayFn.formatDateRange(daterange.value)
        const id = uid.value
        // 防呆
        if (id !== '' && (isNaN(Number(id)) || parseInt(id) <= 0)) alert('無效的UID')
        const hashUid: string = id == '' ? '' : await getHashIdByUid(parseInt(id))

        const firebaseData = await MessageAll.getMessageByClickSearch({
          startDate,
          endDate,
          isMark: isMark.value,
          isRead: isRead.value,
          limit: pageSize.value,
          initLastData: true,
          hashUid
        })
        messageArray.value = await combineAllTableData(firebaseData)
        // reset page
        page.value = 1
      } catch (e) {
        console.error(e)
        if (e == 'not found') alert('無效的UID')
      } finally {
        load.close()
      }
    }

    const toggleSysf1 = (index: number, roomId: string, docId: string, value: boolean) => {
      messageArray.value[index].sysf1 = value
      messageArray.value[index].sysf2 = true
      MessageAll.updateTempMessageItemSysf1(roomId, docId, value)
    }

    const toggleSysf2 = (index: number, roomId: string, docId: string) => {
      const value = messageArray.value[index].sysf2
      MessageAll.updateSysf2(roomId, docId, value)
    }

    const toggleAllSysf2 = () => {
      // firebase 似乎沒有update 多筆 documentId 的功能?
      for (let i = 0; i < messageArray.value.length; i++) {
        const data = messageArray.value[i]
        // 原本 true 就不更新
        if (!data.sysf2) {
          data.sysf2 = true
          MessageAll.updateSysf2(data.roomId, data.id, true)
        }
      }
      nextTick()
    }

    const getMessageByPage = async (pages: number, isPrev: boolean) => {
      // 簡單防呆
      if (messageArray.value.length < 10 && !isPrev) return
      page.value = pages
      const load = startLoading()
      try {
        const data = await MessageAll.getMessageByPage(pages, pageSize.value, isPrev)
        messageArray.value = await combineAllTableData(data)
      } catch (e) {
        console.error(e)
      } finally {
        load.close()
      }
    }

    // @ts-ignore pass value by element UI
    const getSortData = async ({ prop, order }) => {
      if (prop !== null) {
        const load = startLoading()
        const shortOrder = order === 'ascending' ? 'asc' : 'desc'
        // reset page
        page.value = 1
        try {
          const firebaseData = await MessageAll.getMessageBySort(shortOrder, pageSize.value)
          messageArray.value = await combineAllTableData(firebaseData)
        } catch (e) {
          console.error(e)
        } finally {
          load.close()
        }
      }
    }

    return {
      daterange,
      search,
      uid,
      isMark,
      isRead,
      page,
      litePickerOption,
      messageArray,
      columns,
      imageUrlPrefix,
      replaceImgUrl,
      checkSysf1,
      toggleSysf1,
      toggleSysf2,
      pageSize,
      getMessageByPage,
      getSortData,
      toggleAllSysf2,
      idName
    }
  }
})
