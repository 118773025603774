import { render } from "./alertModal.vue?vue&type=template&id=02018142"
import script from "./alertModal.vue?vue&type=script&lang=ts"
export * from "./alertModal.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "02018142"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('02018142', script)) {
    api.reload('02018142', script)
  }
  
  module.hot.accept("./alertModal.vue?vue&type=template&id=02018142", () => {
    api.rerender('02018142', render)
  })

}

script.__file = "src/plugins/modals/alertModal.vue"

export default script