<template>
  <suspense>
    <template #default>
      <div>
        <MobileMenu />
        <div class="flex">
          <!-- BEGIN: Side Menu -->
          <nav class="side-nav">
            <!-- BEGIN: Logo -->
             <router-link
              :to="{ path: '/' }"
              tag="a"
              class="intro-x flex items-center pl-5 pt-4"
            >
              <img
                alt="JKF"
                class="w-10/12"
                src="https://resource.jkf.net/jkface/jkface-logo.png"
                v-once
              />
            </router-link>
            <!-- END: Logo -->
            <div class="side-nav__devider my-6"></div>
            <ul>
              <!-- BEGIN: First Child -->
              <template v-for="(menu, menuKey) in formattedMenu">
                <li
                  v-if="menu == 'devider'"
                  :key="menu + menuKey"
                  class="side-nav__devider my-6"
                ></li>
                <li v-else :key="menu + menuKey">
                  <a
                    class="side-menu cursor-pointer"
                    :class="{
                      'side-menu--active': menu.active,
                      'side-menu--open': menu.activeDropdown
                    }"
                    @click="linkTo(menu, router, $event)"
                  >
                    <div class="side-menu__icon">
                      <!-- <component :is="menu.icon" /> -->
                      <svg-icon :name="menu.icon" />
                    </div>
                    <div class="side-menu__title">
                      {{ menu.title }}
                      <div
                        v-if="menu.subMenu"
                        class="side-menu__sub-icon"
                        :class="{ 'transform rotate-180': menu.activeDropdown }"
                      >
                        <svg-icon name="mdiChevronDown" />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Second Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="menu.subMenu && menu.activeDropdown">
                      <li
                        v-for="(subMenu, subMenuKey) in menu.subMenu"
                        :key="subMenuKey"
                      >
                        <SideMenuTooltip
                          tag="a"
                          :content="subMenu.title"
                          class="side-menu cursor-pointer"
                          :class="{ 'side-menu--active': subMenu.active }"
                          @click="linkTo(subMenu, router, $event)"
                        >
                          <div class="side-menu__icon">
                            <svg-icon :name="subMenu.icon" />
                          </div>
                          <div class="side-menu__title">
                            {{ subMenu.title }}
                          </div>
                        </SideMenuTooltip>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Second Child -->
                </li>
              </template>
            </ul>
          </nav>
          <!-- BEGIN: Content -->
          <div class="content">
            <TopBar />
            <router-view v-slot="{ Component, route }">
              <h1 v-if="SHOW_DEV_TITLE === 'true'" class="text-3xl font-bold text-center text-red-600">測試站</h1>
              <fullscreen :idName="FULLSCREEN_DOM_STRING">
                <div class="flex justify-center my-5">
                  <h2 class="text-3xl font-bold text-center">{{ route.meta?.title || '' }}</h2>
                </div>
                <component
                  :is="Component"
                />
              </fullscreen>
            </router-view>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      loading
    </template>
  </suspense>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import { linkTo, nestedMenu, enter, leave } from './index'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import Fullscreen from '@/components/fullscreen/fullscreen'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { FULLSCREEN_DOM_STRING } from '@/utils/provideInject'

export default defineComponent({
  name: 'Menu',
  components: {
    TopBar,
    MobileMenu,
    SideMenuTooltip,
    Fullscreen,
    SvgIcon
  },
  setup() {
    const SHOW_DEV_TITLE = process.env.VUE_APP_DEVELOPMENT ? process.env.VUE_APP_DEVELOPMENT : false
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      FULLSCREEN_DOM_STRING,
      SHOW_DEV_TITLE
    }
  }
})
</script>
