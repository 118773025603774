<template>
  <div class="box w-1/2">
    <div class="flex flex-col lg:flex-row items-center p-5">
      <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
        <img
          alt="JKF"
          class="rounded-full"
          :src="newUrl"
        />
      </div>
      <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
        <p class="font-medium">
          {{ displayName }}
        </p>
        <div class="text-gray-600 text-xs mt-0.5">
          {{ uid }}
        </div>
      </div>
      <div class="flex mt-4 lg:mt-0">
        <button class="btn btn-primary py-1 px-2 mr-2" @click="toUserProfile(uid)">查看個人資訊</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, ref, watch, onBeforeUnmount } from 'vue'
import { FAKE_AVATAR } from '@/utils/index'
import useRouter from '@/router/useRouter'

export default defineComponent({
  props: {
    displayName: {
      type: String,
      required: true
    },
    uid: {
      type: String,
      required: true
    },
    hashUid: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)
    const { toUserProfile } = useRouter()

    const unwatch = watch(photoURL, () => {
      const img = new Image()
      img.src = photoURL.value
      img.onerror = function () {
        newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
      }
      img.onload = function () {
        newUrl.value = photoURL.value
      }
    })

    onBeforeUnmount(() => {
      unwatch()
    })

    return {
      toUserProfile,
      newUrl
    }
  }
})
</script>

<style scoped>

</style>
