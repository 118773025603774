<template>
  <div>
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start mb-5">
      <form class="sm:flex justify-start w-full relative">
        <div class="flex items-center sm:mr-4 mt-2 xl:mt-0">
          <input
            type="tel"
            class="form-control md:w-40 xxl:w-full"
            v-model="uid"
            placeholder="UID"
          />
        </div>
        <div class="flex items-center sm:mr-4 mt-2 xl:mt-0">
          <select class="form-control md:w-40 xxl:w-full" v-model="status">
            <option value="1">所有紀錄</option>
            <option value="2">使用紀錄</option>
            <option value="3">購買紀錄</option>
            <option value="4">獲贈紀錄</option>
            <option value="5">合成紀錄</option>
            <!-- <option value="send">贈送紀錄</option> -->
          </select>
        </div>
        <div class="mt-2 xl:mt-0">
          <button
            type="button"
            class="btn btn-primary w-full md:w-16"
            @click="search"
          >
            搜尋
          </button>
        </div>
      </form>
    </div>
    <div>
    </div>
    <data-table
      :data="data"
      :columns="columns"
      :page="page"
    >
    <template #type="{ row }">
      {{ transformType(row.type) }}
    </template>
    <template #created="{ row }">
        <span v-formatTime="row.created"></span>
      </template>
      <template #pagination="{ page }">
        <pagination
          v-if="data.length > 0"
          class="mt-3"
          :page="page"
          :totalPage="totalPage"
          @changePage="getDataByPage"
        />
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import dayjs from '@/libs/dayjs'
import DataTable from '@/components/data-table/Main.vue'
import Pagination from '@/components/data-table/Pagination.vue'
import { MaterialService } from '@/services'
import { LogType } from '@/types/'
import { useLoading } from '@/plugins/loading/'

export default defineComponent({
  directives: {
    formatTime: {
      mounted(el, binding) {
        el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
      },
      updated(el, binding) {
        if (binding.value) el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
      }
    }
  },
  name: 'ItemLog',
  components: {
    DataTable,
    Pagination
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10)
    const totalPage = ref<number>(0)
    const data = ref<Array<LogType.LogItem>>([])
    const status = ref<'1'|'2'|'3'|'4'|'5'>('1')
    const { startLoading } = useLoading()
    const uid = ref('')
    const columns = [
      {
        prop: 'type',
        label: '狀態'
      },
      {
        prop: 'text',
        label: '名稱'
      },
      {
        prop: 'created',
        label: '時間'
      },
      {
        prop: 'note',
        label: '備註'
      }
    ]

    const search = async () => {
      const load = startLoading()
      try {
        const result = await MaterialService.getItemlog({
          uid: uid.value,
          page: page.value,
          pageSize: pageSize.value,
          type: LogType.GrpcToLogType[status.value]
        })
        // reset page
        page.value = 1
        data.value = result.data
        totalPage.value = result.totalPage
      } catch (e) {
      } finally {
        load.close()
      }
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MaterialService.getItemlog({
        uid: uid.value,
        page: page.value,
        pageSize: pageSize.value,
        type: LogType.GrpcToLogType[status.value]
      })
      data.value = result.data
      totalPage.value = result.totalPage
    }

    const transformType = (type: LogType.LogType) => {
      return LogType.transformType[type]
    }

    return {
      columns,
      page,
      status,
      data,
      uid,
      search,
      totalPage,
      getDataByPage,
      transformType
    }
  }
})
</script>

<style scoped>

</style>
