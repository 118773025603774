import { MaterialAPI } from '@/api/index'
import { MaterialType, LogType } from '@/types/index'
import { materialMap, setMaterialMap } from '@/store/material'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import { loginedStatus } from '@/store/user'
import * as adminMaterialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/material_pb'

export const getMaterialList = async(pageSize: number, page: number): Promise<{ data:Array<MaterialType.Material>, totalPage: number }> => {
  const result = await MaterialAPI.getAll(loginedStatus.token, pageSize, page)
  // 這裡會有 side effect update store Material
  return {
    data: result.getItemList().map((val: materialPb.Item) => {
      const result = createMaterial(val)
      if (!materialMap.has(val.getId())) setMaterialMap(result.id, result)
      return result
    }),
    totalPage: result.getPagination()!.getTotalPage()
  }
}

export const getMaterialInfo = async(ids: Array<string>): Promise<Array<MaterialType.Material>> => {
  const result = await MaterialAPI.getInfo(loginedStatus.token, ids)
  return result.getItemList().map((val: materialPb.Item) => {
    return createMaterial(val)
  })
}

export const create = async (payload: {
  category: MaterialType.MaterialCategory
  displayName: string
  img: string
  weight: number
  description: string
  furnaceLuck: number
  craftLuck: number
  enhanceLuck: number
}): Promise<string> => {
  const res: adminMaterialPb.CreateMaterialRes = await MaterialAPI.create(loginedStatus.token, payload)
  return res.getId()
}

export const update = async (payload: {
  id: string
  category: MaterialType.MaterialCategory
  displayName: string
  img: string
  weight: number
  description: string
  furnaceLuck: number
  craftLuck: number
  enhanceLuck: number }): Promise<string> => {
  const res: adminMaterialPb.UpdateMaterialRes = await MaterialAPI.update(loginedStatus.token, payload)
  return res.getId()
}

export const createMaterial = (item: materialPb.Item): MaterialType.Material => {
  const category = item.toObject().category
  return {
    id: item.getId(),
    displayName: item.getDisplayName(),
    weight: item.getWeight(),
    category: Number(category),
    img: item.getImg(),
    description: item.getDesc(),
    furnaceLuck: item.getProperty()?.getFurnaceLuck() || 0,
    craftLuck: item.getProperty()?.getCraftLuck() || 0,
    enhanceLuck: item.getProperty()?.getEnhanceLuck() || 0
  } as MaterialType.Material
}

export const shipItem = async(payload: {
  uid: Array<string>,
  isNotification: boolean,
  materialId: string,
  count: number,
  message: string
}): Promise<void> => {
  await MaterialAPI.shipItem(loginedStatus.token, payload)
}

export const shipItemByRole = async(payload: {
  isNotification: boolean,
  materialId: string,
  count: number,
  role: Array<string>,
  message: string
}): Promise<void> => {
  await MaterialAPI.shipItemByRole(loginedStatus.token, payload)
}

export const getItemlog = async (payload: {
  uid: string,
  page: number,
  pageSize: number,
  type: LogType.LogType
}):Promise<{ data: Array<LogType.LogItem>, totalPage: number }> => {
  const res = await MaterialAPI.itemLog(loginedStatus.token, payload)
  return {
    data: res.getItemList().map((val: materialPb.LogItem) => LogType.createLogItem(val)),
    totalPage: res.getPagination()!.getTotalPage()
  }
}

export const prepareUploadImage = async(fileInfo: string):Promise<{
  signedPostPolicyMap: Map<string, string>,
  urlToUpload: string
}> => {
  const res = await MaterialAPI.prepareUploadImage(loginedStatus.token, fileInfo)
  const signedPostPolicyMap = res.getSignedPostPolicyMap()
  const urlToUpload = res.getUrlToUpload()
  return {
    signedPostPolicyMap: signedPostPolicyMap.toObject(),
    urlToUpload
  }
}
