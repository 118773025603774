<template>
  <Modal>
    <template #body>
      <div class="flex flex-col">
        <div class="text-base mt-5 mb-2 text-left">{{ title }}</div>
        <loading-items
          :itemType="itemType"
          :filterItemFunction="filterItemFunction"
          v-slot="{ loadingData, items, state }"
        >
          <infinite-scroll :loadingData="loadingData">
            <div v-if="state !== 'done'" class="w-full h-full animate-pulse bg-gray-300"></div>
            <div
              v-else
              class="border-b border-grey-200 py-3 px-5 cursor-pointer grid grid-cols-2"
              :class="{ 'bg-gray-300': isSelect == item.id }"
              v-for="(item, index) in items"
              :key="index"
              @click="selectNewItem(item.id)"
            >
              <div class="flex justify-start">
                <img :src="item.img" width="25" class="img" />
              </div>
              <div class="flex justify-start items-center">{{ item.displayName }}</div>
            </div>
          </infinite-scroll>
        </loading-items>
      </div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button
        class="mx-1 btn btn-primary py-3 px-4 w-full align-top"
        @click="confirmFunction(isSelect)"
      >新增</button>
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">取消</button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import Modal from './template.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    },
    itemType: {
      type: String as PropType<'Material' | 'Medal'>,
      default: 'Material'
    },
    filterItemFunction: {
      type: Function as PropType<
        (val: any, index: number, array: Array<any>) => boolean
      >,
      default: (val: any, index: any, array: Array<any>) => true
    }
  },
  setup(props) {
    const isSelect = ref<null | string>(null)
    const title = computed(() => {
      return props.itemType == 'Material' ? '選擇道具' : '選擇勳章'
    })

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    return {
      selectNewItem,
      isSelect,
      title
    }
  }
})
</script>
<style lang="scss" scoped>
.item-container {
  margin: 10px 0;
  overflow: scroll;
  max-height: 350px;
}
.observer {
  margin: 15px auto;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #60a917;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img {
  width: 25px;
  height: 25px;
}
</style>
