<template>
  <div class="my-pagination ml-2 text-center sm:text-left">
    <button class="btn-prev" @click="prevPage(page)" :disabled="page == 1">
      <i class="el-icon el-icon-arrow-left"></i>
    </button>
    <span class="pager text-base">{{ page }}</span>
    <button class="btn-next" @click="nextPage(page)" :disabled="page >= totalPage">
      <i class="el-icon el-icon-arrow-right"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      required: true
    },
    totalPage: {
      type: Number,
      default: Infinity
    }
  },
  emits: ['changePage'],
  setup (props, { emit }) {
    const prevPage = (page: number) => {
      emit('changePage', page - 1, true)
    }

    const nextPage = (page: number) => {
      emit('changePage', page + 1, false)
    }
    return {
      prevPage,
      nextPage
    }
  }
})
</script>

<style scoped lang="scss">
.my-pagination {
  --my-color: #409eff;
  --disable-color: #c0c4cc;
  .pager {
    margin: 0 20px;
    color: var(--my-color);
    cursor: pointer;
  }
  button:disabled {
    cursor: not-allowed;
    color: var(--disable-color);
  }
  .btn-next, .btn-prev {
    :hover{
      color: var(--my-color);
    }
  }
}
</style>
