import { render } from "./Main.vue?vue&type=template&id=f4b1f5ba"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f4b1f5ba"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f4b1f5ba', script)) {
    api.reload('f4b1f5ba', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=f4b1f5ba", () => {
    api.rerender('f4b1f5ba', render)
  })

}

script.__file = "src/views/material/shop/edit/Main.vue"

export default script