
import { defineComponent, onMounted, PropType, reactive, ref, unref } from 'vue'
import Modal from './template.vue'
import MyInput from '@/global-components/input/Main.vue'
import MySelect from '@/global-components/select/Main.vue'
import { GuardianService, IamService } from '@/services/'
import { helper as $h } from '@/utils/helper'
import { RoleType } from '@/types'
import { useModal } from '@/plugins/modals'
import { isHasIam } from '@/store/iam'
import { injectStrict, CLOSE_MODAL } from '@/utils/provideInject'

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<'NEW' | 'EDIT'>,
      required: true
    },
    oldEmail: {
      type: String,
      default: ''
    },
    oldDisplayName: {
      type: String,
      default: ''
    },
    role: {
      type: Array as PropType<RoleType.Role[]>,
      default: () => []
    },
    confirmFunction: {
      type: Function,
      default: () => false
    }
  },
  components: {
    Modal,
    MyInput,
    MySelect
  },
  setup(props) {
    const { mode, role } = unref(props)
    const closeModal = injectStrict(CLOSE_MODAL)
    const { openModal } = useModal()
    const email = ref('')
    const displayName = ref('')
    const roleValue = ref('')
    const roleList = reactive<RoleType.Role[]>([])
    const validateRule = {
      email: {
        required: true,
        errorMessage: '請填寫Email',
        requireRule: new RegExp(/^\w+((-\w+)|(\.\w+))*@lctech+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/),
        regexErrorMessage: '請填寫雷麒mail'
      },
      displayName: {
        required: true,
        errorMessage: '請填寫名稱'
      }
    }

    onMounted(async() => {
      const data = await GuardianService.getAllRoleList()
      data.forEach((val) => roleList.push(val))
      // 根據是編輯還是新增去綁定 role value，如果人員沒有設定角色則預設是role list 的第一個
      roleValue.value = mode === 'NEW' ? roleList[0].roleId : role[0]?.roleId || roleList[0].roleId
    })

    const submit = async() => {
      const data = {
        email: email.value,
        displayName: displayName.value
      }
      try {
        if (mode === 'NEW') {
          if (isHasIam(data.email)) {
            openModal('alertModal', { icon: 'warning', message: '重複的email' })
            return
          }
          await IamService.createIdentityAndBindRole(data.displayName, data.email, roleValue.value)
          openModal('alertModal', { icon: 'success', message: '新增成功' })
        } else {
          // update 的話要刪除舊有的role，所以要傳原本屬於這個user的role 進去
          await IamService.updateIdentityAndBindRole(data.displayName, data.email, role, roleValue.value)
          openModal('alertModal', { icon: 'success', message: '編輯成功' })
        }
      } catch (e) {
        openModal('alertModal', { icon: 'warning', message: e.message })
      } finally {
        props.confirmFunction()
      }
    }

    const openConfirm = () => {
      // 防呆
      const data = {
        email: email.value,
        displayName: displayName.value
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { icon: 'warning', message: errorMessage })
        return
      }
      closeModal()
      openModal('confirmModal', { message: '確定要修改嗎', confirmFunction: submit })
    }

    return {
      roleList,
      email,
      displayName,
      submit,
      validateRule,
      roleValue,
      openConfirm
    }
  }
})
