<template>
  <transition v-on="transition">
    <slot></slot>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  setup() {
    // 動畫相關設定
    const transition = {
      beforeEnter(el: any) {
        if (!el.dataset) el.dataset = {}
        el.dataset.oldPaddingTop = el.style.paddingTop
        el.dataset.oldPaddingBottom = el.style.paddingBottom
        el.style.height = '0'
        el.style.paddingTop = 0
        el.style.paddingBottom = 0
      },
      enter(el: any) {
        el.dataset.oldOverflow = el.style.overflow

        if (el.scrollHeight !== 0) {
          const height = el.scrollHeight
          // NOTE: 底下的元素寬高不能也用算的, 不然會有誤差
          el.style.height = `${height}px`
          el.style.paddingTop = el.dataset.oldPaddingTop
          el.style.paddingBottom = el.dataset.oldPaddingBottom
        } else {
          el.style.height = ''
          el.style.paddingTop = el.dataset.oldPaddingTop
          el.style.paddingBottom = el.dataset.oldPaddingBottom
        }
        el.style.overflow = 'hidden'
      },
      afterEnter(el: any) {
        el.style.height = ''
        el.style.overflow = el.dataset.oldOverflow
      },
      beforeLeave(el: any) {
        if (!el.dataset) el.dataset = {}
        el.dataset.oldPaddingTop = el.style.paddingTop
        el.dataset.oldPaddingBottom = el.style.paddingBottom
        el.dataset.oldOverflow = el.style.overflow
        el.style.height = `${el.scrollHeight}px`
        el.style.overflow = 'hidden'
      },
      leave(el: any) {
        if (el.scrollHeight !== 0) {
          el.style.height = 0
          el.style.paddingTop = 0
          el.style.paddingBottom = 0
        }
      },
      afterLeave(el: any) {
        el.style.height = ''
        el.style.overflow = el.dataset.oldOverflow
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
      }
    }
    return {
      transition
    }
  }
})
</script>

<style scoped>

</style>
