<template>
  <div
    class="chat__box__text-box flex items-end float-left mb-4"
    :class="{'justify-end self-end flex-row-reverse': isMe }"
    >
    <div class="w-10 h-10 hidden sm:block flex-none image-fit relative mx-3">
      <img
        alt="avator"
        class="rounded-full"
        :src="newUrl"
      />
    </div>
    <div>
      <div
        class="rounded-r-md rounded-t-md cursor-pointer"
        :class="{
          'px-4 py-3': !notPadding,
          [isMeClass]: true
        }"
        @dblclick="markFunction(!isMark)"
        >
        <slot name="message"></slot>
        <slot name="images"></slot>
      </div>
      <div class="min-h-1 text-gray-700" :class="{ [markClass]: true }">{{ markText }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { FAKE_AVATAR } from '@/utils'
import { computed, defineComponent, toRefs, ref, watch } from 'vue'

export default defineComponent({
  name: 'MessageText',
  props: {
    isMe: {
      type: Boolean,
      required: true
    },
    notPadding: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    },
    isMark: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['markMessage'],
  setup (props, { emit }) {
    const { isMe, index, isMark, photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)

    watch(photoURL, () => {
      const img = new Image()
      img.src = photoURL.value
      img.onerror = function () {
        newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
      }
      img.onload = function () {
        newUrl.value = photoURL.value
      }
    })
    const markText = computed(() => {
      return isMark.value ? '被標記' : ''
    })

    const isMeClass = computed(() => {
      const meStyle = 'text-bg text-white'
      const otherStyle = 'bg-gray-200 text-gray-700'
      return isMe.value ? meStyle : otherStyle
    })

    const markClass = computed(() => {
      const meStyle = 'float-right'
      const otherStyle = 'float-left'
      return isMe.value ? meStyle : otherStyle
    })

    const markFunction = (value: boolean) => {
      emit('markMessage', value, index.value)
    }

    return {
      isMeClass,
      markFunction,
      markClass,
      markText,
      newUrl
    }
  }
})
</script>

<style lang="scss" scoped>
.min-h-1 {
  min-height: 20px;
}
.text-bg {
  background-color: #1c3faa;
}
</style>
r
