import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
export enum LogType {
  LOG_TYPE_NONE = 0,
  LOG_TYPE_ALL = 1,
  LOG_TYPE_USE = 2,
  LOG_TYPE_BUY = 3,
  LOG_TYPE_RECEIVE = 4,
  LOG_TYPE_FURNACE = 5,
  LOG_TYPE_ABANDON = 6,
  LOG_TYPE_ARTIFACT_TREASURE = 7,
  LOG_TYPE_ARTIFACT_EXPLORE = 8,
  LOG_TYPE_ARTIFACT_MINING = 9,
  LOG_TYPE_SEND = 10,
  LOG_TYPE_USE_RESULT = 11
}

export const LogToGrpc = {
  [LogType.LOG_TYPE_NONE]: materialPb.LogType.LOG_TYPE_NONE,
  [LogType.LOG_TYPE_ALL]: materialPb.LogType.LOG_TYPE_ALL,
  [LogType.LOG_TYPE_USE]: materialPb.LogType.LOG_TYPE_USE,
  [LogType.LOG_TYPE_BUY]: materialPb.LogType.LOG_TYPE_BUY,
  [LogType.LOG_TYPE_RECEIVE]: materialPb.LogType.LOG_TYPE_RECEIVE,
  [LogType.LOG_TYPE_FURNACE]: materialPb.LogType.LOG_TYPE_FURNACE,
  [LogType.LOG_TYPE_ABANDON]: materialPb.LogType.LOG_TYPE_ABANDON,
  [LogType.LOG_TYPE_ARTIFACT_TREASURE]: materialPb.LogType.LOG_TYPE_ARTIFACT_TREASURE,
  [LogType.LOG_TYPE_ARTIFACT_EXPLORE]: materialPb.LogType.LOG_TYPE_ARTIFACT_EXPLORE,
  [LogType.LOG_TYPE_ARTIFACT_MINING]: materialPb.LogType.LOG_TYPE_ARTIFACT_MINING,
  [LogType.LOG_TYPE_SEND]: materialPb.LogType.LOG_TYPE_SEND,
  [LogType.LOG_TYPE_USE_RESULT]: materialPb.LogType.LOG_TYPE_USE_RESULT
}

export const GrpcToLogType = {
  [materialPb.LogType.LOG_TYPE_NONE]: LogType.LOG_TYPE_NONE,
  [materialPb.LogType.LOG_TYPE_ALL]: LogType.LOG_TYPE_ALL,
  [materialPb.LogType.LOG_TYPE_USE]: LogType.LOG_TYPE_USE,
  [materialPb.LogType.LOG_TYPE_BUY]: LogType.LOG_TYPE_BUY,
  [materialPb.LogType.LOG_TYPE_RECEIVE]: LogType.LOG_TYPE_RECEIVE,
  [materialPb.LogType.LOG_TYPE_FURNACE]: LogType.LOG_TYPE_FURNACE,
  [materialPb.LogType.LOG_TYPE_ABANDON]: LogType.LOG_TYPE_ABANDON,
  [materialPb.LogType.LOG_TYPE_ARTIFACT_TREASURE]: LogType.LOG_TYPE_ARTIFACT_TREASURE,
  [materialPb.LogType.LOG_TYPE_ARTIFACT_EXPLORE]: LogType.LOG_TYPE_ARTIFACT_EXPLORE,
  [materialPb.LogType.LOG_TYPE_ARTIFACT_MINING]: LogType.LOG_TYPE_ARTIFACT_MINING,
  [materialPb.LogType.LOG_TYPE_SEND]: LogType.LOG_TYPE_SEND,
  [materialPb.LogType.LOG_TYPE_USE_RESULT]: LogType.LOG_TYPE_USE_RESULT
}

export type LogItem = {
  type: LogType
  text: string
  created: number
  note: string
}

export const transformType = {
  [LogType.LOG_TYPE_NONE]: '---',
  [LogType.LOG_TYPE_ALL]: '全部紀錄',
  [LogType.LOG_TYPE_USE]: '使用紀錄',
  [LogType.LOG_TYPE_BUY]: '購買記錄',
  [LogType.LOG_TYPE_RECEIVE]: '獲贈紀錄',
  [LogType.LOG_TYPE_FURNACE]: '合成紀錄',
  [LogType.LOG_TYPE_ABANDON]: '丟棄紀錄',
  [LogType.LOG_TYPE_ARTIFACT_TREASURE]: '寶箱紀錄',
  [LogType.LOG_TYPE_ARTIFACT_EXPLORE]: '探索紀錄',
  [LogType.LOG_TYPE_ARTIFACT_MINING]: '挖礦紀錄',
  [LogType.LOG_TYPE_SEND]: '發放紀錄',
  [LogType.LOG_TYPE_USE_RESULT]: '使用結果'
}

export const createLogItem = (item: materialPb.LogItem): LogItem => {
  return {
    type: GrpcToLogType[item.getType()],
    text: item.getText(),
    created: item.getCreated().getSeconds() * 1000,
    note: item.getNote()
  }
}
