
import { defineComponent, onMounted, ref } from 'vue'
import {
  getDomainAll,
  getRoleAllByDomain,
  updatePermission,
  DomainState,
  changePermissionObjectMode,
  lookupPermissionMatrix
} from '@/store/guardian'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { DomainToOption, OptionToDomain, RoleToOption, OptionToRole } from '@/api/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  components: {
    SvgIcon
  },
  setup() {
    const domainSelValue = ref<null | string>(null)
    const roleSelValue = ref<null | string>(null)
    const roleAll = ref<GuardianQueryPB.Role[]>([])

    onMounted(async() => {
      await getDomainAll()
      if (DomainState.domainAll.length > 0) {
        domainSelValue.value = DomainToOption(DomainState.domainAll[0])
        if (domainSelValue.value) {
          await getRoleAllByDomain(DomainState.domainAll[0])
          const value = DomainState.domainAllRoleAll.get(DomainToOption(DomainState.domainAll[0]))
          if (value) {
            roleAll.value = value
            if (value.length > 0) {
              roleSelValue.value = RoleToOption(value[0])
            }
          }
        }
      }
    })
    const changeDomainValue = async () => {
      if (domainSelValue.value) {
        const selectedDomain = OptionToDomain(domainSelValue.value)
        if (selectedDomain) {
          await getRoleAllByDomain(selectedDomain)
          const value = DomainState.domainAllRoleAll.get(DomainToOption(selectedDomain))
          if (value) {
            roleAll.value = value
            if (value.length > 0) {
              roleSelValue.value = RoleToOption(value[0])
            }
          }
        }
      }
    }
    return {
      DomainState,
      domainSelValue,
      roleSelValue,
      getRoleAllByDomain,
      lookupPermissionMatrix,
      changePermissionObjectMode,
      updatePermission,
      DomainToOption,
      OptionToDomain,
      RoleToOption,
      changeDomainValue,
      roleAll,
      OptionToRole
    }
  }
})
