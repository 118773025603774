
import { defineComponent, onMounted } from 'vue'
import { useModal } from '@/plugins/modals/'
import { getDomainAll, DomainState } from '@/store/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  setup() {
    const { openModal } = useModal()
    const createDomain = async(e: Event) => {
      e.preventDefault()
      openModal('editGuardianDomain',
        {
          mode: 'create',
          done: getDomainAll
        }
      )
    }
    const updateDomain = async(domain: GuardianQueryPB.Domain) => {
      openModal('editGuardianDomain',
        {
          mode: 'edit',
          domainID: domain.getDomainId(),
          displayName: domain.getDisplayName(),
          done: getDomainAll
        }
      )
    }

    onMounted(() => {
      getDomainAll()
    })
    return {
      createDomain,
      DomainState,
      updateDomain
    }
  }
})
