import { MaterialType } from '@/types/'
import * as grpcLib from '@/libs/grpc'
import * as adminMaterialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/store_pb'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import { AdminStorePromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/store_grpc_web_pb'

const ShopService = new AdminStorePromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MATERIAL))
grpcLib.EnableGrpcDebugTools(ShopService)

export const getAllShopItem = async(token: string, pageSize: number, page: number): Promise<adminMaterialPb.GetAllStoreRes> => {
  const req: adminMaterialPb.GetAllStoreReq = new adminMaterialPb.GetAllStoreReq()
  req.setAdminToken(token)
  const paginator = new materialPb.Paginator()
  paginator.setPageSize(pageSize)
  paginator.setPage(page)
  req.setPaginator(paginator)
  const res: adminMaterialPb.GetAllStoreRes = await ShopService.getAll(req)
  return grpcLib.resolveData(res)
}

export const getShopItemInfo = async(token: string, ids: Array<string>) => {
  const req: adminMaterialPb.GetInfoStoreReq = new adminMaterialPb.GetInfoStoreReq()
  req.setAdminToken(token)
  req.setIdList(ids)
  const res: adminMaterialPb.GetInfoStoreRes = await ShopService.getInfo(req)
  return grpcLib.resolveData(res)
}

export const create = async(token: string, { id, creditType, creditValue, stock }: {
  id: string,
  creditType: MaterialType.Credit,
  creditValue: number,
  stock: number
}):Promise<adminMaterialPb.CreateStoreRes> => {
  const req: adminMaterialPb.CreateStoreReq = new adminMaterialPb.CreateStoreReq()
  const creditTypeData = new materialPb.Credit()
  creditTypeData.setId(creditType.id)
  creditTypeData.setDisplayName(creditType.displayName)
  req.setAdminToken(token)
  req.setId(id)
  req.setStock(stock)
  req.setCreditType(creditTypeData)
  req.setCreditValue(creditValue)
  const res: adminMaterialPb.CreateStoreRes = await ShopService.create(req)
  return grpcLib.resolveData(res)
}

export const update = async(token: string, { id, creditType, creditValue, stock }: {
  id: string,
  creditType: MaterialType.Credit,
  creditValue: number,
  stock: number
}):Promise<adminMaterialPb.UpdateStoreRes> => {
  const req: adminMaterialPb.UpdateStoreReq = new adminMaterialPb.UpdateStoreReq()
  const creditTypeData = new materialPb.Credit()
  creditTypeData.setId(creditType.id)
  creditTypeData.setDisplayName(creditType.displayName)
  req.setAdminToken(token)
  req.setId(id)
  req.setStock(stock)
  req.setCreditType(creditTypeData)
  req.setCreditValue(creditValue)
  const res: adminMaterialPb.UpdateStoreRes = await ShopService.update(req)
  return grpcLib.resolveData(res)
}

export const softDelete = async(token: string, id: string): Promise<adminMaterialPb.SoftDeleteStoreRes> => {
  const req: adminMaterialPb.SoftDeleteStoreReq = new adminMaterialPb.SoftDeleteStoreReq()
  req.setAdminToken(token)
  req.setId(id)
  const res: adminMaterialPb.SoftDeleteStoreRes = await ShopService.softDelete(req)
  return grpcLib.resolveData(res)
}
