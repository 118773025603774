import { EnhanceAPI } from '@/api/'
import { MedalType } from '@/types/'
import { loginedStatus } from '@/store/user'
import { createMedal } from './medal'
import { getMedal } from '@/store/medal'
import * as adminEnhancePb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/enhance_pb'

export const getAll = async(pageSize: number, page: number)
:Promise<{
  data: Array<MedalType.EnhanceInterface<MedalType.Medal>>,
  totalPage: number
}> => {
  const result = await EnhanceAPI.getAll(loginedStatus.token, pageSize, page)
  // TOOD: 要重構
  const data = await Promise.all(
    result.getInfoList().map(async(val: adminEnhancePb.GetAllEnhanceRes.Info) => {
      const result = await createEnhanceItem(val)
      return result
    })
  )
  return {
    data: data,
    totalPage: result.getPagination()!.getTotalPage()
  }
}

export const getInfo = async(rootMedalId: string) => {
  const data = await EnhanceAPI.getInfo(loginedStatus.token, rootMedalId)
  return createEnhanceItem(data.getInfo()!)
}

export const create = async(rootMedalId: string, recipeList: Array<MedalType.EnhanceRecipe<MedalType.Medal>>):Promise<string> => {
  const res = await EnhanceAPI.create(loginedStatus.token, rootMedalId, recipeList)
  return res.getRootId()
}
export const update = async(rootMedalId: string, recipeList: Array<MedalType.EnhanceRecipe<MedalType.Medal>>):Promise<string> => {
  const res = await EnhanceAPI.update(loginedStatus.token, rootMedalId, recipeList)
  return res.getRootId()
}

export const createEnhanceItem = async (item: adminEnhancePb.GetAllEnhanceRes.Info | adminEnhancePb.GetInfoEnhanceRes.Info): Promise<MedalType.EnhanceInterface<MedalType.Medal>> => {
  const recipe = await createRecipeList(item.getRecipeList())
  return {
    info: createMedal(item.getRoot()!),
    isRoot: true,
    recipe: recipe
  }
}

export const createRecipeList = async (list: Array<adminEnhancePb.EnhanceRecipe>): Promise<Array<MedalType.EnhanceRecipe<MedalType.Medal>>> => {
  // TODO: 需重構
  const recipe = list.map(async(val: adminEnhancePb.EnhanceRecipe) => {
    const consumableMaterial = val.getConsumableList().map((val: adminEnhancePb.EnhanceRecipe.EnhanceRequire) => {
      return {
        id: val.getMaterialId(),
        count: val.getCount(),
        type: MedalType.ConsumableType.TYPE_MATERIAL
      }
    })
    const inconsumableMaterial = val.getInconsumableList().map((val: adminEnhancePb.EnhanceRecipe.EnhanceRequire) => {
      return {
        id: val.getMaterialId(),
        count: val.getCount(),
        type: MedalType.ConsumableType.TYPE_MATERIAL
      }
    })
    const medal = await getMedal([val.getMedalId()])
    return {
      info: medal.get(val.getMedalId()!)!,
      coins: val.getCoins(),
      successRate: val.getSuccessRate(),
      consumableMaterial: consumableMaterial,
      inconsumableMaterial: inconsumableMaterial
    }
  })
  const result = await Promise.all(recipe)
  return result
}
