import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import MessageLog from '../views/message-log/Main.vue'
import * as UserStore from '@/store/user'
import ROUTE_NAME from './appRouteName'
import {
  itemLogRouter,
  itemManufactureRouter,
  itemRouter,
  itemSendRouter,
  itemShopRouter
} from './material'
import {
  medalManufactureRouter,
  medalRouter,
  medalSendRouter,
  medalLogRouter,
  medalEnhanceRouter
} from './medal'
import { guardianRoute } from '@/router/guardian'
import { iamRoute } from '@/router/iam'
const routes = [
  {
    path: ROUTE_NAME.LOGIN.path,
    name: ROUTE_NAME.LOGIN.name,
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Main.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      const login = UserStore.isLogin.value
      if (!login) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: ROUTE_NAME.HOME.path,
    beforeEnter: (to: any, from: any, next: any) => {
      const login = UserStore.isLogin.value
      if (login) {
        const time = UserStore.adminTokenExpiredAt() - Date.now()
        // 倒數計時，時間到就自動登出
        const timer = setTimeout(() => {
          UserStore.logout()
          router.replace({ path: '/login' })
          clearTimeout(timer)
        }, time)
        next()
      } else {
        next('/login')
      }
    },
    component: SideMenu,
    meta: { requiresAuth: true },
    children: [
      {
        path: ROUTE_NAME.HOME.path,
        name: ROUTE_NAME.HOME.name,
        meta: { title: ROUTE_NAME.HOME.title },
        component: MessageLog
      },
      {
        path: ROUTE_NAME.SEARCH_USER.path,
        name: ROUTE_NAME.SEARCH_USER.name,
        meta: { title: ROUTE_NAME.SEARCH_USER.title },
        component: () => import(/* webpackChunkName: "user-profile" */ '../views/search-user/Main.vue')
      },
      {
        path: ROUTE_NAME.BANNED_USER.path,
        name: ROUTE_NAME.BANNED_USER.name,
        meta: { title: ROUTE_NAME.BANNED_USER.title },
        component: () => import(/* webpackChunkName: "user-profile" */ '../views/search-user/banned-user/Main.vue')
      },
      {
        path: ROUTE_NAME.USER_PROFILE.path,
        name: ROUTE_NAME.USER_PROFILE.name,
        meta: { title: ROUTE_NAME.USER_PROFILE.title },
        component: () => import(/* webpackChunkName: "user-profile" */ '../views/search-user/user-profile/Main.vue')
      },
      {
        path: ROUTE_NAME.USERS.path,
        component: () => import(/* webpackChunkName: "user-profile" */ '../views/users/Main.vue'),
        children: [
          {
            path: ROUTE_NAME.USERS.path,
            name: ROUTE_NAME.USERS.name,
            meta: { title: ROUTE_NAME.USERS.title },
            component: () => import(/* webpackChunkName: "user-profile" */ '../views/users/search/Main.vue')
          }
        ]
      },
      itemRouter,
      itemShopRouter,
      itemManufactureRouter,
      itemSendRouter,
      itemLogRouter,
      medalRouter,
      medalManufactureRouter,
      medalEnhanceRouter,
      medalSendRouter,
      medalLogRouter,
      guardianRoute,
      iamRoute
    ]
  },
  {
    path: ROUTE_NAME.ERROR.path,
    name: ROUTE_NAME.ERROR.name,
    component: () => import(/* webpackChunkName: "error" */ '../views/error-page/Main.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: ROUTE_NAME.ERROR.path
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
