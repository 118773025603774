export default {
  LOGIN: {
    path: '/login',
    name: 'Login'
  },
  HOME: {
    path: '/',
    title: '訊息列表',
    name: 'MessageLog'
  },
  SEARCH_USER: {
    path: '/searchUser',
    title: '搜尋用戶',
    name: 'SearchUser'
  },
  BANNED_USER: {
    path: '/bannedUser',
    title: '禁言名單',
    name: 'BannedUser'
  },
  USER_PROFILE: {
    path: '/userProfile/:uid',
    title: '用戶聊天室',
    name: 'UserProfile'
  },
  USERS: {
    path: '/users',
    title: '搜尋',
    name: 'User'
  },
  ERROR: {
    path: '/404',
    name: 'Error'
  }
} as const

export const materialRouteName = {
  ITEM: {
    path: '/material',
    title: '道具列表',
    name: 'material'
  },
  ITEM_EDIT: {
    path: '/material/edit/:id?',
    title: '道具資訊',
    name: 'EditMaterial'
  },
  SHOP: {
    path: '/shop',
    title: '編輯商店',
    name: 'Shop'
  },
  SHOP_EDIT: {
    path: '/shop/edit/:id',
    title: '編輯上架商品',
    name: 'EditShopItem'
  },
  MANUFACTURE: {
    path: '/manufacture',
    title: '可合成道具',
    name: 'Manufacture'
  },
  MANUFACTURE_EDIT: {
    path: '/manufacture/edit/:id',
    title: '編輯可製作道具',
    name: 'EditManufactureItem'
  },
  SEND_MATERIAL: {
    path: '/sendMaterial',
    title: '寄送道具',
    name: 'SendItem'
  },
  MATERIAL_LOG: {
    path: '/materialLog',
    title: '用戶道具紀錄',
    name: 'MaterialLog'
  }
}

export const medalRouteName = {
  MEDAL: {
    path: '/medal',
    title: '勳章列表',
    name: 'Medal'
  },
  MEDAL_EDIT: {
    path: '/medal/edit/:id?',
    title: '編輯勳章',
    name: 'MedalEdit'
  },
  MEDAL_MANUFACTURE: {
    path: '/medalManufacture',
    title: '合成勳章列表',
    name: 'MedalManufacture'
  },
  MEDAL_MANUFACTURE_EDIT: {
    path: '/medalManufacture/edit/:id',
    title: '編輯可合成勳章',
    name: 'MedalManufactureEdit'
  },
  MEDAL_RECIPE_EDIT: {
    path: '/medalManufacture/recipeEdit/:id',
    title: '編編強化階段勳章',
    name: 'MedalRecipeEdit'
  },
  MEDAL_ENHANCE: {
    path: '/medalEnhance',
    title: '關聯勳章列表',
    name: 'MedalEnhance'
  },
  MEDAL_ENHANCE_EDIT: {
    path: '/medalEnhance/edit/:id',
    title: '編輯關聯勳章',
    name: 'MedalEnhanceEdit'
  },
  SEND_MEDAL: {
    path: '/sendMedal',
    title: '寄送勳章',
    name: 'SendMedal'
  },
  MEDAL_LOG: {
    path: '/medalLog',
    title: '勳章紀錄',
    name: 'MedalLog'
  }
}

export const guardianRouteName = {
  GUARDIAN: {
    path: '/guardian',
    title: '權限系統',
    name: 'Guardian'
  },
  GUARDIAN_PERM: {
    path: '/guardian/permission',
    title: '用戶組權限',
    name: 'Guardian Permission'
  },
  GUARDIAN_ROLE: {
    path: '/guardian/role',
    title: '用戶組清單',
    name: 'Guardian Role'
  },
  GUARDIAN_DOMAIN: {
    path: '/guardian/domain',
    title: '領域管理',
    name: 'Guardian Domain'
  },
  GUARDIAN_DESIGN: {
    path: '/guardian/design',
    title: '設計模式',
    name: 'Guardian Design'
  }
}

export const iamRouteName = {
  IAM: {
    path: '/iam',
    title: 'Admin',
    name: 'IAM'
  },
  IAM_USER: {
    path: '/iam/user',
    title: 'User All',
    name: 'User All'
  },
  IAM_ROLE: {
    path: '/iam/role',
    title: '職位列表',
    name: 'iamRole'
  }
}
