
import { defineComponent, onBeforeMount, ref, reactive } from 'vue'
import { useModal } from '@/plugins/modals/index'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MedalType } from '@/types/index'
import { MedalManufactureService } from '@/services/'
export default defineComponent({
  name: 'Manufacture',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const medalType = ref<'MANUFACTURE' | 'RECIPE'>('MANUFACTURE')
    const data = reactive<Array<MedalType.ManufactureMedal<MedalType.Medal>>>([])
    const showMedalData = (value: any, type: MedalType.MedalKey) => {
      if (value.info) return value.info[type]
    }
    const { openModal } = useModal()
    const columns = TableColumns.MEDAL_MANUFACTURE
    const { toEditManufactureMedalPage } = useRouter()
    let closeNewItem: Function | null = null

    const validateAndChangePage = (id: string) => {
      if (!id) {
        openModal('alertModal', { icon: 'warning', message: '請選擇勳章' })
        return false
      }
      closeNewItem!()
      toEditManufactureMedalPage(id)
    }

    const newItem = () => {
      const { closeModal } = openModal('newItem', { confirmFunction: validateAndChangePage, itemType: 'Medal' })
      closeNewItem = closeModal
    }

    const resetData = () => {
      data.length = 0
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MedalManufactureService.getAllItem(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    onBeforeMount(async () => {
      const result = await MedalManufactureService.getAllItem(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    return {
      columns,
      data,
      page,
      toEditManufactureMedalPage,
      newItem,
      getDataByPage,
      medalType,
      showMedalData,
      totalPage
    }
  }
})
