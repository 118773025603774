
import { defineComponent, onMounted, PropType, reactive, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import Modal from './template.vue'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { GuardianAPI } from '@/api/index'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    domainID: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<'create' | 'edit'>,
      required: true
    },
    done: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { openModal } = useModal()
    const formData = reactive({
      domainID: props.domainID,
      domainDisplayName: props.displayName
    })
    const rules = {
      domainID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      domainDisplayName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))

    const submit = (closeModal: Function) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        const errorMessage = '請修正資料格式'
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return
      }
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const domain = new GuardianQueryPB.Domain()
      domain.setDomainId(formData.domainID)
      domain.setDisplayName(formData.domainDisplayName)
      if (props.mode == 'create') {
        GuardianAPI.CreateDomain(token, domain).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
      } else if (props.mode == 'edit') {
        GuardianAPI.UpdateDomain(token, domain).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
      }
    }

    onMounted(() => {
    })

    return {
      formData,
      validate,
      submit
    }
  }
})
