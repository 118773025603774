import { materialRouteName } from './appRouteName'
export const itemRouter = {
  path: materialRouteName.ITEM.path,
  component: () => import(/* webpackChunkName: "material" */ '../views/material/Main.vue'),
  children: [
    {
      path: materialRouteName.ITEM.path,
      name: materialRouteName.ITEM.name,
      meta: { title: materialRouteName.ITEM.title },
      component: () => import(/* webpackChunkName: "material" */ '../views/material/material/Main.vue')
    },
    {
      path: materialRouteName.ITEM_EDIT.path,
      name: materialRouteName.ITEM_EDIT.name,
      meta: { title: materialRouteName.ITEM_EDIT.title },
      component: () => import(/* webpackChunkName: "material" */ '../views/material/material/edit/Main.vue')
    }
  ]
}

export const itemShopRouter = {
  path: materialRouteName.SHOP.path,
  component: () => import(/* webpackChunkName: "itemShop" */ '../views/material/Main.vue'),
  children: [
    {
      path: materialRouteName.SHOP.path,
      name: materialRouteName.SHOP.name,
      meta: { title: materialRouteName.SHOP.title },
      component: () => import(/* webpackChunkName: "itemShop" */ '../views/material/shop/Main.vue')
    },
    {
      path: materialRouteName.SHOP_EDIT.path,
      name: materialRouteName.SHOP_EDIT.name,
      meta: { title: materialRouteName.SHOP_EDIT.title },
      component: () => import(/* webpackChunkName: "itemShop" */ '../views/material/shop/edit/Main.vue')
    }
  ]
}

export const itemManufactureRouter = {
  path: materialRouteName.MANUFACTURE.path,
  component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/material/Main.vue'),
  children: [
    {
      path: materialRouteName.MANUFACTURE.path,
      name: materialRouteName.MANUFACTURE.name,
      meta: { title: materialRouteName.MANUFACTURE.title },
      component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/material/manufacture/Main.vue')
    },
    {
      path: materialRouteName.MANUFACTURE_EDIT.path,
      name: materialRouteName.MANUFACTURE_EDIT.name,
      meta: { title: materialRouteName.MANUFACTURE_EDIT.title },
      component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/material/manufacture/edit/Main.vue')
    }
  ]
}

export const itemSendRouter = {
  path: materialRouteName.SEND_MATERIAL.path,
  name: materialRouteName.SEND_MATERIAL.name,
  meta: { title: materialRouteName.SEND_MATERIAL.title },
  component: () => import(/* webpackChunkName: "itemSend" */ '../views/send-material/Main.vue')
}

export const itemLogRouter = {
  path: materialRouteName.MATERIAL_LOG.path,
  name: materialRouteName.MATERIAL_LOG.name,
  meta: { title: materialRouteName.MATERIAL_LOG.title },
  component: () => import(/* webpackChunkName: "itemLog" */ '../views/material/log/Main.vue')
}
