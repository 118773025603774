import { Ref } from 'vue'
import EffectSetting from '@/components/item/effectSetting.vue'
import { getMaterial } from '@/store/material'
import { MaterialType, EffectType } from '@/types/'
import type { UnwrapNestedRefs } from '@vue/reactivity'

export default function itemEdit () {
  const validateRule = {
    displayName: {
      required: true,
      errorMessage: '請輸入名稱'
    },
    weight: {
      required: true,
      rule: new RegExp(/^(0|[1-9][0-9]*)$/),
      errorMessage: '請輸入重量',
      ruleMessage: '請輸入正確的數字格式'
    },
    luckNumber: {
      rule: new RegExp(/^[0-9]$|^[1-9][0-9]$|^(100)$/),
      errorMessage: '請輸入幸運值',
      ruleMessage: '請輸入正確的數字格式(0~100)'
    },
    description: {
      required: true,
      errorMessage: '請輸入簡介'
    },
    img: {
      required: true,
      errorMessage: '請上傳道具圖'
    }
  }

  const createChestData = async (value: EffectType.ChestInterface):Promise<EffectType.Chest<MaterialType.Material | MaterialType.Credit>> => {
    const isMaterial = value.chestType === EffectType.ChestType.CHEST_TYPE_MATERIAL
    let data
    if (isMaterial) {
      const map = await getMaterial([value.dependId])
      data = map.get(value.dependId)!
    } else {
      data = MaterialType.getCredit(value.dependId)
    }
    return {
      chestType: value.chestType,
      info: data,
      rate: value.rate,
      minCount: value.minCount,
      maxCount: value.maxCount
    }
  }

  const checkEffectData = (ref: Ref<InstanceType<typeof EffectSetting>>): [
    isValidate: boolean,
    errorMessage: string,
    effectType: EffectType.EffectType,
    chest: Array<EffectType.Chest<any>>
  ] => {
    let isValidate = true
    let errorMessage = ''
    const { effectType, chest } = ref.value?.submitData()
    // 防呆
    if (chest.length <= 0) {
      isValidate = false
      errorMessage = '請設定被使用後的效果'
    }
    // 模式1 2 機率總和為100
    if (isValidate) {
      if (effectType === 1 || effectType === 2) {
        const rate = chest.reduce((prev: any, next: any) => {
          return prev + Number(next.rate)
        }, 0)
        if (rate != 100) {
          isValidate = false
          errorMessage = '機率總和必須為100'
        }
      }
    }
    // 檢查最小數量是否超過最大數量
    if (isValidate) {
      isValidate = !chest.some((val: any) => {
        if (val.minCount > val.maxCount) errorMessage = '最小獲得數量不得超過最大獲得數量'
        return val.minCount > val.maxCount
      })
    }

    return [
      isValidate,
      errorMessage,
      effectType,
      chest
    ]
  }

  const setEffectInfo = async(data: EffectType.EffectInterface, info: UnwrapNestedRefs<EffectType.EffectInfo<MaterialType.Material | MaterialType.Credit>>) => {
    info.effectType = data.effectType
    info.minKind = data.minKind
    info.maxKind = data.maxKind
    for (let i = 0; i < data.chest.length; i++) {
      const value = data.chest[i]
      const chestData = await createChestData(value)
      info.chest.push(chestData)
    }
  }

  return {
    validateRule,
    createChestData,
    checkEffectData,
    setEffectInfo
  }
}
