
import { defineComponent, PropType, reactive, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import Modal from './template.vue'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { GuardianAPI } from '@/api/index'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    designDomainID: {
      type: String,
      default: ''
    },
    designObjectID: {
      type: String,
      default: ''
    },
    designSummary: {
      type: String,
      default: ''
    },
    designActionID: {
      type: String,
      default: ''
    },
    designExtraValue: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<'create' | 'edit'>,
      required: true
    },
    done: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { openModal } = useModal('fullscreen-dom')
    const formData = reactive({
      designObjectID: props.designObjectID,
      designSummary: props.designSummary,
      designActionID: props.designActionID,
      designExtraValue: props.designExtraValue
    })
    const oldPolicy = new GuardianQueryPB.RolePolicy()
    oldPolicy.setDomain(props.designDomainID)
    oldPolicy.setRoleId('r:design')
    oldPolicy.setObject(formData.designObjectID)
    oldPolicy.setSummary(formData.designSummary)
    oldPolicy.setAction(formData.designActionID)
    oldPolicy.setExtraValue(formData.designExtraValue)
    const rules = {
      designObjectID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      designSummary: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      designActionID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      designExtraValue: {
        maxLength: maxLength(30)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))

    const submit = (closeModal: Function) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        const errorMessage = '請修正資料格式'
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return
      }
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const rp = new GuardianQueryPB.RolePolicy()
      rp.setDomain(props.designDomainID)
      rp.setRoleId('r:design')
      rp.setObject(formData.designObjectID)
      rp.setSummary(formData.designSummary)
      rp.setAction(formData.designActionID)
      rp.setExtraValue(formData.designExtraValue)
      if (props.mode == 'create') {
        GuardianAPI.CreatePolicyForDesign(token, rp).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
      } else if (props.mode == 'edit') {
        GuardianAPI.UpdatePolicyForDesign(token, oldPolicy, rp).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
      }
    }

    return {
      formData,
      validate,
      submit
    }
  }
})
