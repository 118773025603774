<template>
  <Modal position="center">
    <template #body>
      <svg-icon name="mdiAlertOctagonOutline" class="w-16 h-16 text-theme-12 mx-auto mt-3" />
      <div class="text-3xl mt-5">{{ message }}</div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button class="mx-1 btn btn-primary py-3 px-4 w-full align-top" @click="confirm">
        是
      </button>
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">
        否
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from './template.vue'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { useLoading } from '@/plugins/loading'
import { CLOSE_MODAL, injectStrict } from '@/utils/provideInject'
export default defineComponent({
  components: {
    Modal,
    SvgIcon
  },
  props: {
    message: {
      type: String,
      required: true
    },
    confirmFunction: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const { startLoading } = useLoading()
    const closeModal = injectStrict(CLOSE_MODAL)
    const confirm = async() => {
      const loading = startLoading()
      try {
        closeModal()
        await props.confirmFunction()
      } catch (e) {
        console.error(e)
      } finally {
        loading.close()
      }
    }
    return {
      confirm
    }
  }
})
</script>
