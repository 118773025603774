import { IamAPI } from '@/api/index'
import { reactive, readonly } from 'vue'
import * as IdentityPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/identity_pb'
import { IamType, RoleType } from '@/types'

type State = {
  userAll: IdentityPB.Identity[]
}

export const DomainID = RoleType.Domain.adm

export const IamState = reactive<State>({
  userAll: []
})

const iamMap = reactive<Map<string, IamType.Iam>>(new Map())

const getIam = (email: string) => {
  const role = iamMap.get(email)
  if (role) return readonly(role)
  return IamType.emptyIam
}

export const setIamMap = (email: string, role: IamType.Iam) => {
  iamMap.set(email, role)
}

export const isHasIam = (email: string): boolean => {
  const iam = getIam(email)
  return !!iam.email
}

export const deleteIamUser = (email: string) => {
  iamMap.delete(email)
}

export const getUserAll = async() => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }
  const result = await IamAPI.QueryIdentityAll(token)
  IamState.userAll = result.getIdentityAllList()
}

export const deleteUser = async(identity: IdentityPB.Identity, done: any) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }
  // await IamAPI.DeleteIdentity(token, identity)
  done()
}
