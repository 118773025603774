
import { defineComponent, reactive, ref } from 'vue'
import * as Users from '@/store/users'
import UserData from '@/components/user/user'
import UserCard from '@/components/user-card/Main.vue'
import { useLoading } from '@/plugins/loading/'

export default defineComponent({
  name: 'SearchUser',
  components: {
    UserData,
    UserCard
  },
  setup() {
    const user = reactive([
      {
        name: 'Neil3',
        uid: '98832'
      }
    ])
    const searchText = ref<string>('')
    const userHashId = ref<string>('')
    const { startLoading } = useLoading()
    const search = async () => {
      const uid = searchText.value
      // 簡易防呆
      if (uid !== '' && (isNaN(Number(uid)) || parseInt(uid) <= 0)) {
        alert('無效的UID')
      }
      const load = startLoading()
      try {
        userHashId.value = await Users.getHashIdByUid(parseInt(uid))
      } catch (e) {
        console.error(e)
        if (e == 'not found') alert('無效的UID')
      } finally {
        load.close()
      }
    }

    return {
      user,
      searchText,
      search,
      userHashId
    }
  }
})
