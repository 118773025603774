
import { defineComponent, onBeforeMount, ref, reactive } from 'vue'
import { useModal } from '@/plugins/modals/index'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MaterialType } from '@/types/index'
import { ManufactureService } from '@/services/'
export default defineComponent({
  name: 'Manufacture',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const data = reactive<Array<MaterialType.ManufactureMaterial<MaterialType.Material>>>([])
    const { openModal } = useModal()
    const columns = TableColumns.MANUFACTURE
    const { toEditManufactureitemPage } = useRouter()
    let closeNewItem: Function | null = null
    const showMaterialData = (value: any, type: MaterialType.MaterialKey) => {
      if (value.info) return value.info[type]
    }

    onBeforeMount(async () => {
      const result = await ManufactureService.getAllManufactureItem(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    const validateAndChangePage = (id: string) => {
      if (!id) {
        openModal('alertModal', { icon: 'warning', message: '請選擇道具' })
        return false
      }
      closeNewItem!()
      toEditManufactureitemPage(id)
    }

    const newItem = () => {
      const { closeModal } = openModal('newItem', { confirmFunction: validateAndChangePage })
      closeNewItem = closeModal
    }

    const resetData = () => {
      data.length = 0
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await ManufactureService.getAllManufactureItem(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    return {
      columns,
      data,
      page,
      toEditManufactureitemPage,
      newItem,
      getDataByPage,
      totalPage,
      showMaterialData
    }
  }
})
