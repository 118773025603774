import * as grpcLib from '@/libs/grpc'
import * as adminPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/iam_pb'
import { IamPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/iam_grpc_web_pb'
// 新的API 路徑
grpcLib.setJkfHost(grpcLib.JkfHost.ADMIN, process.env.VUE_APP_JKF_API_HOST as string)
const IamService = new IamPromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.ADMIN))
grpcLib.EnableGrpcDebugTools(IamService)

export const googleSignIn = async (accessToken: string, expireAt: number): Promise<adminPb.LoginResponse> => {
  const req: adminPb.LoginRequest = new adminPb.LoginRequest()
  req.setGoogleAccessToken(accessToken)
  // @ts-ignore
  const protoTime = new window.proto.google.protobuf.Timestamp()
  protoTime.fromDate(new Date(expireAt))
  req.setExpireAt(protoTime)
  const res: adminPb.LoginResponse = await IamService.login(req)
  return grpcLib.resolveData(res)
}
