
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      required: true
    },
    totalPage: {
      type: Number,
      default: Infinity
    }
  },
  emits: ['changePage'],
  setup (props, { emit }) {
    const prevPage = (page: number) => {
      emit('changePage', page - 1, true)
    }

    const nextPage = (page: number) => {
      emit('changePage', page + 1, false)
    }
    return {
      prevPage,
      nextPage
    }
  }
})
