
import { defineComponent, ref, reactive, onBeforeMount, computed, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useModal } from '@/plugins/modals/index'
import { ManufactureService } from '@/services/'
import { MaterialType, TableColumns } from '@/types'
import { getMaterial } from '@/store/material'
import { helper as $h } from '@/utils/helper'
import { useLoading } from '@/plugins/loading/'
import useRouter from '@/router/useRouter'
import InfoCard from '@/components/info-card/Main.vue'
import DataTable from '@/components/data-table/Main.vue'
import ItemData from '@/components/data/itemOrMedal'
import SvgIcon from '@/components/svg-icons/Main.vue'

type FURNACE = {
  id: string
  description: string
  displayName: string
  count: number
}
type SIDE_PRODUCT = {
  id: string
  description: string
  displayName: string
  maxCount: number
  minCount: number
}

type MANUFACTURE_INFO = {
  coins: string
  successRate: string
  count: string
  consumableMaterial: Array<FURNACE>
  inconsumableMaterial: Array<FURNACE>
  sideProduct: Array<SIDE_PRODUCT>
}

export default defineComponent({
  components: {
    InfoCard,
    DataTable,
    ItemData,
    SvgIcon
  },
  setup () {
    const route = useRoute()
    const itemId: string = route.params.id as string
    const { openModal } = useModal()
    const { toManufacturePage } = useRouter()
    const { startLoading } = useLoading()
    const state = ref<'new' | 'edit'>('new')
    const tableColumns = TableColumns.CONSUMABLE_COLUMNS
    const withMaxAndMinColumns = TableColumns.SIDE_PRODUCT_COLUMNS
    const columns = [
      'ID',
      'weight'
    ]
    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存變更' : '新增合成道具'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? ManufactureService.update : ManufactureService.create
      return {
        btnText,
        successText,
        API
      }
    })
    const manufactureInfo = reactive<MANUFACTURE_INFO>({
      coins: '',
      successRate: '',
      count: '',
      consumableMaterial: [],
      inconsumableMaterial: [],
      sideProduct: []
    })

    const validateRule = {
      coins: {
        required: true,
        errorMessage: '請輸入消耗金幣',
        rule: new RegExp(/^(0|[1-9][0-9]*)$/),
        ruleMessage: '請輸入正確的數字格式'
      },
      successRate: {
        required: true,
        errorMessage: '請輸入成功率',
        rule: new RegExp(/^(0|[1-9][0-9]?|100)$/),
        ruleMessage: '請輸入正確的數字格式'
      },
      count: {
        required: true,
        errorMessage: '請輸入製造數量',
        rule: new RegExp(/^(0|[1-9][0-9]*)$/),
        ruleMessage: '請輸入正確的數字格式'
      },
      consumableMaterial: {
        required: true,
        errorMessage: '請選擇要消耗的道具'
      },
      inconsumableMaterial: {
        required: false
      },
      sideProduct: {
        required: true,
        errorMessage: '合成失敗的副產品不得為空'
      }
    }

    const deleteMaterial = (id: string, type: 'consumableMaterial' | 'inconsumableMaterial' | 'sideProduct') => {
      const index = manufactureInfo[type].findIndex((val: any) => val.id === id)
      manufactureInfo[type].splice(index, 1)
    }

    const addSideProductModal = (type: 'sideProduct') => {
      // 副產品防呆
      if (type === 'sideProduct' && manufactureInfo.sideProduct.length > 0) {
        openModal('alertModal', { message: '副產品只能設定一個', icon: 'warning' })
        return false
      }
      const confirmFunction = async ({ materialId, rate, minCount, maxCount }: {
        materialId: string,
        rate: number,
        minCount: number,
        maxCount: number
      }) => {
        const map = await getMaterial([materialId])
        manufactureInfo.sideProduct.push({
          id: materialId as string,
          description: map.get(materialId)?.description || '',
          displayName: map.get(materialId)?.displayName || '',
          minCount,
          maxCount
        })
      }
      const filterItemIds = manufactureInfo[type].map(val => val.id)
      openModal('addChestMaterialModal', { confirmFunction, filterItemIds, needRate: false })
    }

    const addMaterialModal = (type: 'inconsumableMaterial' | 'consumableMaterial') => {
      const confirmFunction = async(id: string, count: number) => {
        const map = await getMaterial([id])
        manufactureInfo[type].push({
          id: id as string,
          description: map.get(id)?.description || '',
          displayName: map.get(id)?.displayName || '',
          count: count
        })
      }
      const filterItemIds = manufactureInfo[type].map(val => val.id)
      openModal('addMaterialModal', { confirmFunction, filterItemIds })
    }

    const submit = async(e: Event) => {
      e.preventDefault()
      const fn = unref(stateMethod)
      const data = {
        id: itemId,
        coins: parseInt(manufactureInfo.coins),
        successRate: parseInt(manufactureInfo.successRate),
        count: parseInt(manufactureInfo.count),
        sideProduct: manufactureInfo.sideProduct.map((val: any) => ({ id: val.id.toString(), maxCount: parseInt(val.maxCount), minCount: parseInt(val.minCount) })),
        consumableMaterial: manufactureInfo.consumableMaterial.map((val: any) => ({ id: val.id.toString(), count: parseInt(val.count) })),
        inconsumableMaterial: manufactureInfo.inconsumableMaterial.map((val: any) => ({ id: val.id.toString(), count: parseInt(val.count) }))
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }

      // 副產品防呆
      if (data.sideProduct.length > 1) {
        openModal('alertModal', { message: '副產品只能設定一個', icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        await fn.API(data)
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toManufacturePage()
      } catch (e) {
        console.error(e)
        openModal('alertModal', { icon: 'success', message: '出錯了' })
      } finally {
        loading.close()
      }
    }

    const softDelete = () => {
      const deleateAPI = async() => {
        const loading = startLoading()
        try {
          await ManufactureService.deleteItem(itemId)
          openModal('alertModal', { icon: 'success', message: '刪除成功' })
          toManufacturePage()
        } catch (e) {
          console.error(e.message)
          openModal('alertModal', { icon: 'success', message: '出錯了' })
        } finally {
          loading.close()
        }
      }
      openModal('confirmModal', { message: '確定刪除此合成道具嗎?', confirmFunction: deleateAPI })
    }

    onBeforeMount(async() => {
      const data = await ManufactureService.getManufactureInfo([itemId])
      if (data.length <= 0) {
        state.value = 'new'
        return false
      }
      state.value = 'edit'
      manufactureInfo.coins = data[0].coins.toString()
      manufactureInfo.successRate = data[0].successRate.toString()
      manufactureInfo.count = data[0].count.toString()

      // 轉換製作材料, 需要優化
      const consumableID = data[0].consumableMaterial.map((val: MaterialType.ConsumableMaterial) => val.id)
      const inconsumableID = data[0].inconsumableMaterial.map((val: MaterialType.ConsumableMaterial) => val.id)
      const sideProductID = data[0].sideProduct.map((val: MaterialType.SideProductMaterial) => val.id)

      const materialIDs = ([] as Array<string>).concat(consumableID, inconsumableID, sideProductID)
      const materialMap = await getMaterial(materialIDs)

      const getMaterialInfo = (val: any) => {
        const data = materialMap.get(val.id)
        return {
          id: val.id,
          description: data?.description || '',
          displayName: data?.displayName || '',
          count: val.count
        }
      }
      const getSideProductInfo = (val: any) => {
        const data = materialMap.get(val.id)
        return {
          id: val.id,
          description: data?.description || '',
          displayName: data?.displayName || '',
          minCount: val.minCount,
          maxCount: val.maxCount
        }
      }
      manufactureInfo.consumableMaterial = data[0].consumableMaterial.map(getMaterialInfo)
      manufactureInfo.inconsumableMaterial = data[0].inconsumableMaterial.map(getMaterialInfo)
      manufactureInfo.sideProduct = data[0].sideProduct.map(getSideProductInfo)
    })

    return {
      columns,
      itemId,
      tableColumns,
      withMaxAndMinColumns,
      state,
      manufactureInfo,
      validateRule,
      stateMethod,
      submit,
      softDelete,
      deleteMaterial,
      addMaterialModal,
      addSideProductModal
    }
  }
})
