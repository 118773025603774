import { GuardianAPI, IamAPI } from '@/api/'
import { QueryRoleAllByUser } from '@/api/guardian'
import { loginedStatus } from '@/store/user'
import { getRole } from '@/store/role'
import { DomainID, setIamMap, deleteIamUser } from '@/store/iam'
import { IamType, RoleType } from '@/types'
import { GuardianService } from '@/services/'
import * as IdentityPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/identity_pb'

export const createIdentity = async(displayName: string, email: string) => {
  await IamAPI.CreateIdentity(loginedStatus.token, { displayName, email })
}

export const createIdentityAndBindRole = async(displayName: string, email: string, roleId: string) => {
  // 創建 iam user
  await IamAPI.CreateIdentity(loginedStatus.token, { displayName, email })
  const role = getRole(roleId)
  if (!role.isExist) {
    throw new Error('not has this role')
  }

  // 綁定 role 到 user
  await GuardianAPI.AddRoleForUser(loginedStatus.token, {
    email,
    roleId: role.roleId,
    domain: role.domain
  })
}

export const updateIdentityAndBindRole = async(displayName: string, email: string, oldRole: RoleType.Role[], roleId: string) => {
  // 更新 iam user 資料
  await IamAPI.UpdateIdentity(loginedStatus.token, { displayName, email })

  const deleteAPIList = oldRole.map((val) => {
    // 如果有設定舊的role 要刪除
    const oldRole = getRole(val.roleId)
    if (oldRole.isExist) {
      return GuardianAPI.RemoveRoleForUser(loginedStatus.token, {
        email,
        roleId: oldRole.roleId,
        domain: oldRole.domain
      })
    }
  })
  await Promise.all(deleteAPIList)
  const role = getRole(roleId)

  if (!role.isExist) {
    throw new Error('not has this role')
  }

  await GuardianAPI.AddRoleForUser(loginedStatus.token, {
    email,
    roleId: role.roleId,
    domain: role.domain
  })
}

export const createIamUser = (data: IdentityPB.Identity): IamType.Iam => {
  return {
    email: data.getEmail(),
    displayName: data.getDisplayName()
  }
}

export const getAllIamUserAndRole = async():Promise<{ user: IamType.Iam, role: RoleType.Role[] }[]> => {
  const iamUserList = await getAllIamUser()
  const roleList = await Promise.all(
    iamUserList.map((iam) => getRoleAllByUser(iam.email))
  )
  return iamUserList.map((iamUser, index) => {
    return {
      user: iamUser,
      role: roleList[index].getRoleAllList().map((val) => GuardianService.createRoleUser(val))
    }
  })
}

export const deleteIdentity = async(displayName: string, email: string, oldRole: RoleType.Role[]) => {
  await IamAPI.DeleteIdentity(loginedStatus.token, { displayName, email })
  deleteIamUser(email)
  oldRole.forEach((val) => {
    const oldRole = getRole(val.roleId)
    if (oldRole.isExist) {
      GuardianAPI.RemoveRoleForUser(loginedStatus.token, {
        email,
        roleId: oldRole.roleId,
        domain: oldRole.domain
      })
    }
  })
}

const getAllIamUser = async():Promise<IamType.Iam[]> => {
  const apiData = await IamAPI.QueryIdentityAll(loginedStatus.token)
  return apiData.getIdentityAllList().map((val) => {
    const iam = createIamUser(val)
    setIamMap(iam.email, iam)
    return iam
  })
}

const getRoleAllByUser = async(email: string) => {
  const result = await QueryRoleAllByUser(loginedStatus.token, DomainID, email)
  return result
}
