import { render } from "./Main.vue?vue&type=template&id=2814dbd2"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2814dbd2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2814dbd2', script)) {
    api.reload('2814dbd2', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=2814dbd2", () => {
    api.rerender('2814dbd2', render)
  })

}

script.__file = "src/views/search-user/Main.vue"

export default script