import * as grpcLib from '@/libs/grpc'
import * as adminEnhancePb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/enhance_pb'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import { AdminEnhancePromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/enhance_grpc_web_pb'
import { MedalType } from '@/types'

grpcLib.setJkfHost(grpcLib.JkfHost.MEDAL, process.env.VUE_APP_JKF_API_HOST as string)
const EnhanceService = new AdminEnhancePromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MEDAL))
grpcLib.EnableGrpcDebugTools(EnhanceService)

// 這裡出來的 isSeries 一定是 false
export const getAll = async(token: string, pageSize: number, page: number): Promise<adminEnhancePb.GetAllEnhanceRes> => {
  const req: adminEnhancePb.GetAllEnhanceReq = new adminEnhancePb.GetAllEnhanceReq()
  req.setAdminToken(token)
  const paginator = new materialPb.Paginator()
  paginator.setPage(page)
  paginator.setPageSize(pageSize)
  req.setPaginator(paginator)
  try {
    const res = await EnhanceService.getAll(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getInfo = async(token: string, rootMedalId: string):Promise<adminEnhancePb.GetInfoEnhanceRes> => {
  const req: adminEnhancePb.GetInfoEnhanceReq = new adminEnhancePb.GetInfoEnhanceReq()
  req.setAdminToken(token)
  req.setRootId(rootMedalId)
  try {
    const res: adminEnhancePb.GetInfoEnhanceRes = await EnhanceService.getInfo(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const create = async(token: string, rootId: string, recipeList: Array<MedalType.EnhanceRecipe<MedalType.Medal>>): Promise<adminEnhancePb.CreateEnhanceRes> => {
  const req: adminEnhancePb.CreateEnhanceReq = new adminEnhancePb.CreateEnhanceReq()
  req.setAdminToken(token)
  req.setRootId(rootId)
  const dataList = createEnhanceRecipe(recipeList)
  req.setRecipeList(dataList)
  try {
    const res: adminEnhancePb.CreateEnhanceRes = await EnhanceService.create(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const update = async(token: string, rootId: string, recipeList: Array<MedalType.EnhanceRecipe<MedalType.Medal>>):Promise<adminEnhancePb.UpdateEnhanceRes> => {
  const req: adminEnhancePb.UpdateEnhanceReq = new adminEnhancePb.UpdateEnhanceReq()
  req.setAdminToken(token)
  req.setRootId(rootId)
  const dataList = createEnhanceRecipe(recipeList)
  req.setRecipeList(dataList)
  try {
    const res: adminEnhancePb.UpdateEnhanceRes = await EnhanceService.update(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

const createEnhanceRecipe = (recipeList: Array<MedalType.EnhanceRecipe<MedalType.Medal>>): Array<adminEnhancePb.EnhanceRecipe> => {
  // 好多層
  return recipeList.map((val) => {
    const enhanceRecipe = new adminEnhancePb.EnhanceRecipe()
    enhanceRecipe.setCoins(val.coins)
    enhanceRecipe.setSuccessRate(val.successRate)
    enhanceRecipe.setMedalId(val.info.id)
    const consumable = val.consumableMaterial.map((val) => {
      const data = new adminEnhancePb.EnhanceRecipe.EnhanceRequire()
      data.setMaterialId(val.id)
      data.setCount(val.count)
      return data
    })

    const inconsumable = val.inconsumableMaterial.map((val) => {
      const data = new adminEnhancePb.EnhanceRecipe.EnhanceRequire()
      data.setMaterialId(val.id)
      data.setCount(val.count)
      return data
    })
    enhanceRecipe.setConsumableList(consumable)
    enhanceRecipe.setInconsumableList(inconsumable)
    return enhanceRecipe
  })
}
