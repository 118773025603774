import * as medalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/medal/query_pb'
import * as adminMedalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/furnace_pb'
type MedalLogType = keyof typeof medalPb.LogType

export enum LogType {
  LOG_TYPE_NONE = 0,
  LOG_TYPE_ALL = 1,
  LOG_TYPE_SEND = 2,
  LOG_TYPE_RECEIVE = 3,
  LOG_TYPE_FURNACE = 4,
  LOG_TYPE_UPGRADE = 5,
  LOG_TYPE_ENHANCE = 7
}

export type LogItem = {
  type: LogType
  text: string
  created: number
  note: string
}

export const transfer = {
  [medalPb.LogType.LOG_TYPE_NONE]: LogType.LOG_TYPE_NONE,
  [medalPb.LogType.LOG_TYPE_ALL]: LogType.LOG_TYPE_ALL,
  [medalPb.LogType.LOG_TYPE_SEND]: LogType.LOG_TYPE_SEND,
  [medalPb.LogType.LOG_TYPE_RECEIVE]: LogType.LOG_TYPE_RECEIVE,
  [medalPb.LogType.LOG_TYPE_FURNACE]: LogType.LOG_TYPE_FURNACE,
  [medalPb.LogType.LOG_TYPE_UPGRADE]: LogType.LOG_TYPE_UPGRADE,
  [medalPb.LogType.LOG_TYPE_ENHANCE]: LogType.LOG_TYPE_ENHANCE
}

export const LogToGrpc = {
  [LogType.LOG_TYPE_NONE]: medalPb.LogType.LOG_TYPE_NONE,
  [LogType.LOG_TYPE_ALL]: medalPb.LogType.LOG_TYPE_ALL,
  [LogType.LOG_TYPE_SEND]: medalPb.LogType.LOG_TYPE_SEND,
  [LogType.LOG_TYPE_RECEIVE]: medalPb.LogType.LOG_TYPE_RECEIVE,
  [LogType.LOG_TYPE_FURNACE]: medalPb.LogType.LOG_TYPE_FURNACE,
  [LogType.LOG_TYPE_UPGRADE]: medalPb.LogType.LOG_TYPE_UPGRADE,
  [LogType.LOG_TYPE_ENHANCE]: medalPb.LogType.LOG_TYPE_ENHANCE
}
export const transformType = {
  [LogType.LOG_TYPE_NONE]: '---',
  [LogType.LOG_TYPE_ALL]: '全部紀錄',
  [LogType.LOG_TYPE_RECEIVE]: '獲贈紀錄',
  [LogType.LOG_TYPE_FURNACE]: '合成記錄',
  [LogType.LOG_TYPE_UPGRADE]: '升級紀錄',
  [LogType.LOG_TYPE_SEND]: '贈送紀錄',
  [LogType.LOG_TYPE_ENHANCE]: '強化紀錄'
}

export enum ConsumableType {
  TYPE_NONE = 0,
  TYPE_MATERIAL = 1,
  TYPE_MEDAL = 2
}

export enum MedalCategory {
  CATEGORY_NONE = 0,
  CATEGORY_ONE = 1,
  CATEGORY_TWO = 2,
  CATEGORY_THREE = 3
}

export type MedalProperty = {
  furnaceLuck: number
  craftLuck: number
  enhanceLuck: number
}

export type Medal = {
  id: string
  displayName: string
  img: string
  enhanceAnimationImg: string
  description: string
  category: MedalCategory
  canApply: boolean
  canEnhance: boolean
  isSeries: boolean
  isRoot: boolean
  property: MedalProperty
  available: boolean
}

export type ConsumableMaterial = {
  id: string
  type: ConsumableType
  count: number
}

export type SideProduct = {
  id: string
  minCount: number
  maxCount: number
}

export interface ManufactureMedal<T> {
  info: T
  successRate: number
  coins: number
  count: number
  consumableMaterial: Array<ConsumableMaterial>
  inconsumableMaterial: Array<ConsumableMaterial>
  sideProduct: Array<SideProduct>
}

export type MedalKey = keyof Medal

export const categoryToGrpcType = {
  [MedalCategory.CATEGORY_NONE]: medalPb.Category.CATEGORY_NONE,
  [MedalCategory.CATEGORY_ONE]: medalPb.Category.CATEGORY_ONE,
  [MedalCategory.CATEGORY_TWO]: medalPb.Category.CATEGORY_TWO,
  [MedalCategory.CATEGORY_THREE]: medalPb.Category.CATEGORY_THREE
}

export const grpcToConsumableMaterial = {
  [adminMedalPb.ConsumableType.TYPE_NONE]: ConsumableType.TYPE_NONE,
  [adminMedalPb.ConsumableType.TYPE_MEDAL]: ConsumableType.TYPE_MEDAL,
  [adminMedalPb.ConsumableType.TYPE_MATERIAL]: ConsumableType.TYPE_MATERIAL
}

export type EnhanceRecipe<T> = {
  // medal leaf
  // 照規則來講 這裡的medalId isSeries 都會是true
  info: T
  coins: number
  successRate: number
  consumableMaterial: Array<ConsumableMaterial>
  inconsumableMaterial: Array<ConsumableMaterial>
}

export type EnhanceInterface<T> = {
  info: T
  // 是否為強化勳章的起源
  isRoot: boolean
  recipe: Array<EnhanceRecipe<T>>
}

export const transformToConsumableMaterial = <T extends ConsumableMaterial>(data: T):ConsumableMaterial => {
  return {
    id: data.id,
    type: ConsumableType.TYPE_MATERIAL,
    count: data.count
  }
}

export const createLogItem = (item: medalPb.LogMedal): LogItem => {
  return {
    type: transfer[item.getType()],
    text: item.getText(),
    created: item.getCreated().getSeconds() * 1000,
    note: item.getNote()
  }
}
