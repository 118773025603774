
import { defineComponent, PropType } from 'vue'
import { ElTable, ElTableColumn } from 'element-plus'

type ColumnType = {
  prop: string
  label: string
  sortable?: boolean
  width?: string | number
}
export default defineComponent({
  components: {
    ElTable,
    ElTableColumn
  },
  props: {
    border: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array as PropType<Array<ColumnType>>,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    sortChange: {
      type: Function as PropType<(...args: any[]) => void>,
      // @ts-ignore
      default: () => {
        console.log('default')
      }
    }
  }
})
