import { render } from "./uploadImg.vue?vue&type=template&id=d3f62dd4&scoped=true"
import script from "./uploadImg.vue?vue&type=script&lang=ts"
export * from "./uploadImg.vue?vue&type=script&lang=ts"

import "./uploadImg.vue?vue&type=style&index=0&id=d3f62dd4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-d3f62dd4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d3f62dd4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d3f62dd4', script)) {
    api.reload('d3f62dd4', script)
  }
  
  module.hot.accept("./uploadImg.vue?vue&type=template&id=d3f62dd4&scoped=true", () => {
    api.rerender('d3f62dd4', render)
  })

}

script.__file = "src/components/item/uploadImg.vue"

export default script