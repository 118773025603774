<template>
  <div class="grid grid-cols-1 xl:grid-cols-3">
    <my-input
      class="mb-3 xl:col-start-2"
      type="text"
      label="UID (可用,輸入多格UID)"
      v-model="submitData.uid"
    />
    <my-select
      class="mb-3 xl:col-start-2"
      label="發送通知"
      v-model="submitData.isNotification"
    >
    <option value="true">是</option>
      <option value="false">否</option>
    </my-select>
    <my-input
      class="mb-3 xl:col-start-2"
      type="textarea"
      label="發送說明"
      v-model="submitData.message"
    />
    <div class="mb-3 xl:col-start-2">
      <label class="form-label text-lg">選擇勳章</label>
      <loading-items itemType="Medal" v-slot="{ loadingData, items, state }">
        <infinite-scroll :loadingData="loadingData" class="border border-gray-200 mt-0">
          <div v-if="state !== 'done'" class="w-full h-full animate-pulse bg-gray-300"></div>
          <div
              v-else
              class="border-b border-grey-200 py-3 px-5 cursor-pointer grid grid-cols-2"
              :class="{ 'bg-gray-300': submitData.medalId == item.id }"
              v-for="(item, index) in items"
              :key="index"
              @click=selectNewItem(item.id)
              >
              <div class="flex justify-start items-center">
                <img :src="item.img" width="20" class="img">
              </div>
            <div class="flex justify-start items-center">
              {{ item.displayName }}
            </div>
          </div>
        </infinite-scroll>
      </loading-items>
    </div>
    <!-- <div class="mb-3 col-start-1 xl:col-end-4 xl:ml-20"> -->
      <!-- <p class="text-xl font-bold border-b-2 mb-5 pb-2 border-gray-200">會員組</p> -->
      <!-- <role-list v-model="submitData.allowed" /> -->
    <!-- </div> -->
    <div class="mt-4 xl:col-start-2">
      <button class="btn btn-primary w-full" @click="submit">寄出</button>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { useLoading } from '@/plugins/loading/'
import { useModal } from '@/plugins/modals/'
import { helper as $h } from '@/utils/helper'
// import RoleList from '@/components/roleList/Main.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import { MedalService } from '@/services'

export default defineComponent({
  name: 'SendItem',
  components: {
    // RoleList,
    InfiniteScroll,
    LoadingItems
  },
  setup () {
    const { openModal } = useModal()
    const submitData = reactive({
      uid: '',
      isNotification: 'false',
      medalId: '',
      allowed: [],
      message: ''
    })

    const validateRule = {
      uid: {
        required: true,
        errorMessage: '請輸入Uid'
      },
      isNotification: {
        required: true,
        errorMessage: '請選擇是否通知'
      },
      medalId: {
        required: true,
        errorMessage: '請選擇勳章'
      },
      message: {
        required: false
      }
    }

    const selectNewItem = (item: string) => {
      submitData.medalId = item
    }
    const resetData = () => {
      submitData.uid = ''
      submitData.isNotification = 'false'
      submitData.medalId = ''
      submitData.allowed = []
      submitData.message = ''
    }

    const { startLoading } = useLoading()
    const submit = async(e: Event) => {
      e.preventDefault()
      // 用uid 寄送道具
      if (submitData.allowed.length <= 0) await sendMedalByUid()
      // 用會員組寄送道具
      else await sendMedalByRole()
    }

    const sendMedalByUid = async() => {
      const data = {
        uid: submitData.uid,
        isNotification: submitData.isNotification,
        medalId: submitData.medalId
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      // uid 要過濾
      const filterUid = data.uid.split(',').filter((val) => {
        return val && !val.startsWith('0') && !isNaN($h.filterInt(val))
      }).map((val) => {
        const data = val.trim()
        return parseInt(data)
      })
      if (filterUid.length <= 0) {
        openModal('alertModal', { message: '請輸入Uid', icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        await MedalService.shipItem({
          uid: filterUid.map((val) => val.toString()),
          isNotification: Boolean(submitData.isNotification),
          medalId: submitData.medalId,
          message: submitData.message
        })
        openModal('alertModal', { icon: 'success', message: '成功寄出道具' })
        resetData()
      } catch (e) {
        console.error(e)
        const errorMessage = e.code === 1001 ? '找不到該用戶'
          : e.code === 1002 ? '無法重複寄送相同勳章' : '出錯了'
        openModal('alertModal', { icon: 'warning', message: errorMessage })
        resetData()
      } finally {
        loading.close()
      }
    }

    const sendMedalByRole = async() => {
      const validateRule = {
        medalId: {
          required: true,
          errorMessage: '請選擇勳章'
        },
        role: {
          required: true,
          errorMessage: '請選擇會員組'
        },
        message: {
          required: false
        }
      }
      const data = {
        medalId: submitData.medalId,
        role: submitData.allowed,
        message: submitData.message
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        await MedalService.shipItemByRole({
          isNotification: Boolean(submitData.isNotification),
          medalId: submitData.medalId,
          role: data.role.map((val) => val),
          message: submitData.message
        })
        openModal('alertModal', { icon: 'success', message: '成功寄出道具' })
        resetData()
      } catch (e) {
        console.error(e)
        openModal('alertModal', { icon: 'warning', message: '出錯了' })
        resetData()
      } finally {
        loading.close()
      }
    }

    return {
      submit,
      submitData,
      selectNewItem
    }
  }
})
</script>

<style scoped>
  .img{
    width: 20px;
    height: 32.72px;
  }
</style>
