<template>
  <div class="flex flex-col sm:flex-row items-center">
    <div class="mr-2">
      <select class="form-select w-full lg:w-auto lg:mt-0 sm:mr-2" v-model="domainSelValue" @change="changeDomainValue()">
        <option v-for="data in DomainState.domainAll" v-bind:key="`role-domain-sel-${data.getDomainId()}`" :value="DomainToOption(data)">
          {{ `${data.getDomainId()}: ${data.getDisplayName()}` }}
        </option>
      </select>
    </div>
    <button class="btn btn-primary lg:mt-0 mt-2" @click="createRole">新增用戶組</button>
  </div>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
      <tr>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Role ID</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">顯示名稱</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Domain</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(data, index) in roleAll" v-bind:key="`role-row-${domainSelValue}-${data.getDomain()}-${data.getRoleId()}`">
        <tr v-if="index % 2 === 0" class="bg-gray-200 dark:bg-dark-1">
          <td class="border-b dark:border-dark-5">{{ data.getRoleId() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getDisplayName() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getDomain() }}</td>
          <td class="border-b dark:border-dark-5">
            <div class="flex flex-nowrap">
              <button class="btn btn-dark mr-2 w-20" @click="updateRole(data)">編輯</button>
            </div>
          </td>
        </tr>
        <tr v-else>
          <td class="border-b dark:border-dark-5">{{ data.getRoleId() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getDisplayName() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getDomain() }}</td>
          <td class="border-b dark:border-dark-5">
            <div class="flex flex-nowrap">
              <button class="btn btn-dark mr-2 w-20" @click="updateRole(data)">編輯</button>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useModal } from '@/plugins/modals/'
import { getDomainAll, getRoleAllByDomain, DomainState } from '@/store/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'
import { DomainToOption, OptionToDomain } from '@/api/guardian'

export default defineComponent({
  setup() {
    const { openModal } = useModal()
    const domainSelValue = ref<null | string>(null)
    const roleAll = ref<GuardianQueryPB.Role[]>([])
    const createRole = async(e: Event) => {
      e.preventDefault()
      if (!domainSelValue.value) {
        return
      }
      const domain = OptionToDomain(domainSelValue.value)
      if (domain) {
        openModal('editGuardianRole',
          {
            mode: 'create',
            domainID: domain.getDomainId(),
            done: () => {
              getRoleAllByDomain(domain)
            }
          }
        )
      }
    }
    const updateRole = async(role: GuardianQueryPB.Role) => {
      if (!domainSelValue.value) {
        return
      }
      const domain = OptionToDomain(domainSelValue.value)
      if (domain) {
        openModal('editGuardianRole',
          {
            mode: 'edit',
            domainID: role.getDomain(),
            roleID: role.getRoleId(),
            displayName: role.getDisplayName(),
            done: () => {
              getRoleAllByDomain(domain)
            }
          }
        )
      }
    }
    const changeDomainValue = async () => {
      if (domainSelValue.value) {
        const selectedDomain = OptionToDomain(domainSelValue.value)
        if (selectedDomain) {
          await getRoleAllByDomain(selectedDomain)
          const value = DomainState.domainAllRoleAll.get(DomainToOption(selectedDomain))
          if (value) {
            roleAll.value = value
          }
        }
      }
    }

    onMounted(async() => {
      await getDomainAll()
      if (DomainState.domainAll.length > 0) {
        domainSelValue.value = DomainToOption(DomainState.domainAll[0])
        if (domainSelValue.value) {
          await getRoleAllByDomain(DomainState.domainAll[0])
          const value = DomainState.domainAllRoleAll.get(DomainToOption(DomainState.domainAll[0]))
          if (value) {
            roleAll.value = value
          }
        }
      }
    })
    return {
      createRole,
      updateRole,
      DomainState,
      domainSelValue,
      getRoleAllByDomain,
      OptionToDomain,
      DomainToOption,
      changeDomainValue,
      roleAll
    }
  }
})
</script>
