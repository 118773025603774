
import { defineComponent, PropType, ref, computed } from 'vue'
import Modal from './template.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    },
    itemType: {
      type: String as PropType<'Material' | 'Medal'>,
      default: 'Material'
    },
    filterItemFunction: {
      type: Function as PropType<
        (val: any, index: number, array: Array<any>) => boolean
      >,
      default: (val: any, index: any, array: Array<any>) => true
    }
  },
  setup(props) {
    const isSelect = ref<null | string>(null)
    const title = computed(() => {
      return props.itemType == 'Material' ? '選擇道具' : '選擇勳章'
    })

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    return {
      selectNewItem,
      isSelect,
      title
    }
  }
})
