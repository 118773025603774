<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar justify-end">
    <!-- BEGIN: Breadcrumb -->
    <!-- <div class="breadcrumb mr-auto hidden sm:flex" v-once> -->
      <!-- <router-link to="/">回首頁</router-link> -->
    <!-- </div> -->
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Account Menu -->
    <div class="dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit"
        role="button"
        aria-expanded="false"
      >
        <img
          :src="require(`@/assets/images/preview-1.jpg`)"
        />
      </div>
      <div class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white rounded-md"
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">{{ loginedStatus.email }}</div>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <a
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer"
              @click="userLogout"
            >
              <svg-icon class="w-4 h-4 mr-2" name="mdiToggleSwitchOutline" /> Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { loginedStatus, logout } from '@/store/user'
import SvgIcon from '@/components/svg-icons/Main.vue'
export default defineComponent({
  components: {
    SvgIcon
  },
  setup() {
    const searchDropdown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }
    const userLogout = () => {
      logout()
      window.location.href = '/login'
    }
    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      logout,
      loginedStatus,
      userLogout
    }
  }
})
</script>
