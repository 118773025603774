<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="box lg:mt-5">
      <div class="p-5">
        <item-data :id="itemId" v-slot="{ img, id, weight, description, displayName }">
          <div class="flex flex-col-reverse xl:flex-row flex-col">
            <!-- 資訊 -->
            <div class="flex-1 mt-6 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 xxl:col-span-6" v-if="state == 'edit'">
                  <my-input
                    label="ID"
                    disabled
                    type="text"
                    :initValue="id"
                    v-model="submitData.id"
                  />
                </div>
                <div class="col-span-12 xxl:col-span-6">
                  <my-input
                    :required="validateRule.displayName.required"
                    :errorMessage="validateRule.displayName.errorMessage"
                    :initValue="displayName"
                    label="名稱"
                    type="text"
                    v-model="submitData.displayName"
                  />
                </div>
                <div class="col-span-12 xxl:col-span-6">
                  <my-input
                    label="重量"
                    :required="validateRule.weight.required"
                    :requireRule="validateRule.weight.rule"
                    :errorMessage="validateRule.weight.errorMessage"
                    :regexErrorMessage="validateRule.weight.ruleMessage"
                    type="number"
                    :initValue="weight"
                    v-model="submitData.weight"
                  />
                </div>
                <div class="col-span-12 xxl:col-span-6">
                  <my-input
                    :required="validateRule.description.required"
                    :errorMessage="validateRule.description.errorMessage"
                    label="簡介"
                    type="textarea"
                    :initValue="description"
                    v-model="submitData.description"
                    />
                </div>
              </div>
            </div>
            <!-- 資訊 end -->
            <!-- 照片 -->
            <div class="w-52 mx-auto xl:mr-0 xl:ml-6">
              <upload-img
                :image="img"
                v-model="submitData.img"
                :buttonTitle="stateMethod.uploadImgText"
                :limitWidth="IMAGE_WIDTH"
                :limitHeight="IMAGE_HEIGHT"
              />
            </div>
            <!-- 照片 end -->
          </div>
        </item-data>
        <div class="border-t border-gray-200 pt-2 mb-2">
          <div v-show="stateMethod.isEdit && selectItemMode !== originItemMode" class="alert alert-warning-soft show flex items-center mb-2" role="alert">注意! 更新模式後，會將原先模式的設定全部清除。</div>
          <div class="w-full sm:w-1/3">
            <my-select
              label="道具類型"
              v-model="selectItemMode"
            >
              <option value="NONE">無</option>
              <option value="CAN_USE">可被使用</option>
              <option value="LOCK_PLUS">幸運加成</option>
            </my-select>
          </div>
        </div>
        <div v-show="selectItemMode !== 'NONE'" class="box pt-3">
          <collapse-transition>
              <effect-setting
                ref="effectSettingRef"
                :isShow="selectItemMode === 'CAN_USE'"
                v-model:effectType="effectInfo.effectType"
                v-model:chest="effectInfo.chest"
              />
          </collapse-transition>
          <collapse-transition>
          <div class="w-1/4 px-5 py-3" v-show="selectItemMode === 'LOCK_PLUS'">
            <item-data :id="itemId" v-slot="{ furnaceLuck, craftLuck, enhanceLuck }">
            <div class="col-span-12 xxl:col-span-6">
              <my-input
                label="製作道具加成"
                :requireRule="validateRule.luckNumber.rule"
                :errorMessage="validateRule.luckNumber.errorMessage"
                :regexErrorMessage="validateRule.luckNumber.ruleMessage"
                type="number"
                :initValue="craftLuck"
                :min="0"
                :max="100"
                v-model="submitData.craftLuck"
              />
            </div>
            <div class="col-span-12 xxl:col-span-6">
              <my-input
                label="合成勳章加成"
                :requireRule="validateRule.luckNumber.rule"
                :errorMessage="validateRule.luckNumber.errorMessage"
                :regexErrorMessage="validateRule.luckNumber.ruleMessage"
                type="number"
                :initValue="furnaceLuck"
                :min="0"
                :max="100"
                v-model="submitData.furnaceLuck"
              />
            </div>
            <div class="col-span-12 xxl:col-span-6">
              <my-input
                label="強化勳章加成"
                :requireRule="validateRule.luckNumber.rule"
                :errorMessage="validateRule.luckNumber.errorMessage"
                :regexErrorMessage="validateRule.luckNumber.ruleMessage"
                type="number"
                :initValue="enhanceLuck"
                :min="0"
                :max="100"
                v-model="submitData.enhanceLuck"
              />
            </div>
            </item-data>
          </div>
          </collapse-transition>
        </div>
        <div class="flex mt-5">
          <button type="button" class="btn btn-primary w-full md:w-36 mt-3" @click="submit">
            {{ stateMethod.btnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, unref, ref, onBeforeMount, Ref } from 'vue'
import UploadImg from '@/components/item/uploadImg.vue'
import ItemData from '@/components/data/itemOrMedal'
import { useRoute } from 'vue-router'
import myRouter from '@/router/useRouter'
import { helper as $h } from '@/utils/helper'
import { useModal } from '@/plugins/modals/'
import { setMaterialMap, updateMaterialMap } from '@/store/material'
import { MaterialService, EffectService } from '@/services/'
import { useLoading } from '@/plugins/loading'
import { MaterialType, EffectType } from '@/types/'
import EffectSetting from '@/components/item/effectSetting.vue'
import CollapseTransition from '@/components/transition/collapse.vue'
import itemEditComposition from '@/composition/itemEdit'

type ItemMode = 'NONE'|'CAN_USE'|'LOCK_PLUS'

export default defineComponent({
  name: 'ItemEdit',
  components: {
    UploadImg,
    ItemData,
    EffectSetting,
    CollapseTransition
  },
  setup () {
    const IMAGE_WIDTH = 100
    const IMAGE_HEIGHT = 100
    const route = useRoute()
    const { toItemPage } = myRouter()
    const itemId = route.params.id || ''
    const { startLoading } = useLoading()
    const {
      validateRule,
      checkEffectData,
      setEffectInfo
    } = itemEditComposition()
    const originItemMode = ref<ItemMode>('NONE')
    const selectItemMode = ref<ItemMode>('NONE')
    const { openModal } = useModal()
    const effectSettingRef = ref<InstanceType<typeof EffectSetting>>()
    // const isImplementEffect = ref(true)
    const state = computed(() => {
      return itemId ? 'edit' : 'new'
    })
    const effectState = ref<'new' | 'edit'>('new')
    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存' : '新增'
      const uploadImgText = isEdit ? '更換圖片' : '上傳圖片'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? MaterialService.update : MaterialService.create
      const effectAPI = effectState.value === 'edit' ? EffectService.update : EffectService.create
      const storeFunction = isEdit ? updateMaterialMap : setMaterialMap
      return {
        isEdit,
        btnText,
        successText,
        API,
        effectAPI,
        storeFunction,
        uploadImgText
      }
    })

    // 效果設定
    const effectInfo = reactive<EffectType.EffectInfo<MaterialType.Material | MaterialType.Credit>>({
      effectType: EffectType.EffectType.EFFECT_TYPE_OPEN_ONE,
      minKind: 0,
      maxKind: 0,
      chest: []
    })

    const submitData = reactive({
      id: '',
      displayName: '',
      weight: 0,
      img: '',
      description: '',
      furnaceLuck: 0,
      craftLuck: 0,
      enhanceLuck: 0
    })

    const submit = async(event: Event) => {
      event.preventDefault()
      const fn = unref(stateMethod)
      const data = {
        // NOTE: category寫死
        category: MaterialType.MaterialCategory.CATEGORY_A,
        id: submitData.id,
        displayName: submitData.displayName,
        img: submitData.img,
        weight: Number(submitData.weight),
        description: submitData.description,
        furnaceLuck: Number(submitData.furnaceLuck),
        craftLuck: Number(submitData.craftLuck),
        enhanceLuck: Number(submitData.enhanceLuck)
      }
      let submitEffectType: EffectType.EffectType = EffectType.EffectType.EFFECT_TYPE_OPEN_ONE
      let submitChestList: Array<EffectType.Chest<any>> = []
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      // 有打開使用道具
      if (selectItemMode.value === 'CAN_USE') {
        const [effectDataIsValidate, errorMessage, effectType, chest] = checkEffectData(effectSettingRef as Ref<InstanceType<typeof EffectSetting>>)
        // 使用道具驗證沒過
        if (!effectDataIsValidate) {
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
          return false
        }
        submitEffectType = effectType
        submitChestList = chest.map((val: any) => val)
      }

      // call API
      const loading = startLoading()
      try {
        // 有打開要call create or update 沒打開要call delete
        // 如果是新道具必須要等他回傳id 才能create effect
        // TODO: need refactor
        let id: string = ''
        if (state.value === 'edit') {
          // 道具類型: 無, 幸運加成
          if (['NONE', 'LOCK_PLUS'].includes(selectItemMode.value)) {
            await EffectService.deleteEffect(itemId.toString())
          }

          // 道具類型: 無
          if (['NONE'].includes(selectItemMode.value)) {
            Object.assign(data, {
              furnaceLuck: 0,
              craftLuck: 0,
              enhanceLuck: 0
            })
          }

          // 道具類型: 可使用
          if (['CAN_USE'].includes(selectItemMode.value)) {
            Object.assign(data, {
              furnaceLuck: 0,
              craftLuck: 0,
              enhanceLuck: 0
            })
            // 清空"幸運加成"
            await fn.API({
              ...data
            }).then(async () => {
              await fn.effectAPI(itemId.toString(), submitEffectType, submitChestList)
            })
          }
          id = await fn.API(data)
        } else {
          id = await fn.API(data)
          // 有打開才要call effectCreate
          selectItemMode.value === 'CAN_USE' && await fn.effectAPI(id.toString(), submitEffectType, submitChestList)
        }
        // update store 中的 material
        fn.storeFunction(id, { ...data, id })
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toItemPage()
      } catch (e) {
        console.error(e.message)
        openModal('alertModal', { icon: 'warning', message: '出錯了' })
      } finally {
        loading.close()
      }
    }

    onBeforeMount(async() => {
      if (state.value === 'new') return
      const [MaterialInfo, itemEffect] = await Promise.all([
        MaterialService.getMaterialInfo([itemId.toString()]),
        EffectService.getInfo([itemId.toString()])
      ])

      const { enhanceLuck = 0, furnaceLuck = 0, craftLuck = 0 } = MaterialInfo[0] || {}

      selectItemMode.value = itemEffect.length === 0
        ? [enhanceLuck, furnaceLuck, craftLuck].some(luck => luck > 0)
          ? 'LOCK_PLUS'
          : 'NONE'
        : 'CAN_USE'

      originItemMode.value = selectItemMode.value
      // 沒有使用道具的設定
      if (selectItemMode.value === 'CAN_USE') {
        effectState.value = 'edit'
        setEffectInfo(itemEffect[0], effectInfo)
      }
    })

    return {
      IMAGE_HEIGHT,
      IMAGE_WIDTH,
      itemId,
      state,
      originItemMode,
      stateMethod,
      selectItemMode,
      validateRule,
      submitData,
      submit,
      checkEffectData,
      effectSettingRef,
      effectInfo
    }
  }
})
</script>
