<template>
  <Modal>
    <template #body>
      <my-input
        class="flex flex-col items-start mt-3"
        type="text"
        v-model="email"
        label="Email"
        :required="validateRule.email.required"
        :errorMessage="validateRule.email.errorMessage"
        :requireRule="validateRule.email.rule"
        :regexErrorMessage="validateRule.email.ruleMessage"
      />
      <div class="text-left text-red-600 mt-4">
        修改Email將設定新的Email或取代現有的Email，且會直接視為驗證通過
      </div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button
        class="mx-1 btn btn-primary py-3 px-4 w-full align-top"
        @click="submit"
      >
        送出
      </button>
      <button
        class="mx-1 btn btn-secondary py-3 px-4 w-full align-top"
        @click="closeModal"
      >
        取消
      </button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import MyInput from '@/global-components/input/Main.vue'
import { useModal } from '@/plugins/modals'
import { MemberService } from '@/services'
import { helper as $h } from '@/utils/helper'
import { CLOSE_MODAL, injectStrict } from '@/utils/provideInject'
import { defineProps, ref } from 'vue'
import Modal from './template.vue'

interface Props {
  input: {
    uid: number
    email: string
  }
  callback?: {
    succeed?: () => void
    failed?: (e: unknown) => void
  }
}

const props = defineProps<Props>()

const closeModal = injectStrict(CLOSE_MODAL)

const { openModal } = useModal()
const email = ref<string>(props.input.email)
const validateRule = {
  email: {
    required: true,
    errorMessage: '請填寫Email',
    rule: new RegExp(
      /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
    ),
    ruleMessage: '無效的Email格式，請確認'
  }
}

const submit = async () => {
  const data = {
    email: email.value
  }
  const { isValidate, errorMessage } = $h.validate(data, validateRule)
  if (!isValidate) {
    openModal('alertModal', {
      icon: 'warning',
      message: errorMessage
    })
    return
  }

  try {
    await MemberService.changeMemberEmail(props.input.uid, email.value)
    closeModal()
    props.callback?.succeed?.()
  } catch (e) {
    closeModal()
    props.callback?.failed?.(e)
  }
}
</script>

<style scoped>
.modal-body {
  overflow-y: visible;
}
</style>
