/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
declare global {
  interface Window {
    jkfhosts: Map<string, string> | undefined
  }
}

export enum JkfHost {
  ACTIVITY = 'ACTIVITY',
  DONATE = 'DONATE',
  PAY = 'PAY',
  PAN = 'PAN',
  GPG = 'GPG',
  IM = 'IM',
  ADMIN = 'ADMIN',
  ADMIN_GUARDIAN = 'ADMIN_GUARDIAN',
  GUARDIAN = 'GUARDIAN',
  ADMIN_PAN = 'ADMIN_PAN',
  MATERIAL = 'MATERIAL',
  MEDAL = 'MEDAL',
  JKTALK = 'JKTALK'
}

export const EnableGrpcDebugTools = (service: unknown): void => {
  if (localStorage.getItem('gubed') === 'true') {
    // @ts-ignore
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
    enableDevTools([service])
  }
}

export const setJkfHost = (host: JkfHost, domain: string): void => {
  let hosts = window.jkfhosts
  if (!hosts) {
    hosts = new Map()
  }
  hosts.set(host, domain)
  window.jkfhosts = hosts
}

export const getJkfHost = (host: JkfHost): string => {
  const hosts = window.jkfhosts
  let domain = ''
  if (hosts) {
    domain = hosts.get(host) || ''
  }
  return domain
}

export const resolveData = <T>(data: T): Promise<T> => {
  try {
    return Promise.resolve(data)
  } catch (e) {
    return Promise.reject(e)
  }
}
