import * as materialPb from '@lctech-tw/jkf-data-proto/dist/js/jkfapi/data/material/query_pb'
export enum MaterialCategory {
  CATEGORY_NONE = 0,
  CATEGORY_A = 1,
  CATEGORY_B = 2,
  CATEGORY_C = 3,
  CATEGORY_D = 4,
}

export type CreateMaterial = {
  displayName: string
  description: string
  img: string
  weight: number
  category: MaterialCategory
  furnaceLuck: number
  craftLuck: number
  enhanceLuck: number
}
export type ConsumableMaterial = {
  id: string
  count: number
}

export type SideProductMaterial = {
  id: string
  minCount: number
  maxCount: number
}

export interface Material extends CreateMaterial {
  id: string
}

export type Credit = {
  id: string,
  displayName: string
}

export interface ShopMaterial extends Material {
  creditType: Credit
  creditValue: number
  stock?: number
}

export interface ManufactureMaterial<T> {
  info: T
  successRate: number
  coins: number
  count: number
  sideProduct: Array<SideProductMaterial>
  consumableMaterial: Array<ConsumableMaterial>
  inconsumableMaterial: Array<ConsumableMaterial>
}

export const categoryToGrpcType = {
  [MaterialCategory.CATEGORY_NONE]: materialPb.Category.CATEGORY_NONE,
  [MaterialCategory.CATEGORY_A]: materialPb.Category.CATEGORY_A,
  [MaterialCategory.CATEGORY_B]: materialPb.Category.CATEGORY_B,
  [MaterialCategory.CATEGORY_C]: materialPb.Category.CATEGORY_C,
  [MaterialCategory.CATEGORY_D]: materialPb.Category.CATEGORY_D
}

export const grpcTypeToCategory = {
  [materialPb.Category.CATEGORY_NONE]: MaterialCategory.CATEGORY_NONE,
  [materialPb.Category.CATEGORY_A]: MaterialCategory.CATEGORY_A,
  [materialPb.Category.CATEGORY_B]: MaterialCategory.CATEGORY_B,
  [materialPb.Category.CATEGORY_C]: MaterialCategory.CATEGORY_C,
  [materialPb.Category.CATEGORY_D]: MaterialCategory.CATEGORY_D
}

export type MaterialKey = keyof Material

export const getCredit = (id: string): Credit => {
  const data = [
    {
      id: '101',
      displayName: '名聲'
    },
    {
      id: '102',
      displayName: '金幣'
    },
    {
      id: '103',
      displayName: '點數'
    },
    {
      id: '104',
      displayName: '愛心'
    },
    {
      id: '105',
      displayName: '寶石'
    },
    {
      id: '106',
      displayName: '送出'
    },
    {
      id: '107',
      displayName: '體力'
    },
    {
      id: '108',
      displayName: '邀請'
    }
  ]
  return data.find((val) => val.id === id) || { id: '102', displayName: '金幣' }
}
