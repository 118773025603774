import { defineComponent, ref, onBeforeMount, PropType, toRefs, computed } from 'vue'
import { MaterialService, MedalService } from '@/services/'

export default defineComponent({
  name: 'loadItems',
  props: {
    itemType: {
      type: String as PropType<'Material' | 'Medal'>,
      default: 'Material'
    },
    filterItemFunction: {
      type: Function as PropType<(val: any, index: number, array: Array<any>) => boolean>,
      default: (val: any, index: any, array: Array<any>) => true
    },
    asyncFilterFunction: {
      type: Function,
      default: async (data:any) => data
    }
  },
  setup (props, { slots }) {
    const { itemType } = toRefs(props)

    const dynamicFn = computed(() => {
      const map = {
        Material: {
          get: MaterialService.getMaterialList
        },
        Medal: {
          get: MedalService.getMedalList
        }
      }
      return map[itemType.value]
    })
    const state = ref('init')
    const items = ref<Array<any>>([])
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const page = ref(1)
    const filterData = computed(() => {
      return items.value.filter(props.filterItemFunction)
    })

    const loadingData = async() => {
      if (page.value >= totalPage.value) return
      page.value = page.value + 1
      const result = await dynamicFn.value.get(pageSize.value, page.value)
      const filter = await props.asyncFilterFunction(result.data)
      items.value = items.value.concat(...filter)
      totalPage.value = result.totalPage
    }

    // init
    onBeforeMount(async() => {
      state.value = 'loading'
      const result = await dynamicFn.value.get(pageSize.value, page.value)
      items.value = await props.asyncFilterFunction(result.data)
      totalPage.value = result.totalPage
      state.value = 'done'
    })

    return () => {
      if (slots.default) {
        return slots.default({
          state: state.value,
          items: filterData.value,
          loadingData: loadingData
        })
      }
    }
  }
})
