import { reactive } from 'vue'
import dayjs from '@/libs/dayjs'
import { FirebaseType } from '@/types'
import * as Users from '@/store/users'

export default function messageLog () {
  const litePickerOption = (litePickerParentEL = null) => {
    return reactive({
      autoApply: false,
      singleMode: false,
      ...(litePickerParentEL ? { parentEl: litePickerParentEL } : {}),
      numberOfColumns: 2,
      numberOfMonths: 2,
      showWeekNumbers: true,
      maxDate: dayjs().format('YYYY-MM-DD'),
      dropdowns: {
        minYear: 1990,
        maxYear: null,
        months: true,
        years: true
      }
    })
  }

  const replaceImgUrl = (url: string) => url.replace(/origin-image/, 'private-image')
  const imageUrlPrefix = process.env.VUE_APP_JKF_IMAGE_URL_PREFIX

  const checkSysf1 = (sysf1: boolean, type: 'sysf1' | 'button') => {
    const textMap = {
      button: {
        true: '取消標記',
        false: '標記'
      },
      sysf1: {
        true: '被標記',
        false: ''
      }
    }
    // @ts-ignore
    return textMap[type][sysf1 === true ? 'true' : 'false']
  }

  const filterUserIdAndGetData = async(docs: Array<FirebaseType.MessageItem>) => {
    const allId = docs.map((messageItem) => {
      return [...messageItem.AllowRead]
    })
    // @ts-ignore
    const users = await Users.getUser([].concat(...allId))
    return users
  }

  const combineUserDataAndfirebaseData = (messageItems: Array<FirebaseType.MessageItem>, userMap: Map<string, Users.User>): Array<any> => {
    return messageItems.map((messageItem) => {
      const sender = userMap.get(messageItem.SenderID)
      const receiver = userMap.get(messageItem.ReceiverID)
      return {
        roomId: messageItem.RoomId,
        id: messageItem.id,
        uid: sender!.uid,
        sender: sender!.displayName,
        receiver: receiver!.displayName,
        text: messageItem.Text,
        time: messageItem.Created.toMillis(),
        sysf1: messageItem.Sysf1,
        sysf2: messageItem.Sysf2,
        imageAll: messageItem.ImageAll
      }
    })
  }
  const combineAllTableData = async(data: Array<FirebaseType.MessageItem>) => {
    const userData = await filterUserIdAndGetData(data)
    return combineUserDataAndfirebaseData(data, userData)
  }

  return {
    litePickerOption,
    replaceImgUrl,
    imageUrlPrefix,
    checkSysf1,
    filterUserIdAndGetData,
    combineUserDataAndfirebaseData,
    combineAllTableData
  }
}
