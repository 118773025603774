<template>
  <div>
    <div class="container sm:px-10">
      <div class="block">
        <div class="h-screen flex">
          <div class="my-auto mx-auto bg-white dark:bg-dark-1 px-5 sm:px-8 py-8 rounded-md shadow-md w-full sm:w-3/4 max-w-sm">
            <div class="text-center">
              <Google  @onSuccess="loginGoogle" />
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import Google from '@/components/google-login/Main.vue'
import { loginWithGoogle } from '@/store/user'
import { GoogleUser } from '@/types/index'

export default defineComponent({
  name: 'Login',
  components: {
    Google
  },
  setup() {
    const loginGoogle = async (googleUser: any) => {
      try {
        const user = new GoogleUser.GUser(googleUser)
        await loginWithGoogle(user)
        router.push({ path: '/' })
      } catch (e) {
        console.error(e)
      }
    }
    const showModal = ref<boolean>(false)
    const account = ref<string>()
    const password = ref<string>()
    const router = useRouter()

    const closeModal = (value: boolean) => {
      showModal.value = value
    }
    return {
      showModal,
      closeModal,
      account,
      password,
      loginGoogle
    }
  }
})
</script>
