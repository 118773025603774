import { defineComponent, toRefs, ref, watch, computed, onMounted, PropType } from 'vue'
import { tryOnBeforeUnmount } from '@vueuse/core'
import * as Material from '@/store/material'
import * as Medal from '@/store/medal'
import useRouter from '@/router/useRouter'

export default defineComponent({
  name: 'getMaterialOrMedal',
  props: {
    id: {
      type: String,
      required: true
    },
    getType: {
      type: String as PropType<'Material' | 'Medal'>,
      default: 'Material'
    }
  },
  setup(props, { slots }) {
    const { id = ref(''), getType } = toRefs(props)
    const { toErrorPage } = useRouter()
    const item = ref(new Map())
    const dynamicFn = computed(() => {
      const functionMap = {
        Material: {
          get: Material.getMaterial,
          emptyValue: Material.emptyMaterial
        },
        Medal: {
          get: Medal.getMedal,
          emptyValue: Medal.emptyMedal
        }
      }
      return functionMap[getType.value]
    })

    const onChanged = async (id: string) => {
      try {
        if (id !== '') {
          item.value = await dynamicFn.value.get([id])
        }
      } catch (e) {
        console.error(e)
        toErrorPage()
      }
    }

    onMounted(async() => {
      await onChanged(id.value)
    })

    const unwatch = watch(id, onChanged)

    const data = computed(() => {
      const ID = id.value
      return item.value.has(ID) ? item.value.get(ID) : dynamicFn.value.emptyValue.value
    })

    const getPhoto = computed(() => {
      return data.value.img || ''
    })

    const getId = computed(() => {
      return data.value.id || ''
    })

    tryOnBeforeUnmount(() => unwatch())

    return () => {
      if (slots.default) {
        return slots.default(
          {
            displayName: data.value.displayName,
            ...(getType.value === 'Material' ? { weight: data.value.weight } : { enhanceAnimationImg: data.value.enhanceAnimationImg || '' }),
            id: getId.value,
            img: getPhoto.value,
            description: data.value.description,
            furnaceLuck: getType.value === 'Material' ? data.value.furnaceLuck || 0 : data.value.property.furnaceLuck || 0,
            craftLuck: getType.value === 'Material' ? data.value.craftLuck || 0 : data.value.property.craftLuck || 0,
            enhanceLuck: getType.value === 'Material' ? data.value.enhanceLuck || 0 : data.value.property.enhanceLuck || 0,
            available: getType.value === 'Material' ? false : data.value.available
          }
        )
      }
    }
  }
})
