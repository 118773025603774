
import { defineComponent } from 'vue'
import Modal from './template.vue'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { useLoading } from '@/plugins/loading'
import { CLOSE_MODAL, injectStrict } from '@/utils/provideInject'
export default defineComponent({
  components: {
    Modal,
    SvgIcon
  },
  props: {
    message: {
      type: String,
      required: true
    },
    confirmFunction: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const { startLoading } = useLoading()
    const closeModal = injectStrict(CLOSE_MODAL)
    const confirm = async() => {
      const loading = startLoading()
      try {
        closeModal()
        await props.confirmFunction()
      } catch (e) {
        console.error(e)
      } finally {
        loading.close()
      }
    }
    return {
      confirm
    }
  }
})
