import { render } from "./Main.vue?vue&type=template&id=08498426"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "08498426"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('08498426', script)) {
    api.reload('08498426', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=08498426", () => {
    api.rerender('08498426', render)
  })

}

script.__file = "src/views/iam/Main.vue"

export default script