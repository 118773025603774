
import { defineComponent, ref, reactive } from 'vue'
import Modal from './template.vue'
import { useModal } from '@/plugins/modals/index'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MedalType } from '@/types'
import { getMaterial } from '@/store/material'
import { getMedal } from '@/store/medal'
import { helper as $h } from '@/utils/helper'
import MyInput from '@/global-components/input/Main.vue'
import { MedalManufactureService } from '@/services'
import SvgIcon from '@/components/svg-icons/Main.vue'

type RECIPE_MATERIAL = {
  id: string
  displayName: string
  count: number
}

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems,
    DataTable,
    SvgIcon,
    MyInput
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    },
    filterItemIds: {
      type: Array,
      required: true
    },
    whiteListId: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const medalType = ref<'MANUFACTURE' | 'RECIPE'>('MANUFACTURE')
    const isSelect = ref<null | string>(null)
    const { openModal } = useModal('fullscreen-dom')
    const tableColumns = TableColumns.ENHANCE_EDIT_MODAL_COLUMNS
    const successRate = ref('')
    const coins = ref('')
    const consumableMaterial = reactive<Array<RECIPE_MATERIAL>>([])
    const inconsumableMaterial = reactive<Array<RECIPE_MATERIAL>>([])
    const validateRule = {
      id: {
        required: true,
        errorMessage: '請選擇勳章'
      },
      successRate: {
        required: true,
        errorMessage: '請輸入成功率',
        requireRule: new RegExp(/^(0|[1-9][0-9]?|100)$/),
        regexErrorMessage: '請輸入正確的數字格式'
      },
      coins: {
        required: true,
        requireRule: new RegExp(/^(0|[1-9][0-9]*)$/),
        errorMessage: '請輸入消耗金幣',
        regexErrorMessage: '請輸入正確的數字格式'
      },
      consumableMaterial: {
        required: true,
        errorMessage: '請輸入消耗材料'
      }
    }

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    const addMaterialModal = (
      type: 'consumableMaterial' | 'inconsumableMaterial'
    ) => {
      const targetArray =
        type === 'consumableMaterial'
          ? consumableMaterial
          : inconsumableMaterial
      const filterItemIds = targetArray.map((val) => val.id)
      const confirmFunction = async (id: string, count: number) => {
        const map = await getMaterial([id])
        targetArray.push({
          id: id as string,
          displayName: map.get(id)?.displayName || '',
          count: count
        })
      }
      openModal('addMaterialModal', { confirmFunction, filterItemIds })
    }

    const deleteRecipeMaterial = (
      type: 'consumableMaterial' | 'inconsumableMaterial',
      id: string
    ) => {
      const targetArray =
        type === 'consumableMaterial'
          ? consumableMaterial
          : inconsumableMaterial
      const index = targetArray.findIndex((val) => val.id === id)
      targetArray.splice(index, 1)
    }

    // submit
    const confirm = async (closeModal: Function) => {
      const data = {
        id: isSelect.value,
        successRate: successRate.value,
        coins: coins.value,
        consumableMaterial: consumableMaterial.map((val) =>
          MedalType.transformToConsumableMaterial({
            id: val.id,
            count: val.count,
            type: MedalType.ConsumableType.TYPE_MATERIAL
          })
        ),
        inconsumableMaterial: inconsumableMaterial.map((val) =>
          MedalType.transformToConsumableMaterial({
            id: val.id,
            count: val.count,
            type: MedalType.ConsumableType.TYPE_MATERIAL
          })
        )
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const medal = (await getMedal([data.id!])).get(data.id!)!
      if (!medal.enhanceAnimationImg) {
        openModal('alertModal', { message: '該勳章沒有動畫圖片，無法設定關聯', icon: 'warning' })
        return false
      }
      const recipe: MedalType.EnhanceRecipe<MedalType.Medal> = {
        info: medal,
        successRate: parseInt(data.successRate),
        coins: parseInt(data.coins),
        consumableMaterial: data.consumableMaterial,
        inconsumableMaterial: data.inconsumableMaterial
      }
      props.confirmFunction(recipe)
      closeModal()
    }

    const asyncFilterFunction = async (items: Array<MedalType.Medal>) => {
      // 可以選做被關聯的勳章要被過濾
      // 不能是 root 不能是 seriers 不能是被合成的勳章
      // 自己的勳章關聯也要拿掉，不能重複
      const ids = items.map((val) => val.id)
      const medalInfo = await getMedal(ids)
      const manufactureInfo = await MedalManufactureService.getManufactureInfo(
        ids
      )
      return items.filter((item) => {
        const medal = medalInfo.get(item.id)!
        return (
          props.whiteListId.includes(item.id) ||
          (medal.isRoot === false &&
            medal.isSeries === false &&
            !manufactureInfo.some(
              (manufacture) => item.id === manufacture.info.id
            ) &&
            !props.filterItemIds.includes(item.id))
        )
      })
    }

    return {
      selectNewItem,
      isSelect,
      medalType,
      addMaterialModal,
      tableColumns,
      consumableMaterial,
      inconsumableMaterial,
      deleteRecipeMaterial,
      successRate,
      coins,
      confirm,
      validateRule,
      asyncFilterFunction
    }
  }
})
