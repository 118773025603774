import { render } from "./Main.vue?vue&type=template&id=6bee3cf3"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6bee3cf3"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6bee3cf3', script)) {
    api.reload('6bee3cf3', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=6bee3cf3", () => {
    api.rerender('6bee3cf3', render)
  })

}

script.__file = "src/views/material/material/Main.vue"

export default script