
import { defineComponent, ref, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import { helper as $h } from '@/utils/helper'
import Modal from './template.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import MyInput from '@/global-components/input/Main.vue'

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems,
    MyInput
  },
  props: {
    filterItemIds: {
      type: Array,
      required: true
    },
    confirmFunction: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { filterItemIds } = toRefs(props)
    const isSelect = ref<null | string>(null)
    const { openModal } = useModal('fullscreen-dom')
    const count = ref(0)
    const validateRule = {
      id: {
        required: true,
        errorMessage: '請選擇道具'
      },
      count: {
        required: true,
        requireRule: new RegExp(/^([1-9][0-9]*)$/),
        errorMessage: '請輸入數量',
        regexErrorMessage: '請輸入正確的數字格式'
      }
    }

    const filter = (items: Array<any>) => {
      return items.filter((val) => !filterItemIds.value.includes(val.id))
    }

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    const submit = (closeModal: Function) => {
      const data = {
        id: isSelect.value,
        count: count.value
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      props.confirmFunction(data.id, data.count)
      closeModal()
    }

    return {
      selectNewItem,
      isSelect,
      count,
      validateRule,
      submit,
      filter
    }
  }
})
