
import { helper as $h } from '@/utils/helper'
import { defineComponent, onMounted, toRefs, computed, ref, onBeforeUnmount } from 'vue'
export default defineComponent({
  props: {
    height: {
      type: String,
      default: '350'
    },
    loadingData: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const { height, loadingData } = toRefs(props)
    const observer = ref<IntersectionObserver | null>(null)
    const scrollId = $h.uuid4()
    const observerId = $h.uuid4()

    const setHeight = computed(() => {
      return {
        height: `${height.value}px`
      }
    })

    const options = {
      root: document.getElementById(scrollId),
      rootMargin: '0px 0px 100px 0px',
      threshold: 0
    }

    const callback = (entries: Array<IntersectionObserverEntry>) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry && entry.isIntersecting) {
          loadingData.value()
        }
      })
    }

    onMounted(() => {
      observer.value = new IntersectionObserver(callback, options)
      // @ts-ignore
      observer.value.observe(document.getElementById(observerId))
    })

    onBeforeUnmount(() => {
      observer.value?.disconnect()
    })

    return {
      setHeight,
      observerId,
      scrollId
    }
  }
})
