import { render } from "./editGuardianDesign.vue?vue&type=template&id=7b2cfe2c"
import script from "./editGuardianDesign.vue?vue&type=script&lang=ts"
export * from "./editGuardianDesign.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7b2cfe2c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7b2cfe2c', script)) {
    api.reload('7b2cfe2c', script)
  }
  
  module.hot.accept("./editGuardianDesign.vue?vue&type=template&id=7b2cfe2c", () => {
    api.rerender('7b2cfe2c', render)
  })

}

script.__file = "src/plugins/modals/editGuardianDesign.vue"

export default script