
import { defineComponent, onMounted, reactive } from 'vue'
import { useModal } from '@/plugins/modals/'
import { IamState, DomainID } from '@/store/iam'
import { DomainState } from '@/store/guardian'
import { AdminUid } from '@/api/guardian'
import { IamService } from '@/services/'
import { IamType, RoleType } from '@/types/'

export default defineComponent({
  setup() {
    const { openModal } = useModal()
    const columnData = reactive<{ user: IamType.Iam, role: RoleType.Role[] }[]>([])

    const refresh = async() => {
      columnData.length = 0
      const data = await IamService.getAllIamUserAndRole()
      data.forEach((val) => {
        columnData.push(val)
      })
    }

    const createUser = async(e: Event) => {
      e.preventDefault()
      openModal('editIamIdentity', {
        mode: 'NEW',
        confirmFunction: refresh
      })
    }

    const updateUser = async(user: IamType.Iam, role: RoleType.Role[]) => {
      openModal('editIamIdentity', {
        mode: 'EDIT',
        oldEmail: user.email,
        oldDisplayName: user.displayName,
        role: role,
        confirmFunction: refresh
      })
    }

    const delUser = async(user: IamType.Iam, role: RoleType.Role[]) => {
      openModal('confirmModal',
        {
          message: '確定要刪除嗎?',
          confirmFunction: async() => {
            await IamService.deleteIdentity(user.displayName, user.email, role)
            openModal('alertModal', { icon: 'success', message: '刪除成功' })
            refresh()
          }
        }
      )
    }

    onMounted(() => {
      refresh()
    })

    return {
      createUser,
      IamState,
      updateUser,
      delUser,
      DomainState,
      AdminUid,
      DomainID,
      columnData
    }
  }
})
