import { render } from "./Main.vue?vue&type=template&id=84444db4"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "84444db4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('84444db4', script)) {
    api.reload('84444db4', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=84444db4", () => {
    api.rerender('84444db4', render)
  })

}

script.__file = "src/views/guardian/design/Main.vue"

export default script