
import { defineComponent, onMounted, PropType, reactive, ref, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import Modal from './template.vue'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'
import { getDomainAll, DomainState } from '@/store/guardian'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    domainID: {
      type: String,
      default: ''
    },
    roleID: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<'create' | 'edit'>,
      required: true
    },
    done: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { openModal } = useModal()
    const formData = reactive({
      domainID: props.domainID,
      roleID: props.roleID,
      displayName: props.displayName
    })
    const rules = {
      domainID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      roleID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      displayName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const domainSelValue = ref<null | string>(null)

    const submit = (closeModal: Function) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        const errorMessage = '請修正資料格式'
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return
      }
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const role = new GuardianQueryPB.Role()
      role.setRoleId(formData.roleID)
      role.setDomain(formData.domainID)
      role.setDisplayName(formData.displayName)
      if (props.mode == 'create') {
        /*
        GuardianService.createRoleApi(role).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
        */
      } else if (props.mode == 'edit') {
        /*
        GuardianAPI.UpdateRole(token, role).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
        */
      }
    }

    onMounted(async() => {
      await getDomainAll()
      domainSelValue.value = DomainState.domainAll[0].getDomainId()
    })

    return {
      formData,
      validate,
      submit,
      DomainState,
      domainSelValue
    }
  }
})
