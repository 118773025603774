import { defineComponent, ref, unref, computed, provide, readonly, toRefs, onScopeDispose } from 'vue'
import { tryOnMounted } from '@vueuse/core'
import { helper as $h } from '@/utils/helper'
import provideInjectName from '@/utils/provideInject'
export default defineComponent({
  name: 'fullscreen',
  props: {
    idName: {
      type: String,
      required: true
    }
  },
  setup(props, { slots }) {
    const { idName } = toRefs(props)
    // provide dom name
    provide(provideInjectName.FULLSCREEN_DOM, readonly(idName))
    const isFullscreen = ref(false)
    const fullscreen = computed(() => {
      return {
        text: isFullscreen.value ? '取消全螢幕' : '全螢幕',
        click: isFullscreen.value ? cancelFullscreen : startFullscreen,
        isFull: isFullscreen.value
      }
    })
    const startFullscreen = () => {
      if (!$h.isFullscreen()) $h.startFullscreen(unref(idName))
    }
    const cancelFullscreen = () => {
      if ($h.isFullscreen()) $h.cancelFullscreen()
    }
    const watchFullscreen = () => {
      if ($h.isFullscreen()) {
        isFullscreen.value = true
      } else {
        isFullscreen.value = false
      }
    }

    tryOnMounted(() => {
      document.addEventListener('fullscreenchange', watchFullscreen)
    })

    onScopeDispose(() => {
      document.removeEventListener('fullscreenchange', watchFullscreen)
    })

    return () =>
      <div class={`box p-5 mt-5 ${fullscreen.value.isFull ? 'overflow-y-scroll' : ''}`} id={unref(idName)}>
        <div class="hidden sm:flex md:justify-end justify-center">
          <button class="btn btn-secondary w-full md:w-36 mr-1 mb-2 mt-4" onClick={fullscreen.value.click}>{fullscreen.value.text}</button>
        </div>
        {
          slots.default ? slots.default() : ''
        }
      </div>
  }
})
