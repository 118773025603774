import { render } from "./modal.vue?vue&type=template&id=4c235aa8&scoped=true"
import script from "./modal.vue?vue&type=script&lang=ts"
export * from "./modal.vue?vue&type=script&lang=ts"

import "./modal.vue?vue&type=style&index=0&id=4c235aa8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-4c235aa8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4c235aa8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4c235aa8', script)) {
    api.reload('4c235aa8', script)
  }
  
  module.hot.accept("./modal.vue?vue&type=template&id=4c235aa8&scoped=true", () => {
    api.rerender('4c235aa8', render)
  })

}

script.__file = "src/plugins/modals/modal.vue"

export default script