

import { toRefs } from '@vueuse/core'
import { defineComponent, computed, getCurrentInstance, ref, PropType, watch } from 'vue'
import { MdiKeyType } from '@/types/'
export default defineComponent({
  name: 'svg-icon',
  props: {
    size: {
      type: [String, Number],
      default: 24
    },
    name: {
      type: String as PropType<MdiKeyType.mdiKey>,
      required: true
    }
  },
  setup (props) {
    const { name } = toRefs(props)
    const instance = getCurrentInstance()
    const svgcontent = ref('')
    const DEFAULT_EXCLUDE_KEYS = ['class', 'style']
    // 過濾掉 class style
    const attrs = computed(() => {
      return Object.fromEntries(
        Object.entries(instance!.proxy?.$attrs!).filter(
          ([key]) => !DEFAULT_EXCLUDE_KEYS.includes(key)
        ))
    })
    watch(name, async() => {
      const { [name.value]: content } = await import('@/libs/mdi')
      svgcontent.value = content || name.value
    }, { immediate: true })
    return {
      attrs,
      svgcontent
    }
  }
})
