// import faker from './faker'
import helper from './helper'
export const FAKE_AVATAR = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8ePX2fwAIggOCR8M6ygAAAABJRU5ErkJggg=='
// import lodash from './lodash'

export default app => {
  // app.use(faker)
  app.use(helper)
  // app.use(lodash)
}
