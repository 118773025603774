import { render } from "./Main.vue?vue&type=template&id=9d79591a&scoped=true"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"

import "./Main.vue?vue&type=style&index=0&id=9d79591a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-9d79591a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "9d79591a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9d79591a', script)) {
    api.reload('9d79591a', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=9d79591a&scoped=true", () => {
    api.rerender('9d79591a', render)
  })

}

script.__file = "src/components/google-login/Main.vue"

export default script