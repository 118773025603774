import { PanAPI } from '@/api/index'
import { loginedStatus } from '@/store/user'

export const changeMemberPassword = async (uid: number, password: string) => {
  try {
    const res = await PanAPI.changePassword(loginedStatus.token, uid, password)
    return res
  } catch (e) {
    return Promise.reject(e)
  }
}

export const removeMemberAvatar = async (uid: number) => {
  try {
    const res = await PanAPI.removeMemberAvatar(loginedStatus.token, uid)
    return res
  } catch (e) {
    return Promise.reject(e)
  }
}

export const changeMemberEmail = async (uid: number, email: string) => {
  try {
    const res = await PanAPI.changeMemberEmail(loginedStatus.token, uid, email)
    return res
  } catch (e) {
    return Promise.reject(e)
  }
}
