
import { defineComponent, ref, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import { helper as $h } from '@/utils/helper'
import Modal from './template.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import MyInput from '@/global-components/input/Main.vue'

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems,
    MyInput
  },
  props: {
    needRate: {
      type: Boolean,
      required: true
    },
    filterItemIds: {
      type: Array,
      required: true
    },
    confirmFunction: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { filterItemIds } = toRefs(props)
    const isSelect = ref<null | string>(null)
    const { openModal } = useModal('fullscreen-dom')
    const rate = ref(0)
    const minCount = ref(1)
    const maxCount = ref(1)
    const validateRule = {
      id: {
        required: true,
        errorMessage: '請選擇道具'
      },
      rate: {
        required: true,
        requireRule: new RegExp(/^([1-9][0-9]*)$/),
        errorMessage: '請輸入機率',
        regexErrorMessage: '請輸入正確的數字格式'
      }
    }

    const filter = (items: Array<any>) => {
      return items.filter((val) => !filterItemIds.value.includes(val.id))
    }

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    const submit = (closeModal: Function) => {
      // 不需要機率
      const data = {
        id: isSelect.value,
        rate: props.needRate ? rate.value : 100
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      if (maxCount.value < minCount.value) {
        const message = '數量區間填寫錯誤'
        openModal('alertModal', { message, icon: 'warning' })
        return false
      }
      props.confirmFunction({
        materialId: data.id,
        rate: data.rate,
        minCount: minCount.value,
        maxCount: maxCount.value
      })
      closeModal()
    }

    return {
      selectNewItem,
      isSelect,
      rate,
      minCount,
      maxCount,
      validateRule,
      submit,
      filter
    }
  }
})
