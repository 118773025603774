
import { defineComponent, reactive, ref, onMounted, computed } from 'vue'
import UserData from '@/components/user/user'
import UserBigCard from '@/components/info-card/Main.vue'
import TalkGroup from '@/components/talk-groups/Main.vue'
import TalkingRoom from '@/components/talking-room/Main.vue'
import EmptyRoom from '@/components/talking-room/EmptyRoom'
import { useRoute, useRouter } from 'vue-router'
import { getUserByUid } from '@/store/users'
import { Room } from '@/services/index'
import { FirebaseType } from '@/types'
import SvgIcon from '@/components/svg-icons/Main.vue'

export default defineComponent({
  name: 'UserProfile',
  components: {
    UserData,
    UserBigCard,
    TalkGroup,
    TalkingRoom,
    EmptyRoom,
    SvgIcon
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const senderID = ref('')
    const uid: string = route.params.uid.toString()
    const rooms = reactive<Array<FirebaseType.RoomType>>([])
    const selectRoom = reactive<{ roomID: string, receiverID: string }>({
      roomID: '',
      receiverID: ''
    })
    const infoColumns = [
      'email'
    ]
    const isSelectRoom = computed(() => selectRoom.roomID !== '')
    const openRoom = (roomID: string, member: string) => {
      selectRoom.roomID = roomID
      selectRoom.receiverID = member
    }

    onMounted(async() => {
      try {
        const data = await getUserByUid(parseInt(uid))
        senderID.value = data.hashId!
        const roomData = await Room.getRoomLists(senderID.value)
        roomData.forEach((value) => {
          rooms.push(value)
        })
      } catch (e) {
        console.error(e)
        router.push({ name: 'Error' })
      }
    })
    return {
      senderID,
      rooms,
      openRoom,
      isSelectRoom,
      selectRoom,
      infoColumns
    }
  }
})
