<template>
  <Modal position="center">
    <template #body>
      <svg-icon :name="iconComponent" class="w-16 h-16 mx-auto mt-3" :class="[iconColor]"></svg-icon>
      <div class="text-2xl mt-5">{{ message }}</div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">
        關閉
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, unref } from 'vue'
import Modal from './template.vue'
import SvgIcon from '../../components/svg-icons/Main.vue'
export default defineComponent({
  name: 'alertModal',
  components: {
    Modal,
    SvgIcon
  },
  props: {
    icon: {
      type: String as PropType<'warning' | 'success'>,
      required: true
    },
    message: {
      type: String,
      default: 'ERROR'
    }
  },
  setup (props) {
    const icon = unref(props.icon)
    const iconComponent = computed(() => {
      return icon == 'warning' ? 'mdiAlphaXCircleOutline' : 'mdiCheckCircleOutline'
    })
    const iconColor = computed(() => icon == 'warning' ? 'text-theme-12' : 'text-btn-success')
    return {
      iconComponent,
      iconColor
    }
  }
})
</script>
