<template>
  <medal-data :id="medalId" getType="Medal" v-slot="{ img, id, description, displayName }">
    <info-card
      :displayName="displayName"
      :photoURL="img"
      :infoColumns="columns"
    >
      <template #description>
        {{ description }}
      </template>
      <template #infoTitle>
        勳章資訊
      </template>
      <template #ID>
        ID: {{ id }}
      </template>
    </info-card>
  </medal-data>
  <div class="box lg:mt-5">
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <svg-icon name="mdiPlusCircleOutline" class="cursor-pointer mr-1 text-green-600" @click="addRecipe"/>
      <h2 class="item-center font-medium text-xl mr-auto">
        關聯勳章設定
      </h2>
    </div>
    <div class="p-5">
      <div class="mt-4 pt-4">
        <data-table
          :columns="tableColumns"
          border
          :data="rootInfo.recipe"
        >
          <template #level="{ index }">
            {{ index + 1 }}級
          </template>
          <template #materialLength="{ row }">
            {{ calcMaterialLength(row) }}
          </template>
          <template #displayName="{ row }">
            {{ row?.info?.displayName }}
          </template>
          <template #delete="{ index }">
            <svg-icon name="mdiTrashCanOutline" class="text-red-600 cursor-pointer" @click.stop="deleteRecipeMaterial($event, index)" ></svg-icon>
          </template>
        </data-table>
      </div>
      <div class="flex mt-5">
        <button type="button" class="btn btn-primary w-full md:w-36 mt-3" @click="submit">
          {{ stateMethod.btnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onBeforeMount, computed, ref, nextTick, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useModal } from '@/plugins/modals/index'
import { EnhanceService } from '@/services/'
import { MedalType, TableColumns } from '@/types'
import { useLoading } from '@/plugins/loading/'
import useRouter from '@/router/useRouter'
import InfoCard from '@/components/info-card/Main.vue'
import MedalData from '@/components/data/itemOrMedal'
import DataTable from '@/components/data-table/Main.vue'
import SvgIcon from '@/components/svg-icons/Main.vue'

export default defineComponent({
  components: {
    InfoCard,
    MedalData,
    DataTable,
    SvgIcon
  },
  setup () {
    const route = useRoute()
    const medalId: string = route.params.id as string
    const { openModal } = useModal()
    const { toEnhancePage, toErrorPage } = useRouter()
    const whiteListId = ref<Array<string>>([])
    const { startLoading } = useLoading()
    const state = ref<'new'|'edit'>('new')
    const tableColumns = TableColumns.ENHANCE_EDIT_COLUMNS
    const columns = [
      'ID'
    ]
    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存變更' : '新增'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? EnhanceService.update : EnhanceService.create
      return {
        btnText,
        successText,
        API
      }
    })

    const rootInfo = reactive<MedalType.EnhanceInterface<MedalType.Medal>>({
      info: {
        id: '',
        displayName: '',
        img: '',
        description: '',
        enhanceAnimationImg: '',
        category: 0,
        canApply: false,
        canEnhance: false,
        isSeries: false,
        isRoot: false,
        available: false,
        property: {
          enhanceLuck: 0,
          furnaceLuck: 0,
          craftLuck: 0
        }
      },
      isRoot: false,
      recipe: []
    })

    const calcMaterialLength = (row: any) => {
      let count = 0
      if (row?.consumableMaterial) {
        count += row.consumableMaterial.length
      }
      if (row?.inconsumableMaterial) {
        count += row.inconsumableMaterial.length
      }
      return `總共需要：${count}種`
    }

    const deleteRecipeMaterial = async(e: Event, index: number) => {
      e.preventDefault()
      const removeData = rootInfo.recipe.splice(index, 1)
      whiteListId.value.push(removeData[0].info?.id)
      await nextTick()
    }

    const submit = async(e: Event) => {
      const fn = unref(stateMethod)
      e.preventDefault()
      if (rootInfo.recipe.length <= 0) {
        openModal('alertModal', { message: '請設定好關聯', icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        await fn.API(medalId, rootInfo.recipe)
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toEnhancePage()
      } catch (e) {
        console.error(e)
      } finally {
        loading.close()
      }
    }

    const addRecipe = () => {
      const confirmFunction = (recipe: MedalType.EnhanceRecipe<MedalType.Medal>) => {
        rootInfo.recipe.push(recipe)
        nextTick()
      }
      const filterItemIds = rootInfo.recipe.map(val => val.info.id)
      openModal('addRecipe', { confirmFunction, filterItemIds, whiteListId: whiteListId.value })
    }

    onBeforeMount(async() => {
      try {
        const data = await EnhanceService.getInfo(medalId)
        state.value = 'edit'
        rootInfo.info = data.info
        rootInfo.isRoot = data.isRoot
        rootInfo.recipe = data.recipe.map((val: any) => val)
      } catch (e) {
        console.error(e)
        if (e.code === 1001) {
          state.value = 'new'
          return
        }
        toErrorPage()
      }
    })

    return {
      columns,
      medalId,
      tableColumns,
      state,
      rootInfo,
      stateMethod,
      submit,
      addRecipe,
      calcMaterialLength,
      deleteRecipeMaterial
    }
  }
})
</script>

<style scoped>

</style>
