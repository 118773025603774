import { useRouter } from 'vue-router'
import appRouteName, { materialRouteName, medalRouteName } from './appRouteName'
export default function () {
  const router = useRouter()

  const toEditItemPage = (id: string) => {
    const params = {
      ...(id ? { id } : {})
    }
    router.push({ name: materialRouteName.ITEM_EDIT.name, params })
  }

  const toItemPage = () => {
    router.push({ name: materialRouteName.ITEM.name })
  }

  const toShopPage = () => {
    router.push({ name: materialRouteName.SHOP.name })
  }

  const toUserProfile = (uid: string) => {
    router.push({ name: appRouteName.USER_PROFILE.name, params: { uid } })
  }

  const toEditShopItemPage = (id: string) => {
    router.push({ name: materialRouteName.SHOP_EDIT.name, params: { id } })
  }

  const toEditManufactureitemPage = (id: string) => {
    router.push({ name: materialRouteName.MANUFACTURE_EDIT.name, params: { id } })
  }

  const toManufacturePage = () => {
    router.push({ name: materialRouteName.MANUFACTURE.name })
  }

  const goBack = (number: number) => {
    router.go(number)
  }

  const delayChangePage = (pageFunction: Function, millisec: number) => {
    const delay = setTimeout(() => {
      pageFunction()
      clearTimeout(delay)
    }, millisec)
  }

  const toMedalPage = () => {
    router.push({ name: medalRouteName.MEDAL.name })
  }

  const toEditMedalPage = (id: string) => {
    const params = {
      ...(id ? { id } : {})
    }
    router.push({ name: medalRouteName.MEDAL_EDIT.name, params })
  }

  const toEditManufactureMedalPage = (id: string) => {
    router.push({ name: medalRouteName.MEDAL_MANUFACTURE_EDIT.name, params: { id } })
  }

  const toEnhancePage = () => {
    router.push({ name: medalRouteName.MEDAL_ENHANCE.name })
  }

  const toEditEnhanceMedalPage = (id: string) => {
    router.push({ name: medalRouteName.MEDAL_ENHANCE_EDIT.name, params: { id } })
  }

  const toManufactureMedalPage = () => {
    router.push({ name: medalRouteName.MEDAL_MANUFACTURE.name })
  }

  const toErrorPage = () => {
    router.push({ name: 'Error' })
  }

  return {
    toEditItemPage,
    toUserProfile,
    toEditShopItemPage,
    goBack,
    toItemPage,
    toShopPage,
    toEditManufactureitemPage,
    toManufacturePage,
    delayChangePage,
    toEditMedalPage,
    toErrorPage,
    toMedalPage,
    toEditManufactureMedalPage,
    toEnhancePage,
    toEditEnhanceMedalPage,
    toManufactureMedalPage
  }
}
