<template>
  <Modal>
    <template #body>
      <div class="flex flex-col">
        <div class="text-base mt-5 mb-2 text-left">設定勳章</div>
        <loading-items
          itemType="Medal"
          :asyncFilterFunction="asyncFilterFunction"
          v-slot="{ loadingData, items, state }"
        >
          <infinite-scroll :loadingData="loadingData">
            <div v-if="state !== 'done'" class="w-full h-full animate-pulse bg-gray-300"></div>
            <div
              v-else
              class="border-b border-grey-200 py-3 px-5 cursor-pointer grid grid-cols-2"
              :class="{ 'bg-gray-300': isSelect == item.id }"
              v-for="(item, index) in items"
              :key="index"
              v-memo="[item.id === isSelect]"
              @click="selectNewItem(item.id)"
            >
              <div class="flex justify-start">
                <img :src="item.img" width="25" class="img" />
              </div>
              <div class="flex justify-start items-center">{{ item.displayName }}</div>
            </div>
          </infinite-scroll>
        </loading-items>
        <my-input
          class="flex flex-col items-start mt-3"
          type="number"
          label="成功率"
          v-model="successRate"
          :required="validateRule.successRate.required"
          :errorMessage="validateRule.successRate.errorMessage"
          :requireRule="validateRule.successRate.requireRule"
          :regexErrorMessage="validateRule.successRate.regexErrorMessage"
        />
        <my-input
          class="flex flex-col items-start mt-3"
          type="number"
          required
          :errorMessage="validateRule.coins.errorMessage"
          :requireRule="validateRule.coins.requireRule"
          :regexErrorMessage="validateRule.coins.regexErrorMessage"
          label="消耗金幣"
          v-model="coins"
        />
        <div class="mt-4 border-t border-gray-200 pt-4">
          <div class="mb-2">
            <label class="text-xl font-bold">升級所需的材料</label>
          </div>
          <div class="my-10 ml-3">
            <div class="flex items-center cursor-pointer">
              <svg-icon
                name="mdiPlusCircleOutline"
                class="mr-1 text-green-600"
                @click="addMaterialModal('consumableMaterial')"
              />
              <label class="text-lg font-bold">消耗材料</label>
            </div>
            <div class="p-4">
              <data-table :columns="tableColumns" border :data="consumableMaterial">
                <template #delete="{ row }">
                  <svg-icon
                    name="mdiTrashCanOutline"
                    class="text-red-600 cursor-pointer"
                    @click="deleteRecipeMaterial('consumableMaterial', row.id)"
                  />
                </template>
              </data-table>
            </div>
          </div>
          <div class="my-10 ml-3">
            <div class="flex items-center cursor-pointer">
              <svg-icon
                name="mdiPlusCircleOutline"
                class="mr-1 text-green-600"
                @click="addMaterialModal('inconsumableMaterial')"
              />
              <label class="text-lg font-bold">不消耗材料</label>
            </div>
            <div class="p-4">
              <data-table :columns="tableColumns" border :data="inconsumableMaterial">
                <template #delete="{ row }">
                  <svg-icon
                    name="mdiTrashCanOutline"
                    class="text-red-600 cursor-pointer"
                    @click="deleteRecipeMaterial('inconsumableMaterial', row.id)"
                  />
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button
        class="mx-1 btn btn-primary py-3 px-4 w-full align-top"
        @click="confirm(closeModal)"
      >新增</button>
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">取消</button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import Modal from './template.vue'
import { useModal } from '@/plugins/modals/index'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MedalType } from '@/types'
import { getMaterial } from '@/store/material'
import { getMedal } from '@/store/medal'
import { helper as $h } from '@/utils/helper'
import MyInput from '@/global-components/input/Main.vue'
import { MedalManufactureService } from '@/services'
import SvgIcon from '@/components/svg-icons/Main.vue'

type RECIPE_MATERIAL = {
  id: string
  displayName: string
  count: number
}

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems,
    DataTable,
    SvgIcon,
    MyInput
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    },
    filterItemIds: {
      type: Array,
      required: true
    },
    whiteListId: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const medalType = ref<'MANUFACTURE' | 'RECIPE'>('MANUFACTURE')
    const isSelect = ref<null | string>(null)
    const { openModal } = useModal('fullscreen-dom')
    const tableColumns = TableColumns.ENHANCE_EDIT_MODAL_COLUMNS
    const successRate = ref('')
    const coins = ref('')
    const consumableMaterial = reactive<Array<RECIPE_MATERIAL>>([])
    const inconsumableMaterial = reactive<Array<RECIPE_MATERIAL>>([])
    const validateRule = {
      id: {
        required: true,
        errorMessage: '請選擇勳章'
      },
      successRate: {
        required: true,
        errorMessage: '請輸入成功率',
        requireRule: new RegExp(/^(0|[1-9][0-9]?|100)$/),
        regexErrorMessage: '請輸入正確的數字格式'
      },
      coins: {
        required: true,
        requireRule: new RegExp(/^(0|[1-9][0-9]*)$/),
        errorMessage: '請輸入消耗金幣',
        regexErrorMessage: '請輸入正確的數字格式'
      },
      consumableMaterial: {
        required: true,
        errorMessage: '請輸入消耗材料'
      }
    }

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    const addMaterialModal = (
      type: 'consumableMaterial' | 'inconsumableMaterial'
    ) => {
      const targetArray =
        type === 'consumableMaterial'
          ? consumableMaterial
          : inconsumableMaterial
      const filterItemIds = targetArray.map((val) => val.id)
      const confirmFunction = async (id: string, count: number) => {
        const map = await getMaterial([id])
        targetArray.push({
          id: id as string,
          displayName: map.get(id)?.displayName || '',
          count: count
        })
      }
      openModal('addMaterialModal', { confirmFunction, filterItemIds })
    }

    const deleteRecipeMaterial = (
      type: 'consumableMaterial' | 'inconsumableMaterial',
      id: string
    ) => {
      const targetArray =
        type === 'consumableMaterial'
          ? consumableMaterial
          : inconsumableMaterial
      const index = targetArray.findIndex((val) => val.id === id)
      targetArray.splice(index, 1)
    }

    // submit
    const confirm = async (closeModal: Function) => {
      const data = {
        id: isSelect.value,
        successRate: successRate.value,
        coins: coins.value,
        consumableMaterial: consumableMaterial.map((val) =>
          MedalType.transformToConsumableMaterial({
            id: val.id,
            count: val.count,
            type: MedalType.ConsumableType.TYPE_MATERIAL
          })
        ),
        inconsumableMaterial: inconsumableMaterial.map((val) =>
          MedalType.transformToConsumableMaterial({
            id: val.id,
            count: val.count,
            type: MedalType.ConsumableType.TYPE_MATERIAL
          })
        )
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const medal = (await getMedal([data.id!])).get(data.id!)!
      if (!medal.enhanceAnimationImg) {
        openModal('alertModal', { message: '該勳章沒有動畫圖片，無法設定關聯', icon: 'warning' })
        return false
      }
      const recipe: MedalType.EnhanceRecipe<MedalType.Medal> = {
        info: medal,
        successRate: parseInt(data.successRate),
        coins: parseInt(data.coins),
        consumableMaterial: data.consumableMaterial,
        inconsumableMaterial: data.inconsumableMaterial
      }
      props.confirmFunction(recipe)
      closeModal()
    }

    const asyncFilterFunction = async (items: Array<MedalType.Medal>) => {
      // 可以選做被關聯的勳章要被過濾
      // 不能是 root 不能是 seriers 不能是被合成的勳章
      // 自己的勳章關聯也要拿掉，不能重複
      const ids = items.map((val) => val.id)
      const medalInfo = await getMedal(ids)
      const manufactureInfo = await MedalManufactureService.getManufactureInfo(
        ids
      )
      return items.filter((item) => {
        const medal = medalInfo.get(item.id)!
        return (
          props.whiteListId.includes(item.id) ||
          (medal.isRoot === false &&
            medal.isSeries === false &&
            !manufactureInfo.some(
              (manufacture) => item.id === manufacture.info.id
            ) &&
            !props.filterItemIds.includes(item.id))
        )
      })
    }

    return {
      selectNewItem,
      isSelect,
      medalType,
      addMaterialModal,
      tableColumns,
      consumableMaterial,
      inconsumableMaterial,
      deleteRecipeMaterial,
      successRate,
      coins,
      confirm,
      validateRule,
      asyncFilterFunction
    }
  }
})
</script>
<style lang="scss" scoped>
.item-container {
  margin: 10px 0;
  overflow: scroll;
  max-height: 350px;
}
.observer {
  margin: 15px auto;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #60a917;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img {
  width: 25px;
  height: 25px;
}
</style>
