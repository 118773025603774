import { guardianRouteName } from '@/router/appRouteName'

export const guardianRoute = {
  path: guardianRouteName.GUARDIAN.path,
  component: () => import(/* webpackChunkName: "user-profile" */ '../views/guardian/Main.vue'),
  children: [
    {
      path: guardianRouteName.GUARDIAN_ROLE.path,
      name: guardianRouteName.GUARDIAN_ROLE.name,
      meta: { title: guardianRouteName.GUARDIAN_ROLE.title },
      component: () => import(/* webpackChunkName: "user-profile" */ '../views/guardian/role/Main.vue')
    },
    {
      path: guardianRouteName.GUARDIAN_PERM.path,
      name: guardianRouteName.GUARDIAN_PERM.name,
      meta: { title: guardianRouteName.GUARDIAN_PERM.title },
      component: () => import(/* webpackChunkName: "user-profile" */ '../views/guardian/permission/Main.vue')
    },
    {
      path: guardianRouteName.GUARDIAN_DESIGN.path,
      name: guardianRouteName.GUARDIAN_DESIGN.name,
      meta: { title: guardianRouteName.GUARDIAN_DESIGN.title },
      component: () => import(/* webpackChunkName: "user-profile" */ '../views/guardian/design/Main.vue')
    },
    {
      path: guardianRouteName.GUARDIAN_DOMAIN.path,
      name: guardianRouteName.GUARDIAN_DOMAIN.name,
      meta: { title: guardianRouteName.GUARDIAN_DOMAIN.title },
      component: () => import(/* webpackChunkName: "user-profile" */ '../views/guardian/domain/Main.vue')
    }
  ]
}
