
import { defineComponent, toRefs, ref, watch, onBeforeUnmount } from 'vue'
import { FAKE_AVATAR } from '@/utils/index'
import useRouter from '@/router/useRouter'

export default defineComponent({
  props: {
    displayName: {
      type: String,
      required: true
    },
    uid: {
      type: String,
      required: true
    },
    hashUid: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)
    const { toUserProfile } = useRouter()

    const unwatch = watch(photoURL, () => {
      const img = new Image()
      img.src = photoURL.value
      img.onerror = function () {
        newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
      }
      img.onload = function () {
        newUrl.value = photoURL.value
      }
    })

    onBeforeUnmount(() => {
      unwatch()
    })

    return {
      toUserProfile,
      newUrl
    }
  }
})
