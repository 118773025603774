import { render } from "./Main.vue?vue&type=template&id=614ec828"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "614ec828"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('614ec828', script)) {
    api.reload('614ec828', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=614ec828", () => {
    api.rerender('614ec828', render)
  })

}

script.__file = "src/global-components/tippy/Main.vue"

export default script