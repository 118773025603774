<template>
  <div class="flex flex-col sm:flex-row items-center">
    <select class="form-select w-full lg:w-auto lg:mt-0 sm:mr-2" v-model="domainSelValue" @change="getDesignPolicyAllByDomain(domainSelValue)">
      <option v-for="data in DomainState.domainAll" v-bind:key="`design-domain-sel-${data.getDomainId()}`" :value="data.getDomainId()">
        {{ `${data.getDomainId()}: ${data.getDisplayName()}` }}
      </option>
    </select>
    <button class="btn btn-primary w-24 ml-2 lg:mt-0 mt-2" @click="createDesignPolicy">新增</button>
  </div>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
      <tr>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Object ID</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">說明</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Action ID</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Extra Value</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
      </tr>
      </thead>
      <tbody>
        <template v-for="(data, index) in DomainState.designPolicyAll" :key="`design-row-${domainSelValue}-${data.getObject()}-${data.getAction()}-${index}`">
        <tr v-if="index % 2 === 0" class="bg-gray-200 dark:bg-dark-1">
          <td class="border-b dark:border-dark-5">{{ data.getObject() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getSummary() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getAction() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getExtraValue() }} </td>
          <td class="border-b dark:border-dark-5">
            <div class="flex flex-nowrap">
              <button class="btn btn-dark mr-2 w-20" @click="updateDesignPolicy(data)">編輯</button>
<!--              <button class="btn btn-danger-soft mr-2 w-20">刪除</button>-->
            </div>
          </td>
        </tr>
        <tr v-else>
          <td class="border-b dark:border-dark-5">{{ data.getObject() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getSummary() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getAction() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getExtraValue() }} </td>
          <td class="border-b dark:border-dark-5">
            <div class="flex flex-nowrap">
              <button class="btn btn-dark mr-2 w-20" @click="updateDesignPolicy(data)">編輯</button>
<!--              <button class="btn btn-danger-soft mr-2 w-20">刪除</button>-->
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
  <div class="col-span-12 flex mt-5"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useModal } from '@/plugins/modals/'
import { getDomainAll, getDesignPolicyAllByDomain, DomainState } from '@/store/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  setup () {
    const { openModal } = useModal()
    const domainSelValue = ref<null | string>(null)

    const createDesignPolicy = async(e: Event) => {
      e.preventDefault()
      let domainID = ''
      if (!domainSelValue.value) {
        return
      }
      domainID = domainSelValue.value
      openModal('editGuardianDesign',
        {
          mode: 'create',
          designDomainID: domainID,
          done: () => { getDesignPolicyAllByDomain(domainID) }
        }
      )
    }

    const updateDesignPolicy = async(policy: GuardianQueryPB.RolePolicy) => {
      let domainID = ''
      if (!domainSelValue.value) {
        return
      }
      domainID = domainSelValue.value
      openModal('editGuardianDesign',
        {
          mode: 'edit',
          designDomainID: policy.getDomain(),
          designObjectID: policy.getObject(),
          designSummary: policy.getSummary(),
          designActionID: policy.getAction(),
          designExtraValue: policy.getExtraValue(),
          done: () => { getDesignPolicyAllByDomain(domainID) }
        }
      )
    }

    onMounted(async() => {
      await getDomainAll()
      if (DomainState.domainAll.length > 0) {
        domainSelValue.value = DomainState.domainAll[0].getDomainId()
        if (domainSelValue.value) {
          getDesignPolicyAllByDomain(domainSelValue.value)
        }
      }
    })
    return {
      createDesignPolicy,
      updateDesignPolicy,
      DomainState,
      domainSelValue,
      getDesignPolicyAllByDomain
    }
  }
})
</script>
