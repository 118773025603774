
import { computed, defineComponent, reactive, unref } from 'vue'
import UploadImg from '@/components/item/uploadImg.vue'
import MedalData from '@/components/data/itemOrMedal'
import { useRoute } from 'vue-router'
import myRouter from '@/router/useRouter'
import { helper as $h } from '@/utils/helper'
import { useModal } from '@/plugins/modals/'
import { setMedalMap, updateMedalMap } from '@/store/medal'
import { MedalService } from '@/services/'
import { MedalType } from '@/types/'
import { useLoading } from '@/plugins/loading'
import Switch from '@/global-components/switch/Main.vue'
export default defineComponent({
  name: 'MedalEdit',
  components: {
    UploadImg,
    MedalData,
    Switch
  },
  setup () {
    const IMAGE_WIDTH = 22
    const IMAGE_HEIGHT = 36
    const ENHANCE_IMAGE_WIDTH = 100
    const EHNAHCE_IMAGE_HEIGHT = 100
    const route = useRoute()
    const { toMedalPage } = myRouter()
    const medalId = route.params.id || ''
    const { startLoading } = useLoading()
    const { openModal } = useModal()

    const state = computed(() => {
      return medalId ? 'edit' : 'new'
    })

    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存' : '新增'
      const uploadImgText = isEdit ? '更換圖片' : '上傳圖片'
      const uploadAnimationImgText = isEdit ? '更換強化動畫圖片' : '上傳強化動畫圖片'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? MedalService.update : MedalService.create
      const storeFunction = isEdit ? updateMedalMap : setMedalMap
      return {
        btnText,
        successText,
        API,
        storeFunction,
        uploadImgText,
        uploadAnimationImgText
      }
    })

    const submitData = reactive({
      id: '',
      displayName: '',
      img: '',
      enhanceAnimationImg: '',
      description: '',
      furnaceLuck: 0,
      craftLuck: 0,
      enhanceLuck: 0,
      available: false
    })

    const validateRule = {
      displayName: {
        required: true,
        errorMessage: '請輸入名稱'
      },
      description: {
        required: true,
        errorMessage: '請輸入簡介'
      },
      img: {
        required: true,
        errorMessage: '請上傳勳章圖'
      },
      enhanceAnimationImg: {
        required: false,
        errorMessage: '請上傳強化動畫圖'
      },
      luckNumber: {
        rule: new RegExp(/^[0-9]$|^[1-9][0-9]$|^(100)$/),
        errorMessage: '請輸入幸運值',
        ruleMessage: '請輸入正確的數字格式(0~100)'
      }
    }

    const submit = async(event: Event) => {
      event.preventDefault()
      const fn = unref(stateMethod)
      const data = {
        id: submitData.id,
        displayName: submitData.displayName,
        img: submitData.img,
        enhanceAnimationImg: submitData.enhanceAnimationImg,
        description: submitData.description,
        available: submitData.available,
        property: {
          furnaceLuck: submitData.furnaceLuck,
          craftLuck: submitData.craftLuck,
          enhanceLuck: submitData.enhanceLuck
        },
        // 寫死
        category: MedalType.MedalCategory.CATEGORY_ONE,
        canApply: false,
        canEnhance: false,
        isSeries: false,
        isRoot: false
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        const id = await fn.API(data)
        fn.storeFunction(id, { ...data, id })
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toMedalPage()
      } catch (e) {
        console.error(e.message)
        openModal('alertModal', { icon: 'success', message: '出錯了' })
      } finally {
        loading.close()
      }
    }

    return {
      IMAGE_WIDTH,
      IMAGE_HEIGHT,
      medalId,
      state,
      stateMethod,
      validateRule,
      submitData,
      EHNAHCE_IMAGE_HEIGHT,
      ENHANCE_IMAGE_WIDTH,
      submit
    }
  }
})
