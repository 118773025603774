<template>
  <div class="flex flex-col sm:flex-row items-center">
    <button class="btn btn-primary" @click="createUser">新增管理者</button>
  </div>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
      <tr>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Email</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">顯示名稱</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">角色</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
      </tr>
      </thead>
      <tbody>
        <template v-for="(data) in columnData" v-bind:key="`domain-row-${data.email}`">
        <tr>
          <td class="border-b dark:border-dark-5">{{ data.user.email }}</td>
          <td class="border-b dark:border-dark-5">{{ data.user.displayName }}</td>
          <td class="border-b dark:border-dark-5">
            <div v-for="role, in data.role" v-bind:key="`iam-role-${data.email}-${role.roleId}`">
              {{ role.displayName }}
            </div>
          </td>
          <td class="border-b dark:border-dark-5">
            <div class="min-w-max">
              <button class="btn btn-dark mr-2" @click="updateUser(data.user, data.role)">編輯</button>
              <button class="btn btn-danger-soft mr-2" @click="delUser(data.user, data.role)">刪除</button>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue'
import { useModal } from '@/plugins/modals/'
import { IamState, DomainID } from '@/store/iam'
import { DomainState } from '@/store/guardian'
import { AdminUid } from '@/api/guardian'
import { IamService } from '@/services/'
import { IamType, RoleType } from '@/types/'

export default defineComponent({
  setup() {
    const { openModal } = useModal()
    const columnData = reactive<{ user: IamType.Iam, role: RoleType.Role[] }[]>([])

    const refresh = async() => {
      columnData.length = 0
      const data = await IamService.getAllIamUserAndRole()
      data.forEach((val) => {
        columnData.push(val)
      })
    }

    const createUser = async(e: Event) => {
      e.preventDefault()
      openModal('editIamIdentity', {
        mode: 'NEW',
        confirmFunction: refresh
      })
    }

    const updateUser = async(user: IamType.Iam, role: RoleType.Role[]) => {
      openModal('editIamIdentity', {
        mode: 'EDIT',
        oldEmail: user.email,
        oldDisplayName: user.displayName,
        role: role,
        confirmFunction: refresh
      })
    }

    const delUser = async(user: IamType.Iam, role: RoleType.Role[]) => {
      openModal('confirmModal',
        {
          message: '確定要刪除嗎?',
          confirmFunction: async() => {
            await IamService.deleteIdentity(user.displayName, user.email, role)
            openModal('alertModal', { icon: 'success', message: '刪除成功' })
            refresh()
          }
        }
      )
    }

    onMounted(() => {
      refresh()
    })

    return {
      createUser,
      IamState,
      updateUser,
      delUser,
      DomainState,
      AdminUid,
      DomainID,
      columnData
    }
  }
})
</script>

<style scoped>
tr:nth-child(even) {
  background-color: rgba(237, 242, 247, 1);
}
</style>
