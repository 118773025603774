export enum Domain {
  jkforum = 'jkforum',
  adm = 'adm.jkf'
}

export enum RoleEffect {
  allow = 'allow',
  deny = 'deny'
}

export class Role {
  roleId: string
  domain: string
  displayName: string
  constructor({ roleId, domain, displayName }:{
    roleId: string
    domain: string
    displayName: string
  }) {
    this.roleId = roleId
    this.domain = domain
    this.displayName = displayName
  }

  get isExist():boolean {
    return !!this.roleId
  }
}
export const emptyRole = new Role({ roleId: '', domain: '', displayName: 'error' })

export type PolicyApiData = { object: string, action: string }

export enum TalkPolicy {
  MESSAGE_READ = 'admin_talk_message:read',
  MESSAGE_WRITE = 'admin_talk_message:write',
  CHATROOM_READ = 'admin_talk_chatroom:read'
}

export enum MaterialPolicy {
  MATERIAL_ITEM_READ = 'admin_material_item:read',
  MATERIAL_ITEM_WRITE = 'admin_material_item:write',
  MATERIAL_SHIP = 'admin_material_ship:access',
  MATERIAL_MANUFACTORE_READ = 'admin_material_craft:read',
  MATERIAL_MANUFACTORE_WRITE = 'admin_material_craft:write',
  MATERIAL_SHOP_READ = 'admin_material_store:read',
  MATERIAL_SHOP_WRITE = 'admin_material_store:write',
  MATERIAL_LOG_READ = 'admin_material_log:read',
  MATERIAL_EFFECT_READ = 'admin_material_effect:read',
  MATERIAL_EFFECT_WRITE = 'admin_material_effect:write',
  MATERIAL_IAMGE_UPLOAD = 'admin_material_image:upload'
}

export enum MedalPolicy {
  MEDAL_ITEM_READ = 'admin_medal_item:read',
  MEDAL_ITEM_WRITE = 'admin_medal_item:write',
  MEDAL_SHIP = 'admin_medal_ship:access',
  MEDAL_ENHACNE_READ = 'admin_medal_enhance:read',
  MEDAL_ENHANCE_WRITE = 'admin_medal_enhance:write',
  MEDAL_MANUFACTURE_READ = 'admin_medal_furnace:read',
  MEDAL_MANUFACTURE_WRITE = 'admin_medal_furnace:write',
  MEDAL_LOG_READ = 'admin_medal_log:read',
  MEDAL_IMAGE_UPLOAD = 'admin_medal_image:upload',
  MEDAL_COLLECTION_READ = 'admin_medal_collection:read'
}

export enum PanPolicy {
  PAN_SEARCH_READ = 'admin_pan_search:read'
}

export enum AdministratorPolicy {
  ADMINISTRATOR_READ = 'admin_administrator:read',
  ADMINISTRATOR_WRITE = 'admin_administrator:write',
  POLICY_READ = 'admin_policy_permission:read',
  POLICY_WRITE = 'admin_policy_permission:write'
}

export class PolicyData {
  private policyKey: string
  private object: string
  private action: string

  constructor(key: string) {
    const resultList = key.split(':')
    if (resultList.length !== 2) throw new Error('policy format error')
    this.policyKey = key
    this.object = resultList[0]
    this.action = resultList[1]
  }

  get policy(): string {
    return this.policyKey
  }

  get apiData(): PolicyApiData {
    return {
      object: this.object,
      action: this.action
    }
  }
}

interface PolicyInterface {
  readonly displayName: string
  readonly key: string
}

export abstract class Policy {
  abstract policyList: PolicyData[]
  get policy(): PolicyData[] {
    return this.policyList.map(val => val)
  }

  get policyKeyList(): string[] {
    return this.policyList.map(val => val.policy)
  }

  get domain(): string {
    return 'ADM後台'
  }

  abstract get key():string
}

export class PanPolicyGroup extends Policy implements PolicyInterface {
  policyList: PolicyData[] = [
    // -- route: /users --
    new PolicyData(PanPolicy.PAN_SEARCH_READ)
  ]

  get displayName(): string {
    return 'Pan 會員管理'
  }

  get key (): string {
    return 'PanAdmin'
  }
}

export class JkTalkPolicyGroup extends Policy implements PolicyInterface {
  policyList: PolicyData[] = [
    new PolicyData(TalkPolicy.MESSAGE_READ),
    new PolicyData(TalkPolicy.MESSAGE_WRITE),
    new PolicyData(TalkPolicy.CHATROOM_READ)
  ]

  get displayName(): string {
    return 'jk-talk'
  }

  get key (): string {
    return 'JkTalk'
  }
}

export class MaterialPolicyGroup extends Policy implements PolicyInterface {
  policyList: PolicyData[] = [
    // -- route: /material --
    new PolicyData(MaterialPolicy.MATERIAL_ITEM_READ),
    new PolicyData(MaterialPolicy.MATERIAL_ITEM_WRITE),
    // -- route: /shop --
    new PolicyData(MaterialPolicy.MATERIAL_SHOP_READ),
    new PolicyData(MaterialPolicy.MATERIAL_SHOP_WRITE),
    // -- route: /manufacture --
    new PolicyData(MaterialPolicy.MATERIAL_MANUFACTORE_READ),
    new PolicyData(MaterialPolicy.MATERIAL_MANUFACTORE_WRITE),
    // -- route: /sendMaterial --
    new PolicyData(MaterialPolicy.MATERIAL_SHIP),
    // --route: /materialLog --
    new PolicyData(MaterialPolicy.MATERIAL_LOG_READ),
    // -- other
    new PolicyData(MaterialPolicy.MATERIAL_EFFECT_READ),
    new PolicyData(MaterialPolicy.MATERIAL_EFFECT_WRITE),
    new PolicyData(MaterialPolicy.MATERIAL_IAMGE_UPLOAD)
  ]

  get displayName(): string {
    return '道具'
  }

  get key (): string {
    return 'Material'
  }
}

export class MedalPolicyGroup extends Policy implements PolicyInterface {
  policyList: PolicyData[] = [
    // -- route: /medal --
    new PolicyData(MedalPolicy.MEDAL_ITEM_READ),
    new PolicyData(MedalPolicy.MEDAL_ITEM_WRITE),
    // -- route: /medalManufacture --
    new PolicyData(MedalPolicy.MEDAL_MANUFACTURE_READ),
    new PolicyData(MedalPolicy.MEDAL_MANUFACTURE_WRITE),
    // -- route: /medalEnhance --
    new PolicyData(MedalPolicy.MEDAL_ENHACNE_READ),
    new PolicyData(MedalPolicy.MEDAL_ENHANCE_WRITE),
    // -- route: / sendMedal --
    new PolicyData(MedalPolicy.MEDAL_SHIP),
    // --route: /medalLog --
    new PolicyData(MedalPolicy.MEDAL_LOG_READ),
    // -- other
    new PolicyData(MedalPolicy.MEDAL_COLLECTION_READ),
    new PolicyData(MedalPolicy.MEDAL_IMAGE_UPLOAD)
  ]

  get displayName(): string {
    return '勳章'
  }

  get key (): string {
    return 'Medal'
  }
}

export class AdministratorPolicyGroup extends Policy implements PolicyInterface {
  policyList: PolicyData[] = [
    // route: /iam/user
    new PolicyData(AdministratorPolicy.ADMINISTRATOR_READ),
    new PolicyData(AdministratorPolicy.ADMINISTRATOR_WRITE),
    // route: /iam/role
    new PolicyData(AdministratorPolicy.POLICY_READ),
    new PolicyData(AdministratorPolicy.POLICY_WRITE)
  ]

  get displayName(): string {
    return '後台人員管理權限'
  }

  get key (): string {
    return 'Administrator'
  }
}

// 側邊欄列表
export const PolicyList = [
  new JkTalkPolicyGroup(),
  new PanPolicyGroup(),
  new MaterialPolicyGroup(),
  new MedalPolicyGroup(),
  new AdministratorPolicyGroup()
].map(val => val)

export type roleKey = 'JkTalk' | 'PanAdmin' | 'Material' | 'Medal' | 'Administrator'

export const roleAndKeyMap = {
  JkTalk: new JkTalkPolicyGroup(),
  PanAdmin: new PanPolicyGroup(),
  Material: new MaterialPolicyGroup(),
  Medal: new MedalPolicyGroup(),
  Administrator: new AdministratorPolicyGroup()
}
