import { EffectAPI } from '@/api/'
import { loginedStatus } from '@/store/user'
import { EffectType, MaterialType } from '@/types/'
import * as adminEffectPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/effect_pb'
export const getInfo = async(materialId: Array<string>):Promise<Array<EffectType.EffectInterface>> => {
  const res = await EffectAPI.getInfo(loginedStatus.token, materialId)
  return res.getEffectInfoList().map(val => createEffectInterface(val))
}

export const create = async(materialId: string, effectType: EffectType.EffectType, chest: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>): Promise<string> => {
  const { maxKind, minKind } = calcMinAndMaxKind(effectType, chest)
  const res = await EffectAPI.create(loginedStatus.token, { materialId, effectType, minKind, maxKind, chest })
  return res.getMaterialId()
}

export const update = async(materialId: string, effectType: EffectType.EffectType, chest: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>): Promise<string> => {
  const { maxKind, minKind } = calcMinAndMaxKind(effectType, chest)
  const res = await EffectAPI.update(loginedStatus.token, { materialId, effectType, minKind, maxKind, chest })
  return res.getMaterialId()
}

export const deleteEffect = async(materialId: string):Promise<string> => {
  const res = await EffectAPI.deleteEffect(loginedStatus.token, materialId)
  return res.getMaterialId()
}

export const createEffectInterface = (value: adminEffectPb.EffectInfo): EffectType.EffectInterface => {
  return {
    materialId: value.getMaterialId(),
    effectType: Number(value.getEffectType()),
    minKind: value.getMinKind(),
    maxKind: value.getMaxKind(),
    chest: value.getChestList().map((val:any) => createChestInterface(val))
  }
}

export const createChestInterface = (value: adminEffectPb.Chest): EffectType.ChestInterface => {
  return {
    chestType: Number(value.getChestType()),
    dependId: value.getId(),
    rate: value.getRate(),
    minCount: value.getMinCount(),
    maxCount: value.getMaxCount()
  }
}

const calcMinAndMaxKind = (effectType: EffectType.EffectType, chestList: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>): {
  minKind: number
  maxKind: number

} => {
  switch (effectType) {
    case EffectType.EffectType.EFFECT_TYPE_OPEN_ONE || EffectType.EffectType.EFFECT_TYPE_OPEN_MULTI:
      return {
        minKind: 1,
        maxKind: chestList.length
      }
    case EffectType.EffectType.EFFECT_TYPE_OPEN_ALL || EffectType.EffectType.EFFECT_TYPE_OPEN_CREDIT:
      return {
        minKind: chestList.length,
        maxKind: chestList.length
      }
    default:
      return {
        minKind: 1,
        maxKind: 1
      }
  }
}
