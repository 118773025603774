import { render } from "./Text.vue?vue&type=template&id=6c927fca&scoped=true"
import script from "./Text.vue?vue&type=script&lang=ts"
export * from "./Text.vue?vue&type=script&lang=ts"

import "./Text.vue?vue&type=style&index=0&id=6c927fca&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6c927fca"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6c927fca"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6c927fca', script)) {
    api.reload('6c927fca', script)
  }
  
  module.hot.accept("./Text.vue?vue&type=template&id=6c927fca&scoped=true", () => {
    api.rerender('6c927fca', render)
  })

}

script.__file = "src/components/talking-room/Text.vue"

export default script