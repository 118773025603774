import * as grpcLib from '@/libs/grpc'
import * as panPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/pan/pan_pb'
import { PanAdminPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/pan/pan_grpc_web_pb'

grpcLib.setJkfHost(
  grpcLib.JkfHost.ADMIN_PAN,
  process.env.VUE_APP_JKF_API_HOST as string
)
const PanUserService = new PanAdminPromiseClient(
  grpcLib.getJkfHost(grpcLib.JkfHost.ADMIN_PAN)
)
grpcLib.EnableGrpcDebugTools(PanUserService)

export const userInfo = async (
  adminToken: string,
  uid: number,
  phone: string
): Promise<panPb.SearchResponse> => {
  const req: panPb.SearchRequest = new panPb.SearchRequest()
  req.setAdminToken(adminToken)
  req.setUid(uid)
  req.setPhone(phone)
  const res: panPb.SearchResponse = await PanUserService.search(req)
  return grpcLib.resolveData(res)
}
