<template>
  <suspense>
    <div class="overflow-x-auto scrollbar-hidden">
      <el-table
        :data="data"
        stripe
        :border="border"
        style="width: 100%"
        emptyText="無資料"
        @sort-change="sortChange"
      >
        <el-table-column
          :sortable="column.sortable ? 'custom' : false"
          v-for="column in columns"
          :key="column.prop"
          :prop="column.prop"
          :width="column.width ? column.width : ''"
          :label="column.label"
        >
          <template #default="scope">
            <slot :name="column.prop" :row="scope.row" :index="scope.$index">
              {{ scope.row[column.prop] }}
            </slot>
          </template>
        </el-table-column>
      </el-table>
      <slot name="pagination" :page="page"> </slot>
    </div>
  </suspense>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ElTable, ElTableColumn } from 'element-plus'

type ColumnType = {
  prop: string
  label: string
  sortable?: boolean
  width?: string | number
}
export default defineComponent({
  components: {
    ElTable,
    ElTableColumn
  },
  props: {
    border: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array as PropType<Array<ColumnType>>,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    sortChange: {
      type: Function as PropType<(...args: any[]) => void>,
      // @ts-ignore
      default: () => {
        console.log('default')
      }
    }
  }
})
</script>
