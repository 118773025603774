import { inject, InjectionKey } from 'vue'

export default {
  FULLSCREEN_DOM: Symbol('fullscreenDomId')
} as const

export const FULLSCREEN_DOM_STRING = 'fullscreen-dom'
export const CLOSE_MODAL: InjectionKey<() => void> = Symbol('closeModal')
export const mySymbol = (name: string) => {
  return Symbol(name)
}
export const injectStrict = <T>(key: InjectionKey<T>, fallback?: T) => {
  const resolved = inject(key, fallback)
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`)
  }
  return resolved
}
