import { RoleType } from '@/types/'
import { helper } from '@/utils/helper'
import {
  CreateRole,
  CreatePolicyForRole,
  QueryRoleAllByDomain,
  QueryActionAllByRole,
  UpdateRole,
  DeletePolicyForRole
} from '@/api/guardian'
import { loginedStatus } from '@/store/user'
import { setRoleMap } from '@/store/role'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export const createRolePolicy = (item: GuardianQueryPB.Role): RoleType.Role => {
  return new RoleType.Role({
    roleId: item.getRoleId(),
    domain: item.getDomain(),
    displayName: item.getDisplayName()
  })
}

export const createRoleApi = async(displayName: string):Promise<RoleType.Role> => {
  const roleId = helper.sha1(displayName).substring(0, 15)
  await CreateRole(loginedStatus.token, {
    roleId,
    displayName,
    domain: RoleType.Domain.adm
  })
  return new RoleType.Role({
    roleId,
    displayName,
    domain: RoleType.Domain.adm
  })
}

const flatPolicyList = (group: RoleType.Policy[], key: 'apiData' | 'policy'): Array<RoleType.PolicyApiData | string> => {
  const data = group.map((val: RoleType.Policy) => {
    return val.policy.map((policy: RoleType.PolicyData) => policy[key])
  })
  return data.flat()
}

export const createPolicyForRoleAdapter = (roleId: string, policygroup: RoleType.Policy[]) => {
  const policyList = flatPolicyList(policygroup, 'apiData') as RoleType.PolicyApiData[]
  const apiList = policyList.map((val) => {
    return CreatePolicyForRole(localStorage.token, {
      roleId: `r:${roleId}`,
      domain: RoleType.Domain.adm,
      object: val.object,
      action: val.action,
      effect: RoleType.RoleEffect.allow
    })
  })
  return Promise.all(apiList)
}

export const createPolicyForRole = async(roleDisplayName: string, policygroup: RoleType.Policy[]) => {
  // first create role
  const role = await createRoleApi(roleDisplayName)
  // second binding policy to role
  await createPolicyForRoleAdapter(role.roleId, policygroup)
}

export const updatePolicyForRole = async({ role, newDisplayName, oldPolicyList, newPolicyList }: {
  role: RoleType.Role
  newDisplayName: string
  oldPolicyList: RoleType.Policy[],
  newPolicyList: RoleType.Policy[]
}) => {
  // NOTE: 我很抱歉，但我真的不知道我在寫什麼了 (2022/02/25 Neil) 會回來改接口的
  await UpdateRole(loginedStatus.token, { roleId: role.roleId, domain: role.domain, displayName: newDisplayName })
  // need delete
  const oldPolicy = flatPolicyList(oldPolicyList, 'policy') as string[]
  const newPolicy = flatPolicyList(newPolicyList, 'policy') as string[]

  const needDelete = oldPolicy.filter(val => !newPolicy.includes(val))
  await deleteRolePolicyAPIList(needDelete, role.roleId)

  // need create
  const needUpdate = newPolicy.filter(val => !oldPolicy.includes(val))
  await createRolePolicyAPIList(needUpdate, role.roleId)
}

export const getAllRoleList = async(): Promise<RoleType.Role[]> => {
  return (await QueryRoleAllByDomain(loginedStatus.token, RoleType.Domain.adm)).getRoleAllList()
    .map((val: GuardianQueryPB.Role) => {
      const role = createRoleUser(val)
      setRoleMap(role.roleId, role)
      return role
    })
}

export const getRolesActions = async() => {
  const roleList = await getAllRoleList()
  // get all roles and get it's policy
  const policyRawData = await callGetRolesPolicyApi(roleList)
  // 轉換成我要的資料
  return transferPolicyData(policyRawData, roleList)
}

export const createRoleUser = (data: GuardianQueryPB.Role):RoleType.Role => {
  return new RoleType.Role({
    roleId: data.getRoleId(),
    domain: data.getDomain(),
    displayName: data.getDisplayName()
  })
}

// private function ----
const callGetRolesPolicyApi = async(roleList: RoleType.Role[]):Promise<Array<{
  roleId: string
  policy: GuardianQueryPB.QueryActionAllByRoleRes
}>> => {
  const policyData = await Promise.all(
    roleList.map((role) => QueryActionAllByRole(loginedStatus.token, RoleType.Domain.adm, role.roleId))
  )
  return policyData.map((val, index) => {
    return {
      roleId: roleList[index].roleId,
      policy: val
    }
  })
}

const transferPolicyData = (rawData: {roleId: string, policy:GuardianQueryPB.QueryActionAllByRoleRes}[], roleList: RoleType.Role[]): {
  role: RoleType.Role,
  policyList: RoleType.Policy[]
}[] => {
  return rawData.map(val => {
    const role = findRole(val.roleId, roleList)
    // raw policy key list
    const policyListKey = val.policy.getActionAllList().map(value => {
      return `${value.getObject()}:${value.getAction()}`
    })

    // 轉換成我要的 class
    const policyList = RoleType.PolicyList.filter((val, index) => {
      const keyList = val.policyKeyList
      const data2 = keyList.filter((wtf) => {
        return policyListKey.includes(wtf)
      })
      // NOTE: 權限有少的話就不回傳完整的 policyGroup
      if (RoleType.PolicyList[index].policyKeyList.length === data2.length) return RoleType.PolicyList[index]
    })
    return {
      role,
      policyList
    }
  })
}

const findRole = (roleId: string, roleList: RoleType.Role[]): RoleType.Role => {
  return roleList.find(val => val.roleId == roleId) || RoleType.emptyRole
}

const deleteRolePolicyAPIList = async(rawData: string[], roleId: string) => {
  // 轉換成我要的 class
  const apiList = rawData.map((val) => {
    const split = val.split(':')
    return DeletePolicyForRole(localStorage.token, {
      roleId,
      domain: RoleType.Domain.adm,
      object: split[0],
      action: split[1],
      effect: RoleType.RoleEffect.allow
    })
  })
  return Promise.all(apiList)
}

const createRolePolicyAPIList = async(rawData: string[], roleId: string) => {
  const apiList = rawData.map((val) => {
    const split = val.split(':')
    return CreatePolicyForRole(localStorage.token, {
      roleId,
      domain: RoleType.Domain.adm,
      object: split[0],
      action: split[1],
      effect: RoleType.RoleEffect.allow
    })
  })
  return Promise.all(apiList)
}
