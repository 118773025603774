<template>
  <div
    class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-between mb-5"
  >
    <div>
      <form id="search-form" class="lg:flex justify-start w-full relative">
        <div class="flex items-center md:mr-4 mt-2 xl:mt-0">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial sm:mr-2">
            UID:
          </label>
          <input
            v-model="uid"
            type="tel"
            class="form-control md:w-40 xxl:w-full"
            placeholder="UID"
          />
        </div>
        <div class="mt-2 xl:mt-0">
          <button
            type="button"
            class="btn btn-primary w-full md:w-16"
            @click="search"
          >
            搜尋
          </button>
        </div>
      </form>
    </div>

    <div class="mt-2 sm:mt-0">
      <button type="button" class="btn btn-primary" @click="addBannedUserModal">
        新增禁言
      </button>
    </div>
  </div>

  <DataTable
    :data="bannedUserList"
    :columns="columns"
    :page="page"
    :pageSize="pageSize"
  >
    <template #uid="{row}">
      <span>
        {{ row.uid }}
      </span>
    </template>
    <template #time="{row}">
      <span v-formatTime="row.createdAt"></span>
    </template>
    <template #displayName="{row}">
      <span>
        {{ row.displayName }}
      </span>
    </template>

    <template #cause="{row}">
      <span>
        {{ row.reason }}
      </span>
    </template>
    <template #actor="{row}">
      <span>
        {{ row.operatorEmail }}
      </span>
    </template>
    <template #action="{row}">
      <button
        class="btn w-24 mr-1 mb-2 btn-rounded-success"
        @click="cancelBannedUserModal(row)"
      >
        取消禁言
      </button>
    </template>
    <template #pagination="{ page }">
      <!-- 業務需求，所以用自己做的分頁表 -->
      <pagination
        v-if="bannedUserList.length > 0"
        class="mt-3"
        :page-size="pageSize"
        :total-page="totalPage"
        :page="page"
        @changePage="getListByPage"
      />
    </template>
  </DataTable>
</template>

<script lang="ts">
import { JKTalkAPI } from '@/api/index'
import dayjs from '@/libs/dayjs'
import { useLoading } from '@/plugins/loading/'
import { useModal } from '@/plugins/modals/index'
import { TableColumns } from '@/types/index'
import { defineComponent, ref } from 'vue'
import { loginedStatus } from '@/store/user'

import DataTable from '@/components/data-table/Main.vue'
import Pagination from '@/components/data-table/Pagination.vue'

type dataType = {
  uid: number
  createdAt: any
  displayName: string
  reason: string
  operatorEmail: string
}

export default defineComponent({
  name: 'BannedUser',
  directives: {
    formatTime: {
      mounted(el, binding) {
        if (binding.value) {
          el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
        } else {
          el.innerText = ''
        }
      },
      updated(el, binding) {
        if (binding.value) {
          el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
        }
      }
    }
  },
  components: {
    DataTable,
    Pagination
  },
  setup() {
    const { startLoading } = useLoading()
    const { openModal } = useModal()

    const uid = ref<string>('')
    const bannedUserList = ref<Array<dataType>>([])
    const columns = TableColumns.BANNED_USER_COLUMNS
    const page = ref(1)
    const nextPageToken = ref('')
    const prevPageToken = ref('')
    const totalCount = ref(0)
    const totalPage = ref(1)
    const pageSize = ref(30)
    let cancelTarget: dataType | null = null
    let closeAddBannedUserModal: Function | null = null

    const search = async () => {
      const load = startLoading()
      try {
        const res = await JKTalkAPI.getBannedUserList(loginedStatus.token, {
          pageSize: pageSize.value,
          uid: uid.value
        })

        totalCount.value = res.getTotalCount()
        totalPage.value = Math.ceil(res.getTotalCount() / pageSize.value)
        nextPageToken.value = res.getNextPageToken()
        prevPageToken.value = res.getPreviousPageToken()

        bannedUserList.value = res.getResultList().map(data => {
          const result = data.toObject()

          return {
            uid: result.uid,
            createdAt: data.getCreatedAt().toDate(),
            displayName: result.displayName,
            reason: result.reason,
            operatorEmail: result.operatorEmail
          }
        })

        // reset page
        page.value = 1
      } catch (e) {
        // @ts-ignore
        if (e.message) {
          openModal('alertModal', {
            icon: 'warning',
            // @ts-ignore
            message: e.message
          })
        }
      } finally {
        load.close()
      }
    }
    search()

    const getListByPage = async (pages: number, isPrev: boolean) => {
      // 簡單防呆
      if (totalCount.value < pageSize.value && !isPrev) return

      page.value = pages

      const load = startLoading()
      try {
        const res = await JKTalkAPI.getBannedUserList(loginedStatus.token, {
          uid: uid.value,
          pageToken: isPrev ? prevPageToken.value : nextPageToken.value
        })

        nextPageToken.value = res.getNextPageToken()
        prevPageToken.value = res.getPreviousPageToken()

        bannedUserList.value = res.getResultList().map(data => {
          const result = data.toObject()

          return {
            uid: result.uid,
            createdAt: data.getCreatedAt().toDate(),
            displayName: result.displayName,
            reason: result.reason,
            operatorEmail: result.operatorEmail
          }
        })
      } catch (e) {
        // @ts-ignore
        if (e.message) {
          openModal('alertModal', {
            icon: 'warning',
            // @ts-ignore
            message: e.message
          })
        }
      } finally {
        load.close()
      }
    }

    const addBannedUserModal = () => {
      const { closeModal } = openModal('addBannedUserModal', {
        confirmFunction: addBannedUser
      })
      closeAddBannedUserModal = closeModal
    }

    const addBannedUser = async (data: { uid: string; cause: string }) => {
      try {
        await JKTalkAPI.banUser(loginedStatus.token, {
          uid: data.uid,
          reason: data.cause
        })

        uid.value = data.uid
        search()

        closeAddBannedUserModal!()
      } catch (error) {
        // @ts-ignore
        if (error.message) {
          openModal('alertModal', {
            icon: 'warning',
            // @ts-ignore
            message: error.message
          })
        }
      }
    }

    const cancelBannedUserModal = (data: dataType) => {
      cancelTarget = data

      openModal('confirmModal', {
        message: '請注意！確認是否取消該會員禁言？',
        confirmFunction: cancelBannedUser
      })
    }

    const cancelBannedUser = async () => {
      try {
        await JKTalkAPI.UnBanUser(loginedStatus.token, {
          uid: cancelTarget?.uid!
        })

        const targetIndex = bannedUserList.value.findIndex(
          data => data.uid === cancelTarget?.uid
        )

        if (targetIndex >= 0) {
          bannedUserList.value.splice(targetIndex, 1)
        }
      } catch (error) {
        // @ts-ignore
        if (error.message) {
          openModal('alertModal', {
            icon: 'warning',
            // @ts-ignore
            message: error.message
          })
        }
      }
    }

    return {
      uid,
      bannedUserList,
      page,
      totalPage,
      pageSize,
      columns,
      search,
      getListByPage,
      addBannedUserModal,
      cancelBannedUserModal,
      addBannedUser
    }
  }
})
</script>

<style scoped></style>
