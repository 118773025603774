<template>
  <div class="mb-3">
    <button type="button" class="btn btn-primary w-full md:w-32" @click="newItem">
      新上架商品
    </button>
  </div>
  <div>
    <data-table
      :columns="columns"
      :data="data"
      :page="page"
    >
      <template #img="{ row }">
        <img
        :src="row.img"
        class="w-10">
      </template>
      <template #creditType="{ row }">
        {{ row?.creditType?.displayName || '' }}
      </template>
      <template #action="{ row }">
        <button class="btn w-24 mr-1 mb-2 btn-rounded-success" @click="toEditShopItemPage(row.id)">
        編輯
        </button>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, reactive } from 'vue'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { MaterialType, TableColumns } from '@/types/index'
import { useModal } from '@/plugins/modals/index'
import { ShopService } from '@/services'
export default defineComponent({
  name: 'Shop',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    let closeNewItem: Function | null = null
    const { openModal } = useModal()
    // TODO: 未來會有類別
    const columns = TableColumns.SHOP
    const data = reactive<Array<MaterialType.ShopMaterial>>([])
    onBeforeMount(async () => {
      const result = await ShopService.getAllShopItem(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })
    const { toEditShopItemPage } = useRouter()
    const validateAndChangePage = (id: string) => {
      if (!id) {
        openModal('alertModal', { icon: 'warning', message: '請選擇道具' })
        return false
      }
      closeNewItem!()
      toEditShopItemPage(id)
    }

    const newItem = () => {
      const { closeModal } = openModal('newItem', { confirmFunction: validateAndChangePage })
      closeNewItem = closeModal
    }

    const resetData = () => {
      data.length = 0
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await ShopService.getAllShopItem(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    return {
      columns,
      data,
      page,
      toEditShopItemPage,
      getDataByPage,
      newItem,
      totalPage
    }
  }
})
</script>

<style scoped>

</style>
