
import { defineComponent, ref } from 'vue'
import dayjs from '@/libs/dayjs'
import DataTable from '@/components/data-table/Main.vue'
import Pagination from '@/components/data-table/Pagination.vue'
import { MedalService } from '@/services'
import { MedalType } from '@/types/'
import { useLoading } from '@/plugins/loading/'

export default defineComponent({
  directives: {
    formatTime: {
      mounted(el, binding) {
        el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
      },
      updated(el, binding) {
        if (binding.value) el.innerText = dayjs(binding.value).format('YYYY/MM/DD HH:mm:ss')
      }
    }
  },
  name: 'ItemLog',
  components: {
    DataTable,
    Pagination
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10)
    const totalPage = ref<number>(0)
    const data = ref<Array<MedalType.LogItem>>([])
    const status = ref<'1'|'2'|'3'|'4'|'5'>('1')
    const { startLoading } = useLoading()
    const uid = ref('')
    const columns = [
      {
        prop: 'type',
        label: '狀態'
      },
      {
        prop: 'text',
        label: '名稱'
      },
      {
        prop: 'created',
        label: '時間'
      },
      {
        prop: 'note',
        label: '備註'
      }
    ]

    const search = async () => {
      const load = startLoading()
      try {
        const result = await MedalService.getItemlog({
          uid: uid.value,
          page: page.value,
          pageSize: pageSize.value,
          type: MedalType.transfer[status.value]
        })
        // reset page
        page.value = 1
        data.value = result.data
        totalPage.value = result.totalPage
      } catch (e) {
      } finally {
        load.close()
      }
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MedalService.getItemlog({
        uid: uid.value,
        page: page.value,
        pageSize: pageSize.value,
        type: MedalType.transfer[status.value]
      })
      data.value = result.data
      totalPage.value = result.totalPage
    }

    const transformType = (type: MedalType.LogType) => {
      return MedalType.transformType[type]
    }

    return {
      columns,
      page,
      status,
      data,
      uid,
      search,
      totalPage,
      getDataByPage,
      transformType
    }
  }
})
