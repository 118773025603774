<template>
  <Modal>
    <template #body>
        <div class="input-form">
          <label for="edit-role-id" class="form-label w-full flex flex-col sm:flex-row">
            Role ID
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">必填，至少 2 字元</span>
          </label>
          <div class="input-group">
            <div id="input-group-role" class="input-group-text">r:</div>
            <input
              id="edit-role-id"
              v-model.trim="validate.roleID.$model"
              type="text"
              name="name"
              class="form-control"
              :class="{ 'border-theme-6': validate.roleID.$error }"
              placeholder="vip"
              aria-describedby="input-group-role"
              :disabled="mode === 'edit'"
            />
          </div>
          <template v-if="validate.roleID.$error">
            <div
              v-for="(error, index) in validate.roleID.$errors"
              :key="index"
              class="text-theme-6 mt-2"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
        <div class="input-form mt-3">
          <label for="edit-role-displayname" class="form-label w-full flex flex-col sm:flex-row">
            顯示名稱
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">必填，至少 2 字元</span>
          </label>
          <input
            id="edit-role-displayname"
            v-model.trim="validate.displayName.$model"
            type="text"
            name="name"
            class="form-control"
            :class="{ 'border-theme-6': validate.displayName.$error }"
            placeholder="JKF VIP 用戶"
          />
          <template v-if="validate.displayName.$error">
            <div
              v-for="(error, index) in validate.displayName.$errors"
              :key="index"
              class="text-theme-6 mt-2"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
        <div class="input-form mt-3">
          <label for="edit-role-domain" class="form-label w-full flex flex-col sm:flex-row">
            Domain
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">用戶組所歸屬的領域</span>
          </label>
          <select id="edit-role-domain" class="form-select" :disabled="mode === 'edit'" v-model="domainSelValue">
            <option v-for="data in DomainState.domainAll" v-bind:key="`role-domain-sel-${data.getDomainId()}`" :value="data.getDomainId()">
              {{ `${data.getDomainId()}: ${data.getDisplayName()}` }}
            </option>
          </select>
        </div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button class="mx-1 btn btn-primary py-3 px-4 w-full align-top" @click="submit(closeModal)">
        送出
      </button>
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">
        取消
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, ref, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import Modal from './template.vue'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'
import { getDomainAll, DomainState } from '@/store/guardian'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    domainID: {
      type: String,
      default: ''
    },
    roleID: {
      type: String,
      default: ''
    },
    displayName: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<'create' | 'edit'>,
      required: true
    },
    done: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { openModal } = useModal()
    const formData = reactive({
      domainID: props.domainID,
      roleID: props.roleID,
      displayName: props.displayName
    })
    const rules = {
      domainID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      roleID: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      displayName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const domainSelValue = ref<null | string>(null)

    const submit = (closeModal: Function) => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        const errorMessage = '請修正資料格式'
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return
      }
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const role = new GuardianQueryPB.Role()
      role.setRoleId(formData.roleID)
      role.setDomain(formData.domainID)
      role.setDisplayName(formData.displayName)
      if (props.mode == 'create') {
        /*
        GuardianService.createRoleApi(role).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
        */
      } else if (props.mode == 'edit') {
        /*
        GuardianAPI.UpdateRole(token, role).then(value => {
          props.done()
          closeModal()
        }).catch(e => {
          const errorMessage = '伺服器錯誤: ' + e.message
          openModal('alertModal', { message: errorMessage, icon: 'warning' })
        })
        */
      }
    }

    onMounted(async() => {
      await getDomainAll()
      domainSelValue.value = DomainState.domainAll[0].getDomainId()
    })

    return {
      formData,
      validate,
      submit,
      DomainState,
      domainSelValue
    }
  }
})
</script>

<style scoped>

</style>
