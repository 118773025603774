
import { defineComponent, ref } from 'vue'
import { useModal } from '@/plugins/modals/index'
import { helper as $h } from '@/utils/helper'
import Modal from './template.vue'
import MySelect from '@/global-components/select/Main.vue'
import { EffectType } from '@/types/'
import MyInput from '@/global-components/input/Main.vue'

export default defineComponent({
  components: {
    Modal,
    MySelect,
    MyInput
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    },
    needRate: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const isSelect = ref<null | string>(null)
    const OPTIONS = [
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_REPUTE,
        displayName: '名聲'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_COIN,
        displayName: '金幣'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_POINT,
        displayName: '點數'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_HEART,
        displayName: '愛心'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_GEM,
        displayName: '寶石'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_SUBMIT,
        displayName: '送出'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_STAMINA,
        displayName: '體力'
      },
      {
        id: EffectType.ChestType.CHEST_TYPE_CREDIT_INVITE,
        displayName: '邀請'
      }
    ]
    const creditValue = ref('101')
    const { openModal } = useModal('fullscreen-dom')
    const rate = ref(0)
    const minCount = ref(1)
    const maxCount = ref(1)
    const validateRule = {
      id: {
        required: true,
        errorMessage: '請選擇積分'
      },
      rate: {
        required: true,
        requireRule: new RegExp(/^([1-9][0-9]*)$/),
        errorMessage: '請輸入機率',
        regexErrorMessage: '請輸入正確的數字格式'
      }
    }

    const submit = (closeModal: Function) => {
      const data = {
        id: creditValue.value
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      if (maxCount.value < minCount.value) {
        const message = '數量區間填寫錯誤'
        openModal('alertModal', { message, icon: 'warning' })
        return false
      }
      props.confirmFunction({
        creditId: data.id,
        minCount: minCount.value,
        maxCount: maxCount.value,
        rate: props.needRate ? rate.value : 100
      })
      closeModal()
    }

    return {
      isSelect,
      rate,
      minCount,
      maxCount,
      validateRule,
      submit,
      OPTIONS,
      creditValue
    }
  }
})
