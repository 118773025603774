import { render } from "./newItem.vue?vue&type=template&id=6a104012&scoped=true"
import script from "./newItem.vue?vue&type=script&lang=ts"
export * from "./newItem.vue?vue&type=script&lang=ts"

import "./newItem.vue?vue&type=style&index=0&id=6a104012&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6a104012"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6a104012"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6a104012', script)) {
    api.reload('6a104012', script)
  }
  
  module.hot.accept("./newItem.vue?vue&type=template&id=6a104012&scoped=true", () => {
    api.rerender('6a104012', render)
  })

}

script.__file = "src/plugins/modals/newItem.vue"

export default script