import { render } from "./Main.vue?vue&type=template&id=d6a39f14"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d6a39f14"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d6a39f14', script)) {
    api.reload('d6a39f14', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=d6a39f14", () => {
    api.rerender('d6a39f14', render)
  })

}

script.__file = "src/views/medal/log/Main.vue"

export default script