import { render } from "./editEmailModal.vue?vue&type=template&id=36e379a8&scoped=true"
import script from "./editEmailModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./editEmailModal.vue?vue&type=script&setup=true&lang=ts"

import "./editEmailModal.vue?vue&type=style&index=0&id=36e379a8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-36e379a8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "36e379a8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('36e379a8', script)) {
    api.reload('36e379a8', script)
  }
  
  module.hot.accept("./editEmailModal.vue?vue&type=template&id=36e379a8&scoped=true", () => {
    api.rerender('36e379a8', render)
  })

}

script.__file = "src/plugins/modals/editEmailModal.vue"

export default script