<template>
  <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
    <div class="box lg:mt-5">
      <div class="p-5">
        <medal-data :id="medalId" getType="Medal" v-slot="{ img, id, description, displayName, enhanceAnimationImg, furnaceLuck, craftLuck, enhanceLuck, available }">
          <div class="flex flex-col-reverse xl:flex-row flex-col">
            <!-- 資訊 -->
            <div class="flex-1 mt-6 xl:mt-0">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 xxl:col-span-6" v-if="state == 'edit'">
                  <my-input
                    label="ID"
                    disabled
                    type="text"
                    :initValue="id"
                    v-model="submitData.id"
                  />
                </div>
                <div class="col-span-12 xxl:col-span-6">
                  <my-input
                    :required="validateRule.displayName.required"
                    :errorMessage="validateRule.displayName.errorMessage"
                    :initValue="displayName"
                    label="名稱"
                    type="text"
                    v-model="submitData.displayName"
                  />
                </div>
                <div class="col-span-12 xxl:col-span-6">
                  <my-input
                    :required="validateRule.description.required"
                    :errorMessage="validateRule.description.errorMessage"
                    label="簡介"
                    type="textarea"
                    :initValue="description"
                    v-model="submitData.description"
                    />
                </div>
                <div class="col-span-12 xxl:col-span-6">
                  <div class="flex items-center">
                    <label class="form-label text-lg mb-0 mr-1">是否顯示在勳章圖鑑</label>
                    <Switch :initValue="available" v-model="submitData.available" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 資訊 end -->
            <!-- 照片 -->
            <div class="w-52 xl:mt-0 mt-2 mx-auto xl:mr-0 xl:ml-6">
              <upload-img
                :image="img"
                v-model="submitData.img"
                :buttonTitle="stateMethod.uploadImgText"
                type="medal"
                :limitHeight="IMAGE_HEIGHT"
                :limitWidth="IMAGE_WIDTH"
                acceptType="image/png, image/gif"
                />
            </div>
            <div class="w-52 mx-auto xl:mr-0 xl:ml-6">
              <upload-img
                :image="enhanceAnimationImg"
                v-model="submitData.enhanceAnimationImg"
                :buttonTitle="stateMethod.uploadAnimationImgText"
                type="medal"
                :limitHeight="EHNAHCE_IMAGE_HEIGHT"
                :limitWidth="ENHANCE_IMAGE_WIDTH"
                acceptType="image/png, image/gif"
                />
            </div>
            <!-- 照片 end -->
          </div>
          <div class="border-t py-4 mt-4">
            <div class="w-full lg:w-1/4">
              <div class="text-2xl font-bold mb-4">幸運加成</div>
              <div class="col-span-12 xxl:col-span-6">
                <my-input
                  label="製作道具加成"
                  :requireRule="validateRule.luckNumber.rule"
                  :errorMessage="validateRule.luckNumber.errorMessage"
                  :regexErrorMessage="validateRule.luckNumber.ruleMessage"
                  type="number"
                  :initValue="craftLuck"
                  :min="0"
                  :max="100"
                  v-model="submitData.craftLuck"
                />
              </div>
              <div class="col-span-12 xxl:col-span-6">
                <my-input
                  label="合成勳章加成"
                  :requireRule="validateRule.luckNumber.rule"
                  :errorMessage="validateRule.luckNumber.errorMessage"
                  :regexErrorMessage="validateRule.luckNumber.ruleMessage"
                  type="number"
                  :initValue="furnaceLuck"
                  :min="0"
                  :max="100"
                  v-model="submitData.furnaceLuck"
                />
              </div>
              <div class="col-span-12 xxl:col-span-6">
                <my-input
                  label="強化勳章加成"
                  :requireRule="validateRule.luckNumber.rule"
                  :errorMessage="validateRule.luckNumber.errorMessage"
                  :regexErrorMessage="validateRule.luckNumber.ruleMessage"
                  type="number"
                  :initValue="enhanceLuck"
                  :min="0"
                  :max="100"
                  v-model="submitData.enhanceLuck"
                />
              </div>
            </div>
          </div>
        </medal-data>
        <div class="flex mt-5">
          <button type="button" class="btn btn-primary w-full md:w-36 mt-3" @click="submit">
            {{ stateMethod.btnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, unref } from 'vue'
import UploadImg from '@/components/item/uploadImg.vue'
import MedalData from '@/components/data/itemOrMedal'
import { useRoute } from 'vue-router'
import myRouter from '@/router/useRouter'
import { helper as $h } from '@/utils/helper'
import { useModal } from '@/plugins/modals/'
import { setMedalMap, updateMedalMap } from '@/store/medal'
import { MedalService } from '@/services/'
import { MedalType } from '@/types/'
import { useLoading } from '@/plugins/loading'
import Switch from '@/global-components/switch/Main.vue'
export default defineComponent({
  name: 'MedalEdit',
  components: {
    UploadImg,
    MedalData,
    Switch
  },
  setup () {
    const IMAGE_WIDTH = 22
    const IMAGE_HEIGHT = 36
    const ENHANCE_IMAGE_WIDTH = 100
    const EHNAHCE_IMAGE_HEIGHT = 100
    const route = useRoute()
    const { toMedalPage } = myRouter()
    const medalId = route.params.id || ''
    const { startLoading } = useLoading()
    const { openModal } = useModal()

    const state = computed(() => {
      return medalId ? 'edit' : 'new'
    })

    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存' : '新增'
      const uploadImgText = isEdit ? '更換圖片' : '上傳圖片'
      const uploadAnimationImgText = isEdit ? '更換強化動畫圖片' : '上傳強化動畫圖片'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? MedalService.update : MedalService.create
      const storeFunction = isEdit ? updateMedalMap : setMedalMap
      return {
        btnText,
        successText,
        API,
        storeFunction,
        uploadImgText,
        uploadAnimationImgText
      }
    })

    const submitData = reactive({
      id: '',
      displayName: '',
      img: '',
      enhanceAnimationImg: '',
      description: '',
      furnaceLuck: 0,
      craftLuck: 0,
      enhanceLuck: 0,
      available: false
    })

    const validateRule = {
      displayName: {
        required: true,
        errorMessage: '請輸入名稱'
      },
      description: {
        required: true,
        errorMessage: '請輸入簡介'
      },
      img: {
        required: true,
        errorMessage: '請上傳勳章圖'
      },
      enhanceAnimationImg: {
        required: false,
        errorMessage: '請上傳強化動畫圖'
      },
      luckNumber: {
        rule: new RegExp(/^[0-9]$|^[1-9][0-9]$|^(100)$/),
        errorMessage: '請輸入幸運值',
        ruleMessage: '請輸入正確的數字格式(0~100)'
      }
    }

    const submit = async(event: Event) => {
      event.preventDefault()
      const fn = unref(stateMethod)
      const data = {
        id: submitData.id,
        displayName: submitData.displayName,
        img: submitData.img,
        enhanceAnimationImg: submitData.enhanceAnimationImg,
        description: submitData.description,
        available: submitData.available,
        property: {
          furnaceLuck: submitData.furnaceLuck,
          craftLuck: submitData.craftLuck,
          enhanceLuck: submitData.enhanceLuck
        },
        // 寫死
        category: MedalType.MedalCategory.CATEGORY_ONE,
        canApply: false,
        canEnhance: false,
        isSeries: false,
        isRoot: false
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        const id = await fn.API(data)
        fn.storeFunction(id, { ...data, id })
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toMedalPage()
      } catch (e) {
        console.error(e.message)
        openModal('alertModal', { icon: 'success', message: '出錯了' })
      } finally {
        loading.close()
      }
    }

    return {
      IMAGE_WIDTH,
      IMAGE_HEIGHT,
      medalId,
      state,
      stateMethod,
      validateRule,
      submitData,
      EHNAHCE_IMAGE_HEIGHT,
      ENHANCE_IMAGE_WIDTH,
      submit
    }
  }
})
</script>

<style scoped>

</style>
