import { MaterialType } from '@/types/'
import * as grpcLib from '@/libs/grpc'
import * as adminMaterialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/furnace_pb'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import { AdminFurnacePromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/furnace_grpc_web_pb'

const ManufactureService = new AdminFurnacePromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MATERIAL))
grpcLib.EnableGrpcDebugTools(ManufactureService)

export const getAllManufactureItem = async(token: string, pageSize: number, page: number): Promise<adminMaterialPb.GetAllFurnaceRes> => {
  const req: adminMaterialPb.GetAllFurnaceReq = new adminMaterialPb.GetAllFurnaceReq()
  req.setAdminToken(token)
  const paginator = new materialPb.Paginator()
  paginator.setPageSize(pageSize)
  paginator.setPage(page)
  req.setPaginator(paginator)
  const res: adminMaterialPb.GetAllFurnaceRes = await ManufactureService.getAll(req)
  return grpcLib.resolveData(res)
}
export const getManufactureInfo = async(token: string, ids: Array<string>):Promise<adminMaterialPb.GetInfoFurnaceRes> => {
  const req: adminMaterialPb.GetInfoFurnaceReq = new adminMaterialPb.GetInfoFurnaceReq()
  req.setAdminToken(token)
  req.setIdList(ids)
  const res: adminMaterialPb.GetInfoFurnaceRes = await ManufactureService.getInfo(req)
  return grpcLib.resolveData(res)
}

export const create = async(token: string, { id, coins, successRate, count, consumableMaterial, inconsumableMaterial, sideProduct }:{
  id: string
  coins: number
  successRate: number
  count: number
  sideProduct: Array<{ id: string, minCount: number, maxCount: number }>,
  consumableMaterial: Array<{ id: string, count: number }>
  inconsumableMaterial: Array<{ id: string, count: number }>
}): Promise<adminMaterialPb.CreateFurnaceRes> => {
  const req: adminMaterialPb.CreateFurnaceReq = new adminMaterialPb.CreateFurnaceReq()
  req.setAdminToken(token)
  req.setId(id)
  req.setCoins(coins)
  req.setCount(count)
  req.setSuccessRate(successRate)
  const sideProductData = sideProduct.map((val) => {
    const data = new adminMaterialPb.SideProduct()
    data.setId(val.id)
    data.setMinCount(val.minCount)
    data.setMaxCount(val.maxCount)
    return data
  })
  req.setSideProductList(sideProductData)

  const consumableData: Array<adminMaterialPb.ConsumableMaterial> = consumableMaterial.map((val) => {
    const data = new adminMaterialPb.ConsumableMaterial()
    data.setId(val.id)
    data.setCount(val.count)
    return data
  })
  req.setConsumableMaterialList(consumableData)

  const inconsumableData: Array<adminMaterialPb.InconsumableMaterial> = inconsumableMaterial.map((val) => {
    const data = new adminMaterialPb.InconsumableMaterial()
    data.setId(val.id)
    data.setCount(val.count)
    return data
  })
  req.setInconsumableMaterialList(inconsumableData)
  const res: adminMaterialPb.CreateFurnaceRes = await ManufactureService.create(req)
  return grpcLib.resolveData(res)
}

// NOTE: 目前 update and create 類似
export const update = async(token: string, { id, coins, successRate, count, consumableMaterial, inconsumableMaterial, sideProduct }:{
  id: string
  coins: number
  successRate: number
  count: number
  sideProduct: Array<{ id: string, minCount: number, maxCount: number }>,
  consumableMaterial: Array<{ id: string, count: number }>
  inconsumableMaterial: Array<{ id: string, count: number }>
}): Promise<adminMaterialPb.UpdateFurnaceRes> => {
  const req: adminMaterialPb.UpdateFurnaceReq = new adminMaterialPb.UpdateFurnaceReq()
  req.setAdminToken(token)
  req.setId(id)
  req.setCoins(coins)
  req.setCount(count)
  req.setSuccessRate(successRate)
  const sideProductData = sideProduct.map((val) => {
    const data = new adminMaterialPb.SideProduct()
    data.setId(val.id)
    data.setMinCount(val.minCount)
    data.setMaxCount(val.maxCount)
    return data
  })
  req.setSideProductList(sideProductData)

  const consumableData: Array<adminMaterialPb.ConsumableMaterial> = consumableMaterial.map((val) => {
    const data = new adminMaterialPb.ConsumableMaterial()
    data.setId(val.id)
    data.setCount(val.count)
    return data
  })
  req.setConsumableMaterialList(consumableData)

  const inconsumableData: Array<adminMaterialPb.InconsumableMaterial> = inconsumableMaterial.map((val) => {
    const data = new adminMaterialPb.InconsumableMaterial()
    data.setId(val.id)
    data.setCount(val.count)
    return data
  })
  req.setInconsumableMaterialList(inconsumableData)
  const res: adminMaterialPb.UpdateFurnaceRes = await ManufactureService.update(req)
  return grpcLib.resolveData(res)
}

export const deleteItem = async(token: string, id: string): Promise<adminMaterialPb.DeleteFurnaceRes> => {
  const req: adminMaterialPb.DeleteFurnaceReq = new adminMaterialPb.DeleteFurnaceReq()
  req.setAdminToken(token)
  req.setId(id)
  const res: adminMaterialPb.DeleteFurnaceRes = await ManufactureService.delete(req)
  return grpcLib.resolveData(res)
}
