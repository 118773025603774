<template>
  <div class="mb-3 flex flex-col sm:flex-row justify-between">
    <button type="button" class="btn btn-primary w-full md:w-48" @click="newItem">
      新增關聯勳章
    </button>
  </div>
  <div>
    <data-table
      :columns="columns"
      :data="data"
      :page="page"
    >
      <template #id="{ row }">
        {{ showMedalData(row, 'id') }}
      </template>
      <template #img="{ row }">
        <img
          :src="row.info?.img"
        >
      </template>
      <template #displayName="{ row }">
        {{ showMedalData(row, 'displayName') }}
      </template>
      <template #description="{ row }">
        {{ showMedalData(row, 'description') }}
      </template>
      <template #recipeLength="{ row }">
        {{ calcRecipeLength(row) }}
      </template>
      <template #action="{ row }">
        <button class="btn w-24 mr-1 mb-2 btn-rounded-success" @click="toEditEnhanceMedalPage(row?.info?.id)">
          編輯
        </button>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, reactive } from 'vue'
import { useModal } from '@/plugins/modals/index'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MedalType } from '@/types/index'
import { EnhanceService } from '@/services/'
import { getMedal } from '@/store/medal'
export default defineComponent({
  name: 'Manufacture',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const medalType = ref<'MANUFACTURE' | 'RECIPE'>('MANUFACTURE')
    const data = reactive<Array<MedalType.EnhanceInterface<MedalType.Medal>>>([])
    const showMedalData = (value: any, type: MedalType.MedalKey) => {
      if (value.info) return value.info[type]
    }

    const calcRecipeLength = (value: any) => {
      let length = 0
      if (value.recipe) length = value.recipe.length
      return `總共 ${length} 級`
    }

    const { openModal } = useModal()
    const columns = TableColumns.MEDAL_ENHANCE
    const { toEditEnhanceMedalPage } = useRouter()
    let closeNewItem: Function | null = null

    const validateAndChangePage = async(id: string) => {
      if (!id) {
        openModal('alertModal', { icon: 'warning', message: '請選擇勳章' })
        return false
      }
      const medal = (await getMedal([id])).get(id)
      if (!medal?.enhanceAnimationImg) {
        openModal('alertModal', { icon: 'warning', message: '該勳章沒上傳動畫圖片，請補上傳' })
        return false
      }
      closeNewItem!()
      toEditEnhanceMedalPage(id)
    }

    const newItem = () => {
      const filterItemFunction = (val: any, index: number, array: Array<any>) => {
        return val.isSeries === false
      }
      const { closeModal } = openModal('newItem',
        {
          confirmFunction: validateAndChangePage,
          itemType: 'Medal',
          filterItemFunction
        }
      )
      closeNewItem = closeModal
    }

    const resetData = () => {
      data.length = 0
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await EnhanceService.getAll(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    onBeforeMount(async () => {
      const result = await EnhanceService.getAll(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    return {
      columns,
      data,
      page,
      toEditEnhanceMedalPage,
      newItem,
      getDataByPage,
      medalType,
      showMedalData,
      totalPage,
      calcRecipeLength
    }
  }
})
</script>

<style scoped>
</style>
