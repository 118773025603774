import { MemberInfo } from '@/types/member'
import { ref } from 'vue'

const isDrawerOpen = ref(false)
const member = ref<MemberInfo>({
  uid: 0,
  displayName: '',
  photoUrl: ''
})
export const useDrawer = () => {
  const toggleDrawer = (uid: number, displayName: string, photoUrl: string) => {
    if (photoUrl === '') {
      photoUrl = 'https://www.mymyuc.net/images/noavatar_middle.gif'
    }
    member.value = { uid, displayName, photoUrl }
    isDrawerOpen.value = !isDrawerOpen.value
  }
  return {
    isDrawerOpen,
    member,
    toggleDrawer
  }
}
