<template>
  <div class="modal-mask" ref="modalMask" @click="clickOutSide" :style="dynamicStyle">
    <div class="modal-container">
      <div class="modal-body text-center">
        <slot name="body">
          <div class="text-3xl mt-5">Oops...</div>
          <div class="text-gray-600 mt-2">
            Something went wrong!
          </div>
        </slot>
      </div>
      <div class="modal-footer">
        <slot name ="footer" :closeModal="closeModal">
        <button class="btn btn-primary py-3 px-4 w-full align-top" @click="closeModal">
          ok
        </button>
        </slot>
      </div>
    </div>
  </div>
</template>
<script lang='ts'>
import { computed, defineComponent, PropType, ref } from 'vue'
import { unrefElement } from '@vueuse/core'
export default defineComponent({
  name: 'modal',
  props: {
    position: {
      type: String as PropType<'fullScreen'|'center'>,
      default: 'fullScreen'
    }
  },
  emits: ['close'],
  setup (props, { emit, expose }) {
    const dynamicStyle = computed(() => {
      return props.position == 'center' ? [{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }] : []
    })
    const modalMask = ref<HTMLElement | null>(null)
    const closeModal = () => { emit('close') }
    const clickOutSide = (e: Event) => {
      if (unrefElement(modalMask) === e.target) {
        closeModal()
      }
    }
    expose({
      closeModal
    })
    return {
      closeModal,
      modalMask,
      clickOutSide,
      dynamicStyle
    }
  }
})
</script>
<style lang='scss' scoped>

.modal-mask {
  overflow: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .7);
  transition: opacity .2s ease;
  padding: 30px 0;
  /*
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  */
}

.modal-wrapper {
  display: block;
}

.modal-container {
  max-width: 460px;
  width: 95%;
  margin: 0px auto;
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}
.modal-header h3 {
  margin-top: 0;
  color: #313634;
}
.modal-body {
  position: relative;
  padding: 1rem;
  margin: 0px 0;
  overflow-y: visible;
}

.modal-footer{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}
</style>
