<template>
  <div>
    recipe edit
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
})
</script>

<style scoped>

</style>
