<template>
  <div class="mb-3 flex flex-col sm:flex-row justify-between">
    <button type="button" class="btn btn-primary w-full md:w-48" @click="newItem">
      新增合成勳章
    </button>
  </div>
  <div>
    <data-table
      :columns="columns"
      :data="data"
      :page="page"
    >
      <template #id="{ row }">
        {{ showMedalData(row, 'id') }}
      </template>
      <template #img="{ row }">
        <img
          :src="row.info?.img"
        >
      </template>
      <template #displayName="{ row }">
        {{ showMedalData(row, 'displayName') }}
      </template>
      <template #description="{ row }">
        {{ showMedalData(row, 'description') }}
      </template>
      <template #action="{ row }">
        <button class="btn w-24 mr-1 mb-2 btn-rounded-success" @click="toEditManufactureMedalPage(row?.info?.id)">
          編輯
        </button>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, reactive } from 'vue'
import { useModal } from '@/plugins/modals/index'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MedalType } from '@/types/index'
import { MedalManufactureService } from '@/services/'
export default defineComponent({
  name: 'Manufacture',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const medalType = ref<'MANUFACTURE' | 'RECIPE'>('MANUFACTURE')
    const data = reactive<Array<MedalType.ManufactureMedal<MedalType.Medal>>>([])
    const showMedalData = (value: any, type: MedalType.MedalKey) => {
      if (value.info) return value.info[type]
    }
    const { openModal } = useModal()
    const columns = TableColumns.MEDAL_MANUFACTURE
    const { toEditManufactureMedalPage } = useRouter()
    let closeNewItem: Function | null = null

    const validateAndChangePage = (id: string) => {
      if (!id) {
        openModal('alertModal', { icon: 'warning', message: '請選擇勳章' })
        return false
      }
      closeNewItem!()
      toEditManufactureMedalPage(id)
    }

    const newItem = () => {
      const { closeModal } = openModal('newItem', { confirmFunction: validateAndChangePage, itemType: 'Medal' })
      closeNewItem = closeModal
    }

    const resetData = () => {
      data.length = 0
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MedalManufactureService.getAllItem(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    onBeforeMount(async () => {
      const result = await MedalManufactureService.getAllItem(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    return {
      columns,
      data,
      page,
      toEditManufactureMedalPage,
      newItem,
      getDataByPage,
      medalType,
      showMedalData,
      totalPage
    }
  }
})
</script>

<style scoped>
</style>
