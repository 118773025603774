import { loginedStatus } from '@/store/user'
import { LogType, MedalType } from '@/types/'
import { MedalAPI } from '@/api/'
import * as medalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/medal/query_pb'
import * as adminMedalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/medal_pb'

export const getMedalList = async(pageSize: number, page: number): Promise<{ data: Array<MedalType.Medal>, totalPage: number }> => {
  const result = await MedalAPI.getAll(loginedStatus.token, pageSize, page)
  return {
    data: result.getMedalList().map((val: adminMedalPb.GetAllMedalRes.MedalInfo) => {
      return createMedal(val)
    }),
    totalPage: result.getPagination()!.getTotalPage()
  }
}

// NOTE: admin-proto 49 up
export const createMedal = (item: medalPb.Medal | adminMedalPb.GetInfoMedalRes.MedalInfo | adminMedalPb.GetAllMedalRes.MedalInfo): MedalType.Medal => {
  // NOTE: admin-proto 49 up
  const data = item instanceof medalPb.Medal ? item : item.getMedal()!
  const category = data.toObject().category
  return {
    id: data.getId(),
    displayName: data.getDisplayName(),
    img: data.getImg(),
    enhanceAnimationImg: data.getEnhanceAnimationImg(),
    description: data.getDesc(),
    category: Number(category),
    canEnhance: data.getCanEnhance(),
    canApply: data.getCanApply(),
    isSeries: item instanceof medalPb.Medal ? false : item.getIsSeries(),
    isRoot: item instanceof adminMedalPb.GetInfoMedalRes.MedalInfo ? item.getIsRoot() : false,
    available: item instanceof adminMedalPb.GetInfoMedalRes.MedalInfo ? item.getAvailable() : false,
    property: {
      craftLuck: data.getProperty()?.getCraftLuck() || 0,
      furnaceLuck: data.getProperty()?.getFurnaceLuck() || 0,
      enhanceLuck: data.getProperty()?.getEnhanceLuck() || 0
    }
  }
}

export const getInfo = async (medalIds: Array<string>): Promise<Array<MedalType.Medal>> => {
  // TODO: 取得是否是root 以及是否是 series
  const result = await MedalAPI.getInfo(loginedStatus.token, medalIds)
  // NOTE: admin-proto 49 up
  // return result.getInfoList().map(val => createMedal(val.getMedal()!))
  return result.getInfoList().map((val: adminMedalPb.GetInfoMedalRes.MedalInfo) => createMedal(val))
}

export const create = async(payload: {
  category: MedalType.MedalCategory,
  displayName: string
  img: string
  enhanceAnimationImg: string
  description: string
  canApply: boolean
  canEnhance: boolean
  id: string
  isSeries: boolean
  isRoot: boolean
  available: boolean
  property: MedalType.MedalProperty
}): Promise<string> => {
  const res = await MedalAPI.create(loginedStatus.token, payload)
  return res.getMedalId()
}

export const update = async(payload: {
  category: MedalType.MedalCategory,
  displayName: string
  img: string
  enhanceAnimationImg: string
  description: string
  canApply: boolean
  canEnhance: boolean
  id: string
  isSeries: boolean
  isRoot: boolean
  available: boolean
  property: MedalType.MedalProperty
}): Promise<string> => {
  const res = await MedalAPI.update(loginedStatus.token, payload)
  return res.getMedalId()
}

export const shipItem = async(payload: {
  uid: Array<string>,
  isNotification: boolean,
  medalId: string,
  message: string
}): Promise<void> => {
  await MedalAPI.shipItem(loginedStatus.token, payload)
}

export const shipItemByRole = async(payload: {
  isNotification: boolean,
  medalId: string,
  role: Array<string>
  message: string
}): Promise<void> => {
  await MedalAPI.shipItemByRole(loginedStatus.token, payload)
}

export const getItemlog = async (payload: {
  uid: string,
  page: number,
  pageSize: number,
  type: MedalType.LogType
}):Promise<{ data: Array<MedalType.LogItem>, totalPage: number }> => {
  const res = await MedalAPI.itemLog(loginedStatus.token, payload)
  return {
    data: res.getItemList().map((val: medalPb.LogMedal) => MedalType.createLogItem(val)),
    totalPage: res.getPagination()!.getTotalPage()
  }
}

export const prepareUploadImage = async(fileInfo: string):Promise<{
  signedPostPolicyMap: Map<string, string>,
  urlToUpload: string
}> => {
  const res = await MedalAPI.prepareUploadImage(loginedStatus.token, fileInfo)
  const signedPostPolicyMap = res.getSignedPostPolicyMap()
  const urlToUpload = res.getUrlToUpload()
  return {
    signedPostPolicyMap: signedPostPolicyMap.toObject(),
    urlToUpload
  }
}
