
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import { linkTo, nestedMenu, enter, leave } from './index'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import Fullscreen from '@/components/fullscreen/fullscreen'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { FULLSCREEN_DOM_STRING } from '@/utils/provideInject'

export default defineComponent({
  name: 'Menu',
  components: {
    TopBar,
    MobileMenu,
    SideMenuTooltip,
    Fullscreen,
    SvgIcon
  },
  setup() {
    const SHOW_DEV_TITLE = process.env.VUE_APP_DEVELOPMENT ? process.env.VUE_APP_DEVELOPMENT : false
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      FULLSCREEN_DOM_STRING,
      SHOW_DEV_TITLE
    }
  }
})
