import { render } from "./Main.vue?vue&type=template&id=17c9bdd6&scoped=true"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"

import "./Main.vue?vue&type=style&index=0&id=17c9bdd6&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-17c9bdd6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "17c9bdd6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('17c9bdd6', script)) {
    api.reload('17c9bdd6', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=17c9bdd6&scoped=true", () => {
    api.rerender('17c9bdd6', render)
  })

}

script.__file = "src/views/send-material/Main.vue"

export default script