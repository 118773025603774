import { render } from "./Main.vue?vue&type=template&id=2e4fd4a4"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2e4fd4a4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2e4fd4a4', script)) {
    api.reload('2e4fd4a4', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=2e4fd4a4", () => {
    api.rerender('2e4fd4a4', render)
  })

}

script.__file = "src/views/medal/manufacture/edit/Main.vue"

export default script