export const MESSAGE_LOG = [
  {
    prop: 'sysf2',
    label: '已讀',
    width: 50
  },
  {
    prop: 'sysf1',
    label: '是否被標記',
    width: 120
  },
  {
    prop: 'uid',
    label: '發送者ID',
    width: 120
  },
  {
    prop: 'sender',
    label: '發送者',
    width: 120
  },
  {
    prop: 'receiver',
    label: '接收者',
    width: 120
  },
  {
    prop: 'text',
    label: '內容'
  },
  {
    prop: 'time',
    label: '時間',
    width: 120,
    sortable: true
  },
  {
    prop: 'action',
    label: '操作',
    width: 120
  }
]

export const ITEM = [
  {
    prop: 'id',
    label: 'ID',
    width: 100
  },
  {
    prop: 'img',
    label: '圖片',
    width: 120
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 120
  },
  {
    prop: 'description',
    label: '道具簡介'
  },
  {
    prop: 'weight',
    label: '重量',
    width: 100
  },
  {
    prop: 'action',
    label: '操作'
  }
]

export const SHOP = [
  {
    prop: 'id',
    label: 'ID',
    width: 100
  },
  {
    prop: 'img',
    label: '圖片',
    width: 120
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 120
  },
  {
    prop: 'description',
    label: '簡介'
  },
  {
    prop: 'creditValue',
    label: '價格',
    width: 80
  },
  {
    prop: 'creditType',
    label: '幣值',
    width: 120
  },
  {
    prop: 'action',
    label: '操作'
  }
]

export const MANUFACTURE = [
  {
    prop: 'id',
    label: 'ID',
    width: 100
  },
  {
    prop: 'img',
    label: '圖片',
    width: 120
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 120
  },
  {
    prop: 'description',
    label: '道具簡介'
  },
  {
    prop: 'successRate',
    label: '成功率',
    width: 80
  },
  {
    prop: 'coins',
    label: '合成價格',
    width: 100
  },
  {
    prop: 'action',
    label: '操作'
  }
]

export const CONSUMABLE_COLUMNS = [
  {
    prop: 'delete',
    label: '刪除',
    width: 60
  },
  {
    prop: 'displayName',
    label: '名稱'
  },
  {
    prop: 'description',
    label: '簡介'
  },
  {
    prop: 'count',
    label: '數量',
    width: 60
  }
]

export const CREDIT_TYPE = [
  {
    id: '1',
    displayName: '名聲'
  },
  {
    id: '2',
    displayName: '金幣'
  },
  {
    id: '3',
    displayName: '點數'
  },
  {
    id: '4',
    displayName: '愛心'
  },
  {
    id: '5',
    displayName: '寶石'
  },
  {
    id: '6',
    displayName: '送出'
  },
  {
    id: '7',
    displayName: '體力'
  },
  {
    id: '8',
    displayName: '邀請'
  }
]

export const MEDAL = [
  {
    prop: 'id',
    label: 'ID',
    width: 120
  },
  {
    prop: 'img',
    label: '圖片',
    width: 150
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 150
  },
  {
    prop: 'description',
    label: '勳章簡介'
  },
  {
    prop: 'action',
    label: '操作'
  }
]

export const MEDAL_MANUFACTURE = [
  {
    prop: 'id',
    label: 'ID',
    width: 150
  },
  {
    prop: 'img',
    label: '圖片',
    width: 150
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 150
  },
  {
    prop: 'description',
    label: '勳章簡介'
  },
  {
    prop: 'successRate',
    label: '成功率',
    width: 100
  },
  {
    prop: 'coins',
    label: '合成價格',
    width: 100
  },
  {
    prop: 'action',
    label: '操作'
  }
]

export const MEDAL_ENHANCE = [
  {
    prop: 'id',
    label: 'ID',
    width: 150
  },
  {
    prop: 'img',
    label: '圖片',
    width: 150
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 150
  },
  {
    prop: 'description',
    label: '勳章簡介'
  },
  {
    prop: 'recipeLength',
    label: '強化層級'
  },
  {
    prop: 'action',
    label: '操作'
  }
]

export const ENHANCE_EDIT_COLUMNS = [
  {
    prop: 'delete',
    label: '刪除',
    width: 120
  },
  {
    prop: 'level',
    label: '強化層級',
    width: 150
  },
  {
    prop: 'displayName',
    label: '勳章'
  },
  {
    prop: 'materialLength',
    label: '總共材料數量',
    width: 150
  },
  {
    prop: 'successRate',
    label: '成功率',
    width: 150
  },
  {
    prop: 'coins',
    label: '消耗金幣',
    width: 150
  }
]

export const ENHANCE_EDIT_MODAL_COLUMNS = [
  {
    prop: 'delete',
    label: '刪除',
    width: 60
  },
  {
    prop: 'displayName',
    label: '名稱'
  },
  {
    prop: 'count',
    label: '數量',
    width: 60
  }
]

export const EFFECT_MATERIAL_SETTING = [
  {
    prop: 'delete',
    label: '刪除',
    width: 70
  },
  {
    prop: 'displayName',
    label: '名稱',
    width: 150
  },
  {
    prop: 'rate',
    label: '機率',
    width: 150
  },
  {
    prop: 'count',
    label: '數量區間',
    width: 300
  }
]

export const EFFECT_CREDIT_SETTING = [
  {
    prop: 'delete',
    label: '刪除',
    width: 70
  },
  {
    prop: 'displayName',
    label: '積分名稱',
    width: 300
  },
  {
    prop: 'count',
    label: '獲得區間',
    width: 300
  }
]
export const SIDE_PRODUCT_COLUMNS = [
  {
    prop: 'delete',
    label: '刪除',
    width: 70
  },
  {
    prop: 'displayName',
    label: '名稱'
  },
  {
    prop: 'count',
    label: '獲得區間',
    width: 300
  }
]

export const ROLE_COLUMNS = [
  {
    prop: 'displayName',
    label: '職位名稱',
    width: 130
  },
  {
    prop: 'role',
    label: '權限'
  },
  {
    prop: 'action',
    label: '操作',
    width: 130
  }
]

export const BANNED_USER_COLUMNS = [
  {
    prop: 'time',
    label: '時間'
  },
  {
    prop: 'uid',
    label: 'Uid',
    width: 130
  },
  {
    prop: 'displayName',
    label: '會員姓名'
  },
  {
    prop: 'cause',
    label: '禁言原因'
  },
  {
    prop: 'actor',
    label: '操作人'
  },
  {
    prop: 'action',
    label: '操作',
    width: 130
  }
]
