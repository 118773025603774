export {
  mdiAccountOutline,
  mdiMedalOutline,
  mdiMessageReplyOutline,
  mdiPackageVariantClosed,
  mdiCommentSearchOutline,
  mdiAccountSearchOutline,
  mdiStorefrontOutline,
  mdiSend,
  mdiCogs,
  mdiTextBoxOutline,
  mdiSendCircleOutline,
  mdiMathLog,
  mdiPencilOutline,
  mdiApplicationCogOutline,
  mdiToggleSwitchOutline,
  mdiEmailOutline,
  mdiMenu,
  mdiChevronDown,
  mdiCalendar,
  mdiPlusCircleOutline,
  mdiTrashCanOutline,
  mdiAlphaXCircleOutline,
  mdiCheckCircleOutline,
  mdiShieldCheck,
  mdiGoogle,
  mdiShapeOutline,
  mdiShieldEdit,
  mdiAccountGroup,
  mdiShieldLockOutline,
  mdiEmoticonDevilOutline,
  mdiAccountTieHat,
  mdiAlertOctagonOutline
} from '@mdi/js'
