<template>
  <div :class="[$attrs.class]">
    <label class="form-label text-lg mb-0">{{ label }}</label>
    <input
      v-if="type != 'textarea'"
      ref="input"
      v-bind="attr"
      :type="type"
      class="form-control text-lg focus:outline-none"
      :class="{ 'border-red-600': state !== 'OK' }"
      :placeholder="label"
      @input="handleInput"
      @change="handleChange"
      @blur="handleBlur"
    />
    <textarea
      v-else
      ref="textarea"
      type="text"
      class="form-control text-lg input"
      :class="{ 'border-red-600': state !== 'OK' }"
      :placeholder="label"
      @input="handleInput"
      @change="handleChange"
      @blur="handleBlur"
    ></textarea>

    <p class="mt-1 ml-2 text-red-600 relative w-full text-left">
      <span v-if="state === 'OK'">&nbsp;</span>
      <Transition name="fade">
        <span v-if="state !== 'OK'" :key="errorMsg">{{ errorMsg }}</span>
      </Transition>
    </p>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
  getCurrentInstance,
  onMounted,
  WatchStopHandle,
  toRefs,
  unref
} from 'vue'
export default defineComponent({
  inheritAttrs: false,
  name: 'MyInput',
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    requireRule: {
      type: RegExp,
      default: new RegExp('')
    },
    errorMessage: {
      type: String,
      default: ''
    },
    regexErrorMessage: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<'text' | 'number' | 'textarea'>,
      default: 'text'
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    initValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { initValue, required, errorMessage, regexErrorMessage } = toRefs(
      props
    )
    const requireRule = unref(props.requireRule)
    const state = ref<'OK' | 'error' | 'regexError'>('OK')
    const input = ref<null | HTMLInputElement>(null)
    const textarea = ref<null | HTMLInputElement>(null)
    const DEFAULT_EXCLUDE_KEYS = ['class', 'style']
    const inputOrTextarea = computed(() => input.value || textarea.value)
    const errorMsg = computed(() =>
      state.value === 'error' ? errorMessage.value : regexErrorMessage.value
    )

    const instance = getCurrentInstance()
    // 過濾掉 class style
    const attr = computed(() => {
      return Object.fromEntries(
        Object.entries(instance!.proxy?.$attrs!).filter(
          ([key]) => !DEFAULT_EXCLUDE_KEYS.includes(key)
        )
      )
    })

    const nativeInputValue = computed(() => {
      return (props.modelValue === null || props.modelValue === undefined) &&
        props.initValue === ''
        ? ''
        : String(props.initValue || props.modelValue)
    })

    const setNativeInputValue = () => {
      const input = inputOrTextarea.value
      if (input !== null) {
        if (input.value === nativeInputValue.value) return
        input.value = nativeInputValue.value
      }
    }
    const validate = (value: any) => {
      // 簡易防呆
      const debounce = setTimeout(() => {
        if (required.value == true && value === '') {
          state.value = 'error'
        } else {
          if (!requireRule.test(value)) state.value = 'regexError'
          else state.value = 'OK'
        }
        clearTimeout(debounce)
      }, 300)
    }

    const handleInput = (event: Event) => {
      // @ts-ignore
      const { value } = event.target
      validate(value)
      emit('update:modelValue', value)
    }

    const handleChange = (event: Event) => {}

    const handleBlur = (event: Event) => {
      // @ts-ignore
      const { value } = event.target
      validate(value)
    }

    let unwatch: WatchStopHandle
    onMounted(() => {
      setNativeInputValue()
      emit('update:modelValue', nativeInputValue.value)
      unwatch = watch(initValue, value => {
        if (value) {
          setNativeInputValue()
          emit('update:modelValue', nativeInputValue.value)
          unwatch()
        }
      })
    })

    watch(nativeInputValue, () => setNativeInputValue())

    return {
      input,
      attr,
      textarea,
      state,
      handleChange,
      handleBlur,
      errorMsg,
      handleInput
    }
  }
})
</script>

<style lang="scss" scoped>
.input {
  &:focus {
    @apply outline-none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
