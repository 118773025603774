<template>
  <medal-data :id="medalId" getType="Medal" v-slot="{ img, id, description, displayName }">
    <info-card
      :displayName="displayName"
      :photoURL="img"
      :infoColumns="columns"
    >
      <template #description>
        {{ description }}
      </template>
      <template #infoTitle>
        勳章資訊
      </template>
      <template #ID>
        ID: {{ id }}
      </template>
    </info-card>
  </medal-data>
  <div class="box lg:mt-5">
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="font-medium text-xl mr-auto">製作資訊</h2>
    </div>
    <div class="p-5">
      <div class="flex flex-col-reverse xl:flex-row flex-col">
        <div class="flex-1 mt-6 xl:mt-0">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-5 gap-y-3">
            <my-input
              type="number"
              label="消耗金幣"
              v-model="manufactureInfo.coins"
              :required="validateRule.coins.required"
              :errorMessage="validateRule.coins.errorMessage"
              :requireRule="validateRule.coins.rule"
              :regexErrorMessage="validateRule.coins.ruleMessage"
            />
            <my-input
              type="number"
              label="成功率"
              v-model="manufactureInfo.successRate"
              :required="validateRule.successRate.required"
              :errorMessage="validateRule.successRate.errorMessage"
              :requireRule="validateRule.successRate.rule"
              :regexErrorMessage="validateRule.successRate.ruleMessage"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 border-t border-gray-200 pt-4">
        <div class="mb-2">
          <label class="text-xl font-bold">合成材料</label>
        </div>
        <div class="my-10 ml-3">
          <div class="flex items-center cursor-pointer">
            <svg-icon name="mdiPlusCircleOutline" class="mr-1 text-green-600" @click="addMaterialModal('consumableMaterial')"/>
            <label class="text-lg font-bold">
              消耗材料
            </label>
          </div>
          <div class="p-4">
            <data-table
              :columns="tableColumns"
              border
              :data="manufactureInfo.consumableMaterial"
            >
              <template #delete="{ row }">
                <svg-icon name="mdiTrashCanOutline" class="text-red-600 cursor-pointer" @click="deleteMaterial(row.id, 'consumableMaterial')"/>
              </template>
            </data-table>
          </div>
        </div>
        <div class="my-10 ml-3">
          <div class="flex items-center cursor-pointer">
            <svg-icon name="mdiPlusCircleOutline" class="mr-1 text-green-600" @click="addMaterialModal('inconsumableMaterial')"/>
            <label class="text-lg font-bold">
              不消耗材料
            </label>
          </div>
          <div class="p-4">
            <data-table
              :columns="tableColumns"
              border
              :data="manufactureInfo.inconsumableMaterial"
            >
              <template #delete="{ row }">
                <svg-icon name="mdiTrashCanOutline" class="text-red-600 cursor-pointer" @click="deleteMaterial(row.id, 'inconsumableMaterial')"/>
              </template>
            </data-table>
          </div>
        </div>
      </div>
      <div class="mt-4 border-t border-gray-200 pt-4">
        <div class="mb-2">
          <label class="text-xl font-bold">合成失敗的附加產品</label>
        </div>
        <div class="my-10 ml-3">
          <div class="flex items-center cursor-pointer">
            <svg-icon name="mdiPlusCircleOutline" class="mr-1 text-green-600" @click="addSideProductModal('sideProduct')"/>
            <label class="text-lg font-bold">
              新增
            </label>
          </div>
          <div class="p-4">
            <data-table
                :columns="withMinAndMaxColumns"
              border
              :data="manufactureInfo.sideProduct"
            >
              <template #delete="{ row }">
                <svg-icon name="mdiTrashCanOutline" class="text-red-600 cursor-pointer" @click="deleteMaterial(row.id, 'sideProduct')"/>
              </template>
              <template #count="{ row }">
                <div class="flex justify-center items-center flex-wrap">
                  <input
                      type="text"
                      v-model="row.minCount"
                      class="form-control w-full sm:w-20 m-1"
                      />
                  <span>～</span>
                  <input
                      type="text"
                      v-model="row.maxCount"
                      class="form-control w-full sm:w-20 m-1"
                      />
                </div>
              </template>
            </data-table>
          </div>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row mt-5 justify-center items-center sm:justify-start">
        <button type="button" class="btn btn-primary w-full md:w-36 mt-3" @click="submit">
          {{ stateMethod.btnText }}
        </button>
        <button v-if="state === 'edit'" type="button" class="btn btn-danger w-full md:w-36 mt-3 sm:ml-5 ml-0" @click="softDelete">
          刪除此合成勳章
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onBeforeMount, computed, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useModal } from '@/plugins/modals/index'
import { MedalManufactureService } from '@/services/'
import { MedalType, TableColumns } from '@/types'
import { getMaterial } from '@/store/material'
import { helper as $h } from '@/utils/helper'
import { useLoading } from '@/plugins/loading/'
import useRouter from '@/router/useRouter'
import InfoCard from '@/components/info-card/Main.vue'
import DataTable from '@/components/data-table/Main.vue'
import MedalData from '@/components/data/itemOrMedal'
import SvgIcon from '@/components/svg-icons/Main.vue'

type FURNACE = {
  id: string
  description: string
  displayName: string
  count: number
}
type SIDE_PRODUCT = {
  id: string
  description: string
  displayName: string
  minCount: number
  maxCount: number
}

type MANUFACTURE_INFO = {
  coins: string
  successRate: string
  count: string
  consumableMaterial: Array<FURNACE>
  inconsumableMaterial: Array<FURNACE>
  sideProduct: Array<SIDE_PRODUCT>
}

export default defineComponent({
  components: {
    InfoCard,
    DataTable,
    MedalData,
    SvgIcon
  },
  setup () {
    const route = useRoute()
    const medalId: string = route.params.id as string
    const { openModal } = useModal()
    const { toManufactureMedalPage } = useRouter()
    const { startLoading } = useLoading()
    const state = ref<'new' | 'edit'>('new')
    const tableColumns = TableColumns.CONSUMABLE_COLUMNS
    const withMinAndMaxColumns = TableColumns.SIDE_PRODUCT_COLUMNS
    const columns = [
      'ID'
    ]
    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存變更' : '新增'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? MedalManufactureService.update : MedalManufactureService.create
      return {
        btnText,
        successText,
        API
      }
    })
    const manufactureInfo = reactive<MANUFACTURE_INFO>({
      coins: '',
      successRate: '',
      count: '1',
      consumableMaterial: [],
      inconsumableMaterial: [],
      sideProduct: []
    })

    const validateRule = {
      coins: {
        required: true,
        errorMessage: '請輸入消耗金幣',
        rule: new RegExp(/^(0|[1-9][0-9]*)$/),
        ruleMessage: '請輸入正確的數字格式'
      },
      successRate: {
        required: true,
        errorMessage: '請輸入成功率',
        rule: new RegExp(/^(0|[1-9][0-9]?|100)$/),
        ruleMessage: '請輸入正確的數字格式'
      },
      count: {
        required: true,
        errorMessage: '請輸入製造數量',
        rule: new RegExp(/^(0|[1-9][0-9]*)$/),
        ruleMessage: '請輸入正確的數字格式'
      },
      consumableMaterial: {
        required: true,
        errorMessage: '請選擇要消耗的道具'
      },
      inconsumableMaterial: {
        required: false
      },
      sideProduct: {
        required: true,
        errorMessage: '合成失敗的副產品不得為空'
      }
    }

    const deleteMaterial = (id: string, type: 'consumableMaterial' | 'inconsumableMaterial'|'sideProduct') => {
      const index = manufactureInfo[type].findIndex((val: any) => val.id === id)
      manufactureInfo[type].splice(index, 1)
    }

    const addSideProductModal = (type: 'sideProduct') => {
      // 副產品防呆
      if (type === 'sideProduct' && manufactureInfo.sideProduct.length > 0) {
        openModal('alertModal', { message: '副產品只能設定一個', icon: 'warning' })
        return false
      }
      const confirmFunction = async ({ materialId, rate, minCount, maxCount }: {
        materialId: string,
        rate: number,
        minCount: number,
        maxCount: number
      }) => {
        const map = await getMaterial([materialId])
        manufactureInfo.sideProduct.push({
          id: materialId as string,
          description: map.get(materialId)?.description || '',
          displayName: map.get(materialId)?.displayName || '',
          minCount,
          maxCount
        })
      }
      const filterItemIds = manufactureInfo[type].map(val => val.id)
      openModal('addChestMaterialModal', { confirmFunction, filterItemIds, needRate: false })
    }

    const addMaterialModal = (type: 'inconsumableMaterial' | 'consumableMaterial') => {
      const confirmFunction = async(id: string, count: number) => {
        const map = await getMaterial([id])
        manufactureInfo[type].push({
          id: id as string,
          description: map.get(id)?.description || '',
          displayName: map.get(id)?.displayName || '',
          count: count
        })
      }
      const filterItemIds = manufactureInfo[type].map(val => val.id)
      openModal('addMaterialModal', { confirmFunction, filterItemIds })
    }

    const submit = async(e: Event) => {
      e.preventDefault()
      const fn = unref(stateMethod)
      const data = {
        id: medalId,
        coins: parseInt(manufactureInfo.coins),
        successRate: parseInt(manufactureInfo.successRate),
        count: parseInt(manufactureInfo.count),
        sideProduct: manufactureInfo.sideProduct.map((val: any) => ({ id: val.id.toString(), maxCount: parseInt(val.maxCount), minCount: parseInt(val.minCount) })),
        consumableMaterial: manufactureInfo.consumableMaterial.map((val: any) => ({ id: val.id.toString(), count: parseInt(val.count), type: MedalType.ConsumableType.TYPE_MATERIAL })),
        inconsumableMaterial: manufactureInfo.inconsumableMaterial.map((val: any) => ({ id: val.id.toString(), count: parseInt(val.count), type: MedalType.ConsumableType.TYPE_MATERIAL }))
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }

      // 副產品防呆
      if (data.sideProduct.length > 1) {
        openModal('alertModal', { message: '副產品只能設定一個', icon: 'warning' })
        return false
      }

      const loading = startLoading()
      try {
        await fn.API(data)
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toManufactureMedalPage()
      } catch (e) {

      } finally {
        loading.close()
      }
    }

    const softDelete = () => {
      const deleateAPI = async() => {
        const loading = startLoading()
        try {
          await MedalManufactureService.deleteMedal(medalId)
          openModal('alertModal', { icon: 'success', message: '刪除成功' })
          toManufactureMedalPage()
        } catch (e) {
          console.error(e.message)
          openModal('alertModal', { icon: 'success', message: '出錯了' })
        } finally {
          loading.close()
        }
      }
      openModal('confirmModal', { message: '確定刪除此合成道具嗎?', confirmFunction: deleateAPI })
    }

    onBeforeMount(async() => {
      const data = await MedalManufactureService.getManufactureInfo([medalId])
      if (data.length <= 0) {
        state.value = 'new'
        return false
      }
      state.value = 'edit'
      manufactureInfo.coins = data[0].coins.toString()
      manufactureInfo.successRate = data[0].successRate.toString()
      manufactureInfo.count = data[0].count.toString()

      const consumableId = data[0].consumableMaterial.map(val => val.id)
      const inconsumableId = data[0].inconsumableMaterial.map(val => val.id)
      const sideProductID = data[0].sideProduct.map(val => val.id)
      // 目前先限於材料只能用道具
      const materialIDs = consumableId.concat(inconsumableId, consumableId, sideProductID)
      const materialMap = await getMaterial(materialIDs)

      const getMaterialInfo = (val: any) => {
        const data = materialMap.get(val.id)
        return {
          id: val.id,
          description: data?.description || '',
          displayName: data?.displayName || '',
          count: val.count
        }
      }
      const getSideProductInfo = (val: any) => {
        const data = materialMap.get(val.id)
        return {
          id: val.id,
          description: data?.description || '',
          displayName: data?.displayName || '',
          minCount: val.minCount,
          maxCount: val.maxCount
        }
      }
      manufactureInfo.consumableMaterial = data[0]?.consumableMaterial?.map(getMaterialInfo)
      manufactureInfo.inconsumableMaterial = data[0].inconsumableMaterial?.map(getMaterialInfo)
      manufactureInfo.sideProduct = data[0].sideProduct?.map(getSideProductInfo)
    })

    return {
      columns,
      medalId,
      tableColumns,
      withMinAndMaxColumns,
      state,
      manufactureInfo,
      validateRule,
      stateMethod,
      submit,
      softDelete,
      deleteMaterial,
      addMaterialModal,
      addSideProductModal
    }
  }
})
</script>

<style scoped>

</style>
