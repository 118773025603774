import { render } from "./editGuardianDomain.vue?vue&type=template&id=7a06a0f0"
import script from "./editGuardianDomain.vue?vue&type=script&lang=ts"
export * from "./editGuardianDomain.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7a06a0f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7a06a0f0', script)) {
    api.reload('7a06a0f0', script)
  }
  
  module.hot.accept("./editGuardianDomain.vue?vue&type=template&id=7a06a0f0", () => {
    api.rerender('7a06a0f0', render)
  })

}

script.__file = "src/plugins/modals/editGuardianDomain.vue"

export default script