import { iamRouteName } from '@/router/appRouteName'

export const iamRoute = {
  path: iamRouteName.IAM.path,
  component: () => import('../views/iam/Main.vue'),
  children: [
    {
      path: iamRouteName.IAM_USER.path,
      name: iamRouteName.IAM_USER.name,
      meta: { title: iamRouteName.IAM_USER.title },
      component: () => import('../views/iam/user/Main.vue')
    },
    {
      path: iamRouteName.IAM_ROLE.path,
      name: iamRouteName.IAM_ROLE.name,
      meta: { title: iamRouteName.IAM_ROLE.title },
      component: () => import('../views/iam/role/Main.vue')
    }
  ]
}
