import dayjs from 'dayjs'

export default dayjs

export const formatDateRange = (dateRange: string): { startDate: number, endDate: number } => {
  const split = dateRange.split('-')
  const startDate = dayjs(split[0]).startOf('day').valueOf()
  const endDate = dayjs(split[1]).endOf('day').valueOf()
  return {
    startDate,
    endDate
  }
}
