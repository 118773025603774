import { MedalManufactureAPI } from '@/api/index'
import { MedalType } from '@/types/'
import { createMedal } from './medal'
import { loginedStatus } from '@/store/user'
import * as adminMedalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/furnace_pb'

export const getAllItem = async(pageSize: number, page: number):Promise<
{
  data: Array<MedalType.ManufactureMedal<MedalType.Medal>>,
  totalPage: number
}> => {
  const result = await MedalManufactureAPI.getAllItem(loginedStatus.token, pageSize, page)
  return {
    data: result.getItemList().map(val => {
      return createManufactureItem(val)
    }),
    totalPage: result.getPagination()!.getTotalPage()
  }
}

export const createManufactureItem = (item: adminMedalPb.FurnaceItem | adminMedalPb.FurnaceInfo): MedalType.ManufactureMedal<MedalType.Medal> => {
  let consumableMaterial: Array<MedalType.ConsumableMaterial> = []
  let inconsumableMaterial: Array<MedalType.ConsumableMaterial> = []
  let sideProduct: Array<MedalType.SideProduct> = []
  if (item instanceof adminMedalPb.FurnaceInfo) {
    consumableMaterial = item.getConsumableMaterialList()
      .map((val: adminMedalPb.ConsumableMaterial) => ({
        id: val.getId(),
        count: val.getCount(),
        type: MedalType.grpcToConsumableMaterial[val.getType()]
      }))

    inconsumableMaterial = item.getInconsumableMaterialList()
      .map((val: adminMedalPb.InconsumableMaterial) => ({
        id: val.getId(),
        count: val.getCount(),
        type: MedalType.grpcToConsumableMaterial[val.getType()]
      }))

    sideProduct = item.getSideProductList()
      .map((val: adminMedalPb.SideProduct) => ({
        id: val.getId(),
        maxCount: val.getMaxCount(),
        minCount: val.getMinCount()
      }))
  }
  return {
    info: createMedal(item.getInfo()!),
    successRate: item.getSuccessRate(),
    count: item.getCount(),
    coins: item.getCoins(),
    consumableMaterial,
    inconsumableMaterial,
    sideProduct
  } as MedalType.ManufactureMedal<MedalType.Medal>
}

// TODO: api規格是否要一致
export const getManufactureInfo = async(ids: Array<string>): Promise<Array<MedalType.ManufactureMedal<MedalType.Medal>>> => {
  const result = await MedalManufactureAPI.getManufactureInfo(loginedStatus.token, ids)
  return result.getItemList().filter(val => ids.includes(val.getInfo()!.getId()))
    .map(val => createManufactureItem(val))
}

export const create = async (payload:{
  id: string
  coins: number
  successRate: number
  count: number
  consumableMaterial: Array<MedalType.ConsumableMaterial>
  inconsumableMaterial: Array<MedalType.ConsumableMaterial>
  sideProduct: Array<MedalType.SideProduct>
}): Promise<string> => {
  const res = await MedalManufactureAPI.create(loginedStatus.token, payload)
  return res.getMedalId()
}
export const update = async (payload:{
  id: string
  coins: number
  successRate: number
  count: number
  consumableMaterial: Array<MedalType.ConsumableMaterial>
  inconsumableMaterial: Array<MedalType.ConsumableMaterial>
  sideProduct: Array<MedalType.SideProduct>
}): Promise<string> => {
  const res = await MedalManufactureAPI.update(loginedStatus.token, payload)
  return res.getMedalId()
}

export const deleteMedal = async (id: string):Promise<string> => {
  const res = await MedalManufactureAPI.deleteMedal(loginedStatus.token, id)
  return res.getMedalId()
}
