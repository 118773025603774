import { ManufactureAPI } from '@/api/index'
import { MaterialType } from '@/types/'
import { loginedStatus } from '@/store/user'
import * as adminMaterialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/furnace_pb'

export const getAllManufactureItem = async(pageSize: number, page: number)
:Promise<{
  data: Array<MaterialType.ManufactureMaterial<MaterialType.Material>>,
  totalPage: number
}> => {
  const result = await ManufactureAPI.getAllManufactureItem(loginedStatus.token, pageSize, page)
  return {
    data: result.getItemList().map((val: adminMaterialPb.FurnaceItem) => {
      return createManufactureListItem(val)
    }),
    totalPage: result.getPagination()!.getTotalPage()
  }
}

export const createManufactureListItem = (item: adminMaterialPb.FurnaceInfo| adminMaterialPb.FurnaceItem): MaterialType.ManufactureMaterial<MaterialType.Material> => {
  const info = item.getInfo()!
  let consumableMaterial: Array<MaterialType.ConsumableMaterial> = []
  let inconsumableMaterial: Array<MaterialType.ConsumableMaterial> = []
  let sideProduct: Array<MaterialType.SideProductMaterial> = []
  if (item instanceof adminMaterialPb.FurnaceInfo) {
    consumableMaterial = item.getConsumableMaterialList()
      .map((val: adminMaterialPb.ConsumableMaterial) => ({ id: val.getId(), count: val.getCount() }))

    inconsumableMaterial = item.getInconsumableMaterialList()
      .map((val: adminMaterialPb.InconsumableMaterial) => ({ id: val.getId(), count: val.getCount() }))

    sideProduct = item.getSideProductList()
      .map((val: adminMaterialPb.SideProduct) => ({ id: val.getId(), maxCount: val.getMaxCount(), minCount: val.getMinCount() }))
  }
  return {
    info: {
      id: info.getId(),
      displayName: info.getDisplayName(),
      img: info.getImg(),
      description: info.getDesc(),
      weight: info.getWeight(),
      category: MaterialType.grpcTypeToCategory[info.getCategory()],
      furnaceLuck: 0,
      craftLuck: 0,
      enhanceLuck: 0
    },
    successRate: item.getSuccessRate(),
    coins: item.getCoins(),
    count: item.getCount(),
    sideProduct,
    consumableMaterial,
    inconsumableMaterial
  } as MaterialType.ManufactureMaterial<MaterialType.Material>
}

// TODO: api規格是否要一致
export const getManufactureInfo = async(ids: Array<string>): Promise<Array<MaterialType.ManufactureMaterial<MaterialType.Material>>> => {
  const result = await ManufactureAPI.getManufactureInfo(loginedStatus.token, ids)
  return result.getItemList().filter(val => ids.includes(val.getInfo()!.getId()))
    .map(val => createManufactureListItem(val))
}

export const update = async(payload: {
  id: string
  coins: number
  successRate: number
  count: number
  sideProduct: Array<{ id: string, minCount: number, maxCount: number }>,
  consumableMaterial: Array<{ id: string, count: number }>
  inconsumableMaterial: Array<{ id: string, count: number }>
}): Promise<string> => {
  const res: adminMaterialPb.UpdateFurnaceRes = await ManufactureAPI.update(loginedStatus.token, payload)
  return res.getId()
}

export const create = async (payload: {
  id: string
  coins: number
  successRate: number
  count: number
  sideProduct: Array<{ id: string, minCount: number, maxCount: number }>,
  consumableMaterial: Array<{ id: string, count: number }>
  inconsumableMaterial: Array<{ id: string, count: number }>
}): Promise<string> => {
  const res: adminMaterialPb.CreateFurnaceRes = await ManufactureAPI.create(loginedStatus.token, payload)
  return res.getId()
}

export const deleteItem = async (id: string): Promise<string> => {
  const res = await ManufactureAPI.deleteItem(loginedStatus.token, id)
  return res.getId()
}
