import { render } from "./Main.vue?vue&type=template&id=fdc44dd0"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "fdc44dd0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('fdc44dd0', script)) {
    api.reload('fdc44dd0', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=fdc44dd0", () => {
    api.rerender('fdc44dd0', render)
  })

}

script.__file = "src/views/search-user/user-profile/Main.vue"

export default script