import { computed, reactive } from 'vue'
import { MaterialType } from '@/types/index'
import { MaterialService } from '@/services/index'
import { loginedStatus } from './user'

// key: uuid, value: material
export const materialMap = reactive<Map<string, MaterialType.Material>>(new Map())

export const setMaterialMap = (id: string, value: MaterialType.Material) => {
  materialMap.set(id, value)
}

export const updateMaterialMap = (id: string, value: Partial<MaterialType.Material>) => {
  const obj = materialMap.get(id)
  if (!obj) throw new Error('not has this material')
  materialMap.set(id, Object.assign(obj, { ...value }))
}

export const deleteMaterialMap = (id: string) => {
  materialMap.delete(id)
}

export const getMaterial = async (ids: Array<string>): Promise<Map<string, MaterialType.Material>> => {
  // 去除重複的 id
  ids = [...new Set([...ids])]
  const notHasMaterial: Array<string> = []
  const result = new Map(
    ids.filter((id) => {
      if (!materialMap.has(id)) notHasMaterial.push(id)
      return materialMap.has(id)
    }).map(id => [id, materialMap.get(id)])
  ) as Map<string, MaterialType.Material>
  // 有缺資料
  if (notHasMaterial.length > 0) {
    await getAndSetMaterial(notHasMaterial)
    return getMaterial(ids)
  }
  return result
}

export const getAndSetMaterial = async(ids: Array<string>): Promise<void> => {
  const result = await MaterialService.getMaterialInfo(ids)
  result.forEach(val => {
    if (!materialMap.has(val.id)) setMaterialMap(val.id, val)
  })
}

export const emptyMaterial = computed(() => {
  return {
    id: '',
    displayName: '',
    description: '',
    img: '',
    weight: 0,
    furnace_luck: 0,
    craft_luck: 0,
    enhance_luck: 0
  }
})
