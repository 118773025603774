
import { defineComponent, reactive } from 'vue'
import { useLoading } from '@/plugins/loading/'
import { useModal } from '@/plugins/modals/'
import { helper as $h } from '@/utils/helper'
// import RoleList from '@/components/roleList/Main.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import { MaterialService } from '@/services'

export default defineComponent({
  name: 'SendItem',
  components: {
    // RoleList,
    InfiniteScroll,
    LoadingItems
  },
  setup () {
    const { openModal } = useModal()
    const submitData = reactive({
      uid: '',
      isNotification: 'false',
      materialId: '',
      count: 0,
      allowed: [],
      message: ''
    })

    const validateRule = {
      uid: {
        required: true,
        errorMessage: '請輸入Uid'
      },
      isNotification: {
        required: true,
        errorMessage: '請選擇是否通知'
      },
      materialId: {
        required: true,
        errorMessage: '請選擇道具'
      },
      message: {
        required: false
      },
      count: {
        required: true,
        rule: new RegExp(/^([1-9][0-9]*)$/),
        errorMessage: '請輸入數量',
        ruleMessage: '請輸入正確的數字格式'
      }
    }

    const selectNewItem = (item: string) => {
      submitData.materialId = item
    }
    const resetData = () => {
      submitData.uid = ''
      submitData.isNotification = 'false'
      submitData.materialId = ''
      submitData.count = 0
      submitData.allowed = []
      submitData.message = ''
    }

    const { startLoading } = useLoading()
    const submit = async(e: Event) => {
      e.preventDefault()
      // 沒有勾群組
      if (submitData.allowed.length === 0) await sendMaterialByUid()
      // 有勾群組
      else await sendMaterialByRole()
    }

    const sendMaterialByUid = async () => {
      const data = {
        uid: submitData.uid,
        isNotification: submitData.isNotification,
        materialId: submitData.materialId,
        count: submitData.count,
        message: submitData.message
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      // uid 要過濾
      const filterUid = data.uid.split(',').filter((val) => {
        return val && !val.startsWith('0') && !isNaN($h.filterInt(val))
      }).map((val) => {
        const data = val.trim()
        return parseInt(data)
      })
      if (filterUid.length <= 0) {
        openModal('alertModal', { message: '請輸入Uid', icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        await MaterialService.shipItem({
          uid: filterUid.map((val) => val.toString()),
          isNotification: Boolean(submitData.isNotification),
          materialId: submitData.materialId,
          count: submitData.count,
          message: submitData.message
        })
        openModal('alertModal', { icon: 'success', message: '成功寄出道具' })
        resetData()
      } catch (e) {
        console.error(e)
        const errorMessage = e.code === 1001 ? '找不到該用戶' : '出錯了'
        openModal('alertModal', { icon: 'warning', message: errorMessage })
        resetData()
      } finally {
        loading.close()
      }
    }

    const sendMaterialByRole = async () => {
      const validateRule = {
        materialId: {
          required: true,
          errorMessage: '請選擇道具'
        },
        count: {
          required: true,
          rule: new RegExp(/^([1-9][0-9]*)$/),
          errorMessage: '請輸入數量',
          ruleMessage: '請輸入正確的數字格式'
        },
        role: {
          required: true,
          errorMessage: '請選擇會員組'
        },
        message: {
          required: false
        }
      }
      const data = {
        materialId: submitData.materialId,
        count: submitData.count,
        role: submitData.allowed,
        message: submitData.message
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        await MaterialService.shipItemByRole({
          isNotification: Boolean(submitData.isNotification),
          materialId: submitData.materialId,
          count: submitData.count,
          role: data.role.map((val) => val),
          message: submitData.message
        })
        openModal('alertModal', { icon: 'success', message: '成功寄出道具' })
        resetData()
      } catch (e) {
        console.error(e)
        openModal('alertModal', { icon: 'warning', message: '出錯了' })
        resetData()
      } finally {
        loading.close()
      }
    }

    return {
      submit,
      submitData,
      selectNewItem
    }
  }
})
