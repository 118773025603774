import ROUTE_NAME, { materialRouteName, medalRouteName, iamRouteName } from '@/router/appRouteName'

const state = () => {
  return {
    menu: [
      {
        icon: 'mdiMessageReplyOutline',
        pageName: ROUTE_NAME.HOME.name,
        title: 'jk-talk',
        subMenu: [
          {
            icon: 'mdiMessageReplyOutline',
            pageName: ROUTE_NAME.HOME.name,
            title: '聊天訊息'
          },
          {
            icon: 'mdiCommentSearchOutline',
            pageName: ROUTE_NAME.SEARCH_USER.name,
            title: '查詢會員聊天室'
          },
          {
            icon: 'mdiCommentSearchOutline',
            pageName: ROUTE_NAME.BANNED_USER.name,
            title: '禁言名單'
          }
        ]
      },
      {
        icon: 'mdiAccountSearchOutline',
        pageName: ROUTE_NAME.USERS.name,
        title: 'Pan 會員管理',
        subMenu: [
          {
            icon: 'mdiAccountSearchOutline',
            pageName: ROUTE_NAME.USERS.name,
            title: 'Pan 會員搜尋'
          }
        ]
      },
      {
        icon: 'mdiPackageVariantClosed',
        pageName: materialRouteName.ITEM.name,
        title: '道具',
        subMenu: [
          {
            icon: 'mdiPackageVariantClosed',
            pageName: materialRouteName.ITEM.name,
            title: '所有道具列表'
          },
          {
            icon: 'mdiStorefrontOutline',
            pageName: materialRouteName.SHOP.name,
            title: '道具商店'
          },
          {
            icon: 'mdiCogs',
            pageName: materialRouteName.MANUFACTURE.name,
            title: '可製作道具列表'
          },
          {
            icon: 'mdiSend',
            pageName: materialRouteName.SEND_MATERIAL.name,
            title: '發送道具'
          },
          {
            icon: 'mdiTextBoxOutline',
            pageName: materialRouteName.MATERIAL_LOG.name,
            title: '道具使用紀錄'
          }
        ]
      },
      {
        icon: 'mdiMedalOutline',
        pageName: medalRouteName.MEDAL.name,
        title: '勳章',
        subMenu: [
          {
            icon: 'mdiMedalOutline',
            pageName: medalRouteName.MEDAL.name,
            title: '所有勳章列表'
          },
          {
            icon: 'mdiApplicationCogOutline',
            pageName: medalRouteName.MEDAL_MANUFACTURE.name,
            title: '可合成勳章列表'
          },
          {
            icon: 'mdiPencilOutline',
            pageName: medalRouteName.MEDAL_ENHANCE.name,
            title: '關聯勳章列表'
          },
          {
            icon: 'mdiSendCircleOutline',
            pageName: medalRouteName.SEND_MEDAL.name,
            title: '發送勳章'
          },
          {
            icon: 'mdiMathLog',
            pageName: medalRouteName.MEDAL_LOG.name,
            title: '勳章合成紀錄'
          }
        ]
      },
      /*
      {
        icon: 'mdiShieldCheck',
        pageName: guardianRouteName.GUARDIAN.name,
        title: guardianRouteName.GUARDIAN.title,
        subMenu: [
          {
            icon: 'mdiShapeOutline',
            pageName: guardianRouteName.GUARDIAN_DOMAIN.name,
            title: guardianRouteName.GUARDIAN_DOMAIN.title
          },
          {
            icon: 'mdiShieldEdit',
            pageName: guardianRouteName.GUARDIAN_DESIGN.name,
            title: guardianRouteName.GUARDIAN_DESIGN.title
          },
          {
            icon: 'mdiAccountGroup',
            pageName: guardianRouteName.GUARDIAN_ROLE.name,
            title: guardianRouteName.GUARDIAN_ROLE.title
          },
          {
            icon: 'mdiShieldLockOutline',
            pageName: guardianRouteName.GUARDIAN_PERM.name,
            title: guardianRouteName.GUARDIAN_PERM.title
          }
        ]
      },
      */
      {
        icon: 'mdiGoogle',
        pageName: iamRouteName.IAM.name,
        title: iamRouteName.IAM.title,
        subMenu: [
          {
            icon: 'mdiEmoticonDevilOutline',
            pageName: iamRouteName.IAM_USER.name,
            title: iamRouteName.IAM_USER.title
          },
          {
            icon: 'mdiAccountTieHat',
            pageName: iamRouteName.IAM_ROLE.name,
            title: iamRouteName.IAM_ROLE.title
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
