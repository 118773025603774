import { defineComponent, toRefs, ref, watch, computed, onMounted } from 'vue'
import { tryOnBeforeUnmount } from '@vueuse/core'
import * as Users from '@/store/users'

export default defineComponent({
  name: 'getUser',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  setup(props, { slots }) {
    const { userId = ref('') } = toRefs(props)
    const user = ref(new Map())

    const onChanged = async (userid: string) => {
      if (userid !== '') {
        user.value = await Users.getUser([userid])
      }
    }

    onMounted(async() => {
      await onChanged(userId.value)
    })

    const unwatch = watch(userId, onChanged)

    const data = computed(() => {
      const userID = userId.value
      return user.value.has(userID) ? user.value.get(userID) : Users.emptyUser.value
    })

    const getPhoto = computed(() => {
      return data.value.photoURL || require('@/assets/images/preview-1.jpg')
    })

    const getUid = computed(() => {
      return data.value.uid.toString() || ''
    })

    tryOnBeforeUnmount(() => unwatch())

    return () => {
      if (slots.default) {
        return slots.default(
          {
            displayName: data.value.displayName,
            email: data.value.email,
            hashUid: data.value.hashUid,
            uid: getUid.value,
            photoURL: getPhoto.value
          }
        )
      }
    }
  }
})
