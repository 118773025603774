import { reactive, readonly } from 'vue'
import { RoleType } from '@/types/'
import { helper } from '@/utils/helper'

const roleMap = reactive<Map<string, RoleType.Role>>(new Map())

export const getRole = (roleId: string) => {
  const role = roleMap.get(roleId)
  if (role) return readonly(role)
  return RoleType.emptyRole
}

export const setRoleMap = (roleId: string, role: RoleType.Role) => {
  roleMap.set(roleId, role)
}

export const isHasRole = (displayName: string): boolean => {
  const roleId = helper.sha1(displayName)
  const role = getRole(roleId)
  return role.isExist
}
