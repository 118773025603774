
import { computed, defineComponent, PropType, ref } from 'vue'
import { unrefElement } from '@vueuse/core'
export default defineComponent({
  name: 'modal',
  props: {
    position: {
      type: String as PropType<'fullScreen'|'center'>,
      default: 'fullScreen'
    }
  },
  emits: ['close'],
  setup (props, { emit, expose }) {
    const dynamicStyle = computed(() => {
      return props.position == 'center' ? [{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }] : []
    })
    const modalMask = ref<HTMLElement | null>(null)
    const closeModal = () => { emit('close') }
    const clickOutSide = (e: Event) => {
      if (unrefElement(modalMask) === e.target) {
        closeModal()
      }
    }
    expose({
      closeModal
    })
    return {
      closeModal,
      modalMask,
      clickOutSide,
      dynamicStyle
    }
  }
})
