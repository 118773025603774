<template>
  <div class="mb-3">
    <button type="button" class="btn btn-primary w-full md:w-32" @click="newItem">
      新增可製作道具
    </button>
  </div>
  <div>
    <data-table
      :columns="columns"
      :data="data"
      :page="page"
    >
      <template #id="{ row }">
        {{ showMaterialData(row, 'id') }}
      </template>
      <template #img="{ row }">
        <img
          :src="row.info?.img"
          class="w-10"
        >
      </template>
      <template #displayName="{ row }">
        {{ showMaterialData(row, 'displayName') }}
      </template>
      <template #description="{ row }">
        {{ showMaterialData(row, 'description') }}
      </template>
      <template #action="{ row }">
        <button class="btn w-24 mr-1 mb-2 btn-rounded-success" @click="toEditManufactureitemPage(row?.info?.id)">
        編輯
        </button>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, reactive } from 'vue'
import { useModal } from '@/plugins/modals/index'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MaterialType } from '@/types/index'
import { ManufactureService } from '@/services/'
export default defineComponent({
  name: 'Manufacture',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const data = reactive<Array<MaterialType.ManufactureMaterial<MaterialType.Material>>>([])
    const { openModal } = useModal()
    const columns = TableColumns.MANUFACTURE
    const { toEditManufactureitemPage } = useRouter()
    let closeNewItem: Function | null = null
    const showMaterialData = (value: any, type: MaterialType.MaterialKey) => {
      if (value.info) return value.info[type]
    }

    onBeforeMount(async () => {
      const result = await ManufactureService.getAllManufactureItem(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    const validateAndChangePage = (id: string) => {
      if (!id) {
        openModal('alertModal', { icon: 'warning', message: '請選擇道具' })
        return false
      }
      closeNewItem!()
      toEditManufactureitemPage(id)
    }

    const newItem = () => {
      const { closeModal } = openModal('newItem', { confirmFunction: validateAndChangePage })
      closeNewItem = closeModal
    }

    const resetData = () => {
      data.length = 0
    }

    const getDataByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await ManufactureService.getAllManufactureItem(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    return {
      columns,
      data,
      page,
      toEditManufactureitemPage,
      newItem,
      getDataByPage,
      totalPage,
      showMaterialData
    }
  }
})
</script>

<style scoped>

</style>
