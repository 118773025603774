import { reactive, computed } from 'vue'
import { PanAPI } from '@/api/index'

export type User = {
  uid: number
  hashUid: string
  displayName: string
  email: string
  photoURL: string
  emailStatus: number
}

// key: hashId, value: UserData
export const users = reactive<Map<string, User>>(new Map())
// 儲存 uid 以及 hashId 的 map
const uidMap: Map<number, string> = new Map()

export const getUserByUid = async (uid: number) => {
  if (!uidMap.has(uid)) {
    await getAndSetUsers([uid], 'UID')
  }
  const result = await getUser([uidMap.get(uid)!])
  return {
    hashId: uidMap.get(uid),
    userMap: result
  }
}

export const getUser = async (
  hashIds: Array<string>
): Promise<Map<string, User>> => {
  // 去除重複的 uid
  hashIds = [...new Set([...hashIds])]
  const notHasUsers: Array<string> = []
  const result = new Map(
    hashIds
      .filter(id => {
        if (!users.has(id)) notHasUsers.push(id)
        return users.has(id)
      })
      .map(id => [id, users.get(id)])
  ) as Map<string, User>
  // 有缺資料
  if (notHasUsers.length > 0) {
    await getAndSetUsers(notHasUsers, 'HASHID')
    return getUser(hashIds)
  }

  return result
}

export const emptyUser = computed(() => {
  return {
    uid: 0,
    hashUid: '',
    displayName: '',
    email: '',
    photoURL: ''
  }
})

export const getAndSetUsers = async (
  ids: Array<string | number>,
  by: 'UID' | 'HASHID'
): Promise<void> => {
  const functionMap = {
    UID: PanAPI.UniversalReadObjectByUid,
    HASHID: PanAPI.UniversalReadObjectByHashId
  }
  // @ts-ignore
  const usersObject = await functionMap[by](ids)
  usersObject.forEach(panRes => {
    const { hashUid, uid, email, displayName, avatar, emailStatus } = panRes.toObject()
    if (!users.has(hashUid)) users.set(hashUid, { uid, hashUid, displayName, email, photoURL: avatar, emailStatus })
    if (!uidMap.has(uid)) uidMap.set(uid, hashUid)
  })
}

export const getHashIdByUid = async (uid: number) => {
  const user = await getUserByUid(parseInt(uid.toString()))
  // @ts-ignore
  const hashUid = user.userMap.get(user.hashId!)!.hashUid
  return hashUid
}

export const getUsers = async (
  ids: Array<string | number>,
  by: 'UID' | 'HASHID'
): Promise<Map<string, User>> => {
  const functionMap = {
    UID: PanAPI.UniversalReadObjectByUid,
    HASHID: PanAPI.UniversalReadObjectByHashId
  }
  // @ts-ignore
  const usersObject = await functionMap[by](ids)

  const userMaps: Map<string, User> = new Map()
  usersObject.forEach(panRes => {
    const { hashUid, uid, email, displayName, avatar, emailStatus } = panRes.toObject()
    userMaps.set(hashUid, {
      uid,
      hashUid,
      displayName,
      email,
      photoURL: avatar,
      emailStatus
    })
  })

  return userMaps
}
