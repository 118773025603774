import { useJwt } from '@vueuse/integrations'
export interface GoogleUser {
  Name (): string
  Email (): string
  AccessToken (): string
}

export class GUser implements GoogleUser {
  private userObj: any
  private token: string
  private info: any
  constructor(userObj: any) {
    const { payload } = useJwt(userObj.credential)
    this.token = userObj.credential
    this.userObj = userObj
    this.info = payload.value
  }

  Name (): string {
    return this.info.name
  }

  Email (): string {
    return this.info.email
  }

  AccessToken(): string {
    return this.token
  }
}
