
import { defineComponent, reactive, ref, watch, toRefs, nextTick, computed } from 'vue'
import { useMouseInElement, tryOnBeforeUnmount, tryOnMounted, unrefElement, debouncedWatch, useEventListener } from '@vueuse/core'
import { helper as $h } from '@/utils/helper'
import Text from './Text.vue'
import UserData from '@/components/user/user'
import { FirebaseType } from '@/types'
import { MessageAll } from '@/services'
import composition from '@/composition/message'
import { FAKE_AVATAR } from '@/utils'

export default defineComponent({
  name: 'TalkingRoom',
  props: {
    roomID: {
      type: String,
      required: true
    },
    senderID: {
      type: String,
      required: true
    },
    receiverID: {
      type: String,
      required: true
    }
  },
  components: {
    UserData,
    Text
  },
  setup (props) {
    const { roomID, senderID } = toRefs(props)
    const targetRoom = ref<HTMLElement | null>(null)
    const bottomEle = ref<HTMLElement | null>(null)
    const scrollTop = ref(-1)
    const isOnTop = computed(() => scrollTop.value > -1 && scrollTop.value <= 0)
    const { isOutside } = useMouseInElement(targetRoom)
    const onSnapshotMsgCount = ref(30)
    const messages = reactive<Array<FirebaseType.MessageItem>>([])
    const isLoadData = ref(false)
    let lastVisible: FirebaseType.FirebaseDocData | null = null
    const { replaceImgUrl, imageUrlPrefix } = composition()
    const newImageUrl = ref(FAKE_AVATAR)

    const unwatchLoadData = debouncedWatch(isOnTop, (val) => {
      if (val) {
        if (isLoadData.value) return
        loadHistory(roomID.value, senderID.value, lastVisible!)
      }
    }, { debounce: 100 })

    const messageInit = async(roomID: string, hashUid: string, limit: number) => {
      // reset
      isLoadData.value = true
      messages.length = 0
      scrollTop.value = -1
      const { data, lastVisibleData } = await MessageAll.getMessageAll({ roomID, hashUid, limit })
      data.forEach((data: FirebaseType.MessageItem) => {
        messages.unshift(data)
      })
      if (lastVisibleData) lastVisible = lastVisibleData
      await nextTick()
      scrollToBottom()
      isLoadData.value = false
    }

    const loadHistory = async (roomID: string, hashUid: string, lastVisibleValue: FirebaseType.FirebaseDocData) => {
      isLoadData.value = true
      try {
        const limit = 15
        const { data, lastVisibleData } = await MessageAll.getMessageAll({ roomID, hashUid, limit, startAfterValue: lastVisibleValue })
        data.forEach((data: FirebaseType.MessageItem) => {
          messages.unshift(data)
        })
        if (lastVisibleData) {
          lastVisible = lastVisibleData
          scrollToTop(100)
        }
      } catch (e) {
        console.error(e)
      }
      isLoadData.value = false
    }

    // 滑進去聊天室的時候只能控制聊天室的 scroll 不能控制外面的
    const watchIsOutSide = watch(isOutside, (newVal) => {
      if (newVal) {
        $h.enableScroll()
      } else {
        $h.disableScroll()
      }
    })

    const updateInfo = () => {
      // @ts-ignore
      scrollTop.value = unrefElement(targetRoom)?.scrollTop
    }

    const scrollToBottom = () => {
      if (bottomEle.value) {
        // @ts-ignore
        unrefElement(bottomEle).scrollIntoView()
      }
    }

    const scrollToTop = (top: number) => {
      // @ts-ignore
      unrefElement(targetRoom).scrollTo({
        top: top
      })
    }

    const watchRoomID = watch(roomID, async() => {
      await messageInit(roomID.value, senderID.value, onSnapshotMsgCount.value)
    })

    const markMessage = (value: boolean, index: number) => {
      messages[index].Sysf1 = value
      messages[index].Sysf2 = true
      const roomId = messages[index].RoomId
      const docId = messages[index].id
      MessageAll.updateSysf1(roomId, docId, value)
    }

    tryOnMounted(async() => {
      try {
        await messageInit(roomID.value, senderID.value, onSnapshotMsgCount.value)
      } catch (e) {
        console.log(e)
      }
    })

    const changeUrl = (url: string) => {
      const img = new Image()
      img.src = url
      img.onerror = function () {
        newImageUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
      }
      img.onload = function () {
        newImageUrl.value = url
      }
      return newImageUrl.value
    }

    tryOnBeforeUnmount(() => {
      watchIsOutSide()
      watchRoomID()
      unwatchLoadData()
    })

    useEventListener(targetRoom, 'scroll', updateInfo)
    return {
      targetRoom,
      messages,
      replaceImgUrl,
      imageUrlPrefix,
      bottomEle,
      isOnTop,
      isLoadData,
      changeUrl,
      markMessage
    }
  }
})
