import { reactive, computed } from 'vue'
import { FirebaseAuth } from '@/services/'
import { AdminAPI, ChatAPI } from '@/api/index'
import { useJwt } from '@vueuse/integrations'
import { GoogleUser } from '@/types/index'
import { helper } from '@/utils/helper'

const LoginStatus = ['token', 'email', 'name'] as const

// equal type InfoType  = 'token' | 'email' | 'name'
type InfoType = typeof LoginStatus[number]

/*
equal type User = {
  token: string,
  email: string,
  name: string
}
*/

type User = {
  [k in typeof LoginStatus[number] ]: string
}

export const loginedStatus = reactive<User>({
  token: '',
  email: '',
  name: ''
})

export const isLogin = computed(() => {
  const isLogin = !!loginedStatus.token
  return isLogin
})

export const loginWithGoogle = async(user: GoogleUser.GUser): Promise<void> => {
  const expireUnitTime = helper.addTime(new Date(), 10, 'hours').valueOf()
  const res = await AdminAPI.googleSignIn(user.AccessToken(), expireUnitTime)
  const res2 = await ChatAPI.getFirebaseAdminToken(res.getAdminToken())
  await FirebaseAuth.firebaseLogin(res2.getFirebaseToken())
  const type = 'add'
  updateInfo({ type, value: res.toObject().adminToken, key: 'token' })
  updateInfo({ type, value: user.Name(), key: 'name' })
  updateInfo({ type, value: user.Email(), key: 'email' })
}

const updateInfo = ({ key, value, type } : { key: InfoType, value: string, type: 'add' | 'delete' }) => {
  // store
  loginedStatus[key] = value
  // localStorage data
  const functionMap = new Map([['add', setLocalStorage], ['delete', cleanLocalStorage]])

  if (functionMap.has(type)) {
    const addOrDelete = functionMap.get(type)
    addOrDelete!(key, value)
  }
}

export const logout = () => {
  const type = 'delete'
  LoginStatus.forEach(key => {
    updateInfo({ type, value: '', key })
  })
  FirebaseAuth.firebaseLogout()
}

export const loadAllLocalStorageData = async () => {
  const token = localStorage.getItem('token')
  if (token) {
    const res = await ChatAPI.getFirebaseAdminToken(token)
    await FirebaseAuth.firebaseLogin(res.getFirebaseToken())
    LoginStatus.forEach(key => {
      loadFromLocalStorage(key)
    })
  }
}

// 取的這次token 過期時間
export const adminTokenExpiredAt = () => {
  if (loginedStatus.token) {
    const token = loginedStatus.token
    const { payload } = useJwt(token)
    // @ts-ignore
    const ISOtime = payload.value.ExpireAt
    return new Date(ISOtime).getTime()
  }
  return 0
}

const setLocalStorage = (key: InfoType, value: string) => {
  localStorage.setItem(key, value)
}

const cleanLocalStorage = (key: InfoType) => {
  localStorage.removeItem(key)
}

const loadFromLocalStorage = (key: InfoType) => {
  const data = localStorage.getItem(key)
  if (data) {
    updateInfo({ type: 'add', value: data, key })
  }
}
