
import { computed, defineComponent, ref, reactive, PropType, toRefs } from 'vue'
import { debouncedWatch } from '@vueuse/core'
import { useModal } from '@/plugins/modals/'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MaterialType, EffectType } from '@/types'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { getMaterial } from '@/store/material'

export default defineComponent({
  name: 'effect-setting',
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    effectType: {
      type: Number as PropType<EffectType.EffectType>,
      required: true
    },
    chest: {
      type: Object as PropType<Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>>,
      required: true
    }
  },
  components: {
    DataTable,
    SvgIcon
  },
  setup(props, { expose }) {
    const { effectType, chest } = toRefs(props)
    const { openModal } = useModal()
    const materialColumns = TableColumns.EFFECT_MATERIAL_SETTING
    const creditColumns = TableColumns.EFFECT_CREDIT_SETTING
    const selectEffectType = ref<string>('1')
    const materialChest = reactive<Array<EffectType.Chest<MaterialType.Material>>>([])
    const creditChest = reactive<Array<EffectType.Chest<MaterialType.Credit>>>([])
    const dynamicSetting = computed(() => {
      return {
        material: {
          isShow: selectEffectType.value !== '4',
          columns: selectEffectType.value === '3' || selectEffectType.value === '4' ? creditColumns : materialColumns
        },
        credit: {
          isShow: selectEffectType.value == '3' || selectEffectType.value === '4' || selectEffectType.value === '5',
          columns: selectEffectType.value === '5' ? materialColumns : creditColumns
        }
      }
    })

    const changeEffectData = (e: Event) => {
      const selectValue = (e.target as HTMLInputElement).value
      resetValue()
      // 切換到原本的資料
      // 要把道具池內的資料重新顯示
      if (selectValue === props.effectType.toString()) {
        setOriginalValue()
      }
    }

    const resetValue = () => {
      materialChest.splice(0)
      creditChest.splice(0)
    }

    const setOriginalValue = () => {
      selectEffectType.value = effectType.value.toString()
      chest.value.forEach(val => {
        if (val.chestType === EffectType.ChestType.CHEST_TYPE_MATERIAL) {
          // @ts-ignore
          // 不懂為何會出錯？
          materialChest.push(val)
        } else {
          creditChest.push(val)
        }
      })
    }

    const submitData = ():{
      effectType: EffectType.EffectType,
      chest: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>
    } => {
      const chest = [...materialChest, ...creditChest]
      return {
        effectType: parseInt(selectEffectType.value),
        chest
      }
    }

    const addMaterial = () => {
      const filterItemIds = materialChest.map((val) => val.info.id)
      const confirmFunction = async ({ materialId, rate, minCount, maxCount }: {
        materialId: string,
        rate: number,
        minCount: number,
        maxCount: number
      }) => {
        const material = await getMaterial([materialId])
        materialChest.push({
          info: material.get(materialId)!,
          chestType: EffectType.ChestType.CHEST_TYPE_MATERIAL,
          rate,
          minCount,
          maxCount
        })
      }
      const needRate = selectEffectType.value !== '3'
      openModal('addChestMaterialModal', { confirmFunction, filterItemIds, needRate })
    }

    const addCredit = () => {
      // 只有使用後獲得積分的型態要限制
      if (creditChest.length >= 1 && selectEffectType.value === '4') {
        openModal('alertModal', { icon: 'warning', message: '一次只能設定一種積分' })
        return false
      }
      const confirmFunction = ({ creditId, minCount, maxCount, rate }: { creditId: EffectType.ChestType, minCount: number, maxCount: number, rate: number }) => {
        const credit = MaterialType.getCredit(creditId.toString())
        creditChest.push({
          info: credit,
          chestType: creditId,
          rate,
          minCount,
          maxCount
        })
      }
      const needRate = selectEffectType.value === '5'
      openModal('addChestCreditModal', { confirmFunction, needRate })
    }

    const deleteChestData = (type: 'material' | 'credit', id: string) => {
      const dataArray = type === 'material' ? materialChest : creditChest
      const index = dataArray.findIndex((val) => val.info.id === id)
      dataArray.splice(index, 1)
    }

    const unWatch = debouncedWatch(props.chest, () => {
      resetValue()
      setOriginalValue()
      unWatch()
    }, { debounce: 100 })

    expose({
      submitData
    })

    return {
      selectEffectType,
      dynamicSetting,
      creditChest,
      changeEffectData,
      materialChest,
      submitData,
      addMaterial,
      addCredit,
      deleteChestData
    }
  }
})
