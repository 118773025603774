import dayjs from 'dayjs'
import sha1 from 'sha1'

const helpers = {
  sha1 (str) {
    return sha1(str)
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  formatDate(date, format) {
    return dayjs(date).format(format)
  },
  addTime(date, duration, unit) {
    return dayjs(date).add(duration, unit)
  },
  detectDeviceOs(userAgent, device) {
    const deviceMap = new Map([
      ['Windows', new RegExp(/Windows/i)],
      ['Apple', new RegExp(/iPhone|iPad|iPod|Mac/i)],
      ['Android', new RegExp(/Android/i)]
    ])

    return deviceMap.get(device)?.test(userAgent)
  },
  disableScroll () {
    /*
    * NOTE: windows 的 scroll bar 有佔寬度
    * 所以要改用 onscroll function 處理
    */
    if (this.detectDeviceOs(navigator.userAgent, 'Windows')) {
      const x = window.scrollX
      const y = window.scrollY
      window.onscroll = () => {
        window.scrollTo(x, y)
      }
    } else {
      document.body.style.overflow = 'hidden'
    }
  },
  enableScroll() {
    if (this.detectDeviceOs(navigator.userAgent, 'Windows')) {
      window.onscroll = null
    } else {
      document.body.style.overflow = 'auto'
    }
  },
  isFullscreen() {
    return document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
  },
  startFullscreen (id) {
    const dom = document.getElementById(id)
    if (dom.requestFullscreen) {
      dom.requestFullscreen()
    } else if (dom.msRequestFullscreen) {
      dom.msRequestFullscreen()
    } else if (dom.mozRequestFullScreen) {
      dom.mozRequestFullScreen()
    } else if (dom.webkitRequestFullscreen) {
      dom.webkitRequestFullscreen()
    } else {
      console.error('can\'nt full screen')
    }
  },
  cancelFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  },
  validate (data, rule) {
    /*
     * @params: data: {
     *   id: 'id',
     *   name: 'name',
     *   weight: 'weight
     * }
     *
     * @params rule: {
     *  id: { required: true, errorMessage: '' },
     *  name: { required: true, errorMessage: '' }
     *  weight: { required: true, rule: new RegExp(''), errorMessage }
     * }
     *
     * return {
     *  isValidate: boolean
     *  errorMessage: string
     * }
     */
    let errorMessage = ''
    const notValidate = Object.entries(data).some(([key, value]) => {
      if (rule[key]?.required == true && (value === '' || value === undefined || value === null || value.length <= 0)) {
        errorMessage = rule[key]?.errorMessage || ''
        return true
      } else {
        if (rule[key]?.rule && !rule[key]?.rule?.test(value)) {
          errorMessage = rule[key]?.ruleMessage || ''
          return true
        }
        return false
      }
    })
    return {
      isValidate: !notValidate,
      errorMessage
    }
  },
  findMaxZindex () {
    // 感謝 Pony 大大支援
    // 找到目前網站最大大的 zIndex
    return Math.max(1, ...Array.from(document.querySelectorAll('*')).map(el => getComputedStyle(el).zIndex).filter(v => !isNaN(parseInt(v[1]))).map(o => parseInt(o)))
  },
  filterInt (value) {
    if (/^(-|\+)?([0-9]+|Infinity)$/.test(value)) {
      return Number(value)
    }
    return NaN
  },
  uuid4 () {
    const arr = new Uint8Array(16)
    crypto.getRandomValues(arr)

    // Manipulate the 9th byte
    arr[8] &= 0b00111111 // Clear the first two bits
    arr[8] |= 0b10000000 // Set the first two bits to 10

    // Manipulate the 7th byte
    arr[6] &= 0b00001111 // Clear the first four bits
    arr[6] |= 0b01000000 // Set the first four bits to 0100

    const pattern = 'XXXXXXXX-XXXX-XXXX'
    let idx = 0

    return pattern.replace(
      /XX/g,
      () => arr[idx++].toString(16).padStart(2, '0') // padStart ensures a leading zero, if needed
    )
  }

  /*
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substr(0, rest)
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  timeAgo(time) {
    const date = new Date(
      (time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')
    )
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow(time) {
    const startDate = dayjs(
      dayjs()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    )
    const endDate = dayjs(
      dayjs(time)
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    )

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  */
}

const install = app => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
