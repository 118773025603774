import { MedalType } from '@/types/'
import * as grpcLib from '@/libs/grpc'
import * as adminMedalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/medal_pb'
import * as medalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/medal/query_pb'
import { AdminMedalPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/medal_grpc_web_pb'

grpcLib.setJkfHost(grpcLib.JkfHost.MEDAL, process.env.VUE_APP_JKF_API_HOST as string)
const MedalService = new AdminMedalPromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MEDAL))
grpcLib.EnableGrpcDebugTools(MedalService)

export const getAll = async(token: string, pageSize: number, page: number): Promise<adminMedalPb.GetAllMedalRes> => {
  const req: adminMedalPb.GetAllMedalReq = new adminMedalPb.GetAllMedalReq()
  req.setAdminToken(token)
  const paginator = new medalPb.Paginator()
  paginator.setPageSize(pageSize)
  paginator.setPage(page)
  req.setPaginator(paginator)
  const res: adminMedalPb.GetAllMedalRes = await MedalService.getAll(req)
  return grpcLib.resolveData(res)
}

export const getInfo = async (token: string, ids: Array<string>):Promise<adminMedalPb.GetInfoMedalRes> => {
  const req: adminMedalPb.GetInfoMedalReq = new adminMedalPb.GetInfoMedalReq()
  req.setAdminToken(token)
  req.setMedalIdList(ids)
  const res: adminMedalPb.GetInfoMedalRes = await MedalService.getInfo(req)
  return grpcLib.resolveData(res)
}

export const create = async(token: string, { displayName, description, img, category, canApply, enhanceAnimationImg, property, available }: MedalType.Medal):Promise<adminMedalPb.CreateMedalRes> => {
  const req: adminMedalPb.CreateMedalReq = new adminMedalPb.CreateMedalReq()
  req.setAdminToken(token)
  req.setDisplayName(displayName)
  req.setImg(img)
  req.setEnhanceAnimationImg(enhanceAnimationImg)
  req.setDesc(description)
  const categoryData = MedalType.categoryToGrpcType[category]
  req.setCategory(categoryData)
  req.setCanApply(canApply)
  req.setCraftLuck(property.craftLuck)
  req.setFurnaceLuck(property.furnaceLuck)
  req.setEnhanceLuck(property.enhanceLuck)
  req.setAvailable(available)
  const res: adminMedalPb.CreateMedalRes = await MedalService.create(req)
  return grpcLib.resolveData(res)
}

export const update = async(token: string, {
  id,
  displayName,
  description,
  img,
  enhanceAnimationImg,
  canApply,
  canEnhance,
  category,
  available,
  property
}: MedalType.Medal): Promise<adminMedalPb.UpdateMedalRes> => {
  const req: adminMedalPb.UpdateMedalReq = new adminMedalPb.UpdateMedalReq()
  const medal = new medalPb.Medal()
  const medalProperty = new medalPb.MedalProperty()
  req.setAdminToken(token)
  medal.setId(id)
  medal.setDisplayName(displayName)
  medal.setImg(img)
  medal.setEnhanceAnimationImg(enhanceAnimationImg)
  medal.setDesc(description)
  const categoryData = MedalType.categoryToGrpcType[category]
  medal.setCategory(categoryData)
  medal.setCanApply(canApply)
  medal.setCanEnhance(canEnhance)
  medalProperty.setCraftLuck(property.craftLuck)
  medalProperty.setFurnaceLuck(property.furnaceLuck)
  medalProperty.setEnhanceLuck(property.enhanceLuck)
  medal.setProperty(medalProperty)
  req.setInfo(medal)
  req.setAvailable(available)
  const res: adminMedalPb.UpdateMedalRes = await MedalService.update(req)
  return grpcLib.resolveData(res)
}

export const shipItem = async(token: string, { uid, isNotification, medalId, message }: {
  uid: Array<string>,
  isNotification: boolean,
  medalId: string,
  message: string
}): Promise<void> => {
  const req: adminMedalPb.ShipReq = new adminMedalPb.ShipReq()
  req.setAdminToken(token)
  req.setUidAllList(uid)
  req.setIsNotification(isNotification)
  req.setMedalId(medalId)
  req.setMessage(message)
  req.setAllowedList([])
  try {
    await MedalService.ship(req)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const shipItemByRole = async(token: string, { role, medalId, isNotification, message }: {
  isNotification: boolean,
  medalId: string,
  role: Array<string>,
  message: string
}): Promise<void> => {
  const req: adminMedalPb.ShipRoleReq = new adminMedalPb.ShipRoleReq()
  req.setAdminToken(token)
  req.setMedalId(medalId)
  req.setRoleList(role)
  req.setMessage(message)
  req.setIsNotification(isNotification)
  try {
    await MedalService.shipRole(req)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const itemLog = async(token: string, { uid, pageSize, page, type }: {
  uid: string,
  page: number,
  pageSize:number,
  type: MedalType.LogType
}): Promise<adminMedalPb.MedalLogAllRes> => {
  const req: adminMedalPb.MedalLogAllReq = new adminMedalPb.MedalLogAllReq()
  req.setAdminToken(token)
  const pageData = new medalPb.Paginator()
  req.setUid(uid)
  pageData.setPage(page)
  pageData.setPageSize(pageSize)
  req.setPaginator(pageData)
  req.setType(MedalType.LogToGrpc[type])
  const res: adminMedalPb.MedalLogAllRes = await MedalService.medalLogAll(req)
  return grpcLib.resolveData(res)
}

export const prepareUploadImage = async (token: string, fileInfo: string): Promise<adminMedalPb.PrepareUploadImageRes> => {
  const req: adminMedalPb.PrepareUploadImageReq = new adminMedalPb.PrepareUploadImageReq()
  req.setAdminToken(token)
  req.setFileInfo(fileInfo)
  const res = await MedalService.prepareUploadImage(req)
  return grpcLib.resolveData(res)
}
