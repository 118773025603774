import { render } from "./Main.vue?vue&type=template&id=671695b4&scoped=true"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"

import "./Main.vue?vue&type=style&index=0&id=671695b4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-671695b4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "671695b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('671695b4', script)) {
    api.reload('671695b4', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=671695b4&scoped=true", () => {
    api.rerender('671695b4', render)
  })

}

script.__file = "src/global-components/litepicker/Main.vue"

export default script