
import { UserAPI } from '@/api/index'
import UserCard from '@/components/info-card/Main.vue'
import { useLoading } from '@/plugins/loading/'
import { useModal } from '@/plugins/modals/index'
import { MemberService } from '@/services'
import { useDrawer } from '@/store/drawer'
import { loginedStatus } from '@/store/user'
import { User, getUsers } from '@/store/users'
import { helper as $h } from '@/utils/helper'
import * as panPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/pan/pan_pb'
import { ElDrawer } from 'element-plus'
import 'element-plus/dist/index.css'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  components: {
    UserCard,
    ElDrawer
  },
  setup() {
    const data = ref<Array<panPb.PanMember>>([])
    const errorMsg = ref('')
    const userMap = ref<Map<string, User>>(new Map())
    const uid = ref('')
    const phone = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const isLoading = ref(false)
    const passwordIsMatch = computed(
      () => password.value === confirmPassword.value
    )
    const passwordBlackList = ['a12345678']
    const isPasswordInBlackList = computed(() =>
      passwordBlackList.includes(password.value.toLocaleLowerCase())
    )
    const passwordRule = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/)
    const passwordValidateRule = {
      password: {
        required: true,
        rule: passwordRule,
        regexErrorMessage: '密碼至少8位數及必須包含英文和數字',
        errorMessage: '密碼不可為空'
      },
      confirmPassword: {
        required: true,
        rule: passwordRule,
        regexErrorMessage: '密碼至少8位數及必須包含英文和數字',
        errorMessage: '密碼不可為空'
      }
    }
    const passwordValidateResult = computed(
      () =>
        $h.validate(
          { password: password.value, confirmPassword: confirmPassword.value },
          passwordValidateRule
        ).isValidate &&
        passwordIsMatch.value &&
        !isPasswordInBlackList.value
    )

    const { startLoading } = useLoading()
    const { openModal } = useModal()
    const { isDrawerOpen, member, toggleDrawer } = useDrawer()
    const username = ref('username')
    const columns = ['username', 'phone', 'email', 'objectId', 'phoneId']

    const refreshUserMap = async (): Promise<void> => {
      if (data.value.length === 0) {
        userMap.value = new Map()
        return
      }

      userMap.value = await getUsers(
        data.value.map(v => v.getUid()),
        'UID'
      )
    }

    const deleteAvatar = async (uid: number): Promise<void> => {
      try {
        await MemberService.removeMemberAvatar(uid)
        await refreshUserMap()
        openModal('alertModal', { icon: 'success', message: '大頭貼移除成功' })
      } catch (e) {
        console.log(e)
        const err = e as { message: string }
        openModal('alertModal', { icon: 'warning', message: err.message })
      }
    }

    const handleDeleteAvatarClick = (uid: number) => {
      openModal('confirmModal', {
        message: '確定刪除大頭貼嗎?',
        confirmFunction: () => {
          deleteAvatar(uid)
        }
      })
    }

    const submit = async (e?: Event) => {
      if (!uid.value && !phone.value) {
        openModal('alertModal', {
          icon: 'warning',
          message: '請輸入uid或電話號碼'
        })
        return
      }
      const loading = startLoading()
      e?.preventDefault()
      try {
        const id = uid.value ? parseInt(uid.value) : 0
        const res = await UserAPI.userInfo(loginedStatus.token, id, phone.value)
        data.value = res.getPanMemberAllList()
        await refreshUserMap()
      } catch (e) {
        console.error(e)
        openModal('alertModal', { icon: 'warning', message: '出錯了' })
      } finally {
        loading.close()
      }
    }
    const submitChangePassword = async () => {
      isLoading.value = true
      if (passwordValidateResult.value) {
        try {
          await MemberService.changeMemberPassword(
            member.value.uid,
            password.value
          )
          resetValue()
          openModal('alertModal', { icon: 'success', message: '修改密碼成功' })
          isDrawerOpen.value = false
        } catch (e) {
          console.log(e)
          const err = e as { message: string }
          openModal('alertModal', { icon: 'warning', message: err.message })
          setTimeout(() => {
            resetValue()
          }, 3000)
        }
      }
    }

    const cancel = () => {
      resetValue()
      isDrawerOpen.value = false
    }
    const resetValue = () => {
      password.value = ''
      confirmPassword.value = ''
      isLoading.value = false
      errorMsg.value = ''
    }

    const defaultAvatar = 'https://www.mymyuc.net/images/noavatar_middle.gif'

    const getUserData = (
      uid: number
    ): {
      photoURL: string
      emailStatus: number
    } => {
      const userData = {
        photoURL: '',
        emailStatus: 0
      }

      const mapIter = userMap.value.values()

      for (let i = 0; i < userMap.value.size; i++) {
        const value = mapIter.next().value
        if (value.uid === uid) {
          userData.photoURL = value.photoURL || defaultAvatar
          userData.emailStatus = value.emailStatus
        }
      }

      return userData
    }

    const handleEditEmail = (uid: number, email: string) => {
      openModal('editEmailModal', {
        input: { uid, email },
        callback: {
          succeed: () => {
            openModal('alertModal', {
              icon: 'success',
              message: '修改成功'
            })
            setTimeout(async () => {
              submit()
            }, 500)
          },
          failed: (e: unknown) => {
            const err = e as { code?: number; message?: string }
            let errorMessage = err.message || ''
            if (err?.code === 1003 || err?.code === 1005) {
              errorMessage = 'Email已存在，請確認'
            }
            openModal('alertModal', {
              icon: 'warning',
              message: errorMessage
            })
          }
        }
      })
    }

    const deleteEmail = async (uid: number): Promise<void> => {
      try {
        await MemberService.changeMemberEmail(uid, '')
        await submit()
        openModal('alertModal', { icon: 'success', message: 'Email 刪除成功' })
      } catch (e) {
        const err = e as { code?: number; message?: string }
        const errorMessage = err.message || ''
        openModal('alertModal', { icon: 'warning', message: errorMessage })
      }
    }

    const handleDeleteEmail = (uid: number) => {
      openModal('confirmModal', {
        message: '確定刪除 Email 嗎?',
        confirmFunction: () => {
          deleteEmail(uid)
        }
      })
    }

    return {
      submit,
      submitChangePassword,
      cancel,
      toggleDrawer,
      isPasswordInBlackList,
      columns,
      phone,
      uid,
      data,
      username,
      isDrawerOpen,
      member,
      password,
      confirmPassword,
      passwordIsMatch,
      passwordValidateRule,
      passwordValidateResult,
      errorMsg,
      isLoading,
      getUserData,
      handleDeleteAvatarClick,
      handleEditEmail,
      handleDeleteEmail
    }
  }
})
