<template>
  <user-data :user-id="senderID" v-slot="{ displayName, photoURL, email, uid }">
    <user-big-card
      :displayName="displayName"
      :photoURL="photoURL"
      :infoColumns="infoColumns"
    >
    <template #description>
      uid: {{ uid }}
    </template>
    <template #infoTitle>
      個人資訊
    </template>
    <template #email>
      <svg-icon class="w-4 h-4 mr-2" name="mdiEmailOutline"/>
      {{ email }}
    </template>

    </user-big-card>
  </user-data>
  <div>
    <div class="chat grid grid-cols-12 gap-5 mt-5">
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
        <div class="box text-center py-5">
          <span class="font-bold text-base">聊天室列表</span>
        </div>
        <div class="chat__chat-list overflow-y-auto scrollbar-hidden pr-1 pt-1 mt-4 h-full">
          <div class="h-full flex justify-center items-center text-lg font-bold" v-if="rooms.length <= 0">
            暫無聊天室
          </div>
          <user-data
            v-for="room in rooms"
            :key="room.member"
            :user-id="room.member"
            v-slot="{ displayName, hashUid, photoURL }"
            >
            <talk-group
              class="cursor-pointer box relative flex items-center p-5 mt-5"
              :displayName="displayName"
              :hashUid="hashUid"
              :photoURL="photoURL"
              @click="openRoom(room.roomId, hashUid)"
            >
            </talk-group>
          </user-data>
        </div>
      </div>
      <!-- end side menu -->
      <!-- content -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <talking-room
          v-if="isSelectRoom"
          :roomID="selectRoom.roomID"
          :senderID="senderID"
          :receiverID="selectRoom.receiverID"
        />
        <user-data v-else :user-id="senderID" v-slot="{ displayName, photoURL }">
          <empty-room
            :displayName="displayName"
            :photoURL="photoURL"
          />
        </user-data>
      </div>
    <!--end content -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, onMounted, computed } from 'vue'
import UserData from '@/components/user/user'
import UserBigCard from '@/components/info-card/Main.vue'
import TalkGroup from '@/components/talk-groups/Main.vue'
import TalkingRoom from '@/components/talking-room/Main.vue'
import EmptyRoom from '@/components/talking-room/EmptyRoom'
import { useRoute, useRouter } from 'vue-router'
import { getUserByUid } from '@/store/users'
import { Room } from '@/services/index'
import { FirebaseType } from '@/types'
import SvgIcon from '@/components/svg-icons/Main.vue'

export default defineComponent({
  name: 'UserProfile',
  components: {
    UserData,
    UserBigCard,
    TalkGroup,
    TalkingRoom,
    EmptyRoom,
    SvgIcon
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const senderID = ref('')
    const uid: string = route.params.uid.toString()
    const rooms = reactive<Array<FirebaseType.RoomType>>([])
    const selectRoom = reactive<{ roomID: string, receiverID: string }>({
      roomID: '',
      receiverID: ''
    })
    const infoColumns = [
      'email'
    ]
    const isSelectRoom = computed(() => selectRoom.roomID !== '')
    const openRoom = (roomID: string, member: string) => {
      selectRoom.roomID = roomID
      selectRoom.receiverID = member
    }

    onMounted(async() => {
      try {
        const data = await getUserByUid(parseInt(uid))
        senderID.value = data.hashId!
        const roomData = await Room.getRoomLists(senderID.value)
        roomData.forEach((value) => {
          rooms.push(value)
        })
      } catch (e) {
        console.error(e)
        router.push({ name: 'Error' })
      }
    })
    return {
      senderID,
      rooms,
      openRoom,
      isSelectRoom,
      selectRoom,
      infoColumns
    }
  }
})
</script>

<style scoped>

</style>
