<template>
  <div class="box px-5 pt-5 mt-5">
    <div
      class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
    >
      <div
        class="flex flex-1 px-5 items-center justify-center lg:justify-start flex-col sm:flex-row"
      >
        <slot name="avatar">
          <div class="w-20 h-20 sm:w-24 sm:h-24 flex justify-center items-center lg:w-32 lg:h-32 image-fit relative mb-3 sm:mb-0">
            <img
              class="rounded-full object-cover"
              :src="newUrl"
            />
          </div>
        </slot>
        <div class="ml-5 max-w-full">
          <div class="truncate sm:whitespace-normal font-medium text-lg">
            {{ displayName }}
          </div>
          <div class="text-gray-600 text-base">
            <slot name="description"> </slot>
          </div>
        </div>
      </div>
      <div
        class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center text-lg lg:text-left lg:mt-3">
          <slot name="infoTitle">
            資訊
          </slot>
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-1"
        >
          <div
            class="truncate sm:whitespace-normal mt-3 flex items-center text-base"
            v-for="item in infoColumns"
            :key="item"
          >
            <slot :name="item"> </slot>
          </div>
        </div>
      </div>
      <slot name="operationCol"> </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue'
import { FAKE_AVATAR } from '@/utils/index'
import { toRefs } from '@vueuse/core'
export default defineComponent({
  name: 'infoCard',
  props: {
    uid: {
      type: Number,
      required: true
    },
    displayName: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    },
    infoColumns: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)
    watch(
      photoURL,
      () => {
        const img = new Image()
        img.src = photoURL.value
        img.onerror = function() {
          newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
        }
        img.onload = function() {
          newUrl.value = photoURL.value
        }
      },
      { immediate: true }
    )

    return {
      newUrl
    }
  }
})
</script>

<style scoped></style>
