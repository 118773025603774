import { render } from "./Main.vue?vue&type=template&id=38f23ad8"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "38f23ad8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('38f23ad8', script)) {
    api.reload('38f23ad8', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=38f23ad8", () => {
    api.rerender('38f23ad8', render)
  })

}

script.__file = "src/views/material/material/edit/Main.vue"

export default script