import { MaterialType, LogType } from '@/types/'
import * as grpcLib from '@/libs/grpc'
import * as adminMaterialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/material_pb'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import { AdminMaterialPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/material_grpc_web_pb'

grpcLib.setJkfHost(grpcLib.JkfHost.MATERIAL, process.env.VUE_APP_JKF_API_HOST as string)
const MaterialService = new AdminMaterialPromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MATERIAL))
grpcLib.EnableGrpcDebugTools(MaterialService)

export const getAll = async(token: string, pageSize: number, page: number): Promise<adminMaterialPb.GetAllMaterialRes> => {
  const req: adminMaterialPb.GetAllMaterialReq = new adminMaterialPb.GetAllMaterialReq()
  req.setAdminToken(token)
  const paginator = new materialPb.Paginator()
  paginator.setPageSize(pageSize)
  paginator.setPage(page)
  req.setPaginator(paginator)
  const res: adminMaterialPb.GetAllMaterialRes = await MaterialService.getAll(req)
  return grpcLib.resolveData(res)
}

export const getInfo = async(token: string, itemIds: Array<string>): Promise<adminMaterialPb.GetInfoMaterialRes> => {
  const req: adminMaterialPb.GetInfoMaterialReq = new adminMaterialPb.GetInfoMaterialReq()
  req.setAdminToken(token)
  req.setIdList(itemIds)
  const res: adminMaterialPb.GetInfoMaterialRes = await MaterialService.getInfo(req)
  return grpcLib.resolveData(res)
}

export const create = async(token: string, { weight, displayName, description, img, category, furnaceLuck, craftLuck, enhanceLuck }: MaterialType.CreateMaterial):Promise<adminMaterialPb.CreateMaterialRes> => {
  const req: adminMaterialPb.CreateMaterialReq = new adminMaterialPb.CreateMaterialReq()
  req.setAdminToken(token)
  req.setWeight(weight)
  req.setDisplayName(displayName)
  req.setDesc(description)
  req.setImg(img)
  req.setCraftLuck(craftLuck)
  req.setFurnaceLuck(furnaceLuck)
  req.setEnhanceLuck(enhanceLuck)
  const categoryData = MaterialType.categoryToGrpcType[category]
  req.setCategory(categoryData)
  const res: adminMaterialPb.CreateMaterialRes = await MaterialService.create(req)
  return grpcLib.resolveData(res)
}

export const update = async(token: string, { id, weight, displayName, description, img, category, furnaceLuck, craftLuck, enhanceLuck }: MaterialType.Material): Promise<adminMaterialPb.UpdateMaterialRes> => {
  const item = new materialPb.Item()
  const itemProperty = new materialPb.ItemProperty()
  item.setId(id)
  item.setImg(img)
  item.setWeight(weight)
  item.setDisplayName(displayName)
  item.setDesc(description)
  const categoryData = MaterialType.categoryToGrpcType[category]
  item.setCategory(categoryData)
  itemProperty.setCraftLuck(craftLuck)
  itemProperty.setFurnaceLuck(furnaceLuck)
  itemProperty.setEnhanceLuck(enhanceLuck)
  item.setProperty(itemProperty)
  const req: adminMaterialPb.UpdateMaterialReq = new adminMaterialPb.UpdateMaterialReq()
  req.setInfo(item)
  req.setAdminToken(token)
  const res: adminMaterialPb.UpdateMaterialRes = await MaterialService.update(req)
  return grpcLib.resolveData(res)
}

export const shipItem = async(token: string, { uid, isNotification, materialId, count, message }: {
  uid: Array<string>,
  isNotification: boolean,
  materialId: string,
  count: number,
  message: string
}): Promise<void> => {
  const req: adminMaterialPb.ShipReq = new adminMaterialPb.ShipReq()
  req.setAdminToken(token)
  req.setUidAllList(uid)
  req.setIsNotification(isNotification)
  req.setCount(count)
  req.setMaterialId(materialId)
  req.setMessage(message)
  req.setAllowedList([])
  try {
    await MaterialService.ship(req)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const shipItemByRole = async(token: string, { role, materialId, count, isNotification, message }: {
  isNotification: boolean
  materialId: string,
  count: number,
  role: Array<string>,
  message: string
}): Promise<void> => {
  const req: adminMaterialPb.ShipRoleReq = new adminMaterialPb.ShipRoleReq()
  req.setAdminToken(token)
  req.setCount(count)
  req.setMaterialId(materialId)
  req.setRoleList(role)
  req.setMessage(message)
  req.setIsNotification(isNotification)
  try {
    await MaterialService.shipRole(req)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const itemLog = async(token: string, { uid, page, pageSize, type }: {
  uid: string,
  page: number,
  pageSize:number,
  type: LogType.LogType
}): Promise<adminMaterialPb.ItemLogAllRes> => {
  const req: adminMaterialPb.ItemLogAllReq = new adminMaterialPb.ItemLogAllReq()
  req.setAdminToken(token)
  const pageData = new materialPb.Paginator()
  req.setUid(uid)
  pageData.setPage(page)
  pageData.setPageSize(pageSize)
  req.setPaginator(pageData)
  req.setType(LogType.LogToGrpc[type])
  const res: adminMaterialPb.ItemLogAllRes = await MaterialService.itemLogAll(req)
  return grpcLib.resolveData(res)
}

export const prepareUploadImage = async (token: string, fileInfo: string): Promise<adminMaterialPb.PrepareUploadImageRes> => {
  const req: adminMaterialPb.PrepareUploadImageReq = new adminMaterialPb.PrepareUploadImageReq()
  req.setAdminToken(token)
  req.setFileInfo(fileInfo)
  const res = await MaterialService.prepareUploadImage(req)
  return grpcLib.resolveData(res)
}
