
import { defineComponent, onMounted, PropType, ref, unref } from 'vue'
import Modal from './template.vue'
import MyInput from '@/global-components/input/Main.vue'
import { RoleType } from '@/types/'
import { createPolicyForRole, updatePolicyForRole } from '@/services/guardian'
import { useModal } from '@/plugins/modals/'
import { isHasRole } from '@/store/role'

export default defineComponent({
  name: 'addRole',
  props: {
    type: {
      type: String as PropType<'NEW' | 'EDIT'>,
      required: true
    },
    role: {
      type: Object as PropType<RoleType.Role>,
      default: RoleType.emptyRole
    },
    policyList: {
      type: Array as PropType<RoleType.Policy[]>,
      default: () => []
    },
    confirmFunction: {
      type: Function,
      required: true
    }
  },
  components: {
    Modal,
    MyInput
  },
  setup (props) {
    const { role, type, policyList } = unref(props)
    const policyKeyGroup = ref<RoleType.roleKey[]>([])
    const { openModal } = useModal('fullscreen-dom')

    const roleDisplayName = ref<string>('')
    const updateOrCreatePolicyForRole = async() => {
      try {
        if (type === 'NEW') {
          const groupData = unref(policyKeyGroup).map(val => RoleType.roleAndKeyMap[val])
          if (isHasRole(roleDisplayName.value)) {
            openModal('alertModal', { icon: 'warning', message: '重複的職位名稱' })
            return
          }
          await createPolicyForRole(roleDisplayName.value, groupData)
        } else {
          await updatePolicyForRole({
            role: role,
            newDisplayName: roleDisplayName.value,
            oldPolicyList: policyList.map(val => val),
            newPolicyList: unref(policyKeyGroup).map(val => RoleType.roleAndKeyMap[val])
          })
        }
        openModal('alertModal', { icon: 'success', message: '修改成功' })
      } catch (e) {
        openModal('alertModal', { icon: 'warning', message: e.message })
      } finally {
        props.confirmFunction()
      }
    }

    const openConfirm = (closeModal: Function) => {
      // 防呆
      if (!roleDisplayName.value || policyKeyGroup.value.length <= 0) {
        openModal('alertModal', { icon: 'warning', message: '請輸入名稱以及權限' })
        return
      }
      closeModal()
      openModal('confirmModal', { message: '確定要修改嗎', confirmFunction: updateOrCreatePolicyForRole })
    }

    onMounted(() => {
      if (type === 'EDIT') {
        roleDisplayName.value = role.displayName
        policyKeyGroup.value = policyList.map((val) => val.key as RoleType.roleKey)
      }
    })

    return {
      PolicyList: RoleType.PolicyList,
      roleDisplayName,
      updateOrCreatePolicyForRole,
      policyKeyGroup,
      openConfirm
    }
  }
})
