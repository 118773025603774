<template>
  <div class="alert alert-dark alert-dismissible show mb-2" role="alert">
    <svg-icon name="mdiAlertDecagram" /> 注意事項
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      <svg-icon name="mdiWindowClose" />
    </button>
    <div class="mt-3">
      修改權限設定時，請先點擊「切換到編輯模式」，並且請注意在任何 checkbox 上勾選或取消勾選都會立即生效
    </div>
  </div>
  <div class="flex flex-col sm:flex-row items-center">
    <select class="form-select w-full lg:w-auto lg:mt-0 sm:mr-2" v-model="domainSelValue" @change="changeDomainValue">
      <option v-for="data in DomainState.domainAll" v-bind:key="`permission-domain-sel-${data.getDomainId()}`" :value="DomainToOption(data)">
        {{ `${data.getDomainId()}: ${data.getDisplayName()}` }}
      </option>
    </select>
    <select class="form-select w-full lg:w-auto lg:mt-0 sm:mr-2" v-model="roleSelValue">
      <option v-for="data in roleAll" v-bind:key="`permission-role-sel-${data.getDomain()}-${data.getRoleId()}`" :value="RoleToOption(data)">
        {{ `${data.getRoleId()}: ${data.getDisplayName()}` }}
      </option>
    </select>
    <button class="btn btn-primary w-24 ml-2 lg:mt-0 mt-2" @click="lookupPermissionMatrix(OptionToDomain(domainSelValue), OptionToRole(roleSelValue))">查詢</button>
  </div>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
      <tr>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Object ID</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Permissions</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="[index, data] in DomainState.permObjectAll" v-bind:key="`perm-matrix-${domainSelValue}-${roleSelValue}-${index}`">
      <tr>
        <td class="border-b dark:border-dark-5">
          <div class="min-w-max">{{ index }}</div>
        </td>
        <td class="border-b dark:border-dark-5">
          <div class="grid gap-3 grid-cols-3 min-w-max">
            <div v-for="[a, action] in data.actionAll" v-bind:key="`div-perm-${index}-${a}`" class="form-check mr-2 mt-2 lg:mt-0">
              <input :id="`ckbox-perm-${index}-${a}`" class="form-check-input w-1" type="checkbox" :value="a" :disabled="data.mode === 'readonly'" :checked="action.allow === true" @change="updatePermission(OptionToRole(roleSelValue), action.rolePolicy, $event)">
              <label class="form-check-label" :for="`ckbox-perm-${index}-${a}`">{{ `${a}: ${action.rolePolicy.getSummary()}` }}</label>
            </div>
          </div>
        </td>
        <td class="border-b dark:border-dark-5">
          <div class="min-w-max">
            <button v-if="data.mode === 'readonly'" class="btn btn-danger mr-2" @click="changePermissionObjectMode(index, 'edit')">切換到編輯模式</button>
            <button v-else-if="data.mode === 'edit'" class="btn btn-warning mr-2" @click="changePermissionObjectMode(index, 'readonly')">退出編輯模式</button>
          </div>
        </td>
      </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import {
  getDomainAll,
  getRoleAllByDomain,
  updatePermission,
  DomainState,
  changePermissionObjectMode,
  lookupPermissionMatrix
} from '@/store/guardian'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { DomainToOption, OptionToDomain, RoleToOption, OptionToRole } from '@/api/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  components: {
    SvgIcon
  },
  setup() {
    const domainSelValue = ref<null | string>(null)
    const roleSelValue = ref<null | string>(null)
    const roleAll = ref<GuardianQueryPB.Role[]>([])

    onMounted(async() => {
      await getDomainAll()
      if (DomainState.domainAll.length > 0) {
        domainSelValue.value = DomainToOption(DomainState.domainAll[0])
        if (domainSelValue.value) {
          await getRoleAllByDomain(DomainState.domainAll[0])
          const value = DomainState.domainAllRoleAll.get(DomainToOption(DomainState.domainAll[0]))
          if (value) {
            roleAll.value = value
            if (value.length > 0) {
              roleSelValue.value = RoleToOption(value[0])
            }
          }
        }
      }
    })
    const changeDomainValue = async () => {
      if (domainSelValue.value) {
        const selectedDomain = OptionToDomain(domainSelValue.value)
        if (selectedDomain) {
          await getRoleAllByDomain(selectedDomain)
          const value = DomainState.domainAllRoleAll.get(DomainToOption(selectedDomain))
          if (value) {
            roleAll.value = value
            if (value.length > 0) {
              roleSelValue.value = RoleToOption(value[0])
            }
          }
        }
      }
    }
    return {
      DomainState,
      domainSelValue,
      roleSelValue,
      getRoleAllByDomain,
      lookupPermissionMatrix,
      changePermissionObjectMode,
      updatePermission,
      DomainToOption,
      OptionToDomain,
      RoleToOption,
      changeDomainValue,
      roleAll,
      OptionToRole
    }
  }
})
</script>

<style scoped>
tr:nth-child(even) {
  background-color: rgba(237, 242, 247, 1);
}
</style>
