import { render } from "./Main.vue?vue&type=template&id=b037cb54&scoped=true"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"

import "./Main.vue?vue&type=style&index=0&id=b037cb54&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-b037cb54"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b037cb54"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b037cb54', script)) {
    api.reload('b037cb54', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=b037cb54&scoped=true", () => {
    api.rerender('b037cb54', render)
  })

}

script.__file = "src/components/talking-room/Main.vue"

export default script