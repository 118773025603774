import { Firebase } from '@/api/index'
import { FirebaseType } from '@/types/index'
type SearchAllMessageParam = {
  startDate: number
  endDate: number
  isMark: boolean
  isRead: boolean
  limit: number
  order?: 'asc' | 'desc'
  hashUid: string
  initLastData? : boolean
}
let tempMessageItemData: Array<FirebaseType.MessageItem> = []
let lastVisibleData: FirebaseType.FirebaseDocData | null = null
const searchTmpValue = (() => {
  type data = {
    startDate: number
    endDate: number
    order: 'asc' | 'desc'
    isMark: boolean
    isRead: boolean
    hashUid: string
  }
  type key = keyof data
  const obj: data = {
    startDate: 0,
    endDate: 0,
    hashUid: '',
    isMark: false,
    isRead: false,
    order: 'asc'
  }
  function updateTmpValue (key: key, value: data[keyof data]) {
    // @ts-ignore
    obj[key] = value
  }
  return {
    update (key: key, value: data[keyof data]) {
      updateTmpValue(key, value)
    },
    getByKey (key: key) {
      return obj[key]
    },
    getAll () {
      return obj
    }
  }
})()

export const searchAllMessage = async({ startDate, endDate, hashUid, isMark, isRead, limit, order, initLastData }: SearchAllMessageParam) :
  Promise<void> => {
  // 重新search 所以 lastVisibleData 要重新設定
  if (initLastData) lastVisibleData = null
  let call = Firebase.store.collectionGroup('MessageAll')
    .where('Created', '>=', new Date(startDate))
    .where('Created', '<', new Date(endDate))
  if (hashUid?.trim()) call = call.where('SenderID', '==', hashUid.trim())
  if (isMark) call = call.where('Sysf1', '==', isMark)
  call = call.where('Sysf2', '==', isRead)
  // 現在是只限定根據 Created 時間去做排序
  if (order) call = call.orderBy('Created', order)
  if (lastVisibleData) call = call.startAfter(lastVisibleData)
  const res = await call.limit(limit).get()

  lastVisibleData = res.docs[res.size - 1]

  // 如果是重新 search tempData要更新，不是的話就代表是按下一頁，要把data串起來
  tempMessageItemData = initLastData ? transformIntoMessageItem(res.docs) : tempMessageItemData.concat(transformIntoMessageItem(res.docs))
}

const transformIntoMessageItem = (docs: Array<FirebaseType.FirebaseDocData>): Array<FirebaseType.MessageItem> => {
  return docs.map((doc) => createMessageItem(doc))
}

const createMessageItem = (doc: FirebaseType.FirebaseDocData): FirebaseType.MessageItem => {
  const data = doc.data()
  const replyMessageItem = data?.ReplyTo?.id ? data.ReplyTo.id : null
  const messageItem: FirebaseType.MessageItem = {
    RoomId: doc.ref.parent.parent.id,
    id: doc.id,
    ReplyTo: replyMessageItem,
    Text: data.Text,
    ImageAll: data.ImageAll,
    VideoAll: data.VideoAll,
    SenderID: data.SenderID,
    ReceiverID: data.AllowRead.filter((id: string) => id != data.SenderID)[0],
    AllowRead: data.AllowRead,
    Status: data.Status,
    Process: data.Process,
    Created: data.Created,
    Modified: data.Modified,
    ...(data.Sysf1 !== undefined ? { Sysf1: data.Sysf1 } : { Sysf1: false }),
    ...(data.Sysf2 !== undefined ? { Sysf2: data.Sysf2 } : { Sysf2: false })
  }
  return messageItem
}

export const updateTempMessageItemSysf1 = (roomId: string, docId: string, value: boolean) => {
  const index = tempMessageItemData.findIndex((val) => val.id == docId)
  // 永遠已讀
  tempMessageItemData[index].Sysf1 = value
  tempMessageItemData[index].Sysf2 = value
  updateSysf1(roomId, docId, value)
}

// 是否mark
export const updateSysf1 = (roomId: string, docId: string, value: boolean) => {
  Firebase.$collRef.roomAll.doc(roomId).collection('MessageAll').doc(docId).update({
    Sysf1: value,
    Sysf2: true
  })
}

// 是否已讀
export const updateSysf2 = (roomId: string, docId: string, value: boolean) => {
  const index = tempMessageItemData.findIndex((val) => val.id == docId)
  tempMessageItemData[index].Sysf2 = value
  Firebase.$collRef.roomAll.doc(roomId).collection('MessageAll').doc(docId).update({
    Sysf2: value
  })
}
export const getMessageByPage = async (page: number, limit: number, isPrev: boolean): Promise<Array<FirebaseType.MessageItem>> => {
  if (!isPrev) {
    const { startDate, endDate, hashUid, order, isMark, isRead } = searchTmpValue.getAll()
    await searchAllMessage({
      startDate,
      endDate,
      isMark,
      isRead,
      limit: limit,
      hashUid,
      order
    })
  }
  return spliceFirestoreArray(tempMessageItemData, (Math.max(page - 1, 0)) * limit, limit * page)
}

export const getMessageBySort = async (order: SearchAllMessageParam['order'], limit: number): Promise<Array<FirebaseType.MessageItem>> => {
  searchTmpValue.update('order', order!)
  const { startDate, endDate, hashUid, isMark, isRead } = searchTmpValue.getAll()
  await searchAllMessage({
    startDate,
    endDate,
    isMark,
    isRead,
    limit,
    hashUid,
    order,
    initLastData: true
  })
  return spliceFirestoreArray(tempMessageItemData, 0, 10)
}

export const getMessageByClickSearch = async({ startDate, endDate, hashUid, isMark, isRead, limit, order }: SearchAllMessageParam):
 Promise<Array<FirebaseType.MessageItem>> => {
  searchTmpValue.update('startDate', startDate)
  searchTmpValue.update('endDate', endDate)
  searchTmpValue.update('isMark', isMark)
  searchTmpValue.update('isRead', isRead)
  searchTmpValue.update('hashUid', hashUid.trim())
  if (order) searchTmpValue.update('order', order)
  const orderValue = searchTmpValue.getByKey('order') as SearchAllMessageParam['order']
  await searchAllMessage({
    startDate,
    endDate,
    isMark,
    isRead,
    limit: limit,
    hashUid,
    order: orderValue,
    initLastData: true
  })
  return spliceFirestoreArray(tempMessageItemData, 0, 10)
}

const spliceFirestoreArray = (array: Array<FirebaseType.MessageItem>, start: number, end: number): Array<FirebaseType.MessageItem> => {
  const result = []
  for (let i = start; i < end && i < array.length; i++) {
    result.push(array[i])
  }
  return result
}

const getRoomMessage = (docRef: FirebaseType.FirebaseDocRef, hashUid: string) => docRef.collection('MessageAll')
  .where('AllowRead', 'array-contains', hashUid)
  .orderBy('Created', 'desc')

type getMessageAllParam = {
  roomID: string,
  hashUid: string,
  limit: number,
  startAfterValue?: FirebaseType.FirebaseDocData
}
export const getMessageAll = async({ roomID, hashUid, limit, startAfterValue }:getMessageAllParam): Promise<
  {
    data: Array<FirebaseType.MessageItem>,
    lastVisibleData: FirebaseType.FirebaseDocData | null
  }
> => {
  let call = getRoomMessage(Firebase.$collRef.roomAll.doc(roomID), hashUid).limit(limit)
  if (startAfterValue) call = call.startAfter(startAfterValue)
  const doc = await call.get()
  const data = doc.docs.map((doc) => createMessageItem(doc))
  return {
    data,
    lastVisibleData: doc ? doc.docs[doc.size - 1] : null
  }
}
