import { createApp, unref, inject, provide, toRefs, reactive, h } from 'vue'
import provideInjectName, { FULLSCREEN_DOM_STRING, CLOSE_MODAL } from '@/utils/provideInject'
import Modal from './modal.vue'
import { helper as $h } from '@/utils/helper'
import { ComponentAndProps } from './propsInterface/'

type Props<T extends ComponentAndProps, K> = T extends { component: K } ? T['Props'] : never

type ExtractSimpleAction<A extends ComponentAndProps> = A extends any
  ? {} extends A['Props']
    ? A
    : never
  : never

// 把沒有 props 的挑出來
type SimpleType = ExtractSimpleAction<ComponentAndProps>
// 利用 exclude 就可以把有props的抓出來
type ComplexActionType = Exclude<ComponentAndProps, SimpleType>

export const useModal = (targetDom?: string) => {
  const fullscreenDomId = targetDom || unref(inject(provideInjectName.FULLSCREEN_DOM)) as string

  // 如果 component 沒有 props 這樣就可以 dispatch('component')
  // 後面就不用帶成這樣 dispatch('component', {})
  function dispatch<T extends SimpleType['component']>(component: T): void

  // component 是有定義props 的話是走這個
  function dispatch<T extends ComplexActionType['component']>(
    component: T,
    myProps: Props<ComponentAndProps, T>
  ): any
  // 利用 typescript function overload 去實作

  function dispatch (component: any, myProps?: any) {
    const container = document.createElement('div')
    const target = !$h.isFullscreen() ? document.body : document.getElementById(fullscreenDomId)

    const _closeModal = function () {
      vnode.unmount()
      container.parentNode!.removeChild(container)
    }
    const data = reactive({
      isShow: true,
      component,
      myProps,
      close: _closeModal
    })
    const mockModal = {
      name: 'mockModal',
      setup () {
        provide(provideInjectName.FULLSCREEN_DOM, FULLSCREEN_DOM_STRING)
        provide(CLOSE_MODAL, data.close)
        return {
          ...toRefs(data)
        }
      },
      render () {
        return h(Modal, {
          isShow: data.isShow,
          component: data.component,
          myProps: data.myProps,
          close: data.close
        })
      }
    }

    target!.appendChild(container)
    const vnode = createApp(mockModal)
    vnode.mount(container)
    return {
      closeModal: _closeModal
    }
  }
  return {
    openModal: dispatch
  }
}
