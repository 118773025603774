import { render } from "./Main.vue?vue&type=template&id=3c9b1ead"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3c9b1ead"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3c9b1ead', script)) {
    api.reload('3c9b1ead', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=3c9b1ead", () => {
    api.rerender('3c9b1ead', render)
  })

}

script.__file = "src/components/side-menu-tooltip/Main.vue"

export default script