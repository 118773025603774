
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { useModal } from '@/plugins/modals/'
import { RoleType, TableColumns } from '@/types'
import { PolicyList } from '@/types/role'
import { GuardianService } from '@/services'
import DataTable from '@/components/data-table/Main.vue'

type ColumnData = { role: RoleType.Role, policyList: RoleType.Policy[] }

export default defineComponent({
  name: 'iamRoue',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const totalPage = ref<number>(0)
    const pageSize = ref(2)
    const columns = TableColumns.ROLE_COLUMNS
    const { openModal } = useModal('fullscreen-dom')
    const tableData = reactive<ColumnData[]>([])

    const addRole = () => {
      openModal('addRole', { type: 'NEW', confirmFunction: refresh })
    }

    const editRolePolicy = (row: ColumnData) => {
      openModal('addRole', { type: 'EDIT', role: row.role, policyList: row.policyList, confirmFunction: refresh })
    }

    const isCheck = (policy: RoleType.Policy, policyList: RoleType.Policy[]):boolean => {
      return policyList.some(val => val.key == policy.key)
    }

    const refresh = async () => {
      tableData.length = 0
      const data = await GuardianService.getRolesActions()
      data.forEach(val => {
        tableData.push(val)
      })
    }

    onBeforeMount(async () => {
      await refresh()
    })

    return {
      addRole,
      page,
      totalPage,
      pageSize,
      columns,
      tableData,
      PolicyList,
      isCheck,
      editRolePolicy
    }
  }
})
