import Firebase from './firebase'
export * as Firebase from './firebase'
export * as PanAPI from './pan'
export * as UserAPI from './user'
export * as ChatAPI from './chat'
export * as AdminAPI from './admin'
export * as MaterialAPI from './material'
export * as ShopAPI from './shop'
export * as ManufactureAPI from './manufacture'
export * as MedalAPI from './medal'
export * as MedalManufactureAPI from './medalManufacture'
export * as EnhanceAPI from './enhance'
export * as EffectAPI from './effect'
export * as JKTalkAPI from './jktalk'

export const firebase = Firebase

export * as GuardianAPI from './guardian'
export * as IamAPI from './iam'
