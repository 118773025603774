import { EnableGrpcDebugTools, setJkfHost, getJkfHost, JkfHost } from '@/libs/grpc'

// eslint-disable-next-line
// @ts-ignore
import { CommandPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/command_grpc_web_pb'
// eslint-disable-next-line
// @ts-ignore
import { QueryPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_grpc_web_pb'
import * as GuardianCommandPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/command_pb'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

setJkfHost(JkfHost.GUARDIAN, process.env.VUE_APP_GUARDIAN_API_HOST as string)
const service = () => {
  const serviceCommand = new CommandPromiseClient(getJkfHost(JkfHost.GUARDIAN))
  const serviceQuery = new QueryPromiseClient(getJkfHost(JkfHost.GUARDIAN))

  EnableGrpcDebugTools(serviceCommand)
  EnableGrpcDebugTools(serviceQuery)
  return {
    GuardianCommandService: serviceCommand,
    GuardianQueryService: serviceQuery
  }
}

const Service = service()

export const CreateDomain = async(adminToken: string, domain: GuardianQueryPB.Domain): Promise<GuardianCommandPB.CommandCreateDomainRes> => {
  const req: GuardianCommandPB.CommandCreateDomainReq = new GuardianCommandPB.CommandCreateDomainReq()
  let res: GuardianCommandPB.CommandCreateDomainRes
  req.setAdminToken(adminToken)
  req.setDomain(domain)
  try {
    res = await Service.GuardianCommandService.createDomain(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const UpdateDomain = async(adminToken: string, domain: GuardianQueryPB.Domain): Promise<GuardianCommandPB.CommandUpdateDomainRes> => {
  const req: GuardianCommandPB.CommandUpdateDomainReq = new GuardianCommandPB.CommandUpdateDomainReq()
  let res: GuardianCommandPB.CommandUpdateDomainRes
  req.setAdminToken(adminToken)
  req.setDomain(domain)
  try {
    res = await Service.GuardianCommandService.updateDomain(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const QueryDomainAll = async(adminToken: string): Promise<GuardianQueryPB.QueryDomainAllRes> => {
  const req: GuardianQueryPB.QueryDomainAllReq = new GuardianQueryPB.QueryDomainAllReq()
  let res: GuardianQueryPB.QueryDomainAllRes
  req.setAdminToken(adminToken)
  try {
    res = await Service.GuardianQueryService.domainAll(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const CreatePolicyForDesign = async(adminToken: string, policy: GuardianQueryPB.RolePolicy): Promise<GuardianCommandPB.CommandCreatePolicyForDesignRes> => {
  const req: GuardianCommandPB.CommandCreatePolicyForDesignReq = new GuardianCommandPB.CommandCreatePolicyForDesignReq()
  let res: GuardianCommandPB.CommandCreatePolicyForDesignRes
  req.setAdminToken(adminToken)
  req.setPolicy(policy)
  try {
    res = await Service.GuardianCommandService.createPolicyForDesign(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const UpdatePolicyForDesign = async(adminToken: string, oldPolicy: GuardianQueryPB.RolePolicy, newPolicy: GuardianQueryPB.RolePolicy): Promise<GuardianCommandPB.CommandUpdatePolicyForDesignRes> => {
  const req: GuardianCommandPB.CommandUpdatePolicyForDesignReq = new GuardianCommandPB.CommandUpdatePolicyForDesignReq()
  let res: GuardianCommandPB.CommandUpdatePolicyForDesignRes
  req.setAdminToken(adminToken)
  req.setOldpolicy(oldPolicy)
  req.setNewpolicy(newPolicy)
  try {
    res = await Service.GuardianCommandService.updatePolicyForDesign(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const DeletePolicyForDesign = async(adminToken: string, policy: GuardianQueryPB.RolePolicy): Promise<GuardianCommandPB.CommandDeletePolicyForDesignRes> => {
  const req: GuardianCommandPB.CommandDeletePolicyForDesignReq = new GuardianCommandPB.CommandDeletePolicyForDesignReq()
  let res: GuardianCommandPB.CommandDeletePolicyForDesignRes
  req.setAdminToken(adminToken)
  req.setPolicy(policy)
  try {
    res = await Service.GuardianCommandService.deletePolicyForDesign(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const QueryActionAllByDomain = async(adminToken: string, domainID: string): Promise<GuardianQueryPB.QueryActionAllByDomainRes> => {
  const req: GuardianQueryPB.QueryActionAllByDomainReq = new GuardianQueryPB.QueryActionAllByDomainReq()
  let res: GuardianQueryPB.QueryActionAllByDomainRes
  req.setAdminToken(adminToken)
  req.setDomain(domainID)
  try {
    res = await Service.GuardianQueryService.actionAllByDomain(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const CreateRole = async(adminToken: string, { roleId, domain, displayName }: {
  roleId: string,
  domain: 'adm.jkf' | 'jkforum',
  displayName: string
}): Promise<GuardianCommandPB.CommandCreateRoleRes> => {
  const req: GuardianCommandPB.CommandCreateRoleReq = new GuardianCommandPB.CommandCreateRoleReq()
  req.setAdminToken(adminToken)
  const role = new GuardianQueryPB.Role()
  role.setRoleId(`r:${roleId}`)
  role.setDomain(domain)
  role.setDisplayName(displayName)
  req.setRole(role)
  try {
    const res: GuardianCommandPB.CommandCreateRoleRes = await Service.GuardianCommandService.createRole(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const UpdateRole = async(adminToken: string, { roleId, domain, displayName }: {
  roleId: string,
  domain: string | 'ADM後台' | 'jkforum',
  displayName: string
}): Promise<GuardianCommandPB.CommandUpdateRoleRes> => {
  const req: GuardianCommandPB.CommandUpdateRoleReq = new GuardianCommandPB.CommandUpdateRoleReq()
  let res: GuardianCommandPB.CommandUpdateRoleRes
  req.setAdminToken(adminToken)
  const role = new GuardianQueryPB.Role()
  role.setDomain(domain)
  role.setDisplayName(displayName)
  role.setRoleId(roleId)
  req.setRole(role)
  try {
    res = await Service.GuardianCommandService.updateRole(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const QueryRoleAllByDomain = async(adminToken: string, domainID: string): Promise<GuardianQueryPB.QueryRoleAllByDomainRes> => {
  const req: GuardianQueryPB.QueryRoleAllByDomainReq = new GuardianQueryPB.QueryRoleAllByDomainReq()
  let res: GuardianQueryPB.QueryRoleAllByDomainRes
  req.setAdminToken(adminToken)
  req.setDomain(domainID)
  try {
    res = await Service.GuardianQueryService.roleAllByDomain(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const QueryRoleAllByUser = async(adminToken: string, domainID: string, uid: string): Promise<GuardianQueryPB.QueryRoleAllByUserRes> => {
  const req: GuardianQueryPB.QueryRoleAllByUserReq = new GuardianQueryPB.QueryRoleAllByUserReq()
  let res: GuardianQueryPB.QueryRoleAllByUserRes
  req.setAdminToken(adminToken)
  req.setDomain(domainID)
  req.setUid(uid)
  try {
    res = await Service.GuardianQueryService.roleAllByUser(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const QueryActionAllByRole = async(adminToken: string, domainID: string, roleID: string): Promise<GuardianQueryPB.QueryActionAllByRoleRes> => {
  const req: GuardianQueryPB.QueryActionAllByRoleReq = new GuardianQueryPB.QueryActionAllByRoleReq()
  let res: GuardianQueryPB.QueryActionAllByRoleRes
  req.setAdminToken(adminToken)
  req.setDomain(domainID)
  req.setRoleId(roleID)
  try {
    res = await Service.GuardianQueryService.actionAllByRole(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const CreatePolicyForRole = async(adminToken: string, {
  roleId,
  domain,
  object,
  action,
  effect
}: {
  roleId: string,
  domain: string | 'ADM後台' | 'jkforum',
  object: string,
  action: string,
  effect: string | 'allow' | 'deny'
}): Promise<GuardianCommandPB.CommandCreatePolicyForRoleRes> => {
  const req: GuardianCommandPB.CommandCreatePolicyForRoleReq = new GuardianCommandPB.CommandCreatePolicyForRoleReq()
  const policy = new GuardianQueryPB.RolePolicy()
  policy.setRoleId(roleId)
  policy.setDomain(domain)
  policy.setObject(object)
  policy.setAction(action)
  policy.setEffect(effect)

  // NOTE: 目前沒意義
  policy.setSummary('')
  policy.setExtraValue('')

  req.setAdminToken(adminToken)
  req.setPolicy(policy)
  try {
    const res: GuardianCommandPB.CommandCreatePolicyForRoleRes = await Service.GuardianCommandService.createPolicyForRole(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const DeletePolicyForRole = async(adminToken: string, {
  roleId,
  domain,
  object,
  action,
  effect
}: {
  roleId: string,
  domain: string | 'ADM後台' | 'jkforum',
  object: string,
  action: string,
  effect: string | 'allow' | 'deny'
}): Promise<GuardianCommandPB.CommandDeletePolicyForRoleRes> => {
  const req: GuardianCommandPB.CommandDeletePolicyForRoleReq = new GuardianCommandPB.CommandDeletePolicyForRoleReq()
  let res: GuardianCommandPB.CommandDeletePolicyForRoleRes
  req.setAdminToken(adminToken)

  const policy = new GuardianQueryPB.RolePolicy()
  policy.setRoleId(roleId)
  policy.setDomain(domain)
  policy.setObject(object)
  policy.setAction(action)
  policy.setEffect(effect)

  // NOTE: 目前沒意義
  policy.setSummary('')
  policy.setExtraValue('')
  req.setPolicy(policy)
  try {
    res = await Service.GuardianCommandService.deletePolicyForRole(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const RemoveRoleForUser = async(adminToken: string, { email, roleId, domain }: {
  email: string
  roleId: string
  domain: string
}): Promise<GuardianCommandPB.CommandRemoveRoleForUserRes> => {
  const req: GuardianCommandPB.CommandRemoveRoleForUserReq = new GuardianCommandPB.CommandRemoveRoleForUserReq()
  let res: GuardianCommandPB.CommandRemoveRoleForUserRes
  req.setAdminToken(adminToken)
  req.setDomain(domain)
  req.setRoleId(roleId)
  req.setUid(email)
  try {
    res = await Service.GuardianCommandService.removeRoleForUser(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const AddRoleForUser = async(adminToken: string, { email, roleId, domain }: {
  email: string
  roleId: string
  domain: string
}): Promise<GuardianCommandPB.CommandAddRoleForUserRes> => {
  const role = new GuardianQueryPB.RoleUser()
  role.setUid(email)
  role.setRoleId(roleId)
  role.setDomain(domain)
  const req: GuardianCommandPB.CommandAddRoleForUserReq = new GuardianCommandPB.CommandAddRoleForUserReq()
  let res: GuardianCommandPB.CommandAddRoleForUserRes
  req.setAdminToken(adminToken)
  req.setRoleuser(role)
  try {
    res = await Service.GuardianCommandService.addRoleForUser(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const AdminUid = (domainID: string, email: string): string => {
  return domainID + ':' + email
}

export const OptionToRole = (optionValue: string): GuardianQueryPB.Role => {
  const valueAll = optionValue.split(',')
  const role = new GuardianQueryPB.Role()
  role.setDomain(valueAll[0])
  role.setRoleId(valueAll[1])
  role.setDisplayName(valueAll[2])
  return role
}

export const RoleToOption = (role: GuardianQueryPB.Role): string => {
  return role.getDomain() + ',' + role.getRoleId() + ',' + role.getDisplayName()
}

export const OptionToDomain = (optionValue: string): GuardianQueryPB.Domain | null => {
  if (!optionValue) {
    return null
  }
  const valueAll = optionValue.split(',')
  const domain = new GuardianQueryPB.Domain()
  domain.setDomainId(valueAll[0])
  domain.setDisplayName(valueAll[1])
  return domain
}

export const DomainToOption = (domain: GuardianQueryPB.Domain): string => {
  return domain.getDomainId() + ',' + domain.getDisplayName()
}
