import { render } from "./Main.vue?vue&type=template&id=df59fa4e"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "df59fa4e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('df59fa4e', script)) {
    api.reload('df59fa4e', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=df59fa4e", () => {
    api.rerender('df59fa4e', render)
  })

}

script.__file = "src/views/login/Main.vue"

export default script