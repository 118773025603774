
import { defineComponent, reactive, computed, unref, onBeforeMount, ref } from 'vue'
import InfoCard from '@/components/info-card/Main.vue'
import { useRoute } from 'vue-router'
import myRouter from '@/router/useRouter'
import ItemData from '@/components/data/itemOrMedal'
import { TableColumns } from '@/types/'
import { helper as $h } from '@/utils/helper'
import { useModal } from '@/plugins/modals/'
import { useLoading } from '@/plugins/loading/'
import { ShopService } from '@/services'

export default defineComponent({
  components: {
    InfoCard,
    ItemData
  },
  setup () {
    const route = useRoute()
    const { toShopPage } = myRouter()
    const { openModal } = useModal()
    const { startLoading } = useLoading()
    const itemId: string = route.params.id as string
    const OPTIONS = TableColumns.CREDIT_TYPE
    const state = ref<'new' | 'edit'>('new')
    const stateMethod = computed(() => {
      const isEdit = state.value === 'edit'
      const btnText = isEdit ? '儲存變更' : '新增商品'
      const successText = isEdit ? '編輯完成' : '新增成功'
      const API = isEdit ? ShopService.updateShopItem : ShopService.createShopItem
      return {
        btnText,
        successText,
        API
      }
    })

    const shopInfo = reactive({
      creditId: '1',
      creditValue: '',
      stock: ''
    })

    onBeforeMount(async() => {
      const data = await ShopService.getShopItemInfo([itemId])
      if (data.length <= 0) {
        state.value = 'new'
        return false
      }
      state.value = 'edit'
      shopInfo.creditId = data[0].creditType.id
      shopInfo.creditValue = data[0].creditValue.toString()
      shopInfo.stock = data[0].stock?.toString() || ''
    })

    const columns = [
      'ID',
      'weight'
    ]

    const validateRule = {
      creditId: {
        required: true,
        errorMessage: '請選擇幣值'
      },
      creditValue: {
        required: true,
        rule: new RegExp(/^([1-9][0-9]*)$/),
        errorMessage: '請輸入價格',
        ruleMessage: '請輸入正確的數字格式'
      },
      stock: {
        required: true,
        rule: new RegExp(/^(0|[1-9][0-9]*)$/),
        errorMessage: '請輸入庫存',
        ruleMessage: '請輸入正確的數字格式'
      }
    }

    const submit = async(e: Event) => {
      e.preventDefault()
      const fn = unref(stateMethod)
      const data = {
        id: itemId,
        creditId: shopInfo.creditId,
        creditValue: shopInfo.creditValue,
        stock: shopInfo.stock
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      // 驗證沒過
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      const loading = startLoading()
      try {
        const transformData = {
          id: itemId,
          // TODO: 後端需要implement
          creditType: { id: shopInfo.creditId, displayName: OPTIONS.find(v => v.id === shopInfo.creditId)!.displayName },
          creditValue: parseInt(shopInfo.creditValue),
          stock: parseInt(shopInfo.stock)
        }
        await fn.API(transformData)
        openModal('alertModal', { icon: 'success', message: fn.successText })
        toShopPage()
      } catch (e) {
        console.error(e.message)
        openModal('alertModal', { icon: 'success', message: '出錯了' })
      } finally {
        loading.close()
      }
    }

    const deleteShop = () => {
      const deleateAPI = async() => {
        const loading = startLoading()
        try {
          await ShopService.softDelete(itemId)
          openModal('alertModal', { icon: 'success', message: '下架成功' })
          toShopPage()
        } catch (e) {
          console.error(e.message)
          openModal('alertModal', { icon: 'success', message: '出錯了' })
        } finally {
          loading.close()
        }
      }
      openModal('confirmModal', { message: '確定要下架商品嗎?', confirmFunction: deleateAPI })
    }

    return {
      columns,
      itemId,
      shopInfo,
      validateRule,
      OPTIONS,
      submit,
      stateMethod,
      state,
      deleteShop
    }
  }
})
