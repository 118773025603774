import { render } from "./Main.vue?vue&type=template&id=6c5caa72"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6c5caa72"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6c5caa72', script)) {
    api.reload('6c5caa72', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=6c5caa72", () => {
    api.rerender('6c5caa72', render)
  })

}

script.__file = "src/layouts/side-menu/Main.vue"

export default script