
import { defineComponent, watch, ref } from 'vue'
import { FAKE_AVATAR } from '@/utils/index'
import { toRefs } from '@vueuse/core'
export default defineComponent({
  name: 'infoCard',
  props: {
    uid: {
      type: Number,
      required: true
    },
    displayName: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    },
    infoColumns: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)
    watch(
      photoURL,
      () => {
        const img = new Image()
        img.src = photoURL.value
        img.onerror = function() {
          newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
        }
        img.onload = function() {
          newUrl.value = photoURL.value
        }
      },
      { immediate: true }
    )

    return {
      newUrl
    }
  }
})
