<template>
  <div :class="[$attrs.class]">
    <label class="form-label text-lg mb-0">{{ label }}</label>
    <select
      ref="select"
      v-bind="attr"
      class="form-control text-lg"
      @change="handleChange"
      :class="{'border-red-600': state !== 'OK'}"
    >
      <slot>
        <option value="creditId1">名聲</option>
        <option value="creditId2">j幣</option>
        <option value="creditId3">點數</option>
        <option value="creditId4">愛心</option>
        <option value="creditId5">寶石</option>
        <option value="creditId6">送出</option>
        <option value="creditId7">體力</option>
        <option value="creditId8">邀請</option>
      </slot>
    </select>
    <p class="ml-2 text-red-600" style="min-height: 20px">
      <span v-if="state !=='OK'">{{ errorMessage }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance, computed, toRefs, watch, onMounted } from 'vue'
export default defineComponent({
  inheritAttrs: false,
  name: 'MySelect',
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: ''
    },
    initValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { required } = toRefs(props)
    const state = ref<'OK' | 'error'>('OK')
    const instance = getCurrentInstance()
    const DEFAULT_EXCLUDE_KEYS = ['class', 'style']
    const select = ref<HTMLInputElement | null>(null)
    // 過濾掉 class style
    const attr = computed(() => {
      return Object.fromEntries(
        Object.entries(instance!.proxy?.$attrs!).filter(
          ([key]) => !DEFAULT_EXCLUDE_KEYS.includes(key)
        ))
    })

    const setNativeInputValue = (value: any) => {
      const input = select.value
      if (input !== null) {
        input.value = value
      }
    }

    const validate = (value: any) => {
      // 簡易防呆
      const debounce = setTimeout(() => {
        if (required.value == true && value === '') {
          state.value = 'error'
        } else {
          state.value = 'OK'
        }
        clearTimeout(debounce)
      }, 300)
    }

    const handleChange = (event: Event) => {
      // @ts-ignore
      const { value } = event.target
      validate(value)
      emit('update:modelValue', value)
    }

    watch(
      () => props.modelValue,
      () => setNativeInputValue(props.modelValue)
    )

    onMounted(() => {
      setNativeInputValue(props.modelValue)
    })

    return {
      handleChange,
      select,
      attr,
      state
    }
  }
})
</script>

<style scoped>

</style>
