<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2 justify-end"
      >
        <div
          class="w-full flex sm:justify-end mt-3 sm:mt-0 sm:ml-auto md:ml-0 items-center"
        >
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-3">
            <input
              type="text"
              v-model="searchText"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="搜尋會員"
            />
          </div>
          <div class="">
            <button
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="search"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-center col-span-12" v-if="userHashId">
        <user-data
          :user-id="userHashId"
          v-slot="{ displayName, uid, hashUid, photoURL }"
        >
          <userCard
            :displayName="displayName"
            :uid="uid"
            :hashUid="hashUid"
            :photoURL="photoURL"
          />
        </user-data>
      </div>
      <div class="text-center col-span-12" v-else>
        <span class="text-xl font-bold">無資料</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import * as Users from '@/store/users'
import UserData from '@/components/user/user'
import UserCard from '@/components/user-card/Main.vue'
import { useLoading } from '@/plugins/loading/'

export default defineComponent({
  name: 'SearchUser',
  components: {
    UserData,
    UserCard
  },
  setup() {
    const user = reactive([
      {
        name: 'Neil3',
        uid: '98832'
      }
    ])
    const searchText = ref<string>('')
    const userHashId = ref<string>('')
    const { startLoading } = useLoading()
    const search = async () => {
      const uid = searchText.value
      // 簡易防呆
      if (uid !== '' && (isNaN(Number(uid)) || parseInt(uid) <= 0)) {
        alert('無效的UID')
      }
      const load = startLoading()
      try {
        userHashId.value = await Users.getHashIdByUid(parseInt(uid))
      } catch (e) {
        console.error(e)
        if (e == 'not found') alert('無效的UID')
      } finally {
        load.close()
      }
    }

    return {
      user,
      searchText,
      search,
      userHashId
    }
  }
})
</script>

<style scoped></style>
