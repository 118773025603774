
import { computed, defineComponent, PropType, unref } from 'vue'
import Modal from './template.vue'
import SvgIcon from '../../components/svg-icons/Main.vue'
export default defineComponent({
  name: 'alertModal',
  components: {
    Modal,
    SvgIcon
  },
  props: {
    icon: {
      type: String as PropType<'warning' | 'success'>,
      required: true
    },
    message: {
      type: String,
      default: 'ERROR'
    }
  },
  setup (props) {
    const icon = unref(props.icon)
    const iconComponent = computed(() => {
      return icon == 'warning' ? 'mdiAlphaXCircleOutline' : 'mdiCheckCircleOutline'
    })
    const iconColor = computed(() => icon == 'warning' ? 'text-theme-12' : 'text-btn-success')
    return {
      iconComponent,
      iconColor
    }
  }
})
