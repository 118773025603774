
import { helper as $h } from '@/utils/helper'
import { defineComponent, ref } from 'vue'
import { useModal } from '@/plugins/modals/index'

import MyInput from '@/global-components/input/Main.vue'
import Modal from './template.vue'

export default defineComponent({
  components: {
    Modal,
    MyInput
  },
  props: {
    confirmFunction: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const uid = ref('')
    const cause = ref('')
    const validateRule = {
      uid: {
        required: true,
        errorMessage: '請填寫 UID'
      },
      cause: {
        required: true,
        errorMessage: '請填寫原因'
      }
    }

    const openConfirm = () => {
      // 防呆
      const data = {
        uid: uid.value,
        cause: cause.value
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        const { openModal } = useModal()
        openModal('alertModal', {
          icon: 'warning',
          message: errorMessage
        })
        return
      }

      props.confirmFunction(data)
    }

    return {
      uid,
      cause,
      validateRule,
      openConfirm
    }
  }
})
