import { render } from "./Main.vue?vue&type=template&id=7a45230b"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7a45230b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7a45230b', script)) {
    api.reload('7a45230b', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=7a45230b", () => {
    api.rerender('7a45230b', render)
  })

}

script.__file = "src/views/medal/Main.vue"

export default script