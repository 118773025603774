import { render } from "./addChestMaterialModal.vue?vue&type=template&id=00624776&scoped=true"
import script from "./addChestMaterialModal.vue?vue&type=script&lang=ts"
export * from "./addChestMaterialModal.vue?vue&type=script&lang=ts"

import "./addChestMaterialModal.vue?vue&type=style&index=0&id=00624776&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-00624776"
/* hot reload */
if (module.hot) {
  script.__hmrId = "00624776"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('00624776', script)) {
    api.reload('00624776', script)
  }
  
  module.hot.accept("./addChestMaterialModal.vue?vue&type=template&id=00624776&scoped=true", () => {
    api.rerender('00624776', render)
  })

}

script.__file = "src/plugins/modals/addChestMaterialModal.vue"

export default script