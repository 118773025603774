import { render } from "./Main.vue?vue&type=template&id=b0077c74"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "b0077c74"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b0077c74', script)) {
    api.reload('b0077c74', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=b0077c74", () => {
    api.rerender('b0077c74', render)
  })

}

script.__file = "src/global-components/loading-icon/Main.vue"

export default script