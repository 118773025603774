import * as grpcLib from '@/libs/grpc'
import * as chatPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/jkftalk/jkftalk_pb'
import { JkfTalkPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/jkftalk/jkftalk_grpc_web_pb'
grpcLib.setJkfHost(grpcLib.JkfHost.IM, process.env.VUE_APP_JKF_API_HOST as string)
const ChatService = new JkfTalkPromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.IM))

grpcLib.EnableGrpcDebugTools(ChatService)

export const getFirebaseAdminToken = async(adminToken: string): Promise<chatPb.AccessTokenResponse> => {
  const req: chatPb.AccessTokenRequest = new chatPb.AccessTokenRequest()
  req.setAdminToken(adminToken)
  const res = await ChatService.accessToken(req)
  return grpcLib.resolveData(res)
}
