import { medalRouteName } from './appRouteName'

export const medalRouter = {
  path: medalRouteName.MEDAL.path,
  component: () => import(/* webpackChunkName: "medal" */ '../views/medal/Main.vue'),
  children: [
    {
      path: medalRouteName.MEDAL.path,
      name: medalRouteName.MEDAL.name,
      meta: { title: medalRouteName.MEDAL.title },
      component: () => import(/* webpackChunkName: "medal" */ '../views/medal/medal/Main.vue')
    },
    {
      path: medalRouteName.MEDAL_EDIT.path,
      name: medalRouteName.MEDAL_EDIT.name,
      meta: { title: medalRouteName.MEDAL_EDIT.title },
      component: () => import(/* webpackChunkName: "medal" */ '../views/medal/edit/Main.vue')
    }
  ]
}

export const medalManufactureRouter = {
  path: medalRouteName.MEDAL_MANUFACTURE.path,
  component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/material/Main.vue'),
  children: [
    {
      path: medalRouteName.MEDAL_MANUFACTURE.path,
      name: medalRouteName.MEDAL_MANUFACTURE.name,
      meta: { title: medalRouteName.MEDAL_MANUFACTURE.title },
      component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/medal/manufacture/Main.vue')
    },
    {
      path: medalRouteName.MEDAL_MANUFACTURE_EDIT.path,
      name: medalRouteName.MEDAL_MANUFACTURE_EDIT.name,
      meta: { title: medalRouteName.MEDAL_MANUFACTURE_EDIT.title },
      component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/medal/manufacture/edit/Main.vue')
    },
    {
      path: medalRouteName.MEDAL_RECIPE_EDIT.path,
      name: medalRouteName.MEDAL_RECIPE_EDIT.name,
      meta: { title: medalRouteName.MEDAL_RECIPE_EDIT.title },
      component: () => import(/* webpackChunkName: "itemManufacture" */ '../views/medal/manufacture/recipeEdit/Main.vue')
    }
  ]
}

export const medalEnhanceRouter = {
  path: medalRouteName.MEDAL_ENHANCE.path,
  component: () => import(/* webpackChunkName: "itemEnhance" */ '../views/material/Main.vue'),
  children: [
    {
      path: medalRouteName.MEDAL_ENHANCE.path,
      name: medalRouteName.MEDAL_ENHANCE.name,
      meta: { title: medalRouteName.MEDAL_ENHANCE.title },
      component: () => import(/* webpackChunkName: "itemEnhance" */ '../views/medal/enhance/Main.vue')
    },
    {
      path: medalRouteName.MEDAL_ENHANCE_EDIT.path,
      name: medalRouteName.MEDAL_ENHANCE_EDIT.name,
      meta: { title: medalRouteName.MEDAL_ENHANCE_EDIT.title },
      component: () => import(/* webpackChunkName: "itemEnhance" */ '../views/medal/enhance/edit/Main.vue')
    }
  ]
}

export const medalSendRouter = {
  path: medalRouteName.SEND_MEDAL.path,
  name: medalRouteName.SEND_MEDAL.name,
  meta: { title: medalRouteName.SEND_MEDAL.title },
  component: () => import(/* webpackChunkName: "medalSend" */ '../views/send-medal/Main.vue')
}

export const medalLogRouter = {
  path: medalRouteName.MEDAL_LOG.path,
  name: medalRouteName.MEDAL_LOG.name,
  meta: { title: medalRouteName.MEDAL_LOG.title },
  component: () => import(/* webpackChunkName: "medalSend" */ '../views/medal/log/Main.vue')
}
