import * as grpcLib from '@/libs/grpc'
import * as adminMedalPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/furnace_pb'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import { AdminFurnacePromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/medal/furnace_grpc_web_pb'
import { MedalType } from '@/types'

const ManufactureService = new AdminFurnacePromiseClient(grpcLib.getJkfHost(grpcLib.JkfHost.MEDAL))
grpcLib.EnableGrpcDebugTools(ManufactureService)

export const getAllItem = async(token: string, pageSize: number, page: number): Promise<adminMedalPb.GetAllFurnaceRes> => {
  const req: adminMedalPb.GetAllFurnaceReq = new adminMedalPb.GetAllFurnaceReq()
  req.setAdminToken(token)
  const paginator = new materialPb.Paginator()
  paginator.setPageSize(pageSize)
  paginator.setPage(page)
  req.setPaginator(paginator)
  const res: adminMedalPb.GetAllFurnaceRes = await ManufactureService.getAll(req)
  return grpcLib.resolveData(res)
}

export const getManufactureInfo = async(token: string, ids: Array<string>):Promise<adminMedalPb.GetInfoFurnaceRes> => {
  const req: adminMedalPb.GetInfoFurnaceReq = new adminMedalPb.GetInfoFurnaceReq()
  req.setAdminToken(token)
  req.setMedalIdList(ids)
  const res: adminMedalPb.GetInfoFurnaceRes = await ManufactureService.getInfo(req)
  return grpcLib.resolveData(res)
}

export const create = async(token: string, { id, coins, successRate, count, consumableMaterial, inconsumableMaterial, sideProduct }:{
  id: string
  coins: number
  successRate: number
  count: number
  consumableMaterial: Array<MedalType.ConsumableMaterial>
  inconsumableMaterial: Array<MedalType.ConsumableMaterial>
  sideProduct: Array<MedalType.SideProduct>
}): Promise<adminMedalPb.CreateFurnaceRes> => {
  const req: adminMedalPb.CreateFurnaceReq = new adminMedalPb.CreateFurnaceReq()
  req.setAdminToken(token)
  req.setMedalId(id)
  req.setCoins(coins)
  req.setCount(count)
  req.setSuccessRate(successRate)
  const consumableMaterialList = consumableMaterial.map((val) => {
    const data = new adminMedalPb.ConsumableMaterial()
    data.setCount(val.count)
    data.setId(val.id)
    data.setType(val.type)
    return data
  })
  const inconsumableMaterialList = inconsumableMaterial.map((val) => {
    const data = new adminMedalPb.InconsumableMaterial()
    data.setCount(val.count)
    data.setId(val.id)
    data.setType(val.type)
    return data
  })
  const sideProductList = sideProduct.map((val) => {
    const data = new adminMedalPb.SideProduct()
    data.setId(val.id)
    data.setMinCount(val.minCount)
    data.setMaxCount(val.maxCount)
    return data
  })
  req.setConsumableMaterialList(consumableMaterialList)
  req.setInconsumableMaterialList(inconsumableMaterialList)
  req.setSideProductList(sideProductList)
  const res: adminMedalPb.CreateFurnaceRes = await ManufactureService.create(req)
  return grpcLib.resolveData(res)
}

export const update = async(token: string, { id, coins, successRate, count, consumableMaterial, inconsumableMaterial, sideProduct }:{
  id: string
  coins: number
  successRate: number
  count: number
  consumableMaterial: Array<MedalType.ConsumableMaterial>
  inconsumableMaterial: Array<MedalType.ConsumableMaterial>
  sideProduct: Array<MedalType.SideProduct>
}): Promise<adminMedalPb.UpdateFurnaceRes> => {
  const req: adminMedalPb.UpdateFurnaceReq = new adminMedalPb.UpdateFurnaceReq()
  req.setAdminToken(token)
  req.setMedalId(id)
  req.setCoins(coins)
  req.setCount(count)
  req.setSuccessRate(successRate)
  const consumableMaterialList = consumableMaterial.map((val) => {
    const data = new adminMedalPb.ConsumableMaterial()
    data.setCount(val.count)
    data.setId(val.id)
    data.setType(val.type)
    return data
  })
  const inconsumableMaterialList = inconsumableMaterial.map((val) => {
    const data = new adminMedalPb.InconsumableMaterial()
    data.setCount(val.count)
    data.setId(val.id)
    data.setType(val.type)
    return data
  })
  const sideProductList = sideProduct.map((val) => {
    const data = new adminMedalPb.SideProduct()
    data.setId(val.id)
    data.setMinCount(val.minCount)
    data.setMaxCount(val.maxCount)
    return data
  })
  req.setConsumableMaterialList(consumableMaterialList)
  req.setInconsumableMaterialList(inconsumableMaterialList)
  req.setSideProductList(sideProductList)
  const res: adminMedalPb.UpdateFurnaceRes = await ManufactureService.update(req)
  return grpcLib.resolveData(res)
}

export const deleteMedal = async(token: string, id: string): Promise<adminMedalPb.DeleteFurnaceRes> => {
  const req: adminMedalPb.DeleteFurnaceReq = new adminMedalPb.DeleteFurnaceReq()
  req.setAdminToken(token)
  req.setMedalId(id)
  const res: adminMedalPb.DeleteFurnaceRes = await ManufactureService.delete(req)
  return grpcLib.resolveData(res)
}
