<template>
  <div class="flex flex-col sm:flex-row items-center">
    <div class="mr-2">

    </div>
    <button class="btn btn-primary" @click="createDomain">新增領域</button>
  </div>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
      <tr>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Domain ID</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">顯示名稱</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(data, index) in DomainState.domainAll" v-bind:key="`domain-row-${data.getDomainId()}`">
        <tr v-if="index % 2 === 0" class="bg-gray-200 dark:bg-dark-1">
          <td class="border-b dark:border-dark-5">{{ data.getDomainId() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getDisplayName() }}</td>
          <td class="border-b dark:border-dark-5"><button class="btn btn-dark" @click="updateDomain(data)">編輯</button></td>
        </tr>
        <tr v-else>
          <td class="border-b dark:border-dark-5">{{ data.getDomainId() }}</td>
          <td class="border-b dark:border-dark-5">{{ data.getDisplayName() }}</td>
          <td class="border-b dark:border-dark-5"><button class="btn btn-dark" @click="updateDomain(data)">編輯</button></td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useModal } from '@/plugins/modals/'
import { getDomainAll, DomainState } from '@/store/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  setup() {
    const { openModal } = useModal()
    const createDomain = async(e: Event) => {
      e.preventDefault()
      openModal('editGuardianDomain',
        {
          mode: 'create',
          done: getDomainAll
        }
      )
    }
    const updateDomain = async(domain: GuardianQueryPB.Domain) => {
      openModal('editGuardianDomain',
        {
          mode: 'edit',
          domainID: domain.getDomainId(),
          displayName: domain.getDisplayName(),
          done: getDomainAll
        }
      )
    }

    onMounted(() => {
      getDomainAll()
    })
    return {
      createDomain,
      DomainState,
      updateDomain
    }
  }
})
</script>
