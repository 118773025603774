
import { computed, defineComponent, onMounted, ref, toRefs, watch, WatchStopHandle } from 'vue'
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    initValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { initValue } = toRefs(props)
    const checkbox = ref<null | HTMLInputElement>(null)
    const nativeInputValue = computed(() => {
      return (props.modelValue === null || props.modelValue === undefined) && props.initValue
        ? false
        : props.initValue || props.modelValue
    })

    const setNativeInputValue = () => {
      const value = checkbox.value
      if (value !== null) {
        value.checked = nativeInputValue.value
      }
    }

    const handleInput = (event: Event) => {
      // @ts-ignore
      const { checked } = event.target
      emit('update:modelValue', checked)
    }

    let unwatch: WatchStopHandle
    onMounted(() => {
      unwatch = watch(initValue, (value) => {
        if (value === true || value === false) {
          setNativeInputValue()
          emit('update:modelValue', nativeInputValue.value)
          unwatch()
        }
      })
    })

    return {
      checkbox,
      handleInput
    }
  }
})
