import type { Error as grpcError } from 'grpc-web'
import {
  EnableGrpcDebugTools,
  setJkfHost,
  getJkfHost,
  JkfHost
} from '@/libs/grpc'
// eslint-disable-next-line
// @ts-ignore
import { MemberServiceV2PromiseClient as MemberServicePromiseClient } from '@lctech-tw/pan-proto-first/dist/js/general/member/first_grpc_web_pb'
// eslint-disable-next-line
// @ts-ignore
import { AdminServicePromiseClient } from '@lctech-tw/pan-proto-first/dist/js/general/admin/admin_grpc_web_pb'
import * as pan from '@lctech-tw/pan-proto-first/dist/js/general/member/first_pb'
import * as atom from '@lctech-tw/pan-proto-first/dist/js/atom/member/first_pb'
import * as panAdminPB from '@lctech-tw/pan-proto-first/dist/js/general/admin/admin_pb'

export const proto = pan
export const protoAtom = atom
// @ts-ignore
proto.pan = {}

setJkfHost(JkfHost.PAN, process.env.VUE_APP_PAN_API_HOST as string)
const service = () => {
  const service = new MemberServicePromiseClient(getJkfHost(JkfHost.PAN))
  const panAdminServiceClient = new AdminServicePromiseClient(
    getJkfHost(JkfHost.PAN)
  )
  EnableGrpcDebugTools(panAdminServiceClient)
  EnableGrpcDebugTools(service)
  return {
    MemberService: service,
    AdminService: panAdminServiceClient
  }
}
export const UniversalReadObjectByHashId = async (
  hashId: Array<string>
): Promise<Array<atom.Object>> => {
  const req: pan.UniversalReadObjectRequest = new proto.UniversalReadObjectRequest()
  let res: pan.UniversalReadObjectResponse
  req.setHashIdsList([...hashId])
  try {
    res = await service().MemberService.universalReadObject(req)
  } catch (e) {
    const err = e as { code: number }
    return Promise.reject(err.code)
  }
  const list = res.getObjectsList()
  if (list.length >= 1) {
    return Promise.resolve(list)
  }
  return Promise.reject('not found') // eslint-disable-line
}

export const UniversalReadObjectByUid = async (
  uid: Array<number>
): Promise<Array<atom.Object>> => {
  const req: pan.UniversalReadObjectRequest = new proto.UniversalReadObjectRequest()
  let res: pan.UniversalReadObjectResponse
  req.setUidsList([...uid])
  try {
    service().MemberService
    res = await service().MemberService.universalReadObject(req)
  } catch (e) {
    const err = e as { code: number }
    return Promise.reject(err.code)
  }
  const list = res.getObjectsList()
  if (list.length >= 1) {
    return Promise.resolve(list)
  }
  return Promise.reject('not found') // eslint-disable-line
}

export const login = async (
  googleAccessToken: string
): Promise<string | grpcError> => {
  const req = new panAdminPB.GoogleLoginRequest()
  req.setAccessToken(googleAccessToken)
  try {
    const adminToken = await service().AdminService.googleLogin(req)
    return Promise.resolve(adminToken.getAdminToken())
  } catch (e) {
    const err = e as { code: number }
    return Promise.reject(err.code)
  }
}

export const changePassword = async (
  adminToken: string,
  uid: number,
  password: string
) => {
  const req = new panAdminPB.ChangeMemberPasswordRequest()
  let res: panAdminPB.ChangeMemberPasswordResponse
  req.setAdminToken(adminToken)
  req.setUid(uid)
  req.setPassword(password)
  try {
    const res = await service().AdminService.changeMemberPassword(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const removeMemberAvatar = async (
  adminToken: string,
  uid: number
): Promise<panAdminPB.RemoveMemberAvatarResponse> => {
  const req = new panAdminPB.RemoveMemberAvatarRequest()
  req.setAdminToken(adminToken)
  req.setUid(uid)
  try {
    const res: panAdminPB.RemoveMemberAvatarResponse = await service().AdminService.removeMemberAvatar(
      req
    )
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const changeMemberEmail = async (
  adminToken: string,
  uid: number,
  email: string
): Promise<panAdminPB.ChangeMemberEmailResponse> => {
  const req = new panAdminPB.ChangeMemberEmailRequest()
  req.setAdminToken(adminToken)
  req.setUid(uid)
  req.setEmail(email)

  try {
    const res: panAdminPB.ChangeMemberEmailResponse = await service().AdminService.changeMemberEmail(
      req
    )
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}
