import { render } from "./Main.vue?vue&type=template&id=23f34c3c"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "23f34c3c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('23f34c3c', script)) {
    api.reload('23f34c3c', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=23f34c3c", () => {
    api.rerender('23f34c3c', render)
  })

}

script.__file = "src/global-components/switch/Main.vue"

export default script