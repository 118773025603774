import { reactive } from 'vue'
import { GuardianAPI } from '@/api/index'
import { GuardianService } from '@/services/'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'
import { AdminUid, DomainToOption, OptionToRole } from '@/api/guardian'
import { GuardianType } from '@/types'

type PermAction = {
  allow: boolean,
  rolePolicy: GuardianQueryPB.RolePolicy
}

type PermObject = {
  actionAll: Map<string, PermAction>,
  mode: string
}

type State = {
  domainAll: GuardianQueryPB.Domain[]
  designPolicyAll: GuardianQueryPB.RolePolicy[]
  roleAll: Array<GuardianType.Role>
  permissionAll: GuardianQueryPB.RolePolicy[]
  permObjectAll: Map<string, PermObject>
  domainAllRoleAll: Map<string, GuardianQueryPB.Role[]>
  roleAllWithUser: Map<string, GuardianQueryPB.Role[]>
}

export const DomainState = reactive<State>({
  domainAll: [],
  designPolicyAll: [],
  roleAll: [],
  permissionAll: [],
  permObjectAll: new Map<string, PermObject>(),
  domainAllRoleAll: new Map<string, GuardianQueryPB.Role[]>(),
  roleAllWithUser: new Map<string, GuardianQueryPB.Role[]>()
})

export const getDomainAllRoleAll = async() => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }
  await getDomainAll()
  DomainState.domainAllRoleAll.clear()
  for (const value of DomainState.domainAll) {
    // const index = DomainState.domainAll.indexOf(value)
    const result = await GuardianAPI.QueryRoleAllByDomain(token, value.getDomainId())
    for (const role of result.getRoleAllList()) {
      const hasRole = DomainState.domainAllRoleAll.has(DomainToOption(value))
      let roleAll: GuardianQueryPB.Role[] = []
      if (hasRole) {
        const data = DomainState.domainAllRoleAll.get(DomainToOption(value))
        if (data) {
          roleAll = data
        }
      } else {
        DomainState.domainAllRoleAll.set(DomainToOption(value), roleAll)
      }
      roleAll.push(role)
    }
  }
}

export const getDomainAll = async() => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }
  const result = await GuardianAPI.QueryDomainAll(token)
  DomainState.domainAll = result.getDomainAllList()
}

export const getDesignPolicyAllByDomain = async(domainID: string) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }
  const result = await GuardianAPI.QueryActionAllByDomain(token, domainID)
  DomainState.designPolicyAll = result.getActionAllList()
}

export const getRoleAllByDomain = async(domain: GuardianQueryPB.Domain) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }

  const result = await GuardianAPI.QueryRoleAllByDomain(token, domain.getDomainId())
  DomainState.domainAllRoleAll.set(DomainToOption(domain), result.getRoleAllList())
}

export const getRolesByDomain = async(domainId: string) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }

  const result = await GuardianAPI.QueryRoleAllByDomain(token, domainId)
  DomainState.roleAll = result.getRoleAllList().map((val: GuardianQueryPB.Role) => GuardianService.createRolePolicy(val))
}

export const getPermissionAllByDomainRole = async(domainID: string, roleID: string) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return []
  }
  const result = await GuardianAPI.QueryActionAllByRole(token, domainID, roleID)
  DomainState.permissionAll = result.getActionAllList()
}

export const lookupPermissionMatrix = async(domain: GuardianQueryPB.Domain, role: GuardianQueryPB.Role) => {
  DomainState.permObjectAll.clear()
  DomainState.permissionAll = []
  await getDesignPolicyAllByDomain(domain.getDomainId())
  await getPermissionAllByDomainRole(domain.getDomainId(), role.getRoleId())
  DomainState.designPolicyAll.forEach(value => {
    const hasObject = DomainState.permObjectAll.has(value.getObject())
    let object: PermObject
    if (hasObject) {
      object = DomainState.permObjectAll.get(value.getObject()) || {
        actionAll: new Map<string, PermAction>(),
        mode: ''
      }
    } else {
      object = {
        actionAll: new Map<string, PermAction>(),
        mode: 'readonly'
      }
      DomainState.permObjectAll.set(value.getObject(), object)
    }
    const action: PermAction = {
      allow: false,
      rolePolicy: value
    }
    object.actionAll.set(value.getAction(), action)
  })
  DomainState.permissionAll.forEach(value => {
    const object = DomainState.permObjectAll.get(value.getObject())
    if (!object) {
      return
    }
    const action = object.actionAll.get(value.getAction())
    if (!action) {
      return
    }
    action.allow = true
  })
}

export const changePermissionObjectMode = (objectID: string, mode: string) => {
  const object = DomainState.permObjectAll.get(objectID)
  if (!object) {
    return
  }
  object.mode = mode
}

export const updatePermission = async(role: GuardianQueryPB.Role, rolePolicy: GuardianQueryPB.RolePolicy, e: any) => {
  const allow: boolean = e.target.checked
  const token = localStorage.getItem('token')
  if (!token) {
    return
  }
  rolePolicy.setRoleId(role.getRoleId())
  if (allow) {
    await GuardianAPI.CreatePolicyForRole(token, {
      roleId: rolePolicy.getRoleId(),
      domain: rolePolicy.getDomain(),
      action: rolePolicy.getAction(),
      object: rolePolicy.getObject(),
      effect: rolePolicy.getEffect()
    })
  } else {
    await GuardianAPI.DeletePolicyForRole(token, {
      roleId: rolePolicy.getRoleId(),
      domain: rolePolicy.getDomain(),
      action: rolePolicy.getAction(),
      object: rolePolicy.getObject(),
      effect: rolePolicy.getEffect()
    })
  }
  const o = DomainState.permObjectAll.get(rolePolicy.getObject())
  if (o) {
    const a = o.actionAll.get(rolePolicy.getAction())
    if (a) {
      a.allow = allow
    }
  }
}

export const updateRoleForUser = async(domainID: string, uid: string, rolesToAdd: string[], rolesToRemove: string[], justSelected: string[]) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return
  }
  for (const v of rolesToAdd) {
    const role = OptionToRole(v)
    const roleUser = new GuardianQueryPB.RoleUser()
    roleUser.setDomain(role.getDomain())
    roleUser.setRoleId(role.getRoleId())
    roleUser.setUid(uid)
    // await GuardianAPI.AddRoleForUser(token, roleUser)
  }
  for (const v of rolesToRemove) {
    const role = OptionToRole(v)
    // await GuardianAPI.RemoveRoleForUser(token, role.getDomain(), role.getRoleId(), uid)
  }

  const updates: GuardianQueryPB.Role[] = []
  justSelected.forEach((v, i) => {
    const role = OptionToRole(v)
    updates.push(role)
  })
  DomainState.roleAllWithUser.set(AdminUid(domainID, uid), updates)
}
