import { createApp } from 'vue'
import App from '@/App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import * as userStore from '@/store/user'
// table
import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-table.css'
import 'element-plus/theme-chalk/el-checkbox.css'
import 'element-plus/theme-chalk/el-tag.css'
import 'element-plus/theme-chalk/el-tooltip.css'
import 'element-plus/theme-chalk/el-popper.css'
// loading
import 'element-plus/theme-chalk/el-loading.css'
import './libs'
import './assets/sass/app.scss'

// 等待驗證完畢才可以繼續
async function loginProcess () {
  await userStore.loadAllLocalStorageData()
}

loginProcess().catch(e => {
  console.error(e)
  localStorage.removeItem('token')
}).finally(() => {
  const app = createApp(App).use(router).use(store)
  globalComponents(app)
  utils(app)
  app.mount('#app')
})
