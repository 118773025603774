<template>
  <Modal position="center">
    <template #body>
      <div class="flex flex-col">
        <div class="text-base mt-5 mb-2 text-left border-b pb-3">選擇道具</div>
        <loading-items v-slot="{ loadingData, items, state }">
          <infinite-scroll :loadingData="loadingData">
            <div v-if="state !== 'done'" class="w-full h-full animate-pulse bg-gray-300"></div>
            <div
              v-else
              class="border-b border-grey-200 py-3 px-5 cursor-pointer grid grid-cols-2"
              :class="{ 'bg-gray-300': isSelect == item.id }"
              v-for="(item, index) in filter(items)"
              :key="index"
              v-memo="[item.id === isSelect]"
              @click="selectNewItem(item.id)"
            >
              <div class="flex justify-start">
                <img :src="item.img" width="30" class="img" />
              </div>
              <div class="flex justify-start items-center">{{ item.displayName }}</div>
            </div>
          </infinite-scroll>
        </loading-items>
      </div>
      <my-input
        v-if="needRate"
        class="flex flex-col items-start mt-3"
        type="number"
        required
        :errorMessage="validateRule.rate.errorMessage"
        :requireRule="validateRule.rate.requireRule"
        :regexErrorMessage="validateRule.rate.regexErrorMessage"
        label="機率"
        v-model="rate"
      />
      <div>
        <h2 class="text-left text-lg mb-1">取得數量區間</h2>
        <div class="flex items-center flex-wrap">
          <input v-model="minCount" type="number" class="form-control w-32 sm:w-44 m-1" />
          <span>～</span>
          <input v-model="maxCount" type="number" class="form-control w-32 sm:w-44 m-1" />
        </div>
      </div>
    </template>
    <template v-slot:footer="{ closeModal }">
      <button class="mx-1 btn btn-primary py-3 px-4 w-full align-top" @click="submit(closeModal)">新增</button>
      <button class="mx-1 btn btn-secondary py-3 px-4 w-full align-top" @click="closeModal">取消</button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs } from 'vue'
import { useModal } from '@/plugins/modals/index'
import { helper as $h } from '@/utils/helper'
import Modal from './template.vue'
import InfiniteScroll from '@/components/infinite-scroll/Main.vue'
import LoadingItems from '@/components/load-items/main'
import MyInput from '@/global-components/input/Main.vue'

export default defineComponent({
  components: {
    Modal,
    InfiniteScroll,
    LoadingItems,
    MyInput
  },
  props: {
    needRate: {
      type: Boolean,
      required: true
    },
    filterItemIds: {
      type: Array,
      required: true
    },
    confirmFunction: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const { filterItemIds } = toRefs(props)
    const isSelect = ref<null | string>(null)
    const { openModal } = useModal('fullscreen-dom')
    const rate = ref(0)
    const minCount = ref(1)
    const maxCount = ref(1)
    const validateRule = {
      id: {
        required: true,
        errorMessage: '請選擇道具'
      },
      rate: {
        required: true,
        requireRule: new RegExp(/^([1-9][0-9]*)$/),
        errorMessage: '請輸入機率',
        regexErrorMessage: '請輸入正確的數字格式'
      }
    }

    const filter = (items: Array<any>) => {
      return items.filter((val) => !filterItemIds.value.includes(val.id))
    }

    const selectNewItem = (item: string) => {
      isSelect.value = item
    }

    const submit = (closeModal: Function) => {
      // 不需要機率
      const data = {
        id: isSelect.value,
        rate: props.needRate ? rate.value : 100
      }
      const { isValidate, errorMessage } = $h.validate(data, validateRule)
      if (!isValidate) {
        openModal('alertModal', { message: errorMessage, icon: 'warning' })
        return false
      }
      if (maxCount.value < minCount.value) {
        const message = '數量區間填寫錯誤'
        openModal('alertModal', { message, icon: 'warning' })
        return false
      }
      props.confirmFunction({
        materialId: data.id,
        rate: data.rate,
        minCount: minCount.value,
        maxCount: maxCount.value
      })
      closeModal()
    }

    return {
      selectNewItem,
      isSelect,
      rate,
      minCount,
      maxCount,
      validateRule,
      submit,
      filter
    }
  }
})
</script>

<style scoped>
.img {
  width: 30px;
  height: 30px;
}
</style>
