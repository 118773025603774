import provideInjectName from '@/utils/provideInject'
import { inject, unref } from 'vue'
import { ElLoading } from 'element-plus'
import { helper as $h } from '@/utils/helper'
export const useLoading = () => {
  const fullscreenDomId = unref(inject(provideInjectName.FULLSCREEN_DOM))
  const startLoading = () => {
    const options = {
      ...($h.isFullscreen() ? { target: `#${fullscreenDomId}` } : {})
    }
    return ElLoading.service(options)
  }
  return {
    startLoading
  }
}
