
import { defineComponent, onMounted } from 'vue'
export default defineComponent({
  name: 'GoogleLogin',
  emits: ['onSuccess'],
  setup (props, { emit }) {
    const onSuccess = (googleUser: any) => {
      emit('onSuccess', googleUser)
    }
    onMounted(() => {
      async function handleCredentialResponse(response: any) {
        // response.credential 就是 id token
        emit('onSuccess', response)
      }
      // @ts-ignore
      google.accounts.id.initialize({ // eslint-disable-line
        client_id: '635335492910-od4gv35nnslcnhmat6u6jb1si68baa04.apps.googleusercontent.com',
        callback: handleCredentialResponse
      })
      // @ts-ignore
      google.accounts.id.renderButton( // eslint-disable-line
        document.getElementById('buttonDiv'),
        {
          theme: 'outline'
        }
      )
      // @ts-ignore
      google.accounts.id.prompt() // eslint-disable-line
    })
    return {
      onSuccess
    }
  }
})
