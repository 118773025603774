import { render } from "./Main.vue?vue&type=template&id=383f25f4"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "383f25f4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('383f25f4', script)) {
    api.reload('383f25f4', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=383f25f4", () => {
    api.rerender('383f25f4', render)
  })

}

script.__file = "src/components/user-card/Main.vue"

export default script