import { render } from "./Main.vue?vue&type=template&id=332f4a74&scoped=true"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"

import "./Main.vue?vue&type=style&index=0&id=332f4a74&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-332f4a74"
/* hot reload */
if (module.hot) {
  script.__hmrId = "332f4a74"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('332f4a74', script)) {
    api.reload('332f4a74', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=332f4a74&scoped=true", () => {
    api.rerender('332f4a74', render)
  })

}

script.__file = "src/global-components/input/Main.vue"

export default script