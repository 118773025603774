
import { defineComponent, nextTick, onBeforeMount, reactive, ref } from 'vue'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { MaterialType, TableColumns } from '@/types'
import { MaterialService } from '@/services/index'
export default defineComponent({
  name: 'Item',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const totalPage = ref<number>(0)
    const pageSize = ref(10000)
    // TODO: 未來會有類別
    const columns = TableColumns.ITEM
    const data = reactive<Array<MaterialType.Material>>([])
    const { toEditItemPage } = useRouter()
    // init
    onBeforeMount(async() => {
      const result = await MaterialService.getMaterialList(pageSize.value, page.value)
      result.data.forEach(val => {
        data.push(val)
      })
      totalPage.value = result.totalPage
      await nextTick()
    })

    const resetData = () => {
      data.length = 0
    }

    // 換頁
    const getMaterialByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MaterialService.getMaterialList(pageSize.value, page.value)
      resetData()
      result.data.forEach(val => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    return {
      columns,
      data,
      page,
      pageSize,
      toEditItemPage,
      getMaterialByPage,
      totalPage
    }
  }
})
