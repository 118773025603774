<template>
  <div class="mb-3">
    <button type="button" class="btn btn-primary w-full md:w-32" @click="toEditMedalPage()">
      新增勳章
    </button>
  </div>
  <div>
    <data-table
      :columns="columns"
      :data="data"
      :page="page"
      :pageSize="pageSize"
    >
      <template #img="{ row }">
        <img
        :src="row.img"
        >
      </template>
      <template #action="{ row }">
        <button class="btn w-24 mr-1 mb-2 btn-rounded-success" @click="toEditMedalPage(row.id)">
        編輯勳章
        </button>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { MedalType, TableColumns } from '@/types'
import { MedalService } from '@/services/index'
export default defineComponent({
  name: 'Medal',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const columns = TableColumns.MEDAL
    const data = reactive<Array<MedalType.Medal>>([])
    const { toEditMedalPage } = useRouter()
    // init
    onBeforeMount(async() => {
      const result = await MedalService.getMedalList(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    const resetData = () => {
      data.length = 0
    }

    // 換頁
    const getMedalByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MedalService.getMedalList(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    return {
      columns,
      data,
      page,
      pageSize,
      toEditMedalPage,
      getMedalByPage,
      totalPage
    }
  }
})
</script>

<style scoped>

</style>
