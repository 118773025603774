import { render } from "./Main.vue?vue&type=template&id=4073c597"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4073c597"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4073c597', script)) {
    api.reload('4073c597', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=4073c597", () => {
    api.rerender('4073c597', render)
  })

}

script.__file = "src/views/material/Main.vue"

export default script