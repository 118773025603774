
import { defineComponent, onMounted, ref } from 'vue'
import { useModal } from '@/plugins/modals/'
import { getDomainAll, getRoleAllByDomain, DomainState } from '@/store/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'
import { DomainToOption, OptionToDomain } from '@/api/guardian'

export default defineComponent({
  setup() {
    const { openModal } = useModal()
    const domainSelValue = ref<null | string>(null)
    const roleAll = ref<GuardianQueryPB.Role[]>([])
    const createRole = async(e: Event) => {
      e.preventDefault()
      if (!domainSelValue.value) {
        return
      }
      const domain = OptionToDomain(domainSelValue.value)
      if (domain) {
        openModal('editGuardianRole',
          {
            mode: 'create',
            domainID: domain.getDomainId(),
            done: () => {
              getRoleAllByDomain(domain)
            }
          }
        )
      }
    }
    const updateRole = async(role: GuardianQueryPB.Role) => {
      if (!domainSelValue.value) {
        return
      }
      const domain = OptionToDomain(domainSelValue.value)
      if (domain) {
        openModal('editGuardianRole',
          {
            mode: 'edit',
            domainID: role.getDomain(),
            roleID: role.getRoleId(),
            displayName: role.getDisplayName(),
            done: () => {
              getRoleAllByDomain(domain)
            }
          }
        )
      }
    }
    const changeDomainValue = async () => {
      if (domainSelValue.value) {
        const selectedDomain = OptionToDomain(domainSelValue.value)
        if (selectedDomain) {
          await getRoleAllByDomain(selectedDomain)
          const value = DomainState.domainAllRoleAll.get(DomainToOption(selectedDomain))
          if (value) {
            roleAll.value = value
          }
        }
      }
    }

    onMounted(async() => {
      await getDomainAll()
      if (DomainState.domainAll.length > 0) {
        domainSelValue.value = DomainToOption(DomainState.domainAll[0])
        if (domainSelValue.value) {
          await getRoleAllByDomain(DomainState.domainAll[0])
          const value = DomainState.domainAllRoleAll.get(DomainToOption(DomainState.domainAll[0]))
          if (value) {
            roleAll.value = value
          }
        }
      }
    })
    return {
      createRole,
      updateRole,
      DomainState,
      domainSelValue,
      getRoleAllByDomain,
      OptionToDomain,
      DomainToOption,
      changeDomainValue,
      roleAll
    }
  }
})
