import firebase from '@/api/firebase'

export const firebaseLogin = async(token: string): Promise<void> => {
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    await firebase.auth().signInWithCustomToken(token)
  } catch (e) {
    console.error(`firebase login error: ${e}`)
  }
}
export const firebaseLogout = () => {
  firebase.auth().signOut().then(() => {
  // Sign-out successful.
  }).catch((error) => {
    console.error(error)
  })
}
