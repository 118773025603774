import {
  setJkfHost,
  JkfHost,
  getJkfHost,
  EnableGrpcDebugTools
} from '@/libs/grpc'

import { ManageUserPromiseClient } from '@lctech-tw/jktalk-console-proto/dist/js/jktalk/console/v1/user_grpc_web_pb'

import * as UserPB from '@lctech-tw/jktalk-console-proto/dist/js/jktalk/console/v1/user_pb'
import * as BasePB from '@lctech-tw/jktalk-console-proto/dist/js/jktalk/console/v1/base_pb'

setJkfHost(JkfHost.JKTALK, process.env.VUE_APP_JKTALK_API_HOST as string)
const service = () => {
  const JktalkUserService = new ManageUserPromiseClient(
    getJkfHost(JkfHost.JKTALK)
  )

  EnableGrpcDebugTools(JktalkUserService)

  return {
    JktalkUserService
  }
}

const Service = service()

export const getBannedUserList = async (
  adminToken: string,
  {
    uid,
    pageSize,
    pageToken
  }: {
    uid: string
    pageSize?: number
    pageToken?: string
  }
) => {
  const requestPayload: UserPB.GetUserPluBannedMessagePayload = new UserPB.GetUserPluBannedMessagePayload()
  let res: UserPB.GetUserPluBannedMessageAnswer

  requestPayload.setAdminToken(adminToken)
  requestPayload.setSearchUid(uid)
  requestPayload.setPageSize(
    typeof pageSize === 'number' ? pageSize.toString() : '30'
  )
  pageToken && requestPayload.setPageToken(pageToken)

  try {
    res = await Service.JktalkUserService.getUserPluBannedMessage(
      requestPayload
    )
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const banUser = async (
  adminToken: string,
  {
    uid,
    reason
  }: {
    uid: string
    reason: string
  }
) => {
  const requestPayload: UserPB.BanMessagePayload = new UserPB.BanMessagePayload()
  let res: BasePB.CommandState

  requestPayload.setAdminToken(adminToken)
  requestPayload.setUid(Number(uid))
  requestPayload.setReason(reason)

  try {
    res = await Service.JktalkUserService.banMessage(requestPayload)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const UnBanUser = async (
  adminToken: string,
  {
    uid
  }: {
    uid: number
  }
) => {
  const requestPayload: UserPB.UnbanMessagePayload = new UserPB.UnbanMessagePayload()
  let res: BasePB.CommandState

  requestPayload.setAdminToken(adminToken)
  requestPayload.setUid(uid)

  try {
    res = await Service.JktalkUserService.unbanMessage(requestPayload)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}
