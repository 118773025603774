
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import useRouter from '@/router/useRouter'
import DataTable from '@/components/data-table/Main.vue'
import { MedalType, TableColumns } from '@/types'
import { MedalService } from '@/services/index'
export default defineComponent({
  name: 'Medal',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const pageSize = ref(10000)
    const totalPage = ref<number>(0)
    const columns = TableColumns.MEDAL
    const data = reactive<Array<MedalType.Medal>>([])
    const { toEditMedalPage } = useRouter()
    // init
    onBeforeMount(async() => {
      const result = await MedalService.getMedalList(pageSize.value, page.value)
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    })

    const resetData = () => {
      data.length = 0
    }

    // 換頁
    const getMedalByPage = async (pages: number, isPrev: boolean) => {
      page.value = pages
      const result = await MedalService.getMedalList(pageSize.value, page.value)
      resetData()
      result.data.forEach((val) => {
        data.push(val)
      })
      totalPage.value = result.totalPage
    }

    return {
      columns,
      data,
      page,
      pageSize,
      toEditMedalPage,
      getMedalByPage,
      totalPage
    }
  }
})
