<template>
  <div v-show="isShow" class="flex items-center flex-col box-border transition-setting">
    <div class="w-full sm:w-1/2 self-start px-4">
      <my-select
        label="使用方式："
        v-model="selectEffectType"
        @change="changeEffectData"
      >
        <option value="1">
          使用後只會獲得一種道具
        </option>
        <option value="2">
          使用後可獲得一至多種道具
        </option>
        <option value="3">
          使用道具後獲得所有物品
        </option>
        <option value="4">
          使用道具後獲得積分
        </option>
        <option value="5">
          禮包模式
        </option>
      </my-select>
    </div>
    <div class="p-4 w-full" v-if="dynamicSetting.material.isShow">
      <div class="flex items-center cursor-pointer mb-3 border-b pb-3" @click="addMaterial">
        <svg-icon name="mdiPlusCircleOutline" class="mr-1 text-green-600" />
        <h2 class="font-medium text-xl">
          道具池
        </h2>
      </div>
      <div class="mx-auto my-0 w-11/12 max-w-670px">
        <data-table
            :columns="dynamicSetting.material.columns"
            border
            :data="materialChest"
            >
            <template #delete="{ row }">
              <svg-icon name="mdiTrashCanOutline" class="text-red-600 cursor-pointer" @click="deleteChestData('material', row.info?.id)" />
            </template>
          <template #rate="{ row }">
            <input
                type="text"
                v-model="row.rate"
                class="form-control"
                placeholder="機率"
                />
          </template>
          <template #displayName="{ row }">
            {{ row?.info?.displayName }}
          </template>
          <template #count="{ row }">
            <div class="flex justify-center items-center flex-wrap">
              <input
                  type="text"
                  v-model="row.minCount"
                  class="form-control w-full sm:w-20 m-1"
                  />
              <span>～</span>
              <input
                  type="text"
                  v-model="row.maxCount"
                  class="form-control w-full sm:w-20 m-1"
                  />
            </div>
          </template>
        </data-table>
      </div>
    </div>
    <div class="p-4 w-full" v-if="dynamicSetting.credit.isShow">
      <div class="flex items-center cursor-pointer mb-3 border-b pb-3" @click="addCredit">
        <svg-icon name="mdiPlusCircleOutline" class="mr-1 text-green-600" />
        <h2 class="font-medium text-xl">
          積分設定
        </h2>
      </div>
      <div class="mx-auto my-0 w-11/12 max-w-670px">
        <data-table
            :columns="dynamicSetting.credit.columns"
            border
            :data="creditChest"
            >
            <template #delete="{ row }">
              <svg-icon name="mdiTrashCanOutline" class="text-red-600 cursor-pointer" @click="deleteChestData('credit', row.info?.id)" />
            </template>
          <template #displayName="{ row }">
            {{ row?.info?.displayName }}
          </template>
          <template #count="{ row }">
            <div class="flex justify-center items-center flex-wrap">
              <input
                  type="text"
                  v-model="row.minCount"
                  class="form-control w-full sm:w-20 m-1"
                  />
              <span>～</span>
              <input
                  type="text"
                  v-model="row.maxCount"
                  class="form-control w-full sm:w-20 m-1"
                  />
            </div>
          </template>
          <template #rate="{ row }">
            <input
              type="text"
              v-model="row.rate"
              class="form-control"
              placeholder="機率"
            />
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive, PropType, toRefs } from 'vue'
import { debouncedWatch } from '@vueuse/core'
import { useModal } from '@/plugins/modals/'
import DataTable from '@/components/data-table/Main.vue'
import { TableColumns, MaterialType, EffectType } from '@/types'
import SvgIcon from '@/components/svg-icons/Main.vue'
import { getMaterial } from '@/store/material'

export default defineComponent({
  name: 'effect-setting',
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    effectType: {
      type: Number as PropType<EffectType.EffectType>,
      required: true
    },
    chest: {
      type: Object as PropType<Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>>,
      required: true
    }
  },
  components: {
    DataTable,
    SvgIcon
  },
  setup(props, { expose }) {
    const { effectType, chest } = toRefs(props)
    const { openModal } = useModal()
    const materialColumns = TableColumns.EFFECT_MATERIAL_SETTING
    const creditColumns = TableColumns.EFFECT_CREDIT_SETTING
    const selectEffectType = ref<string>('1')
    const materialChest = reactive<Array<EffectType.Chest<MaterialType.Material>>>([])
    const creditChest = reactive<Array<EffectType.Chest<MaterialType.Credit>>>([])
    const dynamicSetting = computed(() => {
      return {
        material: {
          isShow: selectEffectType.value !== '4',
          columns: selectEffectType.value === '3' || selectEffectType.value === '4' ? creditColumns : materialColumns
        },
        credit: {
          isShow: selectEffectType.value == '3' || selectEffectType.value === '4' || selectEffectType.value === '5',
          columns: selectEffectType.value === '5' ? materialColumns : creditColumns
        }
      }
    })

    const changeEffectData = (e: Event) => {
      const selectValue = (e.target as HTMLInputElement).value
      resetValue()
      // 切換到原本的資料
      // 要把道具池內的資料重新顯示
      if (selectValue === props.effectType.toString()) {
        setOriginalValue()
      }
    }

    const resetValue = () => {
      materialChest.splice(0)
      creditChest.splice(0)
    }

    const setOriginalValue = () => {
      selectEffectType.value = effectType.value.toString()
      chest.value.forEach(val => {
        if (val.chestType === EffectType.ChestType.CHEST_TYPE_MATERIAL) {
          // @ts-ignore
          // 不懂為何會出錯？
          materialChest.push(val)
        } else {
          creditChest.push(val)
        }
      })
    }

    const submitData = ():{
      effectType: EffectType.EffectType,
      chest: Array<EffectType.Chest<MaterialType.Material | MaterialType.Credit>>
    } => {
      const chest = [...materialChest, ...creditChest]
      return {
        effectType: parseInt(selectEffectType.value),
        chest
      }
    }

    const addMaterial = () => {
      const filterItemIds = materialChest.map((val) => val.info.id)
      const confirmFunction = async ({ materialId, rate, minCount, maxCount }: {
        materialId: string,
        rate: number,
        minCount: number,
        maxCount: number
      }) => {
        const material = await getMaterial([materialId])
        materialChest.push({
          info: material.get(materialId)!,
          chestType: EffectType.ChestType.CHEST_TYPE_MATERIAL,
          rate,
          minCount,
          maxCount
        })
      }
      const needRate = selectEffectType.value !== '3'
      openModal('addChestMaterialModal', { confirmFunction, filterItemIds, needRate })
    }

    const addCredit = () => {
      // 只有使用後獲得積分的型態要限制
      if (creditChest.length >= 1 && selectEffectType.value === '4') {
        openModal('alertModal', { icon: 'warning', message: '一次只能設定一種積分' })
        return false
      }
      const confirmFunction = ({ creditId, minCount, maxCount, rate }: { creditId: EffectType.ChestType, minCount: number, maxCount: number, rate: number }) => {
        const credit = MaterialType.getCredit(creditId.toString())
        creditChest.push({
          info: credit,
          chestType: creditId,
          rate,
          minCount,
          maxCount
        })
      }
      const needRate = selectEffectType.value === '5'
      openModal('addChestCreditModal', { confirmFunction, needRate })
    }

    const deleteChestData = (type: 'material' | 'credit', id: string) => {
      const dataArray = type === 'material' ? materialChest : creditChest
      const index = dataArray.findIndex((val) => val.info.id === id)
      dataArray.splice(index, 1)
    }

    const unWatch = debouncedWatch(props.chest, () => {
      resetValue()
      setOriginalValue()
      unWatch()
    }, { debounce: 100 })

    expose({
      submitData
    })

    return {
      selectEffectType,
      dynamicSetting,
      creditChest,
      changeEffectData,
      materialChest,
      submitData,
      addMaterial,
      addCredit,
      deleteChestData
    }
  }
})
</script>

<style scoped>
.transition-setting{
  transition: height .3s ease-out;
}
.max-w-670px {
  max-width: 670px;
}
</style>
