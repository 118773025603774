import { render } from "./confirmModal.vue?vue&type=template&id=6e2fba1e"
import script from "./confirmModal.vue?vue&type=script&lang=ts"
export * from "./confirmModal.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6e2fba1e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6e2fba1e', script)) {
    api.reload('6e2fba1e', script)
  }
  
  module.hot.accept("./confirmModal.vue?vue&type=template&id=6e2fba1e", () => {
    api.rerender('6e2fba1e', render)
  })

}

script.__file = "src/plugins/modals/confirmModal.vue"

export default script