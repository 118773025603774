import { EnableGrpcDebugTools, setJkfHost, getJkfHost, JkfHost } from '@/libs/grpc'

// eslint-disable-next-line
// @ts-ignore
import { CommandPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/command_grpc_web_pb'
// eslint-disable-next-line
// @ts-ignore
import { QueryPromiseClient } from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/query_grpc_web_pb'
import * as IamCommandPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/command_pb'
import * as IamQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/query_pb'
import * as IdentityPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/iam/identity_pb'

setJkfHost(JkfHost.ADMIN_GUARDIAN, process.env.VUE_APP_JKF_API_HOST as string)
const service = () => {
  const serviceCommand = new CommandPromiseClient(getJkfHost(JkfHost.ADMIN_GUARDIAN))
  const serviceQuery = new QueryPromiseClient(getJkfHost(JkfHost.ADMIN_GUARDIAN))

  EnableGrpcDebugTools(serviceCommand)
  EnableGrpcDebugTools(serviceQuery)
  return {
    IamCommandService: serviceCommand,
    IamQueryService: serviceQuery
  }
}

const Service = service()

export const CreateIdentity = async(adminToken: string, { displayName, email }: {
  displayName: string
  email: string
}): Promise<IamCommandPB.CmdCreateIdentityRes> => {
  const req: IamCommandPB.CmdCreateIdentityReq = new IamCommandPB.CmdCreateIdentityReq()
  let res: IamCommandPB.CmdCreateIdentityRes
  req.setAdminToken(adminToken)
  const identity = new IdentityPB.Identity()
  identity.setEmail(email)
  identity.setDisplayName(displayName)
  req.setIdentity(identity)
  try {
    res = await Service.IamCommandService.createIdentity(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const UpdateIdentity = async(adminToken: string, { displayName, email }: {
  displayName: string
  email: string
}): Promise<IamCommandPB.CmdEditIdentityRes> => {
  const req: IamCommandPB.CmdEditIdentityReq = new IamCommandPB.CmdEditIdentityReq()
  let res: IamCommandPB.CmdEditIdentityRes
  const identity = new IdentityPB.Identity()
  identity.setEmail(email)
  identity.setDisplayName(displayName)
  req.setIdentity(identity)
  req.setAdminToken(adminToken)
  try {
    res = await Service.IamCommandService.editIdentity(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const DeleteIdentity = async(adminToken: string, { displayName, email }: {
  displayName: string
  email: string
}): Promise<IamCommandPB.CmdDeleteIdentityRes> => {
  const req: IamCommandPB.CmdDeleteIdentityReq = new IamCommandPB.CmdDeleteIdentityReq()
  let res: IamCommandPB.CmdDeleteIdentityRes
  const identity = new IdentityPB.Identity()
  identity.setEmail(email)
  identity.setDisplayName(displayName)
  req.setIdentity(identity)
  req.setAdminToken(adminToken)
  try {
    res = await Service.IamCommandService.deleteIdentity(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const QueryIdentityAll = async(adminToken: string): Promise<IamQueryPB.QueryIdentityAllRes> => {
  const req: IamQueryPB.QueryIdentityAllReq = new IamQueryPB.QueryIdentityAllReq()
  let res: IamQueryPB.QueryIdentityAllRes
  req.setAdminToken(adminToken)
  try {
    res = await Service.IamQueryService.identityAll(req)
    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}
