<template>
  <div class="mb-3">
    <button type="button" class="btn btn-primary w-full md:w-32" @click="addRole">
      新增職位
    </button>
  </div>
  <div>
    <data-table
      :columns="columns"
      :data="tableData"
      :page="page"
      :pageSize="pageSize"
    >
      <template #displayName="{ row }">
        {{ row?.role?.displayName }}
      </template>
      <template #role="{ row }">
        <div class="grid mt-2 grid-cols-3">
          <div class="form-check mt-2" v-for="policy in PolicyList" :key="policy.key">
            <input class="form-check-input checked" type="checkbox" :checked="isCheck(policy, row?.policyList)" disabled />
            <label class="form-check-label text-lg text-[#898989]" >{{ policy.displayName }}</label>
          </div>
        </div>
      </template>
      <template #action="{ row }">
        <button class="btn w-24 mr-1 mb-2 bg-[#3b3b3b] text-white" @click="editRolePolicy(row)">
          編輯
        </button>
      </template>
    </data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { useModal } from '@/plugins/modals/'
import { RoleType, TableColumns } from '@/types'
import { PolicyList } from '@/types/role'
import { GuardianService } from '@/services'
import DataTable from '@/components/data-table/Main.vue'

type ColumnData = { role: RoleType.Role, policyList: RoleType.Policy[] }

export default defineComponent({
  name: 'iamRoue',
  components: {
    DataTable
  },
  setup () {
    const page = ref(1)
    const totalPage = ref<number>(0)
    const pageSize = ref(2)
    const columns = TableColumns.ROLE_COLUMNS
    const { openModal } = useModal('fullscreen-dom')
    const tableData = reactive<ColumnData[]>([])

    const addRole = () => {
      openModal('addRole', { type: 'NEW', confirmFunction: refresh })
    }

    const editRolePolicy = (row: ColumnData) => {
      openModal('addRole', { type: 'EDIT', role: row.role, policyList: row.policyList, confirmFunction: refresh })
    }

    const isCheck = (policy: RoleType.Policy, policyList: RoleType.Policy[]):boolean => {
      return policyList.some(val => val.key == policy.key)
    }

    const refresh = async () => {
      tableData.length = 0
      const data = await GuardianService.getRolesActions()
      data.forEach(val => {
        tableData.push(val)
      })
    }

    onBeforeMount(async () => {
      await refresh()
    })

    return {
      addRole,
      page,
      totalPage,
      pageSize,
      columns,
      tableData,
      PolicyList,
      isCheck,
      editRolePolicy
    }
  }
})
</script>

<style scoped lang="scss">
  .checked:checked {
    background-color: #b2b2b2;
    border-color: transparent;
  }

</style>
