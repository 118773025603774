import { ShopAPI } from '@/api/index'
import { MaterialType } from '@/types/index'
import { loginedStatus } from '@/store/user'
import * as materialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/data/material/query_pb'
import * as adminMaterialPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/store_pb'

export const createShopListMaterial = (item: materialPb.StoreItem): MaterialType.ShopMaterial => {
  const info = item.getInfo()!
  return {
    id: info.getId(),
    displayName: info.getDisplayName(),
    weight: info.getWeight(),
    img: info.getImg(),
    description: info.getDesc(),
    category: MaterialType.grpcTypeToCategory[info.getCategory()],
    creditType: { id: item.getCreditType()!.getId(), displayName: item.getCreditType()!.getDisplayName() },
    creditValue: item.getCreditValue()
  } as MaterialType.ShopMaterial
}

// 兩個結構不一樣Q
export const createShopMaterial = (item: adminMaterialPb.GetInfoStoreRes.StoreItem): MaterialType.ShopMaterial => {
  return {
    id: item.getId(),
    displayName: item.getDisplayName(),
    weight: item.getWeight(),
    img: item.getImg(),
    description: item.getDesc(),
    category: MaterialType.grpcTypeToCategory[item.getCategory()],
    creditType: { id: item.getCreditType()!.getId(), displayName: item.getCreditType()!.getDisplayName() },
    creditValue: item.getCreditValue(),
    stock: item.getStock()
  } as MaterialType.ShopMaterial
}

export const getAllShopItem = async(pageSize: number, page: number): Promise<{ data: Array<MaterialType.ShopMaterial>, totalPage: number }> => {
  const result = await ShopAPI.getAllShopItem(loginedStatus.token, pageSize, page)
  return {
    data: result.getItemList().map((val: materialPb.StoreItem) => createShopListMaterial(val)),
    totalPage: result.getPagination()!.getTotalPage()
  }
}

export const getShopItemInfo = async(ids: Array<string>): Promise<Array<MaterialType.ShopMaterial>> => {
  const result = await ShopAPI.getShopItemInfo(loginedStatus.token, ids)
  return result.getItemList().filter(val => ids.includes(val.getId()))
    .map(val => createShopMaterial(val))
}

export const createShopItem = async (payload: {
  id: string
  creditType: MaterialType.Credit
  creditValue: number
  stock: number
}): Promise<string> => {
  const res: adminMaterialPb.CreateStoreRes = await ShopAPI.create(loginedStatus.token, payload)
  return res.getId()
}

export const updateShopItem = async (payload: {
  id: string
  creditType: MaterialType.Credit
  creditValue: number
  stock: number
}): Promise<string> => {
  const res: adminMaterialPb.UpdateStoreRes = await ShopAPI.update(loginedStatus.token, payload)
  return res.getId()
}

export const softDelete = async(id: string): Promise<string> => {
  const res = await ShopAPI.softDelete(loginedStatus.token, id)
  return res.getId()
}
