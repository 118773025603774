<template>
  <div class="cursor-pointer box relative flex items-center p-5">
    <div class="w-12 h-12 flex-none image-fit mr-1">
      <img
        class="rounded-full"
        :src="newUrl"
        />
    </div>
    <div class="ml-2 overflow-hidden w-full">
      <div class="flex items-center justify-between">
        <span class="font-medium">
          {{ displayName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { FAKE_AVATAR } from '@/utils/index'
import { defineComponent, toRefs, ref, watch } from 'vue'
export default defineComponent({
  name: 'talk-group',
  props: {
    displayName: {
      type: String,
      required: true
    },
    photoURL: {
      type: String,
      required: true
    },
    hashUid: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { photoURL } = toRefs(props)
    const newUrl = ref(FAKE_AVATAR)
    watch(photoURL, () => {
      const img = new Image()
      img.src = photoURL.value
      img.onerror = function () {
        newUrl.value = 'https://www.mymyuc.net/images/noavatar_middle.gif'
      }
      img.onload = function () {
        newUrl.value = photoURL.value
      }
    })
    return {
      newUrl
    }
  }
})
</script>

<style scoped>

</style>
