
import { defineComponent, onMounted, ref } from 'vue'
import { useModal } from '@/plugins/modals/'
import { getDomainAll, getDesignPolicyAllByDomain, DomainState } from '@/store/guardian'
import * as GuardianQueryPB from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/guardian/query_pb'

export default defineComponent({
  setup () {
    const { openModal } = useModal()
    const domainSelValue = ref<null | string>(null)

    const createDesignPolicy = async(e: Event) => {
      e.preventDefault()
      let domainID = ''
      if (!domainSelValue.value) {
        return
      }
      domainID = domainSelValue.value
      openModal('editGuardianDesign',
        {
          mode: 'create',
          designDomainID: domainID,
          done: () => { getDesignPolicyAllByDomain(domainID) }
        }
      )
    }

    const updateDesignPolicy = async(policy: GuardianQueryPB.RolePolicy) => {
      let domainID = ''
      if (!domainSelValue.value) {
        return
      }
      domainID = domainSelValue.value
      openModal('editGuardianDesign',
        {
          mode: 'edit',
          designDomainID: policy.getDomain(),
          designObjectID: policy.getObject(),
          designSummary: policy.getSummary(),
          designActionID: policy.getAction(),
          designExtraValue: policy.getExtraValue(),
          done: () => { getDesignPolicyAllByDomain(domainID) }
        }
      )
    }

    onMounted(async() => {
      await getDomainAll()
      if (DomainState.domainAll.length > 0) {
        domainSelValue.value = DomainState.domainAll[0].getDomainId()
        if (domainSelValue.value) {
          getDesignPolicyAllByDomain(domainSelValue.value)
        }
      }
    })
    return {
      createDesignPolicy,
      updateDesignPolicy,
      DomainState,
      domainSelValue,
      getDesignPolicyAllByDomain
    }
  }
})
