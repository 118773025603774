import { render } from "./Main.vue?vue&type=template&id=3847fcce"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3847fcce"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3847fcce', script)) {
    api.reload('3847fcce', script)
  }
  
  module.hot.accept("./Main.vue?vue&type=template&id=3847fcce", () => {
    api.rerender('3847fcce', render)
  })

}

script.__file = "src/views/medal/manufacture/Main.vue"

export default script