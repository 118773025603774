
import { ref, defineComponent, defineAsyncComponent, watchEffect, toRefs } from 'vue'
import { unrefElement } from '@vueuse/core'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
  name: 'Modal',
  props: ['myProps', 'isShow', 'close', 'component'],
  setup(props) {
    const { component, isShow } = toRefs(props)
    const modal = ref<HTMLElement | null>(null)
    const componentName = defineAsyncComponent(() => import(`./${component.value}.vue`))
    watchEffect(onInvalidate => {
      if (!isShow.value) return

      const overflow = document.documentElement.style.overflow
      const paddingRight = document.documentElement.style.paddingRight
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.paddingRight = `${scrollbarWidth}px`

      const zIndex = $h.findMaxZindex()
      if (unrefElement(modal.value)) {
        unrefElement(modal.value)!.style.zIndex = zIndex.toString()
      }

      onInvalidate(() => {
        document.documentElement.style.overflow = overflow
        document.documentElement.style.paddingRight = paddingRight
      })
    })

    return {
      componentName,
      modal
    }
  }
})
