import { Firebase } from '@/api/index'
import { FirebaseType } from '@/types/index'

export const getFirebaseRoomLists = (uid: string): FirebaseType.FirebaseDoc => {
  return Firebase.$collRef.roomAll.where('ParticipantAll', 'array-contains', uid).orderBy('LastActiveTime', 'desc')
}
export const getRoomLists = async(hashUid: string): Promise<Array<FirebaseType.RoomType>> => {
  const res = await getFirebaseRoomLists(hashUid).get()
  return res.docs.map((doc) => createRoomItem(doc, hashUid))
}

const createRoomItem = (doc: FirebaseType.FirebaseDocData, hashUid: string): FirebaseType.RoomType => {
  const data = doc.data()
  const roomItem: FirebaseType.RoomType = {
    roomId: doc.id,
    type: data.Mode,
    member: data.ParticipantAll.filter((userid: string) => userid !== hashUid)[0],
    senderID: data.ParticipantAll.filter((userid: string) => userid === hashUid)[0]
  }
  return roomItem
}
