import * as adminEffectPb from '@lctech-tw/jkf-admin-proto/dist/js/jkfapi/admin/material/effect_pb'

export enum EffectType {
  EFFECT_TYPE_NONE = 0,
  EFFECT_TYPE_OPEN_ONE = 1,
  EFFECT_TYPE_OPEN_MULTI = 2,
  EFFECT_TYPE_OPEN_ALL = 3,
  EFFECT_TYPE_OPEN_CREDIT = 4,
  EFFECT_TYPE_OPEN_INDEPENDENT = 5
}

export enum ChestType {
  CHEST_TYPE_NONE = 0,
  CHEST_TYPE_MATERIAL = 1,
  CHEST_TYPE_CREDIT_REPUTE = 101,
  CHEST_TYPE_CREDIT_COIN = 102,
  CHEST_TYPE_CREDIT_POINT = 103,
  CHEST_TYPE_CREDIT_HEART = 104,
  CHEST_TYPE_CREDIT_GEM = 105,
  CHEST_TYPE_CREDIT_SUBMIT = 106,
  CHEST_TYPE_CREDIT_STAMINA = 107,
  CHEST_TYPE_CREDIT_INVITE = 108,
}

export type Chest<T> = {
  info: T
  chestType: ChestType
  rate: number
  minCount: number
  maxCount: number
}

export type EffectInfo <T extends { id: string, displayName: string }> = {
  effectType: EffectType
  minKind: number
  maxKind: number
  chest: Array<Chest<T>>
}

export type ChestInterface = {
  chestType: ChestType
  dependId: string
  rate: number
  minCount: number
  maxCount: number
}

export type EffectInterface = {
  materialId: string
  effectType: EffectType
  minKind: number
  maxKind: number
  chest: Array<ChestInterface>
}

export const getChestType = {
  0: ChestType.CHEST_TYPE_NONE,
  1: ChestType.CHEST_TYPE_MATERIAL,
  101: ChestType.CHEST_TYPE_CREDIT_REPUTE,
  102: ChestType.CHEST_TYPE_CREDIT_COIN,
  103: ChestType.CHEST_TYPE_CREDIT_POINT,
  104: ChestType.CHEST_TYPE_CREDIT_HEART,
  105: ChestType.CHEST_TYPE_CREDIT_GEM,
  106: ChestType.CHEST_TYPE_CREDIT_SUBMIT,
  107: ChestType.CHEST_TYPE_CREDIT_STAMINA,
  108: ChestType.CHEST_TYPE_CREDIT_INVITE
}

export const toGrpcChestType = {
  [ChestType.CHEST_TYPE_NONE]: adminEffectPb.ChestType.CHEST_TYPE_NONE,
  [ChestType.CHEST_TYPE_MATERIAL]: adminEffectPb.ChestType.CHEST_TYPE_MATERIAL,
  [ChestType.CHEST_TYPE_CREDIT_REPUTE]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_REPUTE,
  [ChestType.CHEST_TYPE_CREDIT_COIN]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_COIN,
  [ChestType.CHEST_TYPE_CREDIT_POINT]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_POINT,
  [ChestType.CHEST_TYPE_CREDIT_HEART]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_HEART,
  [ChestType.CHEST_TYPE_CREDIT_GEM]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_GEM,
  [ChestType.CHEST_TYPE_CREDIT_SUBMIT]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_SUBMIT,
  [ChestType.CHEST_TYPE_CREDIT_STAMINA]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_STAMINA,
  [ChestType.CHEST_TYPE_CREDIT_INVITE]: adminEffectPb.ChestType.CHEST_TYPE_CREDIT_INVITE
}

export const toGrpcEffectType = {
  [EffectType.EFFECT_TYPE_NONE]: adminEffectPb.EffectType.EFFECT_TYPE_NONE,
  [EffectType.EFFECT_TYPE_OPEN_ONE]: adminEffectPb.EffectType.EFFECT_TYPE_OPEN_ONE,
  [EffectType.EFFECT_TYPE_OPEN_MULTI]: adminEffectPb.EffectType.EFFECT_TYPE_OPEN_MULTI,
  [EffectType.EFFECT_TYPE_OPEN_ALL]: adminEffectPb.EffectType.EFFECT_TYPE_OPEN_ALL,
  [EffectType.EFFECT_TYPE_OPEN_CREDIT]: adminEffectPb.EffectType.EFFECT_TYPE_OPEN_CREDIT,
  [EffectType.EFFECT_TYPE_OPEN_INDEPENDENT]: adminEffectPb.EffectType.EFFECT_TYPE_OPEN_INDEPENDENT
}
